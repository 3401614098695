import { OrderType } from '@glow/entity-types'
import i18next from 'i18next'
import { fromJS, List, Map, Seq, Set } from 'immutable'
import isEqual from 'lodash/isEqual'
import replace from 'lodash/replace'
import split from 'lodash/split'
import toLower from 'lodash/toLower'
import toUpper from 'lodash/toUpper'
import trim from 'lodash/trim'
import { Customer, isStoreHomeDeliveryCustomer } from '../../domain/customer'
import { convert, MeasurementUnit } from '../../domain/measurementUnits'
import { IOptimusService } from '../../domain/optimusService'
import { Consignment, DangerousGoods, Department, Shipment } from '../../types/coreEntitiesTypes'
import { ImmutableMap, ImmutableMapFactory, isNotNullOrUndefined } from '../../types/immutableTypes'
import { isNotEmpty } from '../../utils/collectionUtils'
import { toLocaleDate } from '../../utils/dateTime'
import { isEmpty } from '../../utils/stringUtils'
import {
  AddressProps,
  IAddress,
  IMeasurement,
  INamedPriceListExplanation,
  IOrderForm,
  IOrderFormProps,
  IPriceExplanation,
  ISize,
  LatLng,
  newVAS,
  ValueAddedService
} from './bookingOrderTypes'
import { OrderAction } from './bookingSupport'
import { GroupRecipientProps } from './MultistopGroupForm'
export const DEFAULT_SUBCUSTOMER = ''

// pure functions related to booking order types
function formatPriceExplanationsForCustomerOrResource(explanations: List<INamedPriceListExplanation>): string {
  return explanations
    .map((explanation) => {
      const priceList = explanation.get('priceList')
      const explanations = explanation.get('explanations')
      return (
        '' +
        (priceList
          ? '\t\t' + i18next.t('instant.booking.priceExplanations.priceListName', { pricelist: priceList }) + '\n'
          : '') +
        (explanations
          ? '\t\t' +
            i18next.t('instant.booking.priceExplanations.explanations') +
            '\n' +
            explanations.map((e) => `\t\t\t${e}`).join('\n')
          : '')
      )
    })
    .join('\n')
}

export function formatPriceExplanations(priceExplanations: List<IPriceExplanation>): string {
  return priceExplanations
    .map((e) => {
      const customerExplanations = e.get('customerExplanations')
      const resourceExplanations = e.get('resourceExplanations')
      return (
        i18next.t('instant.booking.priceExplanations.article', { article: e.get('article') }) +
        '\n\tCUSTOMER\n' +
        (customerExplanations && !customerExplanations.isEmpty()
          ? formatPriceExplanationsForCustomerOrResource(customerExplanations)
          : '\t\t' + i18next.t('instant.booking.priceExplanations.noInfo')) +
        '\n\tUNIT\n' +
        (resourceExplanations && !resourceExplanations.isEmpty()
          ? formatPriceExplanationsForCustomerOrResource(resourceExplanations)
          : '\t\t' + i18next.t('instant.booking.priceExplanations.noInfo'))
      )
    })
    .join('\n\n')
}
export function getArticleCodeForPriceExplanation(priceExplanation: IPriceExplanation): string {
  return priceExplanation.get('article')
}
export const isPureVas = (vas: ValueAddedService) => vas.get('pureVas')

export const filterOrderCorrectionVas = (vas: ValueAddedService) =>
  !Set(['WAT PU', 'WAT DEL', 'LUT PU', 'LUT DEL']).contains(vas.get('vasCode', ''))

export function toPureVas(vas: ValueAddedService) {
  return newVAS(vas.get('vasCode'), vas.get('quantity') || '1', true)
}

export function emptyVAS() {
  return newVAS('', '')
}

export function ensureListWithOneItem<T>(list: List<T>, createIfEmpty: () => T): List<T> {
  const nonNull = list ? list : List<T>()
  return nonNull.isEmpty() ? List([createIfEmpty()]) : nonNull
}

export function pureVAS(service: IOptimusService) {
  return isEmpty(service.get('serviceCode')) && isNotNullOrUndefined(service.get('vasCode'))
}

export const bookingFormatLocalDateTime = (date?: string, time?: string) => date && time && `${date}T${time}`

export const getMeasurementVolume = (measurement: IMeasurement): number => {
  const length = parseInt(measurement.get('length'), 10)
  const width = parseInt(measurement.get('width'), 10)
  const height = parseInt(measurement.get('height'), 10)
  if (!(isNaN(length) || isNaN(width) || isNaN(height))) {
    if (length === 0 && width === 0 && height === 0) {
      const volume = parseFloat(measurement.get('volume'))
      return isNaN(volume) ? 0 : volume
    } else {
      return (length * width * height) / 1000
    }
  } else {
    return 0
  }
}

type OrderFormPickupAdressCoordinate = ImmutableMap<
  Partial<Pick<IOrderFormProps, 'pickupAddressLat' | 'pickupAddressLng'>>
>

function getPickupLatLng(values: OrderFormPickupAdressCoordinate) {
  return fromJS(
    !!values.get('pickupAddressLat') && !!values.get('pickupAddressLng')
      ? ({
          lat: values.get('pickupAddressLat'),
          lng: values.get('pickupAddressLng')
        } as LatLng)
      : undefined
  ) as ImmutableMap<LatLng> | undefined
}

type OrderFormDeliveryAdressCoordinate = ImmutableMap<
  Partial<Pick<IOrderFormProps, 'deliveryAddressLat' | 'deliveryAddressLng'>>
>

function getDeliveryLatLng(values: OrderFormDeliveryAdressCoordinate) {
  return fromJS(
    !!values.get('deliveryAddressLat') && !!values.get('deliveryAddressLng')
      ? ({
          lat: values.get('deliveryAddressLat'),
          lng: values.get('deliveryAddressLng')
        } as LatLng)
      : undefined
  ) as ImmutableMap<LatLng> | undefined | LatLng
}

function getLatLng(values: ImmutableMap<GroupRecipientProps>) {
  return fromJS(
    !!values.get('lat') && !!values.get('lng')
      ? ({
          lat: values.get('lat'),
          lng: values.get('lng')
        } as LatLng)
      : undefined
  ) as ImmutableMap<LatLng> | undefined | LatLng
}

export const createPickupAddress = (values: IOrderForm): IAddress => {
  return Map({
    name: values.get('pickupName'),
    address: values.get('pickupAddress'),
    zipArea: values.get('pickupZipArea'),
    zipCode: values.get('pickupZipCode'),
    country: values.get('pickupCountryCode'),
    phone: values.get('pickupPhone'),
    contactPerson: values.get('pickupContactPerson'),
    customerRef: values.get('customerRef'),
    instructions: values.get('pickupInstructions'),
    latLng: getPickupLatLng(values)
  }) as IAddress
}

export const createDeliveryAddress = (values: IOrderForm): IAddress => {
  return Map({
    name: values.get('deliveryName'),
    address: values.get('deliveryAddress'),
    zipArea: values.get('deliveryZipArea'),
    zipCode: values.get('deliveryZipCode'),
    country: values.get('deliveryCountryCode'),
    phone: values.get('deliveryPhone'),
    contactPerson: values.get('deliveryContactPerson'),
    customerRef: values.get('recipientRef'),
    instructions: values.get('deliveryInstructions'),
    latLng: getDeliveryLatLng(values)
  }) as IAddress
}

export const createAddressFromRecipient = (values: ImmutableMap<GroupRecipientProps>): IAddress => {
  return ImmutableMapFactory<AddressProps>({
    name: values.get('name'),
    address: values.get('address'),
    zipArea: values.get('city'),
    zipCode: values.get('zipCode'),
    country: values.get('countryCode'),
    phone: values.get('phone'),
    contactPerson: values.get('contactPerson'),
    instructions: values.get('instructions'),
    latLng: getLatLng(values) as ImmutableMap<LatLng> & LatLng
  }) as IAddress
}

export const stripEmptyPhonePrefix = (phoneNumber: string, prefix: string) =>
  isEqual(trim(phoneNumber), prefix) ? '' : phoneNumber

export const getMeasurementsTotalVolume = (measurements: List<IMeasurement>): number =>
  measurements.map(getMeasurementVolume).reduce((sum, val) => sum + val, 0)

export const getMeasurementsTotalHeight = (measurements: List<IMeasurement>): number => {
  const height = measurements.map((it) => parseInt(it.get('height'), 10)).reduce((sum, val) => sum + val, 0)
  return isNaN(height) ? 0 : height
}

export const getMeasurementsTotalWidth = (measurements: List<IMeasurement>): number => {
  const width = measurements.map((it) => parseInt(it.get('width'), 10)).reduce((sum, val) => sum + val, 0)
  return isNaN(width) ? 0 : width
}

export const getMeasurementsTotalLength = (measurements: List<IMeasurement>): number => {
  const length = measurements.map((it) => parseInt(it.get('length'), 10)).reduce((sum, val) => sum + val, 0)
  return isNaN(length) ? 0 : length
}

export const getMeasurementsTotalWeight = (measurements: List<IMeasurement>): number => {
  const weight = measurements.map((m) => parseFloat(m.get('weight'))).reduce((sum, val) => sum + val, 0)
  return isNaN(weight) ? 0 : weight
}

export const measurements = List<ISize>([
  Map({ id: 0, presetDimension: 'custom', name: 'Custom', weight: '', length: '', width: '', height: '' }),
  Map({ id: 1, presetDimension: 'letter', name: 'Letter', weight: '1', length: '30', width: '42', height: '4' }),
  Map({ id: 2, presetDimension: 'bag', name: 'Bag', weight: '5', length: '20', width: '50', height: '30' }),
  Map({ id: 3, presetDimension: 'smallbox', name: 'Small box', weight: '3', length: '20', width: '10', height: '20' }),
  Map({
    id: 4,
    presetDimension: 'mediumbox',
    name: 'Medium box',
    weight: '10',
    length: '20',
    width: '50',
    height: '30'
  }),
  Map({ id: 5, presetDimension: 'largebox', name: 'Large box', weight: '30', length: '61', width: '35', height: '40' }),
  Map({ id: 6, presetDimension: 'tube', name: 'Tube', weight: '2', length: '80', width: '10', height: '10' }),
  Map({
    id: 12,
    presetDimension: 'quarterpallet',
    name: 'Quarter pallet',
    weight: '50',
    length: '40',
    width: '60',
    height: '50'
  }),
  Map({
    id: 7,
    presetDimension: 'halfpallet',
    name: 'Half pallet',
    weight: '100',
    length: '60',
    width: '80',
    height: '50'
  }),
  Map({
    id: 8,
    presetDimension: 'fullpallet',
    name: 'Full pallet',
    weight: '200',
    length: '80',
    width: '120',
    height: '70'
  }),
  Map({ id: 9, presetDimension: 'suitcase', name: 'Suitcase', weight: '20', length: '80', width: '50', height: '30' }),
  Map({
    id: 10,
    presetDimension: 'movingbox',
    name: 'Moving box',
    weight: '20',
    length: '50',
    width: '25',
    height: '30'
  }),
  Map({
    id: 11,
    presetDimension: 'trolley',
    name: 'Trolley',
    weight: '100',
    length: '120',
    width: '80',
    height: '180'
  })
])

const shdMeasurements = List<ISize>([
  Map({ id: 0, presetDimension: 'custom', name: 'Custom', weight: '', length: '', width: '', height: '' }),
  Map({
    id: 1,
    presetDimension: 'fullpallet',
    name: 'Full pallet',
    weight: '200',
    length: '80',
    width: '120',
    height: '70'
  }),
  Map({ id: 2, presetDimension: 'parcel', name: 'Parcel', weight: '10', length: '20', width: '50', height: '30' })
])

export function defaultBookingMeasurement(): ISize {
  const firstMeasurement = measurements.get(0)

  if (!firstMeasurement) {
    throw new Error('Expected at least one default measurement.')
  }

  return firstMeasurement
}

export function availableMeasurementsForCustomerWithServices(services: List<IOptimusService>) {
  return isStoreHomeDeliveryCustomer(services) ? shdMeasurements : measurements
}

export const shouldShowPalletIcon = (row: Shipment) => {
  if (!row || !row.has('consignments') || !row.get('consignments')) return false
  const presets = row && (row.get('consignments') || Map()).map((it: Consignment) => it.get('presetDimension')).toSet()
  return presets && !presets.intersect(Set(['fullpallet', 'halfpallet', 'quarterpallet', 'trolley'])).isEmpty()
}

export const shouldShowPalletIconOnWeightCheck = (row: Shipment) => {
  if (!row || !row.has('consignments') || !row.get('consignments')) return false
  return (
    row &&
    isNotEmpty(
      (row.get('consignments') || Map()).filter((it: Consignment) => {
        const consignmentWeight = weightInKGM(it.get('weightUnit') || MeasurementUnit.KGM, it.get('weightValue'))
        return consignmentWeight && consignmentWeight > 35
      })
    )
  )
}

export const weightInKGM = (consignmentWeightUnit: string, consignmentWeight: number) => {
  return convert(consignmentWeightUnit, MeasurementUnit.KGM, consignmentWeight)
}

export const vehicleType = List<string>(['bicycle', 'cargobike', 'motorcycle', 'car', 'van', 'truck'])

export const getOptimusXYValue = (param: string, zone: string, qty: number, weight: number): string | undefined => {
  switch (toUpper(param)) {
    case 'ZONE':
      return zone
    case 'QTY':
      return String(qty)
    case 'KG':
      return String(weight)
    default:
      return undefined
  }
}

export const getPhonePrefixFromDepartment = (department?: Department) => {
  switch (toLower(department?.get('country', ''))) {
    case 'fi':
      return '+358'
    case 'se':
      return '+46'
    case 'dk':
      return '+45'
    case 'no':
      return '+47'
    default:
      return ''
  }
}

export const defaultLocationByCountryCode = (countryCode: string) => {
  switch (toUpper(countryCode)) {
    case 'NO':
      return { lat: 59.911525, lng: 10.75425 }
    case 'SE':
      return { lat: 59.336043, lng: 18.072772 }
    case 'FI':
      return { lat: 60.167335, lng: 24.950519 }
  }
  return { lat: 59.911525, lng: 10.75425 }
}

export const replaceEveningAndWeight: (alystraServiceCode: string) => string = (alystraServiceCode: string) =>
  replace(alystraServiceCode, /({WEIGHT})|({EVENING})/, '')

export function formatPickupAddress(shipment: Shipment) {
  const address = shipment.get('pickupAddress') || ''
  const zip = shipment.get('pickupZipCode') || ''
  const city = shipment.get('pickupZipArea') || ''
  return `${address}, ${zip} ${city}`
}

export function formatDeliveryAddress(shipment: Shipment) {
  const address = shipment.get('deliveryAddress') || ''
  const zip = shipment.get('deliveryZipCode') || ''
  const city = shipment.get('deliveryZipArea') || ''
  return `${address}, ${zip} ${city}`
}

export const customerNumberAndSubcustomer = (customerNumber: string, subCustomer?: string | null) =>
  customerNumber + (subCustomer ? '-' + subCustomer : '')

export const getCustomerSubCustomer = (value: string): { customerNumber: string; subcustomer?: string } => {
  const customerParts = split(value, /-(.*)/).map(trim)
  return {
    customerNumber: customerParts[0],
    subcustomer: customerParts.length > 1 ? customerParts[1] : undefined
  }
}

export const getCustomerSuggestionLabel = (suggestion: any) =>
  `${suggestion.name || ''} - ${suggestion.orgNumber || ''} - ${suggestion.alystraId || ''}${
    suggestion.alystraSubcustomer ? '-' + suggestion.alystraSubcustomer : ''
  } - ${suggestion.address || ''}, ${suggestion.zipCode || ''} ${suggestion.zipArea || ''}, ${
    suggestion.customerCode || ''
  }`

export const getCustomerSuggestionLabelWithoutSubcustomer = (suggestion: any) =>
  `${suggestion.name || ''} - ${suggestion.orgNumber || ''} - ${suggestion.alystraId || ''} - ${
    suggestion.address || ''
  }, ${suggestion.zipCode || ''} ${suggestion.zipArea || ''}, ${suggestion.customerCode || ''}`

export const getCustomerLabel = (v: { alystraId: string; alystraSubcustomer?: string }) =>
  customerNumberAndSubcustomer(v.alystraId, v.alystraSubcustomer)

export const getCustomerLabelWithoutSubcustomer = (v: { alystraId: string }) => v.alystraId

export const getDeliveryDateLatest = (deliveryTimeEarliest: string, deliveryTimeLatest: string, pickupDate: string) =>
  toLocaleDate(deliveryTimeEarliest) > toLocaleDate(deliveryTimeLatest)
    ? toLocaleDate(pickupDate).plus({ days: 1 }).toISODate()
    : pickupDate

export const shouldUpdateCustomerAddress = (formValues: IOrderForm, department: Department) =>
  !List.of<keyof IOrderFormProps>(
    'pickupName',
    'pickupAddress',
    'pickupZipCode',
    'pickupZipArea',
    'pickupCountryCode',
    'pickupPhone',
    'pickupContactPerson',
    'pickupInstructions'
  ).some((key) => {
    const value = formValues.get(key)
    const allowedPrefix = key === 'pickupPhone' ? getPhonePrefixFromDepartment(department) : ''
    return value !== null && value !== undefined && value.toString().length > 0 && value !== allowedPrefix
  })

export const userHasFinanceRights = (userRights: string, role: string) =>
  userRights.includes('finance') || role !== 'CUSTOMER'

export const hasPrice = (price: string) => Number(price) > 0

export const formatPrice = (price: string) => (hasPrice(price) ? price : '')

export const anyMandatorySubcustomers = (subcustomers: Map<string, any>): boolean =>
  subcustomers.valueSeq().some((subcustomer) => subcustomer.get('mandatorySubcustomer'))

export const filterSubcustomers = (subcustomers: Map<string, any>, phrase: string) => {
  const mandatorySubcustomers = anyMandatorySubcustomers(subcustomers)
  return isNotEmpty(subcustomers)
    ? subcustomers
        .valueSeq()
        .filter((subcustomer: Customer) => subcustomerMatchesPhrase(subcustomer, phrase))
        .filter((subcustomer: Customer) =>
          mandatorySubcustomers ? subcustomer.get('alystraSubcustomer') !== DEFAULT_SUBCUSTOMER : true
        )
        .sort(sortByKey('alystraSubcustomer', true))
        .toJS()
    : []
}

export const sortByKey = (key: string, isAscending: boolean) => (a: Map<string, any>, b: Map<string, any>) => {
  const first = a.get(key)
  const second = b.get(key)
  const result = first > second ? 1 : second > first ? -1 : 0
  return isAscending ? result : -result
}

export const subcustomerMatchesPhrase = (subcustomer: Customer, phrase: string) =>
  phrase ? subcustomer.get('alystraSubcustomer').toUpperCase().includes(phrase.toUpperCase()) : true

export const formValuesToGroupRecipient = (values: IOrderForm, phonePrefix?: string) => {
  const phone = phonePrefix
    ? stripEmptyPhonePrefix(values.get('deliveryPhone'), phonePrefix)
    : values.get('deliveryPhone')
  const secondPhone = values.get('deliverySecondPhone')
  const secondPhoneStripped = phonePrefix && secondPhone ? stripEmptyPhonePrefix(secondPhone, phonePrefix) : secondPhone

  return ImmutableMapFactory<GroupRecipientProps>({
    name: values.get('deliveryName'),
    address: values.get('deliveryAddress'),
    city: values.get('deliveryZipArea'),
    zipCode: trim(values.get('deliveryZipCode')),
    countryCode: values.get('deliveryCountryCode'),
    phone: phone,
    secondPhone: secondPhoneStripped,
    contactPerson: values.get('deliveryContactPerson'),
    instructions: values.get('deliveryInstructions'),
    reference: values.get('recipientRef'),
    lat: values.get('deliveryAddressLat'),
    lng: values.get('deliveryAddressLng'),
    email: values.get('deliveryEmail')
  })
}

export const formValuesToGroupPickup = (values: IOrderForm, phonePrefix?: string) => {
  const phone = phonePrefix ? stripEmptyPhonePrefix(values.get('pickupPhone'), phonePrefix) : values.get('pickupPhone')
  const secondPhone = values.get('pickupSecondPhone')
  const secondPhoneStripped = phonePrefix && secondPhone ? stripEmptyPhonePrefix(secondPhone, phonePrefix) : secondPhone

  return ImmutableMapFactory<GroupRecipientProps>({
    name: values.get('pickupName'),
    address: values.get('pickupAddress'),
    city: values.get('pickupZipArea'),
    zipCode: trim(values.get('pickupZipCode')),
    countryCode: values.get('pickupCountryCode'),
    phone: phone,
    secondPhone: secondPhoneStripped,
    contactPerson: values.get('pickupContactPerson'),
    instructions: values.get('pickupInstructions'),
    reference: values.get('customerRef'),
    lat: values.get('pickupAddressLat'),
    lng: values.get('pickupAddressLng'),
    email: values.get('pickupEmail')
  })
}

export const getIndicatorText = (actionType: OrderAction) => {
  switch (actionType) {
    case OrderAction.Copy:
      return 'instant.booking.copy'
    case OrderAction.Edit:
      return 'instant.booking.update'
    case OrderAction.Create:
      return 'instant.booking.create'
    case OrderAction.Restore:
      return 'restore'
  }
}

export const isEditOrRestore = (actionType: OrderAction) =>
  actionType == OrderAction.Edit || actionType == OrderAction.Restore

export const isEdit = (actionType: OrderAction) => actionType == OrderAction.Edit

export const createDangerousGoodsSet = (dangerousGoods: List<DangerousGoods> | undefined = List([])) => {
  return dangerousGoods
    .map((goods) => ({
      regulationCode: goods.get('regulationCode'),
      undgHazardIdentifier: goods.get('hazardIdentifier'),
      hazardClass: goods.get('hazardClass'),
      packingGroup: goods.get('packingGroup'),
      technicalName: goods.get('technicalName'),
      hazardPoints: goods.get('hazardPoints'),
      measures: [
        {
          first: 'HazardNetWeight',
          second: {
            unit: 'KGM',
            value: goods.get('netWeightKg')
          }
        }
      ],
      restrictions: goods.get('restrictions')
    }))
    .toJS()
}

export const createConsignmentSet = (
  values: IOrderForm,
  shipmentId: string,
  packageIds: number[],
  service: IOptimusService | null,
  pickupPhone: string,
  pickupSecondPhone: string | null,
  pickup: ImmutableMap<GroupRecipientProps>,
  deliveryPhone: string,
  deliverySecondPhone: string | null,
  recipient: ImmutableMap<GroupRecipientProps>,
  groupIndex: number,
  legSeqNumber: number | null | undefined,
  multilegId: number | null,
  groupId: string | null,
  groupName: string | null,
  orderAction: OrderAction,
  consignments: Seq.Indexed<Consignment> | undefined,
  orderType: OrderType | null,
  scanToCreatePackages: boolean,
  orderId?: number,
  dangerousGoods?: List<DangerousGoods>
) => {
  const serviceVasCode = service?.get('vasCode')
  const valueAddedServices = serviceVasCode ? List([newVAS(serviceVasCode, '1')]) : List()
  const pureValueAddedServices = valueAddedServices.concat(
    values
      .get('additionalServices')
      .filter((s) => s.get('vasCode'))
      .map(toPureVas)
  )
  const measurements = values.get('measurements')
  const customerParts = getCustomerSubCustomer(values.get('customerId'))
  const subcustomer = values.get('subcustomer') || customerParts.subcustomer
  const emailRecipients = {
    emailId: values.get('emailId'),
    createdEvent: values.get('createdEvent'),
    collectedEvent: values.get('collectedEvent'),
    deliveredEvent: values.get('deliveredEvent')
  }
  const fixedOrder = values.get('fixedOrder') || false
  const deliveryDateLatest = getDeliveryDateLatest(
    values.get('deliveryTimeEarliest'),
    values.get('deliveryTimeLatest'),
    values.get('pickupDate')
  )
  const nextPackageId = () => {
    const next = packageIds.pop()
    // all scanToCreatePackages should be placeholder-packages
    return scanToCreatePackages ? `temp-${next}` : `${next}`
  }
  const consignment = consignments?.get(0)
  return {
    updateIndicator: getIndicatorText(orderAction),
    legSeqNumber,
    multilegId,
    overriddenDepartmentId: values.get('selectedDepartmentId') || null,
    shipmentId,
    orderId,
    groupId,
    groupName,
    groupOrder: isEditOrRestore(orderAction) ? values.get('groupOrder') : fixedOrder ? groupIndex : null,
    paidTogether: values.get('paidTogether'),
    vehicleType: values.get('vehicleType'),
    emailRecipients: isEmpty(emailRecipients.emailId) ? undefined : List([emailRecipients]),
    note: values.get('orderNote'),
    controlTotals: {
      weight: { value: getMeasurementsTotalWeight(measurements), unit: MeasurementUnit.KGM },
      volume: { value: getMeasurementsTotalVolume(measurements), unit: MeasurementUnit.DMQ },
      dimensions: {
        height: { value: getMeasurementsTotalHeight(measurements), unit: MeasurementUnit.CMT },
        length: { value: getMeasurementsTotalLength(measurements), unit: MeasurementUnit.CMT },
        width: { value: getMeasurementsTotalWidth(measurements), unit: MeasurementUnit.CMT }
      }
    },
    orderType: orderType,
    dangerousGoods: createDangerousGoodsSet(dangerousGoods),
    correctedArticlePrices: values.get('correctedArticlePrices'),
    allowIncompletePrice: values.get('allowIncompletePrice'),
    hasZeroPriceApproval: values.get('hasZeroPriceApproval'),
    needsZeroPriceApproval: values.get('needsZeroPriceApproval'),
    calculatedArticlePrices: values.get('calculatedArticlePrices'),
    consignments: measurements.map((measurement) => ({
      serviceCode: service?.get('serviceCode'),
      alystraServiceCode: service?.get('alystraServiceCode'),
      additionalServiceCodes: pureValueAddedServices.toJS(),
      packageId: isEditOrRestore(orderAction) ? measurement.get('packageId') || nextPackageId() : nextPackageId(),
      referenceNo: values.get('customerRef'),
      recipientRef: recipient.get('reference'),
      packageTypeCode: consignments
        ?.find((c) => c.get('packageId') === measurement.get('packageId'))
        ?.get('packageTypeCode'),
      customerTotalPrice: consignment?.get('customerTotalPrice'),
      carrierTotalPrice: consignment?.get('carrierTotalPrice'),
      customerContact: values.get('customerContact'),
      consignor: {
        partyId: customerParts.customerNumber,
        alystraPartyId: customerParts.customerNumber,
        subPartyId: subcustomer,
        name: pickup.get('name'),
        address: pickup.get('address'),
        city: pickup.get('city'),
        postalCode: trim(pickup.get('zipCode')),
        countryCode: pickup.get('countryCode'),
        phone: pickupSecondPhone,
        cellPhone: pickupPhone,
        contactPerson: pickup.get('contactPerson'),
        email: pickup.get('email'),
        lat: pickup.get('lat'),
        lng: pickup.get('lng')
      },
      consignee: {
        name: recipient.get('name'),
        address: recipient.get('address'),
        city: recipient.get('city'),
        postalCode: trim(recipient.get('zipCode')),
        countryCode: recipient.get('countryCode'),
        phone: deliverySecondPhone,
        cellPhone: deliveryPhone,
        contactPerson: recipient.get('contactPerson'),
        email: recipient.get('email'),
        lat: recipient.get('lat'),
        lng: recipient.get('lng')
      },
      preplannedUnitId: values.get('preplannedUnitId'),
      pickupDateEarliest: bookingFormatLocalDateTime(values.get('pickupDate'), values.get('pickupTimeEarliest')),
      pickupDateLatest: bookingFormatLocalDateTime(values.get('pickupDate'), values.get('pickupTimeLatest')),
      pickupInstructions: pickup.get('instructions') || '',
      deliveryDateEarliest: bookingFormatLocalDateTime(values.get('pickupDate'), values.get('deliveryTimeEarliest')),
      deliveryDateLatest: bookingFormatLocalDateTime(deliveryDateLatest, values.get('deliveryTimeLatest')),
      deliveryInstructions: recipient.get('instructions') || '',
      specificationDescription: measurement.get('specificationDescription'),
      manualInvoiceInformation: values.get('manualInvoiceInfo'),
      invoiceInformation: values.get('invoiceInfo'),
      packageMeasures: {
        presetDimension: measurement.get('presetDimension'),
        weight: { value: measurement.get('weight'), unit: MeasurementUnit.KGM },
        volume: { value: getMeasurementVolume(measurement), unit: MeasurementUnit.DMQ },
        dimensions: {
          height: { value: measurement.get('height'), unit: MeasurementUnit.CMT },
          length: { value: measurement.get('length'), unit: MeasurementUnit.CMT },
          width: { value: measurement.get('width'), unit: MeasurementUnit.CMT }
        }
      }
    }))
  }
}

export const sortByName = (a: Map<string, any>, b: Map<string, any>) => {
  const first = a.get('name', '')?.toLowerCase()
  const second = b.get('name', '')?.toLowerCase()
  return first > second ? 1 : second > first ? -1 : 0
}

export const prepareClearComponentMap = (component: string, department: Department) => {
  let res = Map<string, any>()
  if (component === 'pickupPhone' || component === 'deliveryPhone') {
    return res.set(component, getPhonePrefixFromDepartment(department))
  } else if (component === 'measurements') {
    return res.set(component, measurements.filter((row: ISize) => row.get('id') == 0).map(sizeToMeasurement))
  } else if (component === 'additionalServices') {
    return res.delete(component).updateIn([component], () => List([emptyVAS()]))
  }
  return res.set(component, '')
}

const sizeToMeasurement = (size: ISize): IMeasurement =>
  Map({
    weight: size.get('weight'),
    height: size.get('height'),
    length: size.get('length'),
    width: size.get('width'),
    presetDimension: size.get('presetDimension'),
    specificationDescription: '',
    volume: ''
  })
