import { LeafletMap } from '@glow/molecule-components'
import i18next from 'i18next'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { UNRESOLVE_DELIVERY_ADDRESS, UNRESOLVE_PICKUP_ADDRESS } from '../../../actions/actionTypes'
import {
  getUnresolvedAddressCount,
  unresolveDeliveryAddress,
  unresolvePickupAddress
} from '../../../actions/creators/helpers'
import { TertiaryButton } from '../../../primitives/Button'
import { ErrorMessages } from '../../../primitives/ErrorMessages'
import { Label } from '../../../primitives/Forms'
import { H3 } from '../../../primitives/Headings'
import { useAppDispatch } from '../../../reducers/redux-hooks'
import { errorMessagesFor } from '../../../selectors/httpStatusSelectors'
import variables from '../../../styles/variables'
import { Shipment } from '../../../types/coreEntitiesTypes'
import { AppStateType } from '../../../utils/appStateReduxStore'
import { concat, isNotEmpty } from '../../../utils/collectionUtils'
import { ConsignmentState } from '../../../utils/consignmentState'
import { isGroupId } from '../../../utils/departmentAndDepartmentGroupUtils'
import { ShipmentMarker } from '../../map-common/LeafletMarkersWithVariants'
import { useShipmentBounds } from '../../map-common/LeafletUtils'
import { FadingNotification } from '../shared/Styles'

export const ShipmentMap: React.FC<{
  shipment: Shipment
  departmentOrGroupId?: string
  allowUnresolving?: boolean
}> = ({ shipment, departmentOrGroupId, allowUnresolving }) => {
  const dispatch = useAppDispatch()
  const [submitting, setSubmitting] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const mapBounds = useShipmentBounds(shipment)

  const showUnresolvingComponent = allowUnresolving && shipment.get('state') === ConsignmentState.RESOLVED
  const mapUnavailable =
    shipment.get('state') === ConsignmentState.UNRESOLVED &&
    (!shipment.get('deliveryLat') || !shipment.get('deliveryLng'))

  const errorMessages = useSelector((state: AppStateType) =>
    concat(errorMessagesFor(state, UNRESOLVE_PICKUP_ADDRESS), errorMessagesFor(state, UNRESOLVE_DELIVERY_ADDRESS))
  )

  const departmentId = !departmentOrGroupId || isGroupId(departmentOrGroupId) ? undefined : Number(departmentOrGroupId)

  const handleSuccess = () => {
    getUnresolvedAddressCount(departmentId)(dispatch)
    setShowNotification(true)
  }

  const onUnresolveDeliveryAddress = () => {
    setSubmitting(true)
    unresolveDeliveryAddress(shipment.get('orderId'))(dispatch)
      .then(
        (res) => (res.ok ? handleSuccess() : res),
        (err) => console.error('unresolveDeliveryAddress', err)
      )
      .finally(() => setSubmitting(false))
  }

  const onUnresolvePickupAddress = () => {
    setSubmitting(true)
    unresolvePickupAddress(shipment.get('orderId'))(dispatch)
      .then(
        (res) => (res.ok ? handleSuccess() : res),
        (err) => console.error('unresolvePickupAddress', err)
      )
      .finally(() => setSubmitting(false))
  }

  const UnresolveShipment = () => (
    <div style={{ margin: '0 0 1rem 1px' }}>
      <H3 size="s">{i18next.t('consignment.wrongCoordinates')}</H3>
      <Label wide fontWeight={400} color={variables.newColors.darkGrey}>
        {i18next.t('consignment.moveShipmentToUnresolved')}
      </Label>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', paddingRight: '1px' }}>
        <TertiaryButton
          onClick={onUnresolvePickupAddress}
          disabled={submitting}
          style={{ minHeight: variables.inputs.height, height: 'auto' }}
        >
          {i18next.t('consignment.unresolvePickupAddress')}
        </TertiaryButton>
        <TertiaryButton
          marginLeft
          onClick={onUnresolveDeliveryAddress}
          disabled={submitting}
          style={{ minHeight: variables.inputs.height, height: 'auto' }}
        >
          {i18next.t('consignment.unresolveDeliveryAddress')}
        </TertiaryButton>
      </div>
    </div>
  )

  return (
    <>
      {isNotEmpty(errorMessages) && <ErrorMessages errorMessages={errorMessages} />}
      {showNotification && <FadingNotification>{i18next.t('consignment.unresolvedSuccessfully')}</FadingNotification>}
      {showUnresolvingComponent && <UnresolveShipment />}
      <LeafletMap bounds={mapBounds} style={{ marginTop: '1rem', height: '400px', width: '100%', background: '#eee' }}>
        {ShipmentMarker(shipment)}
      </LeafletMap>
      {mapUnavailable && (
        <Label wide fontWeight={400} color={variables.newColors.darkGrey} noMargin>
          {i18next.t('consignment.mapUnavailable')}
        </Label>
      )}
    </>
  )
}
