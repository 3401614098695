const en = {
  language: 'en',
  userRoles: {
    admin: 'Admin',
    planner: 'Normal',
    read_only_planner: 'Read Only',
    plus_planner: 'Plus',
    partner: 'Partner',
    handler: 'Handler',
    customer: 'Customer',
    customs_agent: 'Customs agent'
  },
  welcomeToGlow: 'Welcome to Glow',
  welcomeToGlowDescription: 'Use the navigation to the left to start your work!',
  application: {
    all: 'All',
    created: 'Created',
    lastUpdated: 'Last updated',
    requiredLocation: 'Location is required',
    recipient: 'Recipient',
    save: 'Save',
    update: 'Update',
    search: 'Search...',
    required: '*Required field',
    requiredShort: '*Required',
    logOut: 'Log out',
    proceed: 'Proceed',
    courier: 'Courier',
    couriers: 'Couriers',
    consignments: 'Shipments',
    status: 'Status',
    address: 'Address',
    estimated: 'Estimated',
    estimatedDepartureTime: 'ETD',
    adjusted: 'Adjusted',
    actual: 'Actual',
    funeral: 'Funeral',
    tracking: 'Tracking',
    trackingPage: 'Recipient tracking page',
    delivery: 'Delivery',
    phoneNumberShort: 'Phone',
    yes: 'Yes',
    no: 'No',
    off: 'Off',
    sms: 'SMS',
    httpErrorToString: 'HTTP error: code {{status}} {{statusText}}',
    anErrorOccurred: 'An error occurred!',
    noAccessTitle: 'No access',
    noAccessMessage: 'You are not allowed to use this functionality',
    autoComplete: 'Search Places ...',
    autoCompleteWithoutDots: 'Search places',
    totalWeight: 'Total weight',
    totalVolume: 'Total volume',
    validDeviationCode: 'Please enter a valid value',
    backToNewSite: 'Back to new site',
    title: {
      siteName: 'Glow',
      root: 'Welcome',
      planner: 'Planner',
      admin: 'Admin',
      plannerDashboard: 'Planner - Dashboard',
      plannerLive: 'Planner - Live',
      plannerLiveSlot: 'Planner - Live - Slot',
      plannerCouriers: 'Planner - Couriers',
      plannerUnits: 'Planner - Units',
      plannerImport: 'Planner - Import',
      plannerUnresolved: 'Planner - Unresolved',
      plannerConsignments: 'Planner - Shipments',
      plannerSearch: 'Planner - Search',
      plannerPlanning: 'Planner - Planning',
      waybills: 'Planner - Waybills',
      deliveryNotes: 'Planner - Delivery Notes',
      plannerInvoicing: 'Planner - Invoicing',
      plannerCreateBillingOrder: 'Planner - Create billing order',
      plannerEditBillingOrder: 'Planner - Edit billing order',
      plannerCreateCreditNote: 'Planner - Create credit note',
      plannerVehicle: 'Planner - Vehicles',
      report: 'Reports',
      customerDepartmentLive: 'Live view',
      recipient: 'Package tracking - tracking - Bring',
      instant: 'Instant',
      instantBooking: 'Booking',
      h2Booking: 'H2 Booking',
      customerPortal: 'Customer Portal',
      orderConfirmation: 'Order Confirmation',
      instantMap: 'Instant map',
      instantMap2: '$t(application.title.instantMap)',
      recurringOrder: 'Recurring orders',
      corrections: 'Order corrections',
      jobs: 'Jobs Monitoring',
      holidays: 'Holidays',
      shipmentLabels: 'Shipment Labels',
      addresses: 'Addresses',
      shd: 'SHD',
      preadvice: 'Preadvice',
      apollo: 'Apollo',
      airexpress: 'Air Express',
      airexpressBooking: 'Air Express Booking',
      airexpressBookingCopy: 'Air Express Booking Copy',
      airexpressBookingEdit: 'Air Express Booking Edit',
      airExpressBookingConfirmation: 'Order confirmation',
      airexpressCustoms: 'Air Express Dutiable Shipments',
      airexpressImportData: 'SSIM Import',
      airexpressExportData: 'Export AirExpress data',
      airexpressAirports: 'Airports',
      airexpressAirlines: 'Airlines',
      airexpressPreadvice: 'Preadvice',
      airexpressShipments: 'Air Express Shipments',
      airexpressManifest: 'Air Express Manifests',
      airexpressAdminHandlerUsers: 'Air Express Handler users',
      airexpressLocallyAddedFlightSchedules: 'Edit flight schedules',
      airexpressAdminSearchFlights: 'Flight Lookup',
      airexpressRecurringOrder: 'Air Express Recurring Orders',
      airexpressInvoicing: 'Air Express Invoicing',
      airexpressAdminCustomsAgent: 'Air Express Customs Agent',
      routeReceiptList: 'Receipt list',
      loadList: 'Load List',
      pickupOrders: 'Pickup orders',
      airexpressAdminTerminals: 'Terminals',
      customerBooking: 'Customer booking',
      manageReturns: 'Manage Returns',
      preAdviceFollowup: 'Manage preadvice'
    },
    language: {
      en: 'English',
      nb: 'Norwegian',
      sv: 'Swedish',
      dk: 'Danish',
      fi: 'Finnish',
      fr: 'French'
    },
    country: {
      AllCountry: 'All countries',
      Country: 'Country',
      AD: 'Andorra',
      AT: 'Austria',
      BE: 'Belgium',
      BA: 'Bosnia and Herzegovina',
      BG: 'Bulgaria',
      HR: 'Croatia',
      CZ: 'Czechia',
      DK: 'Denmark',
      GB: 'United Kingdom',
      EE: 'Estonia',
      FO: 'Faroe Islands',
      FI: 'Finland',
      FR: 'France',
      DE: 'Germany',
      GI: 'Gibraltar',
      GR: 'Greece',
      HU: 'Hungary',
      IS: 'Iceland',
      IE: 'Ireland',
      IT: 'Italy',
      LV: 'Latvia',
      LT: 'Lithuania',
      LU: 'Luxembourg',
      MK: 'North Macedonia',
      MT: 'Malta',
      MC: 'Monaco',
      ME: 'Montenegro',
      NL: 'Netherlands',
      NO: 'Norway',
      PL: 'Poland',
      PT: 'Portugal',
      RO: 'Romania',
      RU: 'Russia',
      SM: 'San Marino',
      RS: 'Serbia',
      SK: 'Slovakia',
      SI: 'Slovenia',
      ES: 'Spain',
      SE: 'Sweden',
      CH: 'Switzerland',
      UA: 'Ukraine'
    },
    timeDistance: 'Planned time and distance',
    driverReturnToAddress: "Don't specify a return address",
    plannedTime: 'Planned Time',
    plannedDistance: 'Planned distance',
    plannedWeight: 'Planned weight',
    distance: 'Distance',
    helpLink: '(What is this?)',
    selectFromDate: 'Select from date',
    none: 'None',
    noOptions: 'No options',
    selectAll: 'Select all',
    allSelected: 'All selected',
    someSelected: '{{count}} selected',
    noneSelected: 'None selected',
    appBuild: 'App build',
    lastLogin: 'Last login',
    device: 'Device',
    model: 'Model',
    os: 'Os',
    units: 'Units'
  },
  serviceLevel: {
    label: 'Downgrade service level',
    ingress:
      'Set a default email address that can receive a notification on order registration, pickup and/or delivery.',
    notSet: 'Not set',
    none: 'Off',
    standard: 'Standard',
    premium: 'Premium'
  },
  simpleSearch: {
    link: 'Link'
  },
  dropdown: {
    selectedCustomers: '{{count}} customer',
    selectedCustomers_plural: '{{count}} customers',
    allCustomers: 'All customers',
    filterCustomers: 'Filter customers',
    selectedDepartments: '{{count}} department',
    selectedDepartments_plural: '{{count}} departments',
    allDepartments: 'All departments',
    filterDepartments: 'Filter departments',
    selectedServiceCodes: '{{count}} service',
    selectedServiceCodes_plural: '{{count}} services',
    allServiceCodes: 'All services',
    filterServiceCodes: 'Filter services',
    selectedVasCodes: '{{count}} vas',
    selectedVasCodes_plural: '{{count}} vases',
    allVasCodes: 'All vases',
    filterVasCodes: 'Filter vases',
    selectedDestinations: '{{count}} destination',
    selectedDestinations_plural: '{{count}} destinations'
  },
  button: {
    edit: 'Edit',
    back: 'Back',
    cancel: 'Cancel',
    done: 'Done',
    clear: 'Clear',
    clearAll: 'Clear all',
    close: 'Close',
    save: 'Save',
    saveAndPublish: 'Save and Publish',
    delete: 'Delete',
    export: 'Export',
    new: 'Create new',
    search: 'Search',
    add: 'Add',
    remove: 'Remove',
    assign: 'Assign',
    fullEdit: 'Full edit',
    confirm: 'Confirm',
    continue: 'Continue',
    copy: 'Copy'
  },
  consignment: {
    includePreAdvice: 'Preadvice',
    onlyFailedPreAdvice: 'Preadvice failed',
    onlyReturnPreAdvice: 'Return to sender',
    consignmentDetails: 'Package details',
    search: 'Shipment search',
    noShipmentsFound: 'No shipments found',
    clickButtonToSearch: 'Click the search button to search',
    consignmentsForDate: 'Shipments {{date}}',
    showOnlyEditable: 'Only editable',
    showOnlyNotAssigned: 'Not assigned',
    withoutDate: 'Without date',
    extendedSearch: 'Extended Search',
    showDeleted: 'Show deleted',
    showDeviated: 'Show deviated',
    deletedBy: 'Deleted by',
    deletionReason: 'Reason',
    numberOfConsignments: 'Found {{number}} shipments',
    showAll: 'All',
    delivery: 'Delivery',
    pickup: 'Pickup',
    pickups: '{{count}} pickup',
    pickups_plural: '{{count}} pickups',
    edit: 'Edit',
    deleteOrders: 'Delete',
    duplicateOrder: 'Duplicate',
    duplicateConfirmation: 'Are you sure you want to duplicate this order?',
    orderDuplicated: 'Order has been duplicated',
    orderDuplicatedFailed: 'Unable to duplicate order',
    copyOrder: 'Copy',
    editOrder: 'Edit',
    cantDeleteOfferedOrders: "You can only delete upcoming orders that haven't been added to a route yet",
    cantEditOfferedOrders: "You can only edit upcoming orders that haven't been added to a route yet",
    onlyCopyOne: 'You can only copy one order at once',
    onlyEditOne: 'You can only edit one order at once',
    deletedOrderNotCopyable: 'Deleted order cannot be copied because of insufficient order details in Glow',
    restoreOrder: 'Restore',
    deletedOrderNotRestorable: 'Deleted order cannot be restored because of insufficient order details in Glow',
    preplan: 'Preplan',
    deleteConfirmation: 'Are you sure you want to delete this shipment ?',
    deleteMultipleConfirmation: 'Are you sure you want to delete selected shipments ?',
    copyConfirmation: 'Are you sure you want to copy this shipment ?',
    editConsignment: 'Edit shipment information',
    changeDateAndTime: 'Change date and time',
    searchShipments: 'Search shipments:',
    searchCustomers: 'Search customers:',
    linkToNewSearchPage:
      '<0>Try out</0> the new search page, with improved search functions and new design. The page is under development and new functions will be added. <1>Read more on Zendesk</1>',
    filterBy: 'Filter by:',
    limitedShipmentsShownMessage: 'Showing a maximum of {{count}} shipments, consider narrowing your search',
    limitedUnresolvedAddressesShownMessage: 'Showing a maximum of {{count}} unresolved addresses',
    bulkActions: 'Bulk Actions',
    bulkActionsMultiple: 'Bulk Actions ({{count}})',
    moveShipmentsToDifferentDepartment: 'Move to different department',
    addToRecurringGroup: 'Add to recurring group',
    addEvents: 'Add events',
    disabledEditDeleteHelperText:
      'This order cannot be edited or deleted, please contact your local Bring office to change the order.',
    editPickupConsignment: 'Update pickup information',
    editDeliveryConsignment: 'Update delivery information',
    editPickupAndDeliveryTime: 'Update pickup and delivery times',
    editConsignmentLocation: ' Location',
    editConsignmentTime: ' Time',
    editConsignmentName: ' Name',
    editConsignmentPhone: ' Phone number',
    editConsignmentInstructions: ' Instructions',
    earliestPickupTime: 'Earliest pickup time *',
    latestPickupTime: 'Latest pickup time *',
    earliestDeliveryTime: 'Earliest delivery time',
    latestDeliveryTime: 'Latest delivery time',
    enterPickupInstructions: 'Enter pickup instructions',
    enterDeliveryInstructions: 'Enter delivery instructions',
    enterDeliveryName: 'Enter delivery name',
    enterPickupName: 'Enter pickup name',
    enterPickupPhone: 'Enter pickup phone number',
    enterDeliveryPhone: 'Enter delivery phone number',
    specificationSize: 'Specification size',
    enterSpecificationSize: 'Enter specification size',
    editSpecificationSize: 'Edit specification size',
    enterSpecificationDescription: 'Enter description',
    editSpecificationDescription: 'Edit description',
    editPickupInfoDescription: 'To be able to edit info you must choose either of the above options.',
    updateLocationFor: 'Update information for {{count}} shipments',
    updateLocationFor_plural: 'Update pickup information for {{count}} shipments',
    comment: 'Comment',
    show: 'Show',
    filter: 'Filter shipments',
    state: 'State',
    estimatedDelivery: 'Estimated delivery',
    requestedTimePickup: 'Requested pickup time',
    requestedTimeDelivery: 'Requested delivery time',
    timeNotSet: 'Not set',
    customerReference: 'Customer reference',
    shipmentId: 'Shipment ID',
    packageId: 'Package ID',
    noCourier: 'No courier',
    noSlot: 'No slot',
    showEvents: 'Show events →',
    hideEvents: 'Hide events ←',
    events: 'Events',
    send: 'Send',
    sendMessage: 'Send message',
    sendCommentFailed: 'Comment save failed',
    recipient: 'Recipient',
    unresolved: 'Unresolved shipments',
    description: 'Description',
    pickupAddress: 'Pickup address',
    numberOfPackages: 'No of packages',
    registeredPickupAddress: 'Registered pickup address',
    pickupZipCode: 'Zip code',
    pickupZipArea: 'Area',
    pickupLocation: 'Location',
    deliveryAddress: 'Delivery address',
    deliveryZipCode: 'Zip code',
    deliveryZipArea: 'Area',
    deliveryLocation: 'Location',
    registeredDeliveryAddress: 'Registered delivery address',
    updated: 'Shipment has been updated',
    moved: 'Shipment has been moved',
    unresolvedMessage: "There is {{count}} shipment where we can't locate the address, please add it.",
    unresolvedMessage_plural: "There are {{count}} shipments where we can't locate the address, please add them.",
    foundUnresolvedMessage:
      'We have found the following valid addresses for this shipment. If they are correct, press Update. Otherwise please find the correct address in the below map.',
    remove: 'Remove',
    alterSlot: 'Alter slot (without optimizing)',
    alterSlotAndReoptimize: 'Alter slot (optimize new slot with same order)',
    alterSlotNotAvailable: 'Not available while optimizing',
    optimizingNewSlot: 'Optimizing shipment in new slot',
    selectSlot: 'Select new slot',
    slotSelect: 'Choose slot',
    confirmSlotChange: 'Confirm slot change',
    pickupInstructions: 'Pickup instructions',
    deliveryInstructions: 'Delivery instructions',
    specificationDescription: 'Description',
    tracking: 'Tracking',
    recipientPhone: 'Recipient phone number',
    customerRef: 'Customer ref',
    service: 'Service',
    message: {
      CREATED: 'Created',
      ASSIGNED: 'Assigned to route',
      UNASSIGNED: 'Unassigned',
      COLLECTED: 'Collected',
      DELIVERED: 'Delivered',
      DEVIATED: 'Deviated',
      COMMENT: 'Comment',
      RESOLVED: 'Resolved',
      UNRESOLVED: 'Unresolved',
      OFFERED: 'Offered',
      REJECTED: 'Rejected',
      ACCEPTED: 'Accepted',
      RETURNED: 'Returned',
      DELETED: 'Deleted',
      UNCONFIRMED: 'Unconfirmed',
      REFUSED: 'Refused'
    },
    manualOverrideTitle: 'Manual override',
    adjustTabTitle: 'Adjust',
    bookingPanelTitle: 'Booking',
    mapPanelTitle: 'Map',
    recipientName: 'Recipient name',
    receivedBy: 'Received by:',
    manuallyChangeLocations: 'Manually change locations',
    manualOverrideDeviationTimeConfirm: 'Confirm manual override of deviation of selected shipment',
    manualOverrideDeliveryTimeConfirm: 'Confirm manual override of delivery of selected shipment',
    onManuallyChangeLocationsConfirm: 'Are you sure you want to make the shipment unresolved?',
    unresolveDeliveryAddress: 'Unresolve delivery address',
    unresolvePickupAddress: 'Unresolve pickup address',
    unresolveAddress: 'Unresolve address',
    reference: 'Reference',
    shipmentIdNumber: 'No',
    shipmentDetails: 'Shipment details',
    customer: 'Customer',
    subcustomer: 'Customer no',
    dateAndTime: 'Date and times',
    filterCustomer: 'Filter customer',
    searchShipmentDateError: 'Enter date range below 120 days.',
    searchPhraseLength: 'Search phrase should at least be {{minLength}} chars long for extended search.',
    moveOrder: 'Move shipment',
    moveOrderTitle: 'Move shipment to another department',
    moveOrderDefault: 'Select department',
    label: 'Label',
    createLabel: 'Create label',
    createLabelShipment: 'Create label (Shipment)',
    createMultipleLabel: 'Create label ({{count}})',
    viewLabel: 'View label',
    viewLabels: 'View labels',
    generateLabelError: 'Error: {{error}}',
    generateLabelErrorTitle: 'Something went wrong',
    generateLabelErrorDescription: 'Sorry, we couldn’t generate labels at the moment. Try again later',
    generateLabelErrorButton: 'View error code',
    generatingLabels: 'Label PDFs are being generated...',
    labelsGeneratedSuccessfully: 'Labels were generated successfully',
    vas: 'Value added services (VAS)',
    price: 'Price',
    orderSources: {
      expressit: 'ExpressIT',
      glow: 'GLOW',
      glow_customerBooking: 'GLOW Customer booking',
      glow_customerBooking_airExpress: 'GLOW Customer booking',
      glow_excelImportV2: 'GLOW Excel',
      excel: 'GLOW Excel',
      HappyFlow: 'Happy Flow',
      98466118503: 'Mybring',
      98466118504: 'Mybring',
      glow_recurring: 'Recurring',
      ShipmentAPI: 'Shipment API',
      others: 'EDI'
    },
    pickupDeviation: 'Pickup deviation',
    deliveryDeviation: 'Delivery deviation',
    shipmentSyncStatus: {
      no: 'No',
      yes: 'Yes',
      partial: 'Partial'
    },
    unresolvedSuccessfully: 'The shipment is unresolved successfully.',
    wrongCoordinates: 'Wrong coordinates?',
    moveShipmentToUnresolved: 'Move the shipment to "Unresolved" to set the correct coordinates.',
    mapUnavailable: 'The map will show once this shipment is resolved.',
    selectOtherActions: 'Select other actions'
  },
  eventsList: {
    timeWindow: 'Time window',
    from: 'From',
    to: 'To'
  },
  consignmentEvent: {
    message: {
      comment: 'Comment',
      created: 'Created',
      completed: 'Completed',
      assigned: 'Assigned courier',
      plannedOnRoute: 'Planned on route',
      plannedOnRouteMoreInfo: 'Planned on route {{routeName}} ({{unitName}})',
      plannedOnRouteWithRouteInfo: 'Planned on route {{routeName}}',
      delivered: 'Delivered',
      collected: 'Collected',
      returned: 'Returned',
      deviated: 'Deviated',
      damaged: 'Damaged',
      notification: 'Notification',
      slotChanged: 'Slot manually changed',
      unassigned: 'Unassigned',
      rejected: 'Rejected',
      image: 'Image',
      error: 'Error',
      started: 'Started',
      stopped: 'Stopped',
      accepted: 'Accepted',
      offered: 'Offered',
      unitName: 'Unit',
      driverName: 'Driver',
      'delivery-id-check': 'ID check',
      'deviation-sms-sent': 'Deviation SMS sent',
      'delivered-manually-overridden': 'Delivery manually overridden',
      'order-released-for-invoicing': 'Invoincing released',
      'order-paused-from-invoicing': 'Invoicing paused',
      'manually-set-invoicing-ok-for-order-invoicing': 'Invoiced OK',
      'manually-set-not-invoiced-for-order-invoicing': 'Not invoiced',
      'manually-set-excluded-from-invoicing': 'Excluded from invoicing',
      'unit-manually-added-for-invoicing': 'Unit manually added',
      'invoice-acknowledged-by-external-system': 'Invoice acknowledged by external system',
      'invoice-created-in-external-system': 'Invoice created in external system',
      'invoice-sent-to-customer': 'Invoice sent to customer',
      'invoice-paid-by-customer': 'Invoice paid by customer',
      'deviated-manually-overridden': 'Deviation manually overridden',
      'collected-manually-overridden': 'Collection manually overridden',
      'pickup-location-changed': 'Pickup address changed',
      'delivery-time-estimated': 'Updated delivery time',
      'slot-manually-changed': 'Slot manually overridden',
      'pickup-time-changed': 'Pickup time changed',
      'manually-change-location': 'Manual override of locations',
      'pickup-consignment-data-changed': 'Pickup details changed',
      'delivery-consignment-data-changed': 'Delivery details changed',
      'specification-size-data-changed': 'Specification Size changed',
      'specification-description-data-changed': 'Description changed',
      'delivery-time-purchased': 'Time window ordered by recipient',
      'digital-identity-verified': "Recipient's identity verified digitally",
      'digital-identity-verification-failed': "Could not verify the recipient's identity digitally",
      'flex-delivery-ordered': 'Simplified delivery ordered by receiver',
      'flex-delivery-ordered-by-sender': 'Simplified delivery ordered by sender',
      'flex-delivery-changed': 'Simplified delivery changed',
      'flex-delivery-confirmation-sms-sent': 'Simplified delivery confirmation SMS sent',
      'return-to-pickup-point-delivery-deviated': 'Will be handed over to pickup point',
      recipientName: 'Recipient name',
      receivedBy: 'Received by',
      'reject-order-correction': 'Rejected order correction',
      'accept-order-correction': 'Accepted order correction',
      'handed-over-to-other-unit': 'Handed over to other unit',
      deliveredWithoutSignature: 'Delivered without signature',
      deliveredWithoutSignatureReason: {
        left_package_outside: 'Placed as instructed',
        did_not_get_signature: 'In person',
        DELIVER_IN_PERSON: 'In person'
      },
      'returned-manually-overridden': 'Return manually overridden',
      'eta-sms-sent': 'ETA SMS sent',
      calculatedEta: 'Estimated arrival: {{eta}}',
      etaSmsLabel: 'Estimated arrival',
      scanned: 'Arrived at terminal',
      'arrived-at-terminal': 'Arrived at distributing terminal',
      'order-sorted': 'Sorted',
      'not-arrived-at-distributing-terminal': 'Not arrived at distributing terminal',
      'pickup-eta-sms-sent': 'Pickup ETA SMS sent',
      'handed-in-at-posten-terminal': 'Arrived at terminal',
      'pickup-sms-sent': 'Tracking SMS sent',
      'pre-pickup-sms-sent': 'Tracking SMS pre-sent',
      'pickup-address-unresolved': 'Pickup address unresolved',
      'delivery-address-unresolved': 'Delivery address unresolved',
      'in-transit': 'In transit',
      'upcoming-pickup-sms-sent': 'SMS for upcoming delivery sent',
      'order-sorted-same-day': 'Date set to today by terminal app',
      'signature-sms-sent': 'Signature SMS sent',
      'flex-delivery-cancelled': 'Simplified delivery cancelled',
      'delivery-time-ordered': 'Delivery date changed by recipient',
      'delivery-time-ordered-internal': 'Delivery date changed',
      'delivery-time-updated-from-hf-sync': 'Delivery time synced from HF',
      'planned-delivery-date-ordered': 'Planned delivery date ordered',
      'delay-sms-sent': 'SMS regarding delayed delivery sent',
      'production-deviation': 'Production deviation',
      'manual-delay-sms-sent': 'Manual delay SMS sent',
      'route-planning-complete': 'Route complete',
      'signature-delivered': 'Signature from recipient',
      showPicture: 'Show picture',
      'external-address-wash': 'Delivery details changed',
      'order-note-changed': 'Note changed',
      'order-data-changed': 'Order changed',
      'order-moved-to-department': 'Moved between departments',
      'order-moved-to-department-with-data': 'Shipment moved from',
      'package-measurements-changed': 'Package measurements changed',
      'package-data-changed': 'Package data changed',
      'driver-deviated-from-route': 'Driver deviated from route',
      'delivered-outside-service-time-window': 'Delivery or deviation outside the service time window',
      'delivered-outside-estimated-time-window': 'Delivery or deviation outside the estimated time window',
      'delivery-location-deviation': 'Delivery or deviation not at expected location',
      'seen-by-recipient': 'Tracking page seen by recipient',
      'first-estimated-times-seen': 'First estimated times seen by recipient',
      'pre-advice-push-sms-sent': 'Preadvice PUSH-SMS sent',
      'pre-advice-push-return-sms-sent': 'Preadvice PUSH-RETURN-SMS sent',
      'pre-advice-pull-sms-sent': 'Preadvice PULL-SMS sent',
      'pre-advice-pull-email-sent': 'Preadvice PULL-email sent',
      'pre-advice-pull-reminder-sms-sent': 'Preadvice PULL reminder SMS to receiver sent',
      'pre-advice-pull-reminder-email-sent': 'Preadvice PULL reminder email to receiver sent',
      'pre-advice-pup-sms-sent': 'Preadvice PUP-SMS sent',
      'pre-advice-return-sms-sent': 'Preadvice Return-SMS sent',
      'pre-advice-return-email-sent': 'Preadvice Return-email sent',
      'pre-advice-pull-reminder-to-sender-email-sent': 'Preadvice PULL reminder to sender email sent',
      'pre-advice-pup-reminder-to-sender-email-sent': 'Preadvice PUP reminder to sender email sent',
      'pre-advice-return-return-to-sender-email-sent': 'Preadvice RETURN return to sender email sent',
      'pre-advice-pull-return-to-sender-email-sent': 'Preadvice PULL return to sender email sent',
      'pre-advice-manual-attempt': 'Manual preadvice attempt',
      'pre-advice-manual-request': 'Manual preadvice initiated',
      'pre-advice-active-return-to-customer-email-sent': 'Preadvice active return to customer email sent',
      'pre-advice-active-return-not-picked-up-email-sent':
        'Preadvice active return not picked up email sent to customer',
      'pre-advice-active-return-info-to-customer-email-sent':
        'Preadvice active-return information to customer email sent',
      'pre-advice-passive-return-to-customer-email-sent': 'Preadvice passive return to customer email sent',
      resolved: 'Resolved',
      'pre-advice-status-changed': 'Preadvice status changed',
      'reported-damaged': 'Reported Damage',
      'reported-missing-article': 'Reported missing article(s)',
      'delivery-sms-sent': 'Delivery SMS Sent',
      'parcelbox-allocated': 'Space reserved in parcelbox',
      unconfirmed: 'Unconfirmed',
      refused: 'Refused',
      'accepted-by-planner': 'Accepted',
      'apartment-information-updated': 'Apartment information updated',
      'service-upgrade-purchased': 'Service updated',
      serviceUpgradePurchasedDescription: 'Carry-in Service ordered by recipient',
      'claim-registered-email-sent': 'Claim registered e-mail sent',
      'invoicing-failed': 'Invoicing failed',
      'invoiced-ok': 'Invoiced OK',
      'parcel-locker-delivery-selected': 'Parcel box selected',
      'cancelled-parcel-locker-delivery': 'Parcel box cancelled',
      'pre-advice-result': 'Preadvice',
      'order-credited': 'Order credited',
      'parcel-locker-tracking-sms-sent': 'Parcel box tracking sms sent',
      'time-window-cancelled-by-recipient': 'Time window cancelled by recipient',
      'hd-multileg-return-created': 'Return delivery leg created',
      'hd-multileg-return-failed': 'Return delivery leg failed',
      restored: 'Restored',
      'manually-marked-as-handled': 'Return marked as handled',
      'recipient-phone-number-updated': 'Recipient phone number updated',
      'recipient-email-address-updated': 'Recipient email address updated',
      'preliminary-loaded': 'Preliminary loaded',
      selfBillingUnitId: 'Self-billing unit ID',
      selfBillingUser: 'User',
      'manual-price-override': 'Price overridden',
      'incomplete-price-approved': 'Incomplete price approved',
      'incomplete-price-unapproved': 'Incomplete price approval revoked',
      'b2r-return-order-created': 'B2R return order created'
    },
    rejectedReason: {
      reason: 'Reason',
      private: 'Private reasons',
      notEnoughTime: 'Cant make the stop in time',
      notEnoughSpaceOnVehicle: 'Goods do not fit on vehicle',
      other: 'Other',
      CJ: '$t(consignmentEvent.rejectedReason.notEnoughSpaceOnVehicle)',
      CK: '$t(consignmentEvent.rejectedReason.other)',
      CL: '$t(consignmentEvent.rejectedReason.notEnoughTime)'
    },
    idCheck: {
      idCheckFailed: 'ID check failed, but delivered with {{idCheckFailedResult}}',
      idCheckSummaryOther: 'Other ({{otherType}}), birth date {{birthDate}}',
      idCheckSummary: '{{idCheckType}}, birth date {{birthDate}}'
    },
    damaged: {
      damageType: 'Damage type',
      causeCode: 'Cause code',
      courierInput: 'Courier input',
      showPictureLink: 'Show picture {{idx}}',
      type: {
        A: 'Damage on parcel/wrapping',
        D: 'Pressure/squeeze damage',
        E: 'Complete damage'
      }
    },
    invoiceInitiationType: {
      automatic: 'Scheduled invoicing',
      'manual-department-level': 'Manual department invoicing by user',
      'manual-order-level': 'Manual order invoicing by user'
    },
    invoiceCallback: {
      timeOfTransaction: 'Time of transaction',
      transactionNumber: 'Transaction number in {{systemId}}',
      invoiceLink: 'Click to view invoice'
    },
    changedFrom: 'Changed from -',
    blankText: '(blank)',
    name: 'Name',
    deliveryOrderedByReceiver: 'Parcel box delivery ordered by receiver',
    invoiceEventReason: {
      manuallySetByUser: 'Manually set by user',
      manualInvoiceInfo: 'Paused automatically because manual invoice information was provided on order'
    }
  },
  jobs: {
    job: 'Job',
    lastStarted: 'Last Started',
    lastFinished: 'Last Finished',
    disable: 'Disable',
    enable: 'Enable',
    disabled: 'Disabled',
    disabledInConfig: 'Disabled in Config',
    mustBeASuperAdmin: 'You must be a Super Administrator to disable and enable jobs'
  },
  numberSeries: {
    numberSeries: 'Number Series',
    comparisonError: 'Maximum must be bigger than minimum',
    successfullyAdded: 'Number Series was successfully added to the list',
    successfullyDeleted: 'Number Series was successfully deleted',
    addedNumberSeries: 'Add new number series',
    addedNumberSeriesSubHeading: 'Add number series (do not include check digits)',
    selectType: 'Select type',
    shipmentNumber: 'GSIN (Shipment Number)',
    packageNumber: 'SSCC (Package Number)',
    minimum: 'Minimum',
    maximum: 'Maximum',
    addNumberSeriesButton: 'Add number series to list',
    type: 'Type',
    currentNumber: 'Current number',
    maxNumber: 'Max number',
    numbersLeft: 'Numbers left',
    validMinimumValue: 'You must enter a valid minimum value',
    validMaximumValue: 'You must enter a valid maximum value',
    deleteModalTitle: 'Delete Number Series',
    deleteModalDescription: 'You will not be able to recover this action'
  },
  preadvice: {
    preadvice: 'Preadvice',
    hdDepartments: 'HS Departments',
    ceDepartments: 'C&E Departments',
    departmentGroups: 'Department groups',
    customers: 'Customers',
    customerGroups: 'Customer groups',
    customersCount: 'Customers',
    customerGroupsCount: 'Customer groups',
    departmentsCount: 'Departments',
    departmentGroupsCount: 'Department groups',
    lockNotification: 'Lock notification',
    preadviceRule: 'Preadvice rule',
    locked: 'Preadvice locked',
    nameOfRule: 'Name of rule',
    updated: 'Preadvice updated',
    createNewPreadvice: 'Create new preadvice settings',
    searchPlaceholder: 'Type at least 3 characters to filter',
    nameDescription: 'Name and description',
    bizArea: 'Select business area',
    courierAndExpress: 'Courier and Express (C&E)',
    homeDelivery: 'Home Delivery (HD)',
    filterDepartmentAndGroups: 'Filter on departments or department groups',
    filterCustomersAndGroups: 'Filter on customers or customer groups',
    tabsWithErrors: 'Please address issues on the following tabs: {{tabs}}',
    selectedCustomers: 'Selected customers ({{count}})',
    noSelectedCustomers: 'No selected customers',
    customerSearch: 'Search field - Type at least 3 characters to search for customers',
    settingTargetDepartment:
      'This preadvice setting targets specific departments, remove all selected departments to be able to switch setting to department groups',
    settingTargetDepartmentGroups:
      'This preadvice setting targets specific department groups, remove all selected department groups to be able to switch setting to departments',
    settingTargetCustomers:
      'This preadvice setting targets specific customer, remove all selected customers to be able to switch setting to customer groups',
    settingTargetCustomerGroups:
      'This preadvice setting targets specific customer groups, remove all selected customer groups to be able to switch setting to customers',
    conflictHeading: 'There are some conflicts in your preadvice rules',
    conflictingRules: 'Conflicting rules',
    deletePreadviceDescription:
      'Deleting will remove all the notifications settings and the recipient or sender will no longer get any communication. You cannot undo this action.',
    rule: 'Rule',
    ruleTrigger: 'Preadvice on',
    type: 'Type',
    trigger: 'Trigger',
    action: 'Variant',
    result: {
      NO_RULE: 'No matching rule',
      NO_DELIVERY_TIME_WINDOW: 'No delivery time window found',
      SMS_SENT: 'Preadvice rule checked. Adding SMS to queue',
      EMAIL_SENT: 'Preadvice rule checked. Adding Email to queue',
      SMS_AND_EMAIL_SENT: 'Preadvice rule checked. Adding SMS and EMAIL to queue',
      NO_VALID_PHONE_OR_EMAIL: 'No valid phone number or email address',
      FEATURE_OFF: 'Preadvice is switched off',
      ALREADY_PRE_ADVICED: 'Delivery time window already set. No action taken',
      CANCELLED: 'Preadvice cancelled',
      WAITING_FOR_PACKAGES: 'All packages have not arrived at terminal',
      ALREADY_IN_PROGRESS: 'Waiting for recipient to select delivery time',
      ERROR: 'An error occured',
      WRONG_TRIGGER: 'Trigger not matching rule. Ignoring.',
      MANUAL_ATTEMPT: 'Preadvice attempts manually incremented',
      PRE_ADVICE_LIMIT_REACHED: 'Preadvice limit reached',
      PRE_ADVICE_LOCKED: 'Preadvice is locked',
      ORDER_DELIVERED: 'Order is already delivered',
      RETURN_LAST_LEG_CREATION_SKIPPED: 'Return leg not created'
    },
    error: {
      PRE_ADVICE_RULE_COLLISIONS: {
        label: 'Rule conflict!',
        detail: 'Rule for {{service}} will override rule in {{policySetNames}} through relation to {{relations}}.',
        department: 'department',
        department_group: 'department group',
        customer: 'customer',
        customer_group: 'customer group'
      }
    },
    noPoliciesFound: 'No Preadvice settings',
    policies: 'Preadvice settings',
    copyText: 'Copy text',
    customerInGroupTitle: 'Customers in {{groupName}}',
    policy: {
      deleteConfirm: 'Are you sure you want to delete this set of preadvice rules?',
      name: 'Name',
      new: 'New preadvice setting',
      edit: 'Edit preadvice setting',
      description: 'Description',
      save: 'Save',
      saveAnyway: 'Save anyway',
      servicePolicies: 'Preadvice rules',
      targets: 'Customer-/department settings',
      servicePoliciesDescription:
        'Depending on which type of notification you select, different input fields will be displayed',
      servicePolicy: {
        edit: 'Edit settings',
        new: 'Add service preadvice settings',
        cutOff: 'Cut off',
        remove: 'Remove selected',
        serviceCode: 'Service',
        serviceCodeLegend: 'Add service code eg. 3123',
        vasCode: 'VAS',
        vasCodeLegend: 'Add optional value Adding service, e.g. SWAP',
        sameDay: 'SameDay',
        returnToCustomer: 'Return to customer',
        reminderToCustomer: 'Reminder to customer',
        redirectToPuP: 'Redirect to pickup point',
        sameDayTitle: 'Allow same day bookings before cutoff',
        yesRecipientShouldChange: 'Yes, recipient should be able to change',
        noRecipientShouldNotChange: 'No, recipient should not be able to change',
        recipientCanCancelBookedTimeSlot: 'Recipient will be able to cancel the booked time slot.',
        sameDayLegend: 'Tick in this box if you want the shipment to be delivered with Same Day Service.',
        triggerEvent: 'Preadvice on',
        triggerEventTitle: 'Trigger preadvice',
        triggerEventLegend:
          'What should trigger the preadvice send out. When we get the EDI to GLOW, when the packages are received at HUB or received at DIP or if it should not be triggered you select MANUAL.',
        cutoff: 'Cutoff',
        cutoffLegend:
          'What is the cutoff time for when we will no longer offer the receivers the first available time slot in the calendar and instead offer next day.',
        relativeCutoffMinutes: 'Cutoff minutes',
        relativeCutoffMinutesLegend:
          'Minimum number of minutes from time of preadvice to next available time window. Overrides cutoff time.',
        calendarDays: 'Calendar days',
        goodsRedirectToPickupPoint: 'Goods will be redirected to pickup point after 1 delivery attempt',
        dateAndTimeChangeBookingPageTitle: 'Date and time changes on booking page.',
        calenderDaysTitle: 'Specify the number of days to display to the recipient on the booking page',
        calendarDaysLegend: 'How many days should we display to the receiver in the calendar.',
        noServicePoliciesFound: 'No settings added yet',
        returnToSenderAfterDays: 'Return to sender',
        returnToSenderAfterDaysLegend:
          'After how many days should we send back the shipment in return to the sender. This is if we have not been able to reach the receiver.',
        scheme: 'Type',
        schemeLegend: 'What type of preadvice should we send. Push, Pull, Return or Pick up parcel.',
        searchCustomer: 'Search customer',
        remindAfterHours: 'Reminder to receiver',
        remindAfterHoursLegend:
          'This is used for Pull and PUP preadvice. After how many hours we should send a reminder to the recipient saying they have to book a delivery time.',
        preAdviceAttempts: 'Preadvice attempts',
        preAdviceAttemptsLegend:
          'How many reminders should be sent before the shipment will go in return to the sender.',
        reminderToSenderAfterHours: 'Reminder to sender',
        reminderToSenderAfterHoursLegend:
          'This is a reminder to the sender that we have not been able to reach the recipient. The shipment will be returned.',
        deliveryAttempts: 'Delivery attempts',
        deliveryAttemptsTitle: 'After maximum delivery attempts are reached - the package will be returned to customer',
        deliveryAttemptsChoiceTitle: 'What should happen after maximum delivery attempts reached',
        deliveryAttemptsLegend:
          'How many delivery attempts should be made before the shipment should go in return to sender.',
        pickupAttempts: 'Pickup attempts',
        pickupAttemptsTitle: 'Maximum pickup attempts',
        pickupAttemptsDescription:
          'When pickup attempts are reached we inform the customer that there’s nothing to return due to pickup deviation',
        label: {
          days: 'Days',
          hours: 'Hrs',
          smss: '#'
        },
        legend: {
          days: 'Days',
          hours: 'Hours',
          smss: 'Max number of SMSes'
        },
        returnToPup: 'Return to pickup point',
        addNewRule: 'Add new rule',
        enableCancelBookingTimes: 'Enable cancellation on booking page',
        prebooked: 'Prebooked',
        typeOfOrders: 'Type of orders',
        ordersWithDate: 'Orders with date',
        ordersWithoutDate: 'Orders without date',
        ordersWithAndWithoutDate: 'Orders with and without date',
        emailToCustomer: 'Email to customer'
      }
    },
    tooltip: 'Locking the order = no notification is sent to recipient'
  },
  formValidation: {
    mustProvideName: 'You must provide name',
    mustProvideDescription: 'You must provide description',
    mustProvideServiceCode: 'You must provide service code',
    mustProvideCalendarDays: 'You must provide calendar days',
    mustProvideCutOffTime: 'You must provide cutoff time',
    mustProvideCutOffMinutes: 'You must provide cutoff minutes',
    mustProvideDeliveryAttempts: 'You must provide delivery attempts'
  },
  holidays: {
    holidays: 'Holidays',
    holiday: 'Holiday',
    dayBeforeHoliday: 'Day before holiday',
    addHoliday: 'Add new holiday',
    country: 'Country',
    type: 'Type',
    date: 'Date',
    successfullyAdded: 'Holiday was successfully added to the list',
    successfullyDeleted: 'Holiday was successfully deleted',
    filterOnCountry: 'Filter on country',
    selectHolidayType: 'Select holiday type',
    addHolidayToList: 'Add holiday to list',
    selectCountry: 'Select country',
    addedHolidays: 'Added holidays',
    deleteModalTitle: 'Delete Holiday',
    deleteModalDescription: 'You will not be able to recover this action'
  },
  department: {
    departmentTitle: 'Department',
    department: 'department',
    department_plural: 'departments',
    departmentWithCount: '{{count}} department',
    departmentWithCount_plural: '{{count}} departments',
    departmentGroups: 'Department groups',
    numberOfDepartmentGroups: 'Department groups',
    numberOfDepartments: 'Number of departments',
    search: 'Search for departments',
    searchGroups: 'Search for department groups',
    noDepartmentsFound: 'No departments found',
    noDepartmentGroupsFound: 'No department groups found',
    manageDepartment: 'Manage department',
    searchDepartment: 'Filter by department name or alystra ID',
    searchDepartmentByNameOrId: 'Filter by department name or department Id',
    searchDepartmentGroups: 'Search for department name or description',
    noDepartmentName: 'Name is missing',
    noDepartmentId: 'Id is missing',
    noCouriers: 'No couriers',
    courierCount_plural: '{{count}} couriers',
    courierCount: '{{count}} courier',
    noCourier: 'No couriers',
    couriers: 'Number of couriers',
    plannerCount_plural: '{{count}} planners',
    plannerCount: '{{count}} planner',
    noPlanner: 'No planners',
    planners: 'Number of planners',
    choose: 'Choose department',
    new: 'New department',
    alystraId: 'Alystra Id',
    description: 'Description',
    name: 'Name',
    created: 'Department created',
    updated: 'Department updated',
    departmentType: 'Department type',
    notSet: 'Not set',
    airExpress: 'Air Express',
    courier: 'Courier',
    express: 'Express',
    hd: 'Home Delivery',
    h2: 'H2',
    parcel: 'Parcel',
    posten: 'Posten',
    other: 'Other',
    all: 'All',
    deliveryDisplayType: 'Delivery time display',
    terminalName: 'Terminal name',
    terminalNameTooltip: 'If pickup and department name is different, fill in this information',
    selectArea: 'Select area...',
    address: 'Address',
    zipCode: 'Zip Code',
    zipArea: 'Area',
    autoRecurrentTime: 'Auto recurrent time',
    autoInvoiceTitle: 'Automatic invoicing',
    autoInvoiceEnable: 'Set automatic invoicing',
    autoInvoiceTime: 'Time',
    autoInvoiceOffset: {
      legend: 'What orders should be invoiced',
      oneDayEarlier: 'Delivered 1 day ago',
      twoDaysEarlier: 'Delivered 2 days ago',
      threeDaysEarlier: 'Delivered 3 days ago',
      fourDaysEarlier: 'Delivered 4 days ago',
      fiveDaysEarlier: 'Delivered 5 days ago'
    },
    unitNumber: 'Unit number',
    emailAddress: 'Email Address',
    npsId: 'NPS ID',
    defaultAlystraUnitId: 'Selfbilling unit ID',
    timeWindow: 'Time window',
    pickupEarliest: 'Earliest pickup',
    pickupLatest: 'Latest pickup',
    deliveryEarliest: 'Earliest delivery',
    deliveryLatest: 'Latest delivery',
    listAllDepartments: 'List all departments',
    listAllGroups: 'List all department groups',
    successUpdate: '{{name}} was successfully updated',
    editDepartment: 'Edit {{name}}',
    createNewDepartment: 'Create new department',
    addressAndContact: 'Address and contact information',
    searchAddressError: 'Missing values for address. You must use the search field in order to get the correct address',
    addressContactIngress:
      'In order to get the correct address, use the search field. The address, zip code and city will be filled out automatically.',
    settingsInGlow: 'Settings on recipient tracking page',
    settingsBodyText:
      'Depending on your selection, different content and communication will be displayed/sent to the recipients.',
    tooltipNPSID:
      'NPS ID = Glow can send out NPS recipient satisfaction measuring. This is where the unique identifier for each department is filled out',
    tooltipTerminal: 'If pickup and department name is different, fill in this information',
    removeRow: 'Remove row',
    cutoff: 'Cutoff',
    regular: 'Regular',
    sameDay: 'Same day',
    weekdays: 'Weekdays',
    saturdays: 'Saturdays',
    sundays: 'Sundays',
    setTimeWindow: 'Set time window',
    setTimeWindowToDepartment: 'Set time window to department',
    updateTimeWindow: 'Update time window',
    timeWindowTitle: 'Day delivery logic for H2 departments',
    timeWindowIngress:
      'On which days should this department do deliveries? For each day, please fill in either all of the values, or none of the values.',
    orderBillingType: {
      title: 'Order billing type',
      selectBillingType: 'Select Billing Type',
      apollo: 'Apollo',
      h2: 'H2',
      airExpress: 'Air Express',
      pickup: 'Pickup',
      customerBillingNumber: 'Customer billing number',
      addMoreOrderBillingInfo: 'Add New Order Billing Type'
    },
    departmentId: 'Department Id',
    clearTime: 'Clear time',
    delegatedDepartment: 'Delegated department',
    disableSms: 'Disable Tracking SMS on Route',
    smsShouldNotBeSent: 'Tracking SMS should not be sent to recipients in routes produced on this department.',
    routePlanning: 'Route planning',
    autoRouteCreationTime: 'Auto route creation',
    autoRouteOptimizationTime: 'Auto route optimization'
  },
  departmentGroup: {
    departmentGroupDeleted: '{{name}} was deleted',
    departmentGroup: 'Department Group',
    name: 'Name',
    edit: 'Edit department group',
    manageDepartments: 'Manage departments in group',
    selectAllFor: 'Select all for {{name}}',
    selectedDepartments: 'Selected departments ({{count}})',
    deleteConfirm: 'Are you sure you want to delete this department group?',
    noSelectedDepartments: 'No selected departments',
    selectedCountries: '{{count}} selected country',
    selectedCountries_plural: '{{count}} selected country',
    countries: 'Countries',
    allDepartmentTypes: 'All department types',
    selectedDepartmentTypes: '{{count}} selected department type',
    selectedDepartmentTypes_plural: '{{count}} selected department types',
    departmentTypes: 'Department types',
    youMustSelectDepartment: 'You must select department',
    createDepartmentGroup: 'Create new department group'
  },
  deliveryDisplayType: {
    instant: 'Instant',
    customized: 'Customized'
  },
  menu: {
    defaultDisabled: 'Select one customer to enable this menu item',
    backToOldShipmentsPage: 'Back to old site',
    home: 'Home',
    admin: 'Administration',
    planner: 'Planner',
    report: 'Reports',
    customer: 'Customer',
    airexpress: 'Air Express',
    airexpressAdmin: 'Air Express Admin',
    apiOrder: 'Find API order',
    support: 'Support',
    priceMotor: 'Pricing Engine',
    mainNavigation: 'Main navigation',
    closePanel: 'Close panel',
    expandPanel: 'Expand panel'
  },
  planner: {
    users: 'Users',
    chooseYourDepartment: 'Choose your department',
    unassignedShipments: 'Unassigned shipments',
    filterCustomers: 'Filter customers',
    filterServices: 'Filter services',
    autoRoute: 'Auto route',
    saved: 'Saved',
    allUserRoles: 'All user roles',
    userRolesCount_plural: '{{count}} user roles',
    createNewUser: 'Create new user',
    userRolesCount: '{{count}} user role',
    role: 'Role',
    searchForNameOrNumber: 'Search for name or phone number',
    noPlannersFound: 'No planners found',
    typeOfRole: 'Type of user role',
    selectUserRole: 'Select user role',
    secondTab: 'Second tab',
    removeDepartment: 'Remove department {{name}}',
    connectAirlineTerminal: 'Connect airline terminal(s) to user*',
    connectedAirlineTerminals: 'Connected airline terminals ({{count}})',
    noConnectedTerminals: 'No connected terminals yet',
    removeTerminal: 'Remove terminal {{name}}',
    searchTerminals: 'Search for airline terminals',
    youMustSelectTerminal: 'You must select airline terminal',
    youMustProvideName: 'You must provide name of user',
    youMustProvidePhonenumber: 'You must provide phone number',
    showInGoogleMaps: 'Show route in Google Maps',
    detailedAccessRights: 'Detailed access rights for {{name}}',
    autoRouteModal: {
      selectRoutes: 'Select routes',
      selectRoutesText: 'Select the routes you want to include in the automatic routing',
      lockAllToSlot: 'Lock all assigned shipments to slot',
      useMapAreaRestrictions: 'Use map area restrictions',
      usePtv: 'Use PTV for optimizing (NB! beta)',
      usePtvPilot: 'Premium optimization',
      usePtvCloud: 'Use PTV Cloud for optimizing',
      optimizeOn: 'Optimize on',
      optimizeQuality: 'Optimize quality',
      noRelevantRoutes: 'No relevant routes found',
      startAutomaticRouting: 'Start automatic routing'
    },
    wait: 'Wait',
    trips: 'Trips',
    searchSlotCourierName: 'Search slot/courier name',
    createdBy: 'Created by',

    connectDepartment: 'Connect department to user',
    banner: {
      tryNewRoutePlanner:
        "1st of May, <0>route planner 2.0</0> will be the only available route planner. If you haven't used the route planner before, we encourage you to start using it from now. <1>Read more on Zendesk</1> and other related articles to get an overview of how it works."
    },
    totalTitle: 'Packages',
    pickList: 'Pick list',
    export: 'Export for invoicing',
    deleteSlot: 'Delete',
    editSlot: 'Edit',
    deleteSlotConfirmDialogText: 'Are you sure, you want to delete this slot?',
    created: 'Planner created',
    updated: 'Planner updated',
    deleted: 'Planner has been deleted',
    confirmDelete: 'Are you sure you want to delete this user?',
    live: 'Live',
    routePlanning: 'Route planning',
    routeManagement: 'Route management',
    vehicles: 'Vehicles',
    routePlanningConsignmentsAssigned: 'assigned',
    routePlanningRangeDescription: 'Filter shipments by delivery time',
    unresolvedConsignments: 'Unresolved',
    consignments: 'Shipments',
    couriers: 'Couriers',
    units: 'Units',
    invoicing: 'Invoicing',
    planner: 'Planner',
    planners: 'Planners',
    noPlanners: 'No planners',
    missingId: 'Id missing',
    missingName: 'Name missing',
    search: 'Search',
    searchForPlanner: 'Search for planners',
    name: 'Name',
    phoneNumber: 'Phone number',
    canLogIn: 'Can log in',
    selectDepartments: 'Select departments',
    createSlot: 'Create slot',
    editSlotForm: 'Edit slot',
    routeUpdated: 'Route details updated',
    createSlotFormName: 'Slot name',
    createSlotFormUnit: 'Unit',
    createSlotFormCourier: 'Courier',
    createSlotFormSlotMode: 'Slot mode',
    createSlotFormSlotModePickup: 'Draw pickup area on map',
    createSlotFormSlotModeDelivery: 'Draw delivery area on map',
    createSlotFormSlotModeManual: 'Manually select shipments',
    createSlotFormSlotModeSelected: 'Selected shipments',
    createSlotFormTimeRange: 'Time range',
    createSlotFormStartLocation: 'Start location',
    createSlotFormEndTime: 'End time',
    createSlotFormEndLocation: 'Stop location',
    createSlotFormSlotColor: 'Slot color',
    createSlotFormTimeRangeTooBig: 'Time range is too big. Max is {{hours}} hours.',
    createSlotFormFilterCustomers: 'Filter customers',
    createSlotFormFilterServices: 'Filter services (none checked means all)',
    slotFilterInfo:
      'This slot has been filtered, meaning that only shipments belonging to the chosen customer(s) and the chosen service(s) can be connected to the slot.',
    slotFilteredCustomer: 'The slot ONLY includes orders from the following customers',
    slotFilteredService: 'The slot ONLY includes orders with the following services',
    onlyShowApplicable: 'Only show orders applicable for this slot based on the customer/service filtering',
    optimizeButtonLabel: 'Optimize and assign routes',
    optimizeSlotButtonLabel: 'Re-optimize slot',
    reversedOptimizeSlotButtonLabel: 'Reverse delivery order',
    optimizingSince: 'Optimizing in progress, started at {{startTime}}',
    alterSlotIdOnConsignment: 'Are you sure you want to alter the slot for this shipment?',
    addToRoute: 'Add to route...',
    pickup: 'Pickup',
    delivery: 'Delivery',
    slot: 'Slot',
    notAssigned: 'Not assigned',
    readOnly: 'Read only',
    normal: 'Normal',
    plus: 'Plus',
    partner: 'Partner',
    readOnlyAccessPlannersWithCount: '{{count}} planners with read only access',
    plusPlannersWithCount: '{{count}} plus Planners',
    normalPlannersWithCount: '{{count}} normal Planners',
    saveTemplate: 'Save as template',
    loadTemplate: 'Load from template',
    routeListForFilteredSlots: 'Receipt list (print all)',
    loadListForFilteredSlots: 'Load list (print all)',
    deleteFilteredSlots: 'Delete all slots',
    deleteAllSlotsConfirmDialogText: 'Are you sure you want to delete all slots?',
    sendPrePickupSmsesToAll: 'Pre send tracking sms',
    alertBeforeSendingPrePickupSms: 'Are you sure you want to pre-send tracking SMS for all slots for today?',
    createTemplateFormName: 'Template name',
    createOrUpdateTemplateDescription:
      'To be able to save you must EITHER write a unique template name or select an existing template to update',
    newTemplate: 'Create a new template',
    overwriteTemplate: 'Overwrite template',
    templateSaved: 'Template has been saved',
    updateTemplateDescription: 'Select existing template to update',
    updateTemplateConfirm: 'Are you sure you want to update this template?',
    selectOrDeleteTemplateDescription: 'Please choose a template to apply or delete',
    selectTemplate: 'Select template',
    deleteTemplate: 'Delete',
    selectTemplateDescription: 'Select template:',
    templateSelected: 'Template has been applied',
    templateDeleted: 'Template has been deleted',
    templateDeleteConfirm: 'Are you sure you want to delete this template?',
    missingDeliveryMarker: 'Not a valid delivery address or location',
    missingPickupMarker: 'Not a valid pickup address or location',
    unassignConsignmentFromSlot: 'Remove shipment from slot',
    unassignBulkConsignmentsFromSlotsAlert: 'Remove shipments from their respective slots?',
    unassignedFromSlot: 'Shipment has now been unassigned from the slot',
    unassignBulkConsignmentsFromSlots: 'Shipments have now been unassigned from their respective slots',
    unassignTitle: 'Unassign this shipment from slot',
    unassignButton: 'Unassign from slot',
    forceUnassignButton: 'Force unassign from slot',
    moveOrder: 'Are you sure you want to move this shipment?',
    driverName: 'Driver',
    deliveries: 'Deliveries',
    returns: 'Returns',
    installations: 'Installations',
    curbside: 'Curbside',
    filters: {
      assigned: 'Assigned',
      unassigned: 'Unassigned',
      pickups: 'Pickups',
      deliveries: 'Deliveries'
    },
    saveSelected: 'Save selected ({{count}})',
    showSaved: 'Show saved ({{count}})',
    showAll: 'Show all',
    clearSaved: 'Clear saved',
    sendPrePickupSms: 'Pre-send tracking SMS to recipient',
    sendSms: 'Send SMS',
    sendingSmsText: 'Pre-sending {{count}} tracking SMS',
    prePickupSmsSent: '{{sentCount}} out of {{totalCount}} tracking SMS pre-sent',
    manualDelaySms: {
      smsSent: '{{count}} manual delay SMS sent',
      heading: 'Manual Delay SMS',
      sendingSmsText: 'Sending {{count}} manual delay SMS',
      selectSmsDescription:
        'The SMS will start with "Hi! Your delivery from Sender is unfortunately delayed.". Select the SMS ending below.',
      selectSmsEnding: 'Select SMS ending',
      buttonTitle: 'Send',
      endingType: {
        none: 'None',
        soon: '"We\'ll try to deliver as fast as possible."',
        tomorrow: '"We\'ll try to deliver next working day."'
      },
      disabledTooltip: 'Orders cannot be in state Delivered'
    },
    h2: {
      booking: 'H2 Booking',
      vasHelperText: 'Use VAS 1158 for MBH'
    },
    loadList: {
      address: 'Address',
      customerRef: 'Customer reference',
      date: 'Date',
      deliveries: 'Deliveries',
      distance: 'Distance',
      estimatedLoadTime: 'ELT',
      estimatedTotalTime: 'Est. time',
      header: 'Route summary',
      instructions: 'Delivery instructions,\nphone,\nreceiver ref',
      instructionsPhone: 'Tel:',
      instructionsRef: 'Receiver ref:',
      listName: 'Load list',
      name: 'Name',
      qty: 'Qty',
      routeName: 'Route',
      service: 'Service\n(VAS)',
      stopOrder: 'Stp ord',
      summary: 'Summary',
      time: 'Time',
      timeWindow: 'Time window (ETA-ETD)',
      tripNumber: 'Trip',
      totalDistance: 'Total distance',
      totalTime: 'Total time',
      unit: 'Unit',
      volume: 'Vol\nm³',
      weight: 'Wt\nkg',
      zipArea: 'Area',
      zipCode: 'ZIP',
      unknownRouteName: 'Unknown route name',
      unknownUnitName: 'Unknown unit name',
      noDataText: 'Nothing to see here',
      utilization: 'Utilization'
    },
    locationList: {
      listName: 'Location list'
    },
    assignedUnits: 'Assigned units',
    unassignedUnits: 'Unassigned units',
    noUnitSelected: 'No unit selected',
    unitAssignmentCount: 'Unit assigned to {{count}} routes',
    breakDeletedFromRoute: 'Break time was successfully deleted from route'
  },
  plannerDashboard: {
    assignment: 'Assignment',
    courier: 'Courier',
    date: 'Date',
    started: 'Started',
    notStarted: 'Not started',
    earningsRatings: {
      enough: 'Enough',
      good: 'Good',
      tooLittle: 'Too little',
      tooMuch: 'Too much'
    },
    noStatisticsAvailable: 'No statistics available',
    noStatisticsText: 'No data available for this <1>department</1>. Try selecting another department or date.',
    noStatisticsUnitText: 'No data available for this <1>unit</1>. Try selecting another unit or date.',
    chooseDepartment: 'Dashboards not visible',
    chooseDepartmentText: 'You must select one department in the top menu in order to see statistics',
    backToDepartment: 'Back to department page',
    nrOfSelected: '{{count}} selected',
    allStates: 'All states',
    allUnits: 'All units',
    noData: 'No data',
    hoursWorked: 'Hours Worked',
    listOfAllUnits: 'List of all units',
    nameOfUnit: 'Name of unit',
    earnedPerHour: 'Earned per hour',
    overview: 'Overview',
    overviewOf: 'Overview of {{department}}',
    productivity: 'Productivity',
    rejected: 'Rejected',
    routes: 'Routes',
    status: 'Status',
    statusForUnit: 'Status for unit',
    title: 'Dashboards',
    vehicleType: 'Vehicle Type',
    vehicleTypes: {
      car: 'Car',
      bicycle: 'Bicycle',
      truck: 'Truck',
      van: 'Van',
      motorcycle: 'Motorcycle',
      cargobike: 'Cargo bike'
    },
    goal: 'Goal',
    totalEarning: 'Total Earning',
    totalAssignments: 'Total Assignments',
    activeDays: 'Active Days',
    totalHoursWorked: 'Total Hours Worked',
    averageHoursWorkedPerDay: 'Average hours worked per day',
    deliveryQualityServiceWindow: 'Delivery Quality - Service window',
    deliveryQualityEtaWindow: 'Delivery Quality - ETA window',
    deliveriesOutsideExpectedLocation: 'Deliveries outside expected location',
    deliveriesOutsideExpectedTimeWindow: 'Deliveries outside expected time window'
  },
  updateServiceLevel: {
    menuItem: 'Change service level',
    selectOption: 'Select service level',
    heading: 'Change service level',
    confirmation: 'Service level updated',
    subtext: 'Change service level for {{count}} shipment',
    subtext_plural: 'Change service level for {{count}} shipments',
    failed: {
      title: 'Failed to change service level',
      message: 'Failed to change service level for {{count}} shipment',
      message_plural: 'Failed to change service level for {{count}} shipments'
    }
  },
  manualPreAdvice: {
    menuItem: 'Manual preadvice',
    selectOption: 'Select preadvice type',
    heading: 'Manual preadvice',
    confirmation: 'Preadvice request(s) saved',
    subtext: 'Request preadvice for {{count}} shipment',
    subtext_plural: 'Request preadvice for {{count}} shipments',
    failed: {
      title: 'Failed to save preadvice requests',
      message: 'Failed to request preadvice for {{count}} shipment',
      message_plural: 'Failed to request preadvice for {{count}} shipments'
    }
  },
  setPickupDeliveryDuration: {
    menuItem: 'Set pickup/delivery duration',
    heading: 'Set pickup and delivery duration',
    subtext: 'Set pickup and delivery duration for {{count}} shipment',
    subtext_plural: 'Set pickup and delivery duration for {{count}} shipments',
    pickupDuration: 'Pickup duration',
    deliveryDuration: 'Delivery duration',
    confirmation: 'Durations updated',
    description: 'Enter the duration it takes for the pickup time as well as the delivery for selected orders.',
    validationError: 'Not a valid time. Maximum minutes and seconds should be 59',
    tooltipTitle: 'When entering the durations, the maximum amout of minutes and seconds are 59'
  },
  importExportOrders: {
    importExportOrder: 'Import / Export',
    importSuccess: 'All orders in the spreadsheet is added or updated in the system',
    importSuccessTitle: 'Success',
    importOrdersHeading: 'Import orders',
    importStatus: 'There are currently {{imports}} imports in queue with a total number of {{orders}} orders',
    exportForInvoice: 'Export for invoicing',
    fileTemplateLink: 'Download Excel template',
    uploadFile: 'Import orders',
    uploadingMessage: 'Uploads the file and imports the orders...',
    needToSelectFile: 'You have to select a file',
    exportSuccess: 'All orders in the selected date range has been downloaded to spreadsheet',
    exportSuccessTitle: 'Success',
    dateFormat: 'DD.MM.YYYY',
    testDataWarning: "Don't use personal data in test environment",
    testDataInfo:
      'This is a qa/test environment, and you are not allowed to use production data in test without affected users explicit consent.',
    fromDate: 'From date',
    toDate: 'To date',
    fromDateNotAfterToDate: "Can't be later than 'To date'",
    toDateNotBeforeFromDate: "Can't be earlier than 'From date'",
    removeControlColumns: 'Remove control columns',
    importResults: 'Import results',
    fileName: 'Imported file',
    processedAt: 'Processed at',
    createdShipmentId: 'Created shipment id',
    date: 'Date',
    exportShipments: 'Export shipments',
    createLabels: 'Create labels',
    selectSlot: 'Select route',
    selectExportLabel: 'Select what you want to export',
    allOrders: 'All Orders',
    deviatedOrders: 'Deviated Orders',
    all: 'All',
    noSelection: 'No selection',
    selectFile: 'Select file',
    noFileSelected: 'No file',
    updateSelectedFile: 'Update file',
    exportFailed: 'Failed to export data',
    jobList: {
      title: 'Previous exports',
      content: 'Content',
      created: 'Created',
      user: 'User',
      dates: 'Dates',
      download: 'Download',
      exporting: 'Exporting...',
      downloading: 'Downloading...',
      inQueue: 'In Export queue',
      exportFailed: 'Export failed',
      updated: 'Updated',
      previousImports: 'Previous imports',
      fileName: 'File name',
      numberOfOrders: 'Number of orders',
      imported: 'Imported',
      viewOrders: 'View orders',
      lastImports: 'Listing the {{count}} last imports'
    },
    exportStartedNotification: 'Export started',
    exportStartedNotificationMessage: 'Your report will be available as soon as its processed.',
    exportFailedNotification: 'Export failed, try again later',
    refreshData: 'Update data in existing report',
    refreshDataDescription: 'Choose this option if you already have created an existing report for the specific dates',
    types: {
      SHIPMENT_EXPORT: 'Shipments',
      AIREXPRESS_SHIPMENTS: 'Shipments (with AirExpress data)',
      COURIER_WORK_REPORT: 'Courier work report',
      COURIER_WORK_REPORT_EXTENDED: 'Extended courier work report',
      END_OF_DAY_REPORT: 'End of day report',
      SOCIAL_CONTROL: 'Social control',
      LOCATION_LIST: 'Location list',
      ORDER_EXPORT: 'Orders',
      ORDER_DEVIATION_EXPORT: 'Orders (Deviated)',
      ORDER_EXPORT_ANONYMOUS: 'Orders (Anonymized Data)',
      ORDER_PARTNER_EXPORT: 'Orders (For Partners)',
      DEVIATION_EVENTS: 'Deviation Events'
    },
    exportTitles: {
      SHIPMENT_EXPORT: 'Export Shipments',
      AIREXPRESS_SHIPMENTS: 'Export Shipments (with AirExpress data)',
      COURIER_WORK_REPORT: 'Courier work report',
      COURIER_WORK_REPORT_EXTENDED: 'Extended courier work report',
      END_OF_DAY_REPORT: 'End of day report',
      SOCIAL_CONTROL: 'Export Social Control Data',
      LOCATION_LIST: 'Export location list',
      ORDER_EXPORT: 'Export Orders',
      ORDER_DEVIATION_EXPORT: 'Export Orders',
      ORDER_EXPORT_ANONYMOUS: 'Export Orders',
      ORDER_PARTNER_EXPORT: 'Export Orders',
      DEVIATION_EVENTS: 'Export deviation events'
    },
    exportDescriptions: {
      SHIPMENT_EXPORT: '',
      AIREXPRESS_SHIPMENTS: 'This includes AirExpress data, in addition to regular shipment data',
      COURIER_WORK_REPORT: '',
      COURIER_WORK_REPORT_EXTENDED: '',
      END_OF_DAY_REPORT: 'Use this report to view daily route statistics, including operational costs and earnings.',
      SOCIAL_CONTROL: '',
      LOCATION_LIST: '',
      ORDER_EXPORT:
        'If you want to use the data for test enviroments, we recommend to use the option “Orders (Anonymized Data)"',
      ORDER_DEVIATION_EXPORT:
        'If you want to use the data for test enviroments, we recommend to use the option “Orders (Anonymized Data)"',
      ORDER_EXPORT_ANONYMOUS: 'Can be used if data is to be imported into a test environment',
      ORDER_PARTNER_EXPORT: 'Customized excelfile that can be used for importing in Opter',
      DEVIATION_EVENTS: ''
    }
  },
  unit: {
    clearSearch: 'Clear search',
    unitWithCount: '{{count}} unit',
    unitWithCount_plural: '{{count}} units',
    new: 'New unit',
    edit: 'Edit unit',
    addCourier: 'Add courier',
    hideAddCourier: 'Hide add courier',
    requiresCourierUsers: 'Must have at least one courier',
    created: 'Unit has been created',
    updated: 'Unit has been updated',
    search: 'Search for units from other departments...',
    unitsInCurrentDepartment: 'Units in current department',
    unitsInOtherDepartments: 'Units in other departments',
    saveUnit: 'Save unit',
    addCourierToUnit: 'Add courier to unit',
    addCourierToUnitSearch: 'You can only search for couriers in departments you have access to',
    addedCouriers: 'Added couriers ({{length}})',
    newCourier: 'Create new courier',
    name: 'Name',
    alystraId: 'Alystra ID',
    couriers: 'Couriers',
    active: 'Active',
    deleteQuestion: 'Remove unit from current department',
    deleteDescription: 'Unit can be reconnected later if the unit is still connected to other departments',
    unassigned: 'Unit successfully removed from department'
  },
  units: {
    title: 'Units',
    new: 'Create new unit',
    connectFromOtherDepartments: 'Connect from other departments',
    connect: {
      title: 'Connect units to current department',
      description: 'You can only search for units in departments you have access to',
      selected: 'Selected units',
      noneSelected: 'No units',
      submit: 'Connect'
    },
    assigned: 'Unit(s) was successfully connected'
  },
  courier: {
    isActive: 'Is active',
    canLogIn: 'Can log in',
    secureLoginEnabled: 'BankID Login',
    recipientName: 'Recipient name',
    created: 'Courier has been created',
    updated: 'Courier has been updated',
    courier: 'Courier',
    couriers: 'Couriers',
    noCouriers: 'No couriers',
    noCouriersExplain: "We couldn't find any results. Please try searching with another term",
    courierWithCount: '{{count}} courier',
    courierWithCount_plural: '{{count}} couriers',
    numberOfCouriers: 'Found {{number}} couriers',
    multipleDrivers: 'Multiple drivers',
    missingId: 'Id missing',
    missingName: 'Name missing',
    search: 'Search for couriers',
    new: 'New courier',
    edit: 'Edit courier {{name}}',
    name: 'Name',
    alystraId: 'Alystra Id',
    unitId: 'Unit Id',
    phoneNumber: 'Phone number',
    driverId: 'Driver ID',
    capacity: 'Capacity',
    weightCapacity: 'Weight capacity (kg)',
    volumeCapacity: 'Volume capacity (m\u00B3)',
    maxRangeKm: 'Max range (km)',
    minShipmentWeightKg: 'Minimum shipment weight on this vehicle (kg)',
    maxShipmentWeightKg: 'Maximum shipment weight on this vehicle (kg)',
    color: 'Color',
    resetColor: 'reset color',
    speedFactor: 'Speed factor',
    speedFactorExplanation: 'lower = slower',
    serviceTimeFactor: 'Service time factor',
    serviceTimeFactorExplanation: 'lower = quicker',
    defaultStopLocation: 'Default stop location',
    defaultStartLocation: 'Default start location',
    slotNumDeliveries: '{{count}} delivery',
    slotNumDeliveries_plural: '{{count}} deliveries',
    slotNumPickups: '{{count}} pickup',
    slotNumPickups_plural: '{{count}} pickups',
    deliverPerformButtonLabel: 'Deliver',
    handoverPerformButtonLabel: 'Hand over',
    deviationPerformButtonLabel: 'Deviation',
    deliverWithoutSignatureButtonLabel: 'Without signature',
    deliverWithSignatureButtonLabel: 'Signature',
    deviateNotAtHomeButtonLabel: 'Not at home',
    deviateLocNotFoundButtonLabel: 'Could not find location',
    deviateRecipientDeclinedButtonLabel: 'Recipient did not want it',
    deviateTimeWindow: 'Could not deliver within timewindow',
    addressNotFound: 'Address not found',
    deliverySpotUnavailable: 'Agreed delivery location is not available',
    noAvailableHatchInParcelBoxMachine: 'No available hatch',
    packageDoesNotFitInParcelBox: 'The package exceeding the permissible dimensions/weight',
    unknownErrorForParcelBoxDelivery: 'Other Unknown',
    errorInParcelBoxMachine: 'The machine is out of order',
    deviation: {
      'H-12': 'An attempt was made to deliver the shipment, but the recipient was not present',
      'H-11': 'An attempt has been made to deliver the package, but the recipient was not found at the address',
      'H-10': 'An attempt has been made to deliver the package, but the address was not found',
      'H-13': 'Delivery of the consignment has been attempted, but the consignment was refused',
      '7-10': 'Goods could not be picked up, due to incomplete address',
      '7-12': 'The sender was not available. Goods has not been picked up',
      '7-82': 'Goods was not ready for pickup',
      'V-41-K': 'The shipment is delayed, will be forwarded as soon as possible',
      'H-07-R': 'Failed social control',
      'H-07-Z': 'Failed social control',
      'H-08-Z': 'Failed age control',
      'H-09-Z': 'Failed ID control',
      'VI-16-X': 'Could not deliver within timewindow',
      'H-11-Z': 'Address not found',
      'H-BL-A': 'Agreed delivery location is not available',
      'A-10': '$t(courier.deviation.7-10)',
      'A-12': '$t(courier.deviation.7-12)',
      'A-82': '$t(courier.deviation.7-82)',
      'V-100-CA': 'No parcel box available. Parcel will be delivered as home delivery',
      'V-100-CI': 'No available hatch',
      'V-80-CI': 'The package exceeding the permissible dimensions/weight',
      'V-99-CI': 'Other Unknown',
      'V-AO-CI': 'The machine is out of order'
    },
    completionProgressCounter: '{{numCompleted}}/{{numTotal}} completed',
    lockCode: 'Gate code',
    flexDelivery: 'Simplified delivery',
    fuelType: 'Fuel type',
    fuelTypes: {
      diesel: 'Diesel',
      gasoline: 'Gasoline',
      natural_gas: 'Natural gas',
      rme: 'RME',
      hvo: 'HVO',
      ethanol: 'Ethanol',
      bio_gas: 'Biogas',
      electric: 'Electricity',
      bicycle: 'Bicycle',
      hydrogen: 'Hydrogen'
    },
    fuelCategory: 'Fuel category',
    fuelCategories: {
      fossil: 'Fossil',
      fossil_free: 'Fossil free',
      zero_emissions: 'Zero emissions'
    },
    latestLogin: 'Latest login',
    latestVersion: 'Latest version',
    failedSocialControl: 'Failed social control',
    failedIdControl: 'Failed ID control',
    failedAgeControl: 'Failed age control',
    incompletePickupAddress: 'Incomplete pickup address',
    custNotAvailableAtPickupAddress: 'Customer not available at pickup address',
    goodsNotReadyAtPickupForCust: 'Goods not ready for pickup at customer',
    shipmentNotAvailableAtTerminal: 'Shipment not available at terminal',
    registrationNumber: 'Registration number',
    commercialTrafficEligibility: 'Commercial traffic eligibility',
    vehicleBrand: 'Vehicle brand',
    vehicleModel: 'Vehicle model',
    vehicleControl: 'Vehicle control',
    vehicleControlComment: 'Vehicle control comment',
    euroClass: 'Euro class',
    euroClassTypes: {
      euroEL: 'Euro EL',
      euro5: 'Euro 5',
      euro6: 'Euro 6'
    },
    co2: 'CO2',
    alcoholDeliveryEducation: 'Alcohol delivery education',
    approved: 'Approved',
    notApproved: 'Not approved',
    couriersInCurrentDepartment: 'Couriers in current department',
    inCurrentDepartment: 'Current department',
    couriersInOtherDepartments: 'Couriers in other departments',
    inOtherDepartments: 'Different departments',
    confirmConnect: 'Connect courier to current department',
    confirmModalDescription: '',
    searchForCouriers: 'Search for couriers',
    editCourier: 'Edit Courier:',
    courierUserUpdated: 'Courier has been updated successfully.',
    dateOfBirth: 'Date of birth',
    dateNotValid: 'Date not valid',
    dateOfBirthWithFormat: 'Date of birth (yyyy-mm-dd)',
    occupationalInjuryInsurance: 'Occupational injury insurance',
    courierScanEnabled: 'Scan',
    breakTime: 'Break time',
    breakDuration: 'Break duration (min)',
    timeWindow: 'Start of break (earliest and latest)',
    breakTimeDescription:
      'In the input fields <i>Start of break (earliest and latest)</i> you set the earliest and latest time the break should start.' +
      ' For example setting 11:00 - 17:00 means that the latest time the driver can start the break is at 17:00.',
    between: 'Between',
    editBreakTime: 'Edit break time',
    updateBreakTime: 'Update break time',
    latestBreakStart: 'Latest start of break',
    radioButtons: {
      noBreakTime: 'No break time',
      timeWindow: 'Time window',
      driveTime: 'Drive time'
    },
    driveTimeDescription:
      'You set the maximum driving time before the driver needs to take a break. For example, after 8 hours driver needs to take 30 minutes break.',
    maxDrivingTime: 'Max driving time',
    selectMaxDriveTime: 'Select max drive time',
    hours: '{{hours}} hours',
    breakTooltip: 'You can only have one break type per unit'
  },
  airexpress: {
    chooseYourDepartment: 'Choose your Air Express department',
    customs: {
      shortTitle: 'Customs',
      customsID: 'Customs-ID'
    },
    export: {
      shortTitle: 'Export'
    },
    customsAgent: {
      created: 'User created',
      updated: 'User updated',
      title: 'Customs agents',
      noUsers: 'No users',
      createTitle: 'New customs agent',
      editTitle: 'Edit customs agent',
      count: '{{count}} customs user',
      count_plural: '{{count}} customs agent users'
    },
    handlerUser: {
      created: 'User created',
      updated: 'User updated',
      title: 'Handlers',
      noUsers: 'No users',
      createTitle: 'New handler',
      editTitle: 'Edit handler',
      handlerUserWithCount: '{{count}} handler user',
      handlerUserWithCount_plural: '{{count}} handler users',
      selectedAirlineTerminal: 'Select airline terminal(s)'
    },
    booking: {
      shortTitle: 'Booking',
      title: 'Air Express booking',
      headerCreate: 'New Air Express booking',
      headerEdit: 'Edit Air Express booking',
      headerCopy: 'Air Express booking copy',
      copy: 'Copy',
      delete: 'Delete',
      recurring: 'Recurring',
      airWayBill: {
        title: 'Air Waybill',
        specialContent: 'Special content',
        bioMaterial: 'Bio material',
        dryIce: 'Dry ice',
        exemptHumanSpecimen: 'Exempt human specimen',
        awbInfo: {
          placeholder: 'AWB information will be visible on label and waybill'
        },
        securedRadio: {
          unsecured: 'Unsecured',
          spx: 'SPX SC'
        },
        addCustomsHandlingButton: 'Add customs handling'
      },
      flightService: {
        title: 'Flight leg',
        invalidItinerary: '*Departure and arrival airport must be different',
        flightRouteRequired: '*You must select a flight route',
        flight: {
          flightDate: 'Date',
          departure: 'Departure',
          arrival: 'Arrival',
          delivery: 'Est Del',
          overtime: 'OT',
          mAwb: 'MAWB',
          flights: 'Flight',
          price: 'Price',
          status: 'Status'
        },
        departureIata: 'Departure airport',
        arrivalIata: 'Arrival airport',
        fromDate: 'Date',
        fromTime: 'Time',
        searchButton: 'Search',
        manuallySelectFlights: 'Manually select flights',
        earlierButton: 'Earlier',
        laterButton: 'Later',
        noFlightsFound: 'No flights found, choose other criteria',
        replacingRoute: 'Replacing {{flightIds}}'
      },
      multilegService: {
        title: 'Multileg service',
        selectAddressToGetServices: 'Select address/customer to get list of services',
        pickupTimeWindow: 'Pickup time window',
        deliveryTimeWindow: 'Delivery time window',
        dropOffAtAirport: 'Drop off at airport',
        pickUpAtAirport: 'Pick up at airport',
        airportOvertimeHandling: 'Airport overtime handling',
        firstLeg: 'First leg',
        flightLeg: 'Flight leg',
        lastLeg: 'Last leg',
        arrival: 'Arrival',
        handlerAt: 'Handler at',
        noHandlerAtAirport: '!!! No handler at airport',
        airportSuffix: 'airport',
        selectFlight: 'No flight selected',
        reconsiderVIP: 'The shipment will arrive in good time before flight. Reconsider VIP.',
        pickup: 'Pickup',
        pickupAtAirport: 'Pickup at airport',
        delivery: 'Delivery',
        deliveryAtAirport: 'Delivery at airport',
        priceInformation: 'Estimated price including first and last leg',
        priceErrorMessage: 'ExpressIT returned an error message',
        replaceFlight: 'Replace flight',
        replaceFlightInvalidMessage: '*The chosen flight is invalid for this journey. Please choose another one',
        departedFlightInRoute: 'This route contains departed flights'
      },
      preplan: {
        orderNote: 'Note will be visible in the e-mail notification along with waybill',
        airWaybillLabel: 'Air waybill',
        masterAirWaybillLabel: 'Master air waybill',
        addMailButton: 'Add',
        emailAddress: 'E-mail address'
      },
      confirm: {
        confirmNoDangerousGoods: 'I confirm that I’m not sending any dangerous goods according to IATR DGR',
        deleteOrders: 'Delete orders'
      },
      message: {
        invalidTimeWindow: 'Invalid time window',
        mustSelectPickupTimeWindow: 'Please select service or pickup time window',
        mustSelectDeliveryTimeWindow: 'Please select service or delivery time window',
        deliverToAirportTooLate: 'Must be delivered to airport in time for handler to handle',
        deliverToAirportSomewhatTight: 'Planned delivered to airport might be tight. Consider using another service',
        deliverToAirportPlentyTime:
          'The shipment will arrive in good time before the flight. Consider using another service',
        pickupOnAirportTooEarly: 'Cannot be picked up on airport before handler has handled',
        pickupOnAirportPlentyTime: 'Consider earlier pickup',
        overtimeNeeded: 'Outside handlers office hours - overtime needed'
      }
    },
    confirmation: {
      heading: 'Air express order confirmation',
      customerName: 'Customer',
      flightId: 'Flight',
      awb: 'AWB',
      mawb: 'MAWB',
      firstLeg: {
        heading: 'First leg',
        missingLeg: 'Dropped off at airport'
      },
      lastLeg: {
        heading: 'Last leg',
        missingLeg: 'Picked up at airport'
      }
    },
    invoicing: {
      shortTitle: 'Invoicing',
      resend: 'Resend',
      requestResponse: 'Show request/response',
      invoiceNow: 'Invoice now',
      invoiceRequested: 'Invoice request sent',
      invoiceState: {
        NOT_READY_FOR_HANDLING: 'Not ready',
        TO_BE_HANDLED: 'Automatic invoicing',
        NEEDS_MANUAL_HANDLING: 'Manual invoicing',
        SUCCESSFULLY_HANDLED: 'Invoiced OK',
        UNSUCCESSFULLY_HANDLED: 'Failed'
      }
    },
    importData: {
      shortTitle: 'Uploads',
      title: 'SSIM upload',
      formatWarningNorwegian:
        'SSIM-files from Norwegian Air Shuttle requires special handling. To ensure that the data is imported correctly, the word Norwegian must be included in the filename of every SSIM-file from Norwegian Air Shuttle.',
      uploadLink: 'Import airline SSIM file',
      showDetails: 'Show details',
      noExistingUploads: 'No uploads done yet',
      importSuccess: 'Import successful'
    },
    existingUploads: {
      title: 'Previous Uploads',
      uploadedAt: 'Uploaded',
      airline: 'Airline',
      validFrom: 'Valid from',
      validTo: 'Valid To',
      uploadStatus: 'Status',
      numFlights: 'Num flights'
    },
    locallyAddedFlightSchedules: {
      shortTitle: 'Schedules',
      title: 'Locally added flight schedules',
      noFlightSchedules: 'No flights added locally yet',
      createFlightSchedule: 'New flight schedule',
      editFlightSchedule: 'Edit flight schedule',
      created: 'Flight schedule added',
      new: 'Add new flight schedule',
      updated: 'Flight schedule updated',
      deleted: 'Flight schedule deleted',
      deleteConfirmation:
        'Are you sure you want to delete flight schedule for flight {{flightId}} on days {{weekdays}}?'
    },
    flightSchedule: {
      validFrom: 'Valid from',
      validTo: 'Valid To',
      airlineIata: 'Airline',
      flightNo: 'Flight number',
      weekdays: 'Weekdays',
      departureIata: 'Departure airport',
      departureTime: 'Departure time (GMT)',
      departureTimeOffset: 'Departure offset',
      arrivalIata: 'Arrival airport',
      arrivalTime: 'Arrival time (GMT)',
      arrivalTimeOffset: 'Arrival offset'
    },
    adminSearchFlights: {
      shortTitle: 'Flight search',
      title: 'Flight search',
      directFlights: 'Direct flights',
      flightsWithConnections: 'Connections',
      flightsWithTransfers: 'Flights with two transfers',
      connections: 'And then',
      shortestPaths: 'Shortest routes',
      shortestPath: 'Shortest route',
      shortestPathAlternative: 'Alternative',
      noPathFound: 'No routes found',
      flightWithConnections: 'Via {{viaAirportIata}} with {{flightId}} ',
      flightWithTransfers: 'Via {{viaAirportIata}}',
      noDirectFlights: 'No direct flights found',
      noFlightWithConnections: 'No connections found',
      assortedRoutes: 'Assorted Routes',
      noRoutesFound: 'No routes found',
      searchForm: {
        departureIata: 'Departure airport',
        arrivalIata: 'Arrival airport',
        fromDate: 'Search from date',
        fromTime: 'Search from time (searching the next 24 hrs)',
        submitButton: 'Search'
      }
    },
    adminPreadvice: {
      shortTitle: 'Preadvice',
      title: 'Preadvice',
      searchForm: {
        airport: 'Airport',
        airline: 'Airline',
        submitButton: 'Search'
      },
      emailMawb: 'Email MAWB',
      emailAwb: 'Email AWB',
      smsMawb: 'SMS MAWB',
      smsAwb: 'SMS AWB',
      emailManifest: 'Email Manifest',
      editTitle: 'Edit',
      enterQuery: 'Enter airline and airport to find preadvice',
      willBeAdded: 'No preadvice exists on this airline/airport but will be added on save',
      addButton: 'Add',
      saveButton: 'Save',
      editButton: 'Edit',
      saveOk: 'Preadvice Saved',
      saveFailed: 'Preadvice Save Failed'
    },
    terminalServices: {
      TE: 'Terminal',
      LO: 'Loading',
      UL: 'Unloading',
      CU: 'Customs',
      CD: 'Cargo Drive',
      SC: 'Security Scanning',
      OT: 'After office hours opening'
    },
    airlines: {
      title: 'Airlines',
      shortTitle: 'Airlines',
      noAirports: 'No airlines found!',
      createAirline: 'New Airline',
      editAirline: 'Edit airline',
      new: 'Add new airline',
      updated: 'Airline updated',
      deleted: 'Airline deleted',
      created: 'Airline added',
      addAWBPrefix: 'Add New External AWB-prefix'
    },
    airline: {
      iata: 'IATA',
      name: 'Name',
      payment: 'Payment Information',
      arrivalServices: 'Arrival',
      departureServices: 'Departure',
      country: 'Country',
      deleteConfirmation: 'Are you sure you want to delete airline {{iata}}?',
      awb: {
        createdAt: 'Created at',
        header: 'Airwaybill',
        prefix: 'External AWB-prefix',
        stock: 'AWB-stock',
        start: 'Start',
        end: 'End',
        lastUsed: 'Last used',
        addNewStock: 'Add new stock',
        saveBeforeAddAdditionalStock: 'You need to save the form before you can add additional stock.'
      }
    },
    airports: {
      shortTitle: 'Airports',
      title: 'Airports',
      noAirports: 'No airports found!',
      createAirport: 'New airport',
      editAirport: 'Edit airport',
      created: 'Airport added',
      new: 'Add new airport',
      updated: 'Airport updated',
      deleted: 'Airport deleted'
    },
    terminals: {
      title: 'Terminals',
      enterQuery: 'Select airline and airport to find terminal',
      openingHours: 'Opening hours',
      handlingTime: 'Handling time (minutes)',
      transferTime: 'Transfer time (minutes)',
      pickupTime: 'Pickup time (minutes)',
      deliveryTime: 'Delivery time (minutes)',
      handlingAgent: 'Handling agent',
      aohdOpening: 'After office hours opening',
      allowAohd: 'Allow AOHD',
      openingTime: 'Opening time',
      closingTime: 'Closing time',
      saveOk: 'Terminal saved',
      saveFailed: 'Save failed',
      formErrors: {
        opening: 'Must be earlier than closing',
        closing: 'Must be later than opening'
      }
    },
    airport: {
      iata: 'IATA',
      name: 'Name',
      country: 'Country',
      raCode: 'RA-code',
      address: 'Address',
      airportAddress: {
        search: 'Search Airport Address',
        zip: 'Airport Postal Code',
        streetName: 'Airport Street Name'
      },
      airportZip: 'Postal code',
      city: 'City',
      cityAddress: {
        search: 'Search City Address',
        zip: 'City Postal Code',
        streetName: 'City Street Name'
      },
      cityZip: 'City zip',
      timezone: 'Timezone',
      liveFlightHandler: 'Live flight service',
      handlesDangerousGoods: 'Handles dangerous goods',
      handlesGlowDepartures: 'Handles departures',
      handlesGlowArrivals: 'Handles arrivals',
      transferTimeMinutes: 'Transfer time (minutes)',
      createdAt: 'Created',
      updatedAt: 'Last updated',
      selectCountry: 'Select country...',
      noLiveFlightHandler: 'None',
      deleteConfirmation: 'Are you sure you want to delete airport {{iata}}?',
      departments: 'Departments'
    },
    shipments: {
      shortTitle: 'Shipments',
      title: 'Air Shipments',
      searchForm: {
        title: 'Air express shipments'
      },
      noShipmentsFound: 'No Shipments found',
      noDutiableShipmentsFound: 'No dutiable shipments found',
      grid: {
        status: 'Status',
        shipmentId: 'Shipment id',
        spx: 'SPX',
        spxEtd: 'SPX ETD',
        spxTrns: 'SPX TRNS',
        customsExportId: 'Customs Export ID',
        customsImportId: 'Customs Import ID',
        customerName: 'Customer',
        customerNo: 'Customer no',
        mawb: 'MAWB',
        awb: 'AWB',
        externalAwbs: 'External AWBs',
        pickupName: 'Pickup',
        dropoffAtAirport: 'Dropoff',
        pickupTime: 'Pickup time',
        pickupAddress: 'Pickup address',
        pickupZipArea: 'City',
        pickupZipCode: 'Zip',
        departureIata: 'Dep',
        departureAt: 'Departure time',
        flightId: 'Flight',
        arrivalIata: 'Arr',
        arrivalAt: 'Arrival time',
        deliveryTime: 'Delivery time',
        deliveryName: 'Delivery',
        pickupAtAirport: 'Pickup',
        deliveryAddress: 'Delivery address',
        deliveryZipArea: 'City',
        deliveryZipCode: 'Zip',
        invoiceState: 'Invoice state'
      }
    },
    recurring: {
      shortTitle: 'Recurring',
      frequencies: {
        every_week: 'Every week',
        every_second_week: 'Every second week',
        every_third_week: 'Every third week'
      }
    },
    manifest: {
      shortTitle: 'Manifest',
      title: 'Air Express Manifest',
      noShipmentsFound: 'No Shipments found',
      filter: 'Filter flights',
      searchFlight: 'Search flight',
      searchManifest: 'Search manifest or MAWB',
      noManifestsFound: 'No manifests found',
      searchForm: {
        title: 'Air express manifest'
      },
      grid: {
        status: 'Status',
        mawbId: 'MAWB ID',
        mawbNo: 'MAWB NO',
        awb: 'AWB',
        departureIata: 'Dep',
        departureTime: 'Dep Time',
        flightId: 'Flight',
        flightDate: 'Date',
        arrivalIata: 'Arr',
        arrivalTime: 'Arr Time'
      }
    },
    info: {
      mawb: 'MAWB',
      awb: 'AWB',
      externalAwbs: 'External AWBs',
      service: 'Service',
      additionalServices: 'VAS',
      departure: 'Departure',
      arrival: 'Arrival',
      flightId: 'Flight instructions',
      routeInstructions: 'Route instructions',
      awbInfo: 'AWB information',
      multileg: 'Multileg'
    },
    eventType: {
      DELIVERED: 'Delivered',
      ACCEPTED_TERMINAL_DPT: 'Accepted at terminal',
      SPX_ETD: 'SPX ETD',
      SPX_TRNS: 'SPX TRNS',
      SPX_XRAY: 'SPX XRAY',
      SPX_PHS: 'SPX PHS',
      READY_FOR_TRANSPORT_TO_FLIGHT: 'Ready for transport to flight',
      LOADED_ON_FLIGHT: 'Loaded on flight',
      ACCEPTED_AIRSIDE: 'Accepted airside',
      STORAGE_IN_TERMINAL: 'Storage in terminal',
      HANDED_OVER_TO_DRIVER: 'Handed over to driver',
      CUSTOMS_EXPORT: 'Customs export',
      CUSTOMS_IMPORT: 'Customs import',
      HELD_IN_CUSTOMS: 'Held in customs',
      INITIAL_VALUE: 'Select status',
      DELIVERED_AIRPORT: 'Delivered to terminal',
      COLLECTED_AIRPORT: 'Picked up at terminal',
      DEVIATED: 'Deviated',
      FLIGHT_INFO_CHANGED: 'Flight changed',
      SCAN_WRONG_TERMINAL: 'Scanned at wrong terminal'
    },
    manualOverride: {
      eventType: {
        CREATED: 'Created',
        DELIVERED: 'Delivered',
        ACCEPTED_TERMINAL_DPT: 'Accepted at terminal',
        SPX_ETD: 'SPX ETD',
        SPX_TRNS: 'SPX TRNS',
        SPX_XRAY: 'SPX XRAY',
        SPX_PHS: 'SPX PHS',
        READY_FOR_TRANSPORT_TO_FLIGHT: 'Ready for transport to flight',
        LOADED_ON_FLIGHT: 'Loaded on flight',
        ACCEPTED_AIRSIDE: 'Accepted airside',
        STORAGE_IN_TERMINAL: 'Storage in terminal',
        HANDED_OVER_TO_DRIVER: 'Handed over to driver',
        CUSTOMS_EXPORT: 'Customs export',
        CUSTOMS_IMPORT: 'Customs import',
        HELD_IN_CUSTOMS: 'Held in customs',
        DEVIATED: 'Deviated'
      }
    }
  },
  slot: {
    minutesDelay: 'minutes delayed',
    minutesAhead: 'minutes ahead',
    minutes: 'minutes',
    time: 'Time',
    created: 'Created',
    assigned: 'Assigned',
    lock: 'Lock',
    lockDescription:
      'When the slot is locked, it will not be a part of future route optimizations, and cannot be edited.',
    toggleVisibleSlotDescription: 'Hide the slot.',
    inProgress: 'In progress',
    onHold: 'On hold',
    complete: 'Complete',
    unknownState: 'Unknown state',
    courierSelect: 'Select courier',
    unitSelect: 'Select unit',
    printableSlotList: 'Printable slot list',
    followsRoute: 'Follows',
    doesNotFollowRoute: 'Deviate',
    hasLocation: 'Visible',
    hasNoLocation: 'Not visible',
    showActiveSlots: 'Show in progress',
    showSlotNotStarted: 'Show not started',
    showCompletedSlot: 'Show completed',
    showPerfectSlot: 'Show perfect',
    filterSlots: 'Filter slots',
    planningCompleted: 'Complete',
    addMapArea: 'Add map area',
    editMapArea: 'Edit map area'
  },
  login: {
    heading: 'Log in',
    phone: 'Enter your Glow registered phone number with country prefix (e.g. +47 ...), and we will send an SMS token',
    code: 'Token sent, if you have a registered user with that number. It is valid for 5 minutes. Enter token.',
    requestNewCode: 'Request another code',
    verifyFailed: 'Token or phone number is incorrect, or too many attempts used, try again or request another code',
    requestFailed: 'Could not send token, try again.',
    verifyTokenButton: 'Verify token',
    requestTokenButton: 'Request token',
    privacyLink: 'Privacy policy',
    passwordWrong: 'Incorrect password',
    customer: 'I am a customer',
    forgotPassword: 'Forgot your password?',
    tryApp: 'Try the new app!',
    appDownload: 'Download the drivers app here and get access to even more functions!',
    appInfo:
      'Are you a driver with an Android- phone? Download the drivers app <1>here</1> and get access to even more functions!',
    webApp: 'Web App',
    note: 'Note! Only use the web version of the courier app if you for some reason cant use the apps for iOS and Android',
    resendToken: 'Resend token',
    submitPassword: 'Submit password',
    loginAsCustomer: 'Log in as a customer',
    loginAsPlanner: 'Log in as a planner',
    token: 'Token',
    password: 'Password',
    tokenRequired: 'Token is required',
    forgotPasswordDescription:
      "To continue, you need to set a password. We'll send you an email with a link for setting this. If your email has changed, you need to contact an admin to change it.",
    sendEmailDescription:
      "To continue, we need your email. We'll send you a confirmation link to the supplied address. With this link you can set your 2 factor password.",
    emailFormatValidation: 'Email needs to be in valid format',
    sendEmailSuccess: 'The email should arrive shortly',
    sendEmailFailed: 'Sending email failed, try again',
    setYourPassword: 'Set your password',
    sendEmail: 'Send email',
    passwordsDontMatch: "Passwords don't match",
    passwordNotMeetRequirements: 'Password doesn’t meet the requirements',
    passwordRequired: 'Password required',
    newPassword: 'New password',
    repeatPassword: 'Repeat your password',
    lowercaseLetter: 'A <strong>lowercase</strong> letter',
    uppercaseLetter: 'A <strong>capital (uppercase)</strong> letter',
    numberCharacter: 'A <strong>number</strong>',
    minimumCharacters: 'Minimum <strong>15 characters</strong>',
    passwordDescription: 'You may now set your password, it must contain the following'
  },
  error: {
    '404Title': 'Page not found',
    '404Message': 'We couldn’t find the page you’re looking for',
    '500Message': "{{error}} - Sorry, we cannot find your data... we'll try again in a minute...",
    title: 'Something went wrong!',
    dateRequiredWhenSecureLogin: '*Required field if BankID is activated',
    dateNotValid: 'Date is not valid',
    errorMessage:
      'We are sorry! This did not work out as planned, but the developers have been notified about the error..',
    errorClickToReload: 'Click here to reload the page',
    phoneNumber: 'You must provide valid phone number, e.g. +47 12345678',
    phoneNumberPrefix: 'You must provide a country code, e.g. +47 ...',
    somethingWentWrong: 'Something went wrong while trying to save...',
    notAValidNumber: 'Not a valid number',
    notAValidAlphaNumeric: 'Must be numbers and/or letters',
    notAValidDate: 'Must be a valid date (yyyy-mm-dd)',
    notAValidTime: 'Must be a valid time (hh:mm)',
    notAValidDuration: 'Must be a valid duration (mm:ss)',
    notAValidZipCode: 'Must be a valid zip code',
    notAValidTimezone: 'Must be a valid timezone. Try "Europe/Oslo" or similar',
    somethingWentWrongOnTheServer: 'Something went wrong on the server...',
    somethingWentWrongWithTheRequest: 'Something was wrong with the request...',
    somethingWentWrongWithTheResponse: 'Something was wrong with the response...',
    selectAtLeastOne: 'You need to select at least one option',
    invalidEmail: 'You must provide valid email address, e.g. name@gmail.com',
    requiredBreakDuration: 'You must enter break duration',
    notAValidTimeWindow: 'The earliest break start must be before the latest',
    requiredEarliestStartBreak: 'You must enter earliest break start',
    requiredLatestStartBreak: 'You must enter latest break start',
    requiredIfDriveTimeDuration: 'You must enter max drive time',
    timeInWrongFormat: 'Time is not valid',
    invalidUrlParameterCombination: 'This combination of URL parameters is invalid and the page cannot be rendered'
  },
  loading: "We're currently fetching your data...",
  newAppVersion: 'Glow just got a little better, we are now fetching the new version.',
  loadingReceiptList: 'Receipt lists are being generated...',
  loadingLabelUrl: 'Label PDF URLs are being generated...',
  invoicing: {
    departmentInvoicingInProgress: 'Invoicing in progress...'
  },
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December'
  },
  weekdays: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday'
  },
  weekdaysShort: {
    sunday: 'Sun',
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat'
  },
  maps: {
    addressLookup: 'Address lookup'
  },
  notifications: {
    dismiss: 'Dismiss',
    dismissAll: 'Dismiss all',
    error: 'Error',
    reload: 'Refresh',
    reloadCourier: 'Press here to reload',
    navigate: 'Navigate to page',
    networkError:
      'Looks like there is some network issues, it may have been temporary. However the page should be reloaded.',
    networkErrorCourier: 'You have been offline, please reload.'
  },
  modals: {
    preplan: {
      create: 'Preplan shipment',
      change: 'Change preplanned driver',
      shipmentInfo: 'Preplan shipment {{shipmentId}} ({{pickupName}} to {{deliveryName}})',
      pickDriver: 'Choose a driver..',
      unpreplan: 'Unpreplan',
      preplan: 'Preplan',
      error: 'Could not preplan shipment',
      notification: {
        preplanned: 'Preplanned',
        unpreplanned: 'Unpreplanned',
        preplannedMessage: 'Shipment was preplanned to {{courierId}}',
        unpreplannedMessage: 'Shipment was unpreplanned'
      }
    },
    maps: {
      save: 'Save changes',
      cancel: 'Cancel',
      delete: 'Delete',
      editMode: 'Edit mode',
      mapArea: '{{routeName}} map area',
      saved: 'Saved',
      deleted: 'Deleted'
    }
  },
  grid: {
    columns: {
      department: 'Department',
      deadline: 'Deadline',
      failedReason: 'Error message',
      exceptions: 'Exceptions',
      dispatchAsGroup: 'Dispatch as group',
      name: 'Name',
      senderName: 'Sender name',
      puearly: 'PU',
      pulate: 'PU',
      delearly: 'DEL',
      dellate: 'DEL',
      vas: 'VAS',
      zip: 'Zip',
      type: 'Type',
      estimated: 'Estimated time',
      estimatedDepartureTime: 'ETD',
      adjusted: 'Adjusted',
      timeWindow: 'Time window',
      onCar: 'On car',
      frequency: 'Frequency',
      startDate: 'Start date',
      endDate: 'End date (optional)',
      from: 'From',
      to: 'To',
      days: 'Days',
      holidays: 'Holidays',
      startdate: 'Start date',
      enddate: 'End date',
      orders: 'Orders',
      id: 'Id',
      shipmentId: 'Shipment id',
      packageId: 'Package id',
      errorMessage: 'Error message',
      customer: 'Customer',
      pickup: 'Pickup',
      delivery: 'Delivery',
      service: 'Service',
      address: 'Address',
      zipcode: 'Zip Code',
      area: 'Area',
      weight: 'Weight',
      volume: 'Volume{{unit}}',
      qty: 'Qty',
      preplanned: 'Preplanned',
      group: 'Group',
      totalOrders: 'Total orders',
      ordersFailed: 'Orders failed',
      date: 'Date',
      finished: 'Finished',
      failed: 'Failed',
      ordersInvoiced: 'Orders invoiced',
      start: 'Start',
      stop: 'Stop',
      pickupContactPerson: 'Pickup contact person',
      pickupPhone: 'Pickup phone',
      deliveryContactPerson: 'Delivery contact person',
      deliveryPhone: 'Delivery phone',
      deliveryAddress: 'Delivery Address',
      deliveryTime: 'Delivery Time',
      contactPerson: 'Contact person',
      phone: 'Phone',
      email: 'Email',
      deliveryReferencePlaceholder: 'Recipient reference',
      specificationDescriptionPlaceholder: 'Goods information',
      multileg: 'Multileg',
      groupName: 'Group name',
      customerNumber: 'Customer No',
      orderNote: 'Note',
      returned: 'Returned',
      price: 'Price',
      slotName: 'Slot',
      estimatedArrivalTime: 'ETA',
      waitingTime: 'Waiting time',
      timeOfDelivery: 'ToD',
      timeOfPickup: 'ToP',
      deliveryEtaSmsSentAt: 'Del. ETA SMS sent',
      trip: 'Trip',
      addresses: {
        title: 'Addresses',
        search: 'Address search',
        searchAddresses: 'Search addresses',
        filterAddresses: 'Filter addresses',
        deleteConfirmation: 'Are you sure you want to delete this address?',
        deleteMultipleConfirmation: 'Are you sure you want to delete all selected addresses?',
        deleteSuccess: 'Address successfully deleted',
        deleteMultipleSuccess: 'Addresses successfully deleted',
        delete: 'Delete',
        name: 'Name',
        phone: 'Phone number',
        address: 'Address',
        zipArea: 'Area',
        zipCode: 'Zip code',
        country: 'Country',
        contactPerson: 'Contact person',
        instructions: 'Instructions',
        deleteAddress: 'Delete address',
        deleteMultipleAddresses: 'Delete addresses ({{count}})',
        loc: 'Loc',
        locTitle: 'Location'
      },
      orderGrid: {
        vehicleType: 'Vehicle',
        extraInfo: 'Extra info'
      },
      dispatch: {
        id: 'Id',
        address: 'Address',
        adjusted: 'Adjusted',
        alreadyAssigned: '<< Already assigned',
        customerName: 'Customer name',
        estimatedArrivalTime: 'ETA',
        estimatedDepartureTime: 'ETD',
        service: 'Service',
        name: 'Name',
        timeWindow: 'Time window',
        type: 'Type'
      },
      orderSource: 'Order source',
      postalCodes: '# postal codes',
      displayEndCustomerPriceShd: 'Show customer price',
      courier: 'Courier',
      createdAt: 'Creation date',
      createdBy: 'Created by',
      plannedDeliveryDate: 'Planned date',
      orderedDeliveryDate: 'Ordered date',
      lastUpdatedAt: 'Last updated at',
      route: 'Route',
      instructions: 'Instructions',
      pickupDuration: 'PU duration',
      deliveryDuration: 'DEL duration',
      originalPickupAddress: 'Original Sender Address',
      originalPickupZipCode: 'Original Sender Zip',
      originalPickupZipArea: 'Original Sender City',
      originalPickupCountry: 'Original Sender Country',
      originalDeliveryAddress: 'Original Delivery Address',
      originalDeliveryZipCode: 'Original Delivery Zip',
      originalDeliveryZipArea: 'Original Delivery City',
      originalDeliveryCountry: 'Original Delivery Country',
      packagesArrivedAtDip: 'Arrived',
      packagesCollected: 'Collected',
      packagesDelivered: 'Delivered',
      originalDeliveryEarliest: 'Original DEL',
      originalDeliveryEarliest1: 'Original DEL 1',
      originalDeliveryLatest: 'Original DEL',
      originalDeliveryLatest2: 'Original DEL 2',
      preAdvices: 'Preadvice attempts',
      preAdviceScheduledDate: 'First preadvice date',
      hasPreAdviceSettings: 'Preadvice setting',
      arrivedAtHub: 'Arrived hub',
      returnToSender: 'Return to sender',
      preAdviceFailed: 'Preadvice failed',
      deliveryAttempts: 'Delivery attempts',
      shipmentCreationDate: 'Shipment creation date',
      preAdviceResult: 'Preadvice result',
      failedPreAdviceDate: 'Failed preadvice date'
    },
    columnSettings: {
      header: 'Add columns in Data table',
      description: 'When selecting a label this information will be shown in the data table'
    }
  },
  openBooking: {
    pickupReferencePlaceholder: 'Customer reference – visible on the invoice',
    customerContactPlaceholder: '$t(instant.booking.customerContact) – visible on the invoice',
    invoiceInfoPlaceholder: 'Invoice information – visible on the invoice',
    confirmed: {
      header: 'Thank you!',
      text: 'Your order is now booked. Updates on pickup or delivery will be sent to {{email}}.',
      support: 'Do you have any questions about your order? Call support at 04050.',
      book: 'Would you like to book more at a favorable price? <0>Get in touch</0> to get an offer.'
    },
    pickupTimePlaceholder: 'Earliest pickup time',
    error: {
      notInRangeZipCode: 'We do not offer this service in the chosen area',
      notInSameZipCodeRange: 'We do not offer this service between the chosen areas',
      notInAreaRangeZipCode: "Zip code doesn't match any code for the area",
      pickupTimeNotWithinServiceTimeWindow: 'Pickup time is not within service time window',
      pickupTimeBeforeEarliestServicePickupTime: "Pickup time can't be before earliest service pickup time",
      pickupNotAvailableForWeekend: 'Pickup service is not available for weekends',
      invalidOrganisationNumber: 'Invalid organisation number'
    }
  },
  instant: {
    title: 'Dispatching',
    openMap: 'Open map',
    group: 'Group selected',
    editGroup: 'Edit group',
    groupName: 'Group name',
    groupOrders: 'Group orders',
    viewGroup: 'View group',
    ordersToGroup: 'Orders to group',
    fixedOrder: 'Fixed delivery order',
    paidTogether: 'Paid together',
    saveGroup: 'Save group',
    ungroup: 'Ungroup',
    removeSelected: 'Remove selected',
    split: 'Split selected',
    noCourierSelected: 'No courier selected',
    noOrderSelected: 'No order selected',
    workingHours: 'Working hours',
    chatButton: 'Chat with driver',
    offeredShipments: 'Offered shipments',
    rejectedShipments: 'Rejected shipments',
    rejectedShipmentsSnackbar: '{{count}} rejected shipment by "{{name}}"',
    rejectedShipmentsSnackbar_plural: '{{count}} rejected shipments by "{{name}}"',
    toBeDone: 'Pickups and deliveries to be done',
    finishedRoutePoints: 'Finished pickups and deliveries',
    suggestedRouteApplied: 'The suggested route has been applied',
    suggestedRouteCancelled: 'The suggested route has been cancelled',
    reorderValidationError: 'The suggested route order is invalid',
    planSlot: 'Route plan',
    createRoute: {
      vehiclePlaceholder: 'No vehicle selected',
      title: 'Select vehicle to create new route',
      description:
        'This unit is not connected to a route yet. When adding the selected shipment(s) to this unit, a new route will be created. Please select a vehicle for the route.'
    },
    columnSettings: {
      reset: 'Reset',
      settings: 'Settings',
      hideColumns: 'Show columns',
      saveAndClose: 'Save and close'
    },
    routePoint: {
      pickup: 'P',
      delivery: 'D'
    },
    confirmation: {
      heading: 'Order confirmation',
      from: 'From',
      to: 'To',
      service: 'Service',
      pickup: 'Pickup',
      delivery: 'Delivery',
      shipmentNumber: 'Shipment number',
      printLabel: 'Print label',
      copyOrder: 'Copy order',
      editOrder: 'Edit order'
    },
    goods: {
      custom: 'Custom',
      letter: 'Letter',
      bag: 'Bag',
      smallbox: 'Small box',
      mediumbox: 'Medium box',
      largebox: 'Large box',
      tube: 'Tube',
      halfpallet: 'Half pallet',
      quarterpallet: 'Quarter pallet',
      fullpallet: 'Full pallet',
      suitcase: 'Suitcase',
      movingbox: 'Moving box',
      trolley: 'Trolley',
      parcel: 'Parcel',
      expressbox: 'Express box'
    },
    instantGrid: {
      editColumnTooltip: 'Click to edit',
      deleteColumnTooltip: 'Click to delete',
      unassignColumnTooltip: 'Click to unassign shipment',
      dragColumnTooltip: 'Click and drag to assign to courier',
      filtersActive: 'Filters are active',
      clearFilters: 'Clear all filters',
      filterPlaceholder: 'filter <enter>'
    },
    booking: {
      addPackage: 'Add new package',
      removePackageRow: 'Remove package',
      cannotRemoveLastPackageRow: 'Cannot remove last package',
      copyPackageRow: 'Copy package',
      title: 'Booking',
      header: 'Booking',
      headerCreate: 'New booking',
      headerEdit: 'Edit booking',
      headerCopy: 'Copy booking',
      headerRestore: 'Restore booking',
      customer: 'Customer',
      customerPlaceholder: 'Customer id',
      subcustomerPlaceholder: 'Subcustomer',
      invalidSubcustomer: 'Subcustomer name is invalid',
      shipmentOrPackageId: 'Shipment ID or Package ID',
      creditBlockedCustomer: 'Credit blocked customer',
      sender: 'Sender',
      setAsDefaultAddress: 'Set as default address',
      setAsDefaultDisabledText: 'Missing coordinates. Set the address by using address search field',
      defaultAddressUpdated: 'Default address updated',
      recipient: 'Recipient',
      pickupNamePlaceholder: 'Name',
      pickupAddressPlaceholder: 'Address',
      pickupZipCodePlaceholder: 'Postal code',
      pickupZipAreaPlaceholder: 'City',
      pickupCountryPlaceholder: '-- Select country --',
      pickupPhoneNumberPlaceholder: 'Phone',
      pickupContactPerson: 'Contact person',
      customerReference: 'Customer reference',
      pickupReferencePlaceholder: 'Customer reference – This field is visible on the customer invoice',
      pickupInformationPlaceholder: 'Pickup information',
      pickupEmailPlaceHolder: 'E-mail',
      customerContact: 'Customer contact',
      customerContactPlaceholder: '$t(instant.booking.customerContact) – This field is visible on the customer invoice',
      customerInfoPlaceholder: 'General customer specific information',
      customerInfoHelper:
        'This information is connected to the customer. Changes made will be visible in all orders related to this customer.<br><br>To add order specific information, use the ‘Note’ input field below.',
      deliveryNamePlaceholder: 'Name',
      deliveryAddressPlaceholder: 'Address',
      deliveryZipCodePlaceholder: 'Postal code',
      deliveryZipAreaPlaceholder: 'City',
      deliveryCountryPlaceholder: '-- Select country --',
      deliveryPhoneNumberPlaceholder: 'Phone',
      deliverySecondPhoneNumberPlaceholder: 'Second Phone Number',
      deliveryEmailPlaceHolderPlaceholder: 'E-mail',
      deliveryReferencePlaceholder: 'Recipient reference',
      deliveryInformationPlaceholder: 'Delivery information',
      orderNote: 'Note',
      internalNote: 'Internal Note',
      clearData: 'Clear data',
      dimensions: 'Goods',
      weightPlaceholder: 'Weight (kg)',
      lengthPlaceholder: 'Length (cm)',
      widthPlaceholder: 'Width (cm)',
      heightPlaceholder: 'Height (cm)',
      service: 'Service',
      serviceCode: 'Service code',
      servicePlaceholder: 'Service code',
      serviceSelect: 'Select service',
      serviceSelectDisabled: 'Changing services on HD orders must be done in HappyFlow',
      goodsEditDisabled: 'Changes to goods on HD orders must be done in HappyFlow',
      standardServices: '-- STANDARD SERVICES --',
      customizedServices: '-- CUSTOMER UNIQUE SERVICES --',
      specificationDescription: 'Goods',
      specificationDescriptionPlaceholder: 'Goods information',
      invoiceInfo: 'Invoice information',
      invoiceInfoPlaceholder: 'Invoice information – This field is visible on the customer invoice',
      manualInvoiceInfo: 'Manual invoice handling',
      manualInvoiceInfoPlaceholder:
        'Manual invoice handling (If this field is filled in with invoicing instructions, the order will not be automatically invoiced)',
      pickup: 'Pickup',
      delivery: 'Delivery',
      pickupDatePlaceholder: 'Pickup date',
      pickupTimePlaceholder: 'Pickup Time',
      deliveryDatePlaceholder: 'Delivery date',
      deliveryTimePlaceholder: 'Delivery Time',
      deliveryContactPerson: 'Contact person',
      restoreButton: 'Restore',
      orderButton: 'Order',
      editButton: 'Update',
      copyButton: 'Order',
      created: 'Order has been registered',
      edited: 'Order has been updated',
      quickSelect: 'Quick select',
      volume: 'Volume',
      points: 'Points',
      totalVolume: 'Total volume {{volume}}',
      totalWeight: 'Total weight {{weight}}',
      removeItem: 'Remove',
      copyItem: 'Copy',
      addItem: 'Add',
      calculatePrice: 'Calculate price',
      priceExplanations: {
        header: 'Price explanations',
        article: 'Article: {{article}}',
        priceListName: 'Price list: {{pricelist}}',
        explanations: 'Explanations:',
        noInfo: 'No price information available'
      },
      approveIncompletePrice: 'I approve all lines with zero price',
      prePlannedToCourier: 'Select courier',
      automaticDepartment: 'Automatic',
      departmentLabel: 'Department',
      emailNotifications: 'E-mail notifications',
      eventRegistered: 'Registered',
      eventPickup: 'Pickup',
      eventDelivery: 'Delivery',
      emailAddress: 'E-mail Address',
      emailRecipientText: 'Following e-mail addresses will receive notifications:',
      invalidEmail: 'Invalid e-mail address',
      editIndicator: 'Edit',
      updateIndicator: 'Update',
      copyIndicator: 'Copy',
      quantity: 'Quantity',
      additionalServices: 'Additional services',
      paymentInformation: 'Payment information',
      paidTogetherLabel: 'Paid together?',
      groupingLabel: 'Shipment grouping',
      groupIdPlaceholder: 'Unique group id',
      groupOrderPlaceholder: 'Group shipment order',
      addRecipient: 'Add additional recipient',
      addPickup: 'Add additional sender',
      vehicleType: 'Vehicle type',
      vehicleTypeUpdated: 'Vehicle type updated',
      selectVehicleType: 'Select vehicle type',
      customerPrice: 'Customer price',
      resourcePrice: 'Unit price',
      priceDetails: 'Price details',
      priceList: 'Price list',
      manualPrice: 'Manual',
      total: 'Total',
      vehicleTypes: {
        car: 'Car',
        bicycle: 'Bicycle',
        truck: 'Truck',
        van: 'Van',
        motorcycle: 'Motorcycle',
        cargobike: 'Cargo bike'
      },
      date: 'Date',
      unNumber: 'UN Number',
      hazardous: 'Class',
      class: 'Class',
      group: 'Group',
      netWeight: 'Net weight',
      name: 'Name',
      restrictions: 'Restrictions',
      totalPoints: 'Total points',
      unacceptableMeasurementsError:
        'Measurements are not correct. Either enter all the dimensions properly or all packages should have volume.'
    },
    courierSorting: {
      title: 'Sort by',
      alystraId: 'Driver number',
      empty: 'Empty',
      full: 'Full'
    },
    courierFilter: {
      title: 'Filter couriers by',
      all: 'Show all',
      inactive: 'Hide inactive',
      offline: 'Hide offline',
      both: 'Hide inactive and offline'
    },
    invoicingFilter: {
      ALL: 'Show all',
      INVOICED_OK: 'Already invoiced',
      TO_BE_INVOICED: 'To be invoiced',
      ORDER_CORRECTION: 'With order correction',
      INVOICED_FAILED: 'Invoicing failed'
    },
    shipmentFilter: {
      title: 'Filter shipments',
      showAll: 'Show all',
      notCollected: 'Not collected',
      hideAll: 'Hide all'
    },
    offer: {
      carEmpty: 'Car is currently empty',
      suggestedDelivery: 'Suggested delivery order',
      newShipments: 'New shipments',
      manualRoute: 'Manual route',
      confirmRoute: 'Confirm route',
      assigning: 'Assigning',
      replanning: 'Replanning route for',
      shipmentsTo: 'shipments to',
      shipmentTo: 'shipment to'
    },
    useFlexible: 'Use flexible times',
    forceOptimizing: 'Force optimizing order',
    replanRoute: 'Replan route',
    recurring: {
      successMessage: '{{number}} shipment(s) was added to selected recurring groups',
      title: 'Recurring',
      recurringOrders: 'Recurring orders',
      includedOrders: 'Orders included in schedule',
      details: 'Details',
      editOriginal: 'Edit original',
      copyOriginal: 'Copy original',
      notStarted: 'Not started',
      executing: 'Executing...',
      ended: 'Finished',
      status: 'Status',
      pending: 'Pending',
      ok: 'OK',
      total: 'Total',
      failedOrders: 'Failed orders',
      exceptionsHeading: 'Exceptions for orders',
      addExceptionHeading: 'New exception period',
      exceptionStart: 'Start',
      exceptionEnd: 'End',
      editExceptions: 'Edit exceptions',
      addRecurring: 'Recurring',
      addToGroup: 'Select recurring group',
      exceptions: 'Exceptions',
      recurringOrderAddedConfirmation: 'Order added to recurring group',
      exceptionAddedConfirmation: 'Exception added',
      dispatchAsGroup: 'Dispatch as group',
      name: 'Name',
      frequency: 'Frequency',
      frequencies: {
        weekly: 'Every week',
        second_week: 'Every second week',
        third_week: 'Every third week'
      },
      daysOfWeek: 'Days of week',
      includeHolidays: 'Include holidays',
      startDate: 'Start date',
      endDate: 'End date (optional)',
      save: 'Save',
      fromDate: 'From date',
      confirmDelete: 'Are you sure you want to delete the selected recurring order(s)?',
      from: 'From',
      createOrders: 'Create orders',
      showResult: 'Show result',
      startdate: 'Start date',
      enddate: 'End date',
      orders: 'Orders',
      failed: 'Failed',
      by: 'By',
      on: 'on',
      reset: 'reset',
      confirmReset: 'Are you sure you want to reset recurring orders for {{date}}?'
    },
    corrections: {
      title: 'Corrections',
      headline: 'Order corrections for {{date}}',
      accepted: 'Order correction accepted',
      rejected: 'Order correction rejected',
      approve: 'APPROVE',
      decline: 'DECLINE',
      shipmentId: 'Shipment Id',
      courier: 'Courier',
      from: 'From',
      to: 'To',
      details: 'Details',
      pickupWaitingTime: 'PU waiting time',
      deliveryWaitingTime: 'DEL waiting time',
      pickupLoadingTime: 'PU loading time',
      deliveryLoadingTime: 'DEL loading time',
      comment: 'Comment',
      weight: 'Weight',
      volume: 'Volume',
      packages: 'Packages',
      serviceCode: 'Service code'
    },
    dispatch: {
      assigningMultilegDelivery: 'Assigning {{count}} multileg delivery',
      assigningMultilegDelivery_plural: 'Assigning {{count}} multileg deliveries',
      turningThisOnExplanation: '(Turning this on lets you override the route plan)'
    },
    suggestedUnits: {
      unitName: 'Unit Name',
      driverName: 'Driver Name',
      slotName: 'Route',
      timeAtLocation: 'Time at Location',
      distanceFromLocation: 'Distance from location',
      shipmentInformation: 'Shipment Information',
      suggestedVehicles: 'Suggested vehicles',
      suggestedVehiclesOnPickup: 'Suggested vehicles on pickup',
      suggestedVehiclesOnDelivery: 'Suggested vehicles on delivery',
      suggestedUnits: 'Suggested Units',
      noSuggestionsAvailable: 'No suggestions available',
      pickupTime: 'Pickup Time',
      deliveryTime: 'Delivery Time',
      filterBy: 'Filter by'
    },
    shipmentSummary: {
      unPrePlan: 'Unpreplan',
      prePlanTo: 'Preplan to'
    }
  },
  errors: {
    DUPLICATE: 'Duplicate data',
    MISSING_DEPARTMENT_DETAILS:
      'The department does not have area and postal code information. Please add that before proceeding.',
    DEPARTMENT_INVALID_ALYSTRA_ID: 'Department invalid alystra id',
    ENTITY_DOES_NOT_EXIST: 'Entity does not exist',
    INVALID_DATE_RANGE: 'Invalid date range',
    INVALID_DELIVERY_LATITUDE: 'Invalid delivery latitude',
    INVALID_DELIVERY_LONGITUDE: 'Invalid delivery longitude',
    INVALID_LATITUDE: 'Invalid latitude',
    INVALID_LONGITUDE: 'Invalid longitude',
    INVALID_OR_MISSING_TIME: 'Invalid or missing time',
    INVALID_PHONE_NUMBER: 'Invalid phone number',
    INVALID_BANNER_NAME: 'Invalid banner name',
    INVALID_WHATS_THIS_NAME: "Invalid What's this modal name",
    INVALID_CREDIT_ORDER: 'Cannot credit an order which has not been invoiced or has already been credited',
    DUPLICATE_PHONE_NUMBER: 'Duplicate phone number!',
    INVALID_PICKUP_LATITUDE: 'Invalid pickup latitude',
    INVALID_PICKUP_LONGITUDE: 'Invalid pickup longitude',
    INVALID_SLOT_TYPE: 'Invalid slot type',
    MISSING_ADDRESS: 'Missing address',
    MISSING_ALYSTRA_ID: 'Missing alystra id',
    MISSING_AREA: 'Missing area',
    MISSING_CAPACITY: 'Missing capacity',
    MISSING_COLOR: 'Missing color',
    MISSING_CONSIGNMENT_INPUTS: 'Missing consignment inputs',
    MISSING_CONSIGNMENT: 'Missing consignment',
    MISSING_CONSIGNMENTS: 'Missing consignments',
    MISSING_COURIER_ID: 'Missing courier id',
    MISSING_COURIER: 'You must select a courier',
    MISSING_CREATED_AT: 'Missing created at',
    MISSING_CUSTOMER_ID: 'Missing customer id',
    MISSING_CUSTOMER_REF: 'Missing customer ref',
    MISSING_DATE: 'Missing date',
    MISSING_DAYS: 'Missing days',
    MISSING_DEPARTMENT: 'Missing department',
    MISSING_DESCRIPTION: 'Missing description',
    MISSING_DEVIATION_TEXT: 'Missing deviation text',
    MISSING_END_LOCATION_LATITUDE: 'Missing end location latitude',
    MISSING_END_LOCATION_LONGITUDE: 'Missing end location longitude',
    MISSING_EVENT_DATA: 'Missing event data',
    MISSING_EVENT_TYPE: 'Missing event type',
    MISSING_FILE_NAME: 'Missing file name',
    MISSING_FROM_DATE: 'Missing from date',
    MISSING_ID: 'Missing id',
    MISSING_LOCATION_LATITUDE: 'Missing location latitude',
    MISSING_LOCATION_LONGITUDE: 'Missing location longitude',
    MISSING_LOCATION_POSITION: 'Missing location position',
    MISSING_LOCATION_TIME: 'Missing location time',
    MISSING_NAME: 'Missing name',
    MISSING_GROUP: 'Missing grouping',
    MISSING_PACKAGE_ID: 'Missing package id',
    MISSING_PHONE: 'Missing phone',
    MISSING_PLANNER_ID: 'Missing planner id',
    MISSING_SERVICE_CODE: 'Missing service code',
    MISSING_SHIPMENT_ID: 'Missing shipment id',
    MISSING_SLOT_ID: 'Missing slot id',
    MISSING_SLOT_TYPE: 'Missing slot type',
    MISSING_SMS_TOKEN: 'Missing sms token',
    MISSING_START_LOCATION_LATITUDE: 'Missing start location latitude',
    MISSING_START_LOCATION_LONGITUDE: 'Missing start location longitude',
    MISSING_TO_DATE: 'Missing to date',
    MISSING_ZIP_AREA: 'Missing zip area',
    MISSING_ZIP_CODE: 'Missing zip code',
    MISSING_COUNTRY_CODE: 'Missing country code',
    NO_SUCH_USER: 'No such user',
    PARAM_NOT_OF_EXPECTED_TYPE: 'Param not of expected type',
    PARAM_NULL_NOT_ALLOWED: 'Param null not allowed',
    QUERY_NAME_MISSING: 'Query name missing',
    QUERY_PARAMS_MISSING: 'Query params missing',
    USER_NOT_EXIST_OR_INCORRECT_ROLE: 'User not exist or incorrect role',
    USER_EXIST_BUT_INCOMPATIBLE_ROLE: 'User already exists and has another role',
    SLOT_CANNOT_BE_STARTED: 'Slot cannot be started',
    SLOT_CANNOT_BE_DELETED: 'Slot cannot be deleted',
    COURIER_NOT_PART_OF_DEPARTMENT: 'Courier not part of department',
    CONSIGNMENT_IN_INCORRECT_STATE_CANNOT_UPDATE: 'Consignment in incorrect state cannot update',
    ORDER_DOES_NOT_EXISTS: 'Order does not exists',
    OPTIMIZE_ALREADY_RUNNING: 'Optimize already running',
    OVERLAPPING_BANNERS: 'Url is overlapping with a existing banner',
    OVERLAPPING_WHATS_THIS_MODALS: "Url is overlapping with a existing what's this modal",
    CANNOT_CHANGE_ORDER: 'Cannot change order',
    NO_ACCESS: 'No access',
    NO_RELEVANT_CONSIGNMENTS_OR_SLOTS: 'No relevant consignments for given slots',
    NO_RELEVANT_CONSIGNMENTS: 'No relevant consignments for given slots',
    NO_RELEVANT_SLOTS: 'Only routes not started or on hold can be optimized using this feature',
    NOT_EQUAL_PICKUP_ADDRESSES: 'All pickup addresses must be equal to restrict all shipments to one driver',
    NO_SUGGESTIONS_FOUND_FOR_ORDERS: 'No drivers eligible for the orders',
    NO_SUCH_QUERY: 'No such query',
    EXCEL_MISSING_SHIPMENT_ID: 'Missing shipment id in cell {{col}} on row {{row}}',
    EXCEL_MISSING_CUSTOMER_REF: 'Missing customer ref in cell {{col}} on row {{row}}',
    EXCEL_MISSING_CUSTOMER_ID: 'Missing customer id in cell {{col}} on row {{row}}',
    EXCEL_MISSING_DEPARTMENT: 'Missing department in cell {{col}} on row {{row}}',
    EXCEL_MISSING_PACKAGE_ID: 'Missing package id in cell {{col}} on row {{row}}',
    EXCEL_MISSING_NAME: 'Missing name in cell {{col}} on row {{row}}',
    EXCEL_INVALID_PHONE: 'Invalid phone number in cell{{col}} on row {{row}}',
    EXCEL_MISSING_ADDRESS: 'Missing address in cell {{col}} on row {{row}}',
    EXCEL_MISSING_ZIP_AREA: 'Missing zip code in cell {{col}} on row {{row}}',
    EXCEL_MISSING_ZIP_CODE: 'Missing city in cell {{col}} on row {{row}}',
    EXCEL_MISSING_FROM_DATE: 'Missing from date in cell {{col}} on row {{row}}',
    EXCEL_MISSING_TO_DATE: 'Missing to date in cell {{col}} on row {{row}}',
    EXCEL_INVALID_FROM_DATE: "From date can't be before today. Found in cell {{col}} on row {{row}}",
    EXCEL_INVALID_TO_DATE: "To date can't be before today. Found in cell {{col}} on row {{row}}",
    EXCEL_MISSING_NOTIFICATION_SMS: 'Missing notification SMS in cell {{col}} on row {{row}}',
    EXCEL_MISSING_SIZE: 'Missing size in cell {{col}} on row {{row}}',
    EXCEL_MISSING_ORDERS: 'No orders found in the spreadsheet.',
    EXCEL_MISSING_COUNTRY: 'Missing country code or name in cell {{col}} on row {{row}}',
    EXCEL_NO_ACCESS_DEPARTMENT: 'No access to department "{{dept_ref}}" as found in on row {{row}}',
    EXCEL_MISSING_DESCRIPTION: 'Missing description in "{{cell}}" on row {{row}}',
    EXCEL_MISSING_PICKUP_INSTRUCTIONS: 'Missing pickup description in "{{cell}}" on row {{row}}',
    EXCEL_MISSING_DELIVERY_INSTRUCTIONS: 'Missing delivery description in "{{cell}}" on row {{row}}',
    MISSING_PICKUP_LOCATION_AND_TIME: 'Missing pickup location and time',
    MISSING_EARLIEST_PICKUP_DATE: 'Missing earliest pickup date',
    MISSING_LATEST_PICKUP_DATE: 'Missing latest pickup date',
    MISSING_EARLIEST_DELIVERY_DATE: 'Missing earliest delivery date',
    MISSING_LATEST_DELIVERY_DATE: 'Missing latest delivery date',
    INVALID_EARLIEST_PICKUP_DATE: 'Invalid pickup date',
    INVALID_LATEST_PICKUP_DATE: 'Invalid pickup date',
    INVALID_EARLIEST_DELIVERY_DATE: 'Invalid delivery date',
    INVALID_LATEST_DELIVERY_DATE: 'Invalid delivery date',
    CANNOT_MODIFY_ORDERS_FROM_DIFFERENT_SOURCE: 'Cannot modify API orders from Excel',
    PICKUP_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST: 'Pickup time should be before delivery time',
    DELIVERY_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST: 'Delivery earliest must be before delivery latest',
    CANNOT_DELIVER_ON_A_HOLIDAY: 'Cannot deliver on a holiday',
    MISSING_TEMPLATE_ID: 'Missing template id',
    TEMPLATE_DOES_NOT_EXIST: 'The template does not exist',
    TEMPLATE_CANNOT_BE_DELETED: 'The template cannot be deleted',
    MISSING_TEMPLATE_SLOTS: 'The template is missing slots',
    TEMPLATE_NAME_ALREADY_EXISTS: 'Template with this name already existing',
    COURIER_ALREADY_EXISTS: 'There is already another courier with this phone number',
    ORDER_EXIST_FOR_SERVICE: 'Cannot delete service as orders exist for this service',
    NO_SERVICE_FOUND: 'Add a service before adding customer mapping',
    CANNOT_DELETE_AS_SERVICE_EXIST: 'Delete customer service mapping before deleting service',
    SLOT_IN_INCORRECT_STATE_CANNOT_UPDATE: 'Slot is not in a valid state for this update',
    GRAPHHOPPER_UNASSIGNED:
      'Could not assign all shipments (because of time, capacity or other factors), details: {{details}}',
    GRAPHHOPPER_REQUEST_FAILED: 'The request for optimizing the route failed',
    GRAPHHOPPER_LATEST_ARRIVAL_AT_DELIVERY_AFTER_EARLIEST_DEPARTURE:
      'Latest arrival at delivery cannot be after earliest departure - is pickup time window in the past?',
    GRAPHHOPPER_EARLIEST_GREATER_THAN_LATEST:
      'Earliest time cannot be greater than latest - is some of the times in the past?',
    GRAPHHOPPER_PICKUP_EARLIEST_AFTER_LATEST:
      'Pickup time window for shipment {{shipmentId}} cannot be in the past ({{pickupEarliest}} - {{pickupLatest}})',
    GRAPHHOPPER_DELIVERY_EARLIEST_AFTER_LATEST:
      'Delivery time window for shipment {{shipmentId}} cannot be in the past ({{deliveryEarliest}} - {{deliveryLatest}})',
    SLOT_CANNOT_BE_EDITED: 'Slot is not in a valid state for this update',
    INVALID_POSTCODE_AND_COUNTRY_CODE_CANNOT_BE_HANDLED: 'Invalid zip code and country code',
    INVALID_BASIC_SERVICE_CODE: 'Invalid basic service code',
    INVALID_PICKUP_DATE_EARLIEST_FORMAT: 'Invalid pickup date earliest format',
    INVALID_PICKUP_DATE_LATEST_FORMAT: 'Invalid pickup date latest format',
    INVALID_DELIVERY_DATE_LATEST_FORMAT: 'Invalid delivery date latest format',
    INVALID_DELIVERY_DATE_EARLIEST_FORMAT: 'Invalid delivery date earliest format',
    INVALID_POSTAL_CODE: 'Invalid zip code',
    INVALID_MEASUREMENT_UNIT: 'Invalid measurement unit',
    MISSING_CONSIGNMENT_SET: 'Missing consignment set',
    MISSING_UPDATE_INDICATOR: 'Missing update indicator',
    MISSING_CONSIGNMENT_ID: 'Missing consignment id',
    MISSING_REFERENCE_NO: 'Missing reference id',
    MISSING_PACKAGE: 'Missing package',
    MISSING_DELIVERY_DATE_EARLIEST: 'Missing delivery date earliest',
    MISSING_DELIVERY_DATE_LATEST: 'Missint delivery date latest',
    MISSING_CONSIGNEE: 'Missing consignee',
    MISSING_CONSIGNOR: 'Missing consignor',
    MISSING_POSTAL_CODE: 'Missing zip code',
    MISSING_CITY: 'Missing zip are',
    MISSING_PICKUP_DATE_LATEST: 'Missing pickup date latest',
    MISSING_PICKUP_DATE_EARLIEST: 'Missing pickup date earliest',
    MISSING_CONSIGNOR_PARTY_ID: 'Missing consignor party id',
    INVALID_CUSTOMER_NO_MAPPING: 'Invalid customer number mapping',
    INVALID_ROUTING: 'Invalid routing',
    NO_SERVICE_MATCHING_SERVICE_CODE: 'No service matching service code',
    NO_SERVICE_MATCHING_DAY_OF_WEEK: 'No service matching day of week',
    NO_SERVICE_MATCHING_PICKUP_AND_DELIVERY_TIMES: 'No service matching pickup and delivery times',
    ORDER_WAS_AFTER_CUTOFF_TIME: 'Order was after cutoff time',
    OPTIMUS_SERVICE_MUST_HAVE_PICKUP_AND_DELIVERY_TIMES: 'Must have pickup and delivery times',
    ORDER_CANNOT_BE_MOVED: 'The shipment cannot be moved, it must not be assigned to a slot',
    MISSING_DESTINATION_DEPARTMENT_ID: 'Missing destination department',
    SHIPMENT_NOT_FOUND: 'Shipment not found',
    DUPLICATE_EXCEL_IMPORT:
      'The same filename has already been imported to this department today. You can see the results of that import <0>here</0>. Please change the filename if you wish to import it again.',
    noOrdersForDateAndCourier: 'No orders present for the selected date and unit',
    SHIPMENT_INVALID_QUERY:
      'During Peak search will be limited to ensure performance. You can search for all orders on one day or add a search parameter.',
    DURATION_IS_MORE: 'Please limit your search to a period of {{maxDuration}} days.',
    ADDRESS_CANNOT_BE_DELETED: 'Address is used as default address and cannot be deleted.',
    INVALID_ORDER_BILLING_CUSTOMER_NUMBER:
      '{{locale}}Order billing customer number for type - {{orderBillingType}} is either null or invalid',
    MISSING_ORDER_BILLING_TYPE: 'Missing order billing type',
    INVALID_ORDER_BILLING_TYPE:
      'Order billing type must be one of {{orderBillingTypes}}, but was - {{orderBillingType}}',
    INVALID_ORDER_BILLING_INFO: 'Invalid order billing info',
    UNKOWN_ERROR: 'Changes could not be saved',
    INCORRECT_STATES_OR_ASSIGNED_TO_SLOT: 'Incorrect state or assigned to route',
    CANNOT_MOVE_ORDERS: 'Cannot move orders',
    DESTINATION_DEPARTMENT_INELIGIBLE: 'Destination department ineligable',
    DEPARTMENT_MISSING_ADDRESS_DETAILS: 'Department is missing address details',
    MISSING_DELIVERY_PHONE_NUMBER: 'Phone number is missing for',
    INVALID_DELIVERY_PHONE_NUMBER: 'Phone number is not valid for',
    SMS_SERVICE_NOT_ORDERED: 'SMS service is not ordered for',
    CANNOT_SEND_SMS_FOR_MESTER_GRONN_FUNERAL: 'Mester Grønn funeral is ordered for',
    SLOT_ROUTED_IN_HAPPY_FLOW: 'Slot is routed in Happy Flow for',
    SERVICE_LEVEL_SHOULD_NOT_BE_NONE: 'Service level is "none" for',
    SLOT_NAME_ALREADY_CREATED: 'Route name already exists',
    ORDER_IN_INCORRECT_STATE: 'Order is in incorrect state',
    ORDER_IN_INCORRECT_STATE_plural: 'Order is in incorrect state',
    INVALID_PARAMETERS: 'Invalid parameters, {{description}}',
    ROLE_IN_USE: 'The role cannot be removed since the role is in use.',
    INVALID_NAME: 'Role name must be unique. A role with this name already exist.',
    TEXT_CANNOT_BE_DELETED:
      'Text is used on a service and cannot be deleted. Disconnect text from service in order to delete.',
    FAILED_DELETING_WEBHOOK_SUBSCRIPTION: 'Webhook subscription could not be deleted. Try again later.',
    DEFAULT_ROLE_ALREADY_EXISTS: 'A different role {{name}} is already default for the same user type'
  },
  customer: {
    successfullyCreated: '{{name}} was successfully created',
    successfullyUpdated: '{{name}} was successfully updated',
    showAll: 'Show all customers',
    customer: 'Customer',
    apiKey: {
      tabName: 'API key',
      generateApiKey: 'Generate API Key',
      bodyUnsaved: 'You first need to save and publish the customer in order to be able to generate an API Key',
      body: 'The API key is a unique key per customer. The key is used when your customer are creating a booking in the customer portal. <0>Read more about our API here.</0>',
      generateNewApiKey: 'Generate new API Key',
      allowApiKeyForCustomer: 'Allow API key for customer',
      allowApiKeyBody: 'Before generating an API key, you first need to allow this action to be done.',
      allowApiKey: 'Allow API key',
      copyApiKeyInfoText: 'Make sure to copy the API key now. You won’t be able to see it again!',
      yourApiKey: 'Your API Key',
      copyApiKey: 'Copy API Key',
      apiKeyCopied: 'API key has been copied',
      customerIsBlocked: "This customer is blocked and can't create any bookings. Unblock to activate the customer.",
      keyAlreadyExists: 'You have already generated an API key for this customer.',
      blockCustomer: 'Block customer',
      unblockCustomer: 'Unblock customer'
    },
    groups: {
      title: 'Customer groups',
      titleSingular: 'Customer group',
      titleWithCount: '{{count}} customer groups',
      name: 'Group name',
      description: 'description',
      numberOfCustomers: 'Number of customers',
      none: 'No customer groups',
      new: 'New customer group',
      edit: 'Edit customer group',
      searchCustomer: 'Search customer',
      members: 'Customers in group',
      save: 'Save',
      deleteConfirm: 'Are you sure you want to delete customer group?',
      deleted: 'Customer group deleted.',
      cannotDelete: "Customer group can't be deleted because it is used in a preadvice rule."
    },
    customerTitle: 'Customers',
    customerNo: 'Customer No',
    name: 'Name',
    emailToSender: 'Email to sender',
    emailSubscriptions: 'Email subscriptions',
    emailToSenderDescription:
      'Set default notifications for all orders from this customer number, for example order registration and deliveries.',
    deviations: 'Deviations',
    deviationsDescription: 'Set up customer specific deviations in the courier app.',
    settings: {
      successfullyAdded: 'was successfully added',
      notSet: 'Not set yet',
      deviations: {
        addAndManageDeviations: 'Add and manage deviations',
        addToList: 'Add deviation to list',
        addChangesToList: 'Add deviation changes to list',
        editDeviationsFor: 'Edit deviations for {{code}}',
        addedDeviations: 'Added deviations ({{count}})',
        writeCode: 'Write the correct code in the fields below.',
        writeDescriptions: 'Write descriptions for the deviation in the text fields',
        headline: 'Headline',
        description: 'Description',
        headlineError: 'You must provide a headline',
        headlineForAllLanguagesError: 'You must fill out headline for all languages',
        required: 'Required'
      },
      webhook: {
        overviewTitle: 'Webhook Configuration',
        create: 'Click on the button to create a Webhook configuration',
        delete: {
          title: 'Delete subscription',
          description: 'You will not be able to recover this action',
          successfullyDeleted: 'Webhook subscription was successfully deleted'
        },
        newOrEdit: {
          createTitle: 'Create new webhook configuration',
          editTitle: 'Edit webhook configuration',
          created: 'New webhook configuration was successfully created',
          updated: 'Webhook configuration was successfully updated',
          endpoint: 'Endpoint',
          endpointDescription: 'Enter the URL where the event data should be sent',
          httpAuthentication: 'HTTP Basic Authentication',
          username: 'Username',
          password: 'Password',
          retryPolicy: 'Retry policy',
          retryPolicyDescription: 'If the webhook fails, configure the times and delays between retries.',
          retry: '{{count}} retry',
          retry_plural: '{{count}} retries',
          retryDescription: 'Number of retries',
          delay: {
            description: 'Delay before retrying',
            value: 'Delay {{seconds}} seconds'
          },
          pause: 'Pause this subscription for now',
          pauseTooltip:
            'Pause will queue events send out until it is released again. Upon release queued events will be resent.',
          validation: {
            endpointError: 'You must provide a valid URL',
            endpointRequired: 'URL is required',
            usernameRequired: 'Username is required',
            passwordRequired: 'Password is required',
            passwordNotLongEnoughError: 'Password must be at least 8 characters',
            noEventSelectedError: 'You must select at least one event',
            retryDelayRequired: 'Retry delay is required'
          }
        },
        count: '{{count}} subscription',
        count_plural: '{{count}} subscriptions',
        noSubscriptions: 'You have no subscriptions at the moment',
        events: {
          prefix: 'Subscribed to ',
          all: 'all',
          suffix: ' events'
        },
        state: {
          running: 'Running',
          paused: 'Paused'
        },
        event: {
          CREATED: 'order is registered',
          ROUTE_PLANNING_COMPLETE: 'delivery route has been finalized',
          COLLECTED: 'driver collected the goods',
          DELIVERED: 'driver delivered the goods',
          DEVIATED: 'driver has added deviation to the order',
          SCANNED: 'order has passed transit terminal',
          ARRIVED_AT_TERMINAL: 'order has arrived to distributing terminal',
          DAMAGED: 'order has been damaged',
          RETURNED: 'driver handed over goods back to terminal',
          PICKUP_ETA_SMS_SENT: 'sms with estimated time of arrival for collection has been sent',
          ETA_SMS_SENT: 'sms with estimated time of arrival for delivery has been sent',
          PRE_ADVICE_PULL_SMS_SENT: 'sms with possible delivery windows has been sent',
          DELIVERY_TIME_ORDERED: 'recipient has chosen delivery time',
          PRE_ADVICE_PUSH_SMS_SENT: 'sms with ordered delivery window has been sent to recipient',
          PICKUP_SMS_SENT: 'sms about coming delivery has been sent to recipient',
          APARTMENT_INFORMATION_UPDATED: 'recipient has update delivery details'
        }
      }
    },
    add: 'Add',
    noOrders: 'No orders in Glow',
    noOrdersDescription: 'The customers you have access to do not have any orders in Glow',
    choose: 'Please select a customer',
    findCustomer: 'Find a customer',
    manageCustomers: 'Manage customers',
    searchAndSelect: 'Search and select customer',
    changeCustomer: 'Change customer',
    customerPlaceHolder: 'Search for customers',
    emailRecipients: 'Email recipients',
    smsRecipients: 'Sms recipients',
    addEditEmails: 'Add/edit emails',
    addEditSms: 'Add/edit sms',
    addEditDeviations: 'Add/edit deviations',
    live: 'Live',
    search: 'Search',
    startTypingPlaceHolder: 'Start typing',
    AddEdit: 'Add/Edit',
    searchHint: 'Search for shipments based on shipment id, customer ref, package id, delivery name or address',
    customerNumber: 'Customer number',
    customerNumberAlreadyExists: 'Customer number already exists',
    senderName: 'Sender name',
    senderNameIngress: 'Override the customer name set in Glow when sending sms notifications.',
    addSenderName: 'Add a sender name',
    senderNameAdded: 'Sender name added',
    addInvoiceHDOrdersFromDate: 'Add start date for HD order invoicing through Glow',
    invoiceHDOrdersFromDate: 'Invoice HD orders in Glow from date',
    invoiceHDOrdersFromDateDescription: 'Set a date for when Glow Pricing Engine is activated for HD customers.',
    invoiceHDOrdersFromDateAdded: 'Start date for HD order invoicing in Glow has been changed',
    addNPSFromDate: 'Add start date for NPS',
    activateNPSFromDate: 'Activate NPS from date',
    activateNPSFromDateIngress:
      'Set a start date for activating NPS (Net Promoter Score) from Medallia. This function requires the customer to also be set up in Medallia.',
    npsFromDateUpdated: 'Start date for NPS has been changed',
    scanOnPickup: {
      title: 'Scan packages on pickup',
      ingress: 'Turn on if the courier should be forced to scan packages on pickup.'
    },
    scanToCreatePackages: {
      title: 'Scan to create packages',
      label: 'Use scan to create packages',
      details:
        'When enabling scan to create packages the courier is forced to scan packages on pickup. The scanned packages will automatically be updated on the shipment',
      success: 'Scan to create packages updated',
      enabled: 'enabled',
      disabled: 'disabled'
    },
    billingCustomerNumber: 'Billing customer number',
    billingCustomerNumberIngress:
      'Override the invoice number if the invoicing should happen on a different customer number.',
    addBillingCustomerNumber: 'Add billing customer number',
    billingCustomerNumberAdded: 'Billing customer number added',
    searchPage: {
      fromDate: 'From date',
      toDate: 'To date',
      today: 'Today',
      tomorrow: 'Tomorrow',
      yesterday: 'Yesterday',
      last30days: 'Last 30 days',
      plusMinus15Days: '+/- 15 days',
      plusMinus30Days: '+/- 30 days'
    },
    smsText: {
      title: 'Override notification texts',
      ingress: 'Set up customer unique texts in the SMS to recipients.',
      addEditSmsText: 'Add/edit texts',
      smsTextUpdated: 'SMS Text added/ updated',
      en: 'ENG',
      se: 'SWE',
      no: 'NOR',
      fi: 'FIN',
      dk: 'DEN',
      fr: 'FRA',
      description: 'Description',
      submit: 'Submit',
      smsType: 'Notification type',
      smsTypeTexts: {
        common: 'SMS',
        preadvice: 'Preadvice',
        pickup: 'Tracking SMS',
        pre_pickup: 'Pre-tracking SMS',
        manual_delay: 'Manual Delay SMS',
        eta_pickup: 'ETA Pickup SMS',
        eta_delivery: 'ETA Delivery SMS',
        flex_delivery_confirmation: 'Flex Delivery Confirmation SMS',
        air_express_tracking: 'Air Express Tracking SMS',
        pickup_at_airport: 'Pickup at Airport SMS',
        advise: 'Advise SMS',
        delivery_confirmation: 'Delivery Confirmation SMS',
        pre_advice_push: 'Push SMS',
        pre_advice_push_for_prebooked: 'Push for prebooked customers SMS',
        pre_advice_push_return: 'Push Return SMS',
        pre_advice_push_email: 'Push email',
        pre_advice_push_2nd: 'Push 2nd preadvice SMS',
        pre_advice_push_2nd_email: 'Push 2nd preadvice email',
        pre_advice_push_return_to_sender_email: 'Push return to sender email',
        pre_advice_pull: 'Pull SMS',
        pre_advice_pull_email: 'Pull email',
        pre_advice_pull_reminder: 'Pull reminder SMS',
        pre_advice_pull_reminder_email: 'Pull reminder email',
        pre_advice_pull_reminder_to_sender_email: 'Pull reminder to sender email',
        pre_advice_pull_return_to_sender_email: 'Pull return to sender email',
        pre_advice_return: 'Return delivery SMS',
        pre_advice_return_email: 'Return delivery email',
        pre_advice_return_reminder: 'Return delivery påminnelse SMS',
        pre_advice_return_reminder_email: 'Return delivery påminnelse email',
        pre_advice_return_2nd: 'Return delivery 2nd preadvice SMS',
        pre_advice_return_2nd_email: 'Return delivery 2nd preadvice email',
        pre_advice_return_return_to_sender_email: 'Return delivery return to sender email',
        pre_advice_pup: 'PUP SMS',
        pre_advice_pup_email: 'PUP email',
        pre_advice_pup_reminder: 'PUP reminder SMS',
        pre_advice_pup_reminder_email: 'PUP reminder email',
        pre_advice_pup_reminder_to_sender_email: 'PUP reminder to sender email',
        pre_advice_pup_return_to_sender_email: 'PUP return to sender email',
        delivery: 'Delivery SMS',
        deviated: 'Delivery deviation SMS'
      }
    },
    showNotCollected: 'Not collected',
    booking: {
      pickupReady: 'Pickup ready',
      pickup: 'Pickup',
      delivery: 'Delivery',
      deliveryAvailable: 'Delivery available',
      serviceMessage: 'Remember to add both pickup and delivery information to see available services',
      priceComment:
        'You will be billed according to your customer agreement with Bring. Fees are not included in this price.',
      serviceUnavailable: 'The seleced service is not available for postal code {{postalCode}}',
      recipientPrice: 'Recipient price:',
      customerContactPlaceholder: 'Ordered by – This field is visible on the customer invoice'
    },
    email: {
      addToList: 'Add email to list',
      addChangesToList: 'Add email changes to list',
      emailTitle: 'E-mail to customer',
      getNotificationsFor: 'Get notifications for',
      emailAddress: 'Email address',
      emailAddressError: 'You must enter an email address',
      createdEvent: 'Registered',
      collectedEvent: 'Pickup',
      deliveredEvent: 'Delivery',
      returnToSender: 'Return to sender',
      reminderToSender: 'Reminder to sender',
      save: 'Save',
      email: 'E-mail',
      edit: 'Edit',
      delete: 'Delete',
      emailExists: 'E-mail already exists',
      invalidEmail: 'Invalid e-mail',
      true: 'Yes',
      false: 'No',
      claimsReported: 'Claims reported by recipient',
      claims: 'Claims',
      notificationType: {
        standard: 'Standard information',
        hdPreAdvice: 'HD preadvice',
        reportsFromRecipients: 'Reports from recipients',
        error: 'You must enter type of notifications'
      },
      customizedNotifications: 'Customized notifications ({{count}})',
      mustAddEmailToList:
        'In order for the email addresses to be saved when pressing add setting, you must add them to the list',
      informationToCustomer: 'Information to customer',
      returnToCustomer: 'Return to customer',
      addEmailToList: 'Add email to list',
      registeredEmailSubscription: 'Registered email subscription',
      updateEmail: 'Update email',
      noRegisteredEmails: 'No registered emails',
      detailsSavedSuccessfully: 'Email to sender was successfully saved'
    },
    customerDeviations: {
      new: 'Add new Deviation',
      deviationTitle: 'Deviations for customer',
      addDeviation: 'Add new deviation',
      editDeviation: 'Edit deviation',
      en: 'ENG',
      sv: 'SWE',
      nb: 'NOR',
      fi: 'FIN',
      dk: 'DEN',
      event: 'Event',
      reason: 'Reason',
      action: 'Action',
      submit: 'Submit',
      deviationCode: 'Deviation codes',
      deviation: 'Deviation',
      deviationType: 'Deviation type',
      description: 'Description',
      code: 'Code',
      edit: 'Edit',
      delete: 'Delete',
      deviationAdded: 'Deviation added',
      deviationEdited: 'Deviation edited',
      deviationExists: 'Deviation already exists',
      deviationDeleted: 'Deviation deleted'
    },
    customerInfo: 'Customer Info',
    customerInformation: 'Customer information',
    customerName: 'Customer Name',
    invoicingAddress: 'Invoicing Address',
    organisationNumber: 'Organisation Number',
    visitingAddress: 'Visiting Address',
    creditBlocked: 'Credit Blocked',
    creditBlockedFrom: 'Credit Blocked from date',
    creditBlockedFromInfo: 'Block credit for this customer from a select date',
    creditBlockedIngress: 'Set the last date to create orders in Glow for this customer.',
    from: 'From',
    address: 'Address',
    zipCode: 'Zip Code',
    zipArea: 'Zip Area',
    country: 'Country',
    edit: 'Edit {{name}}',
    selectCountry: 'Select country',
    settingsInGlow: 'Settings in Glow',
    addSetting: 'Add setting',
    editSetting: 'Edit setting',
    addNewCustomer: 'Add new customer',
    bookingPage: 'Booking page',
    bookingPageCustomerPortal: 'Booking page on Customer Portal',
    bookingPageIngress:
      'Turn off booking if the customer should not be able to book shipments on this customer number in the Glow customer portal.',
    bookingPageModalHeading: 'Booking in Customer portal',
    bookingPageModalDescription:
      'Turn off booking if the customer should not be able to book shipments on this customer number in the Glow customer portal.',
    brandedTrackingModalHeading: 'Branded Tracking Invoicing',
    brandedTrackingModalDescription: 'Turn on to activate invoicing for Branded Tracking.',
    returnLastLegModalHeading: 'Return last leg',
    returnLastLegModalDescription:
      'Selecting "yes" splits the order and adds return delivery leg. "No" indicates no delivery leg needs to be created. "Not set" indicates that return handling rule is not defined for a specific customer.',
    returnAddressModalHeading: 'Goods return address',
    removeReturnAddressModalHeading: 'Remove current goods return address',
    removeReturnAddressConfirm: 'Are you sure you want to remove the current goods return address?',
    returnAddressModalDescription:
      'Enter the goods return address to use when goods are being returned from the terminal',
    returnAddressCustomerNumber: 'Customer number: {{customerNumber}}',
    bookingActive: 'On',
    bookingInactive: 'Off',
    brandedTrackingActive: 'On',
    brandedTrackingInactive: 'Off',
    bookingPageAvailable: 'On',
    bookingPageHidden: 'Off',
    customerBookingPageAccessUpdated: 'Booking page access updated',
    brandedTrackingAccessUpdated: 'Branded Tracking Invoicing status updated',
    returnLastLegUpdated: 'Return last leg status updated',
    returnAddressUpdated: 'Return address updated',
    returnAddressRemoved: 'Return address removed',
    connectMatrices: 'Connect matrices',
    customerMatrices: 'Customer matrices',
    customerMatricesIngress: 'Connect customer number to existing matrices.'
  },
  serviceMapping: {
    title: 'Services',
    serviceName: 'Service Name',
    pickupTime: 'Pickup Time',
    deliveryTime: 'Delivery Time',
    internalName: 'Internal name',
    internalDescription: 'Internal description',
    externalName: 'External name',
    externalDescription: 'External description',
    alystraId: 'Alystra Id',
    allowFlexDelivery: 'Allow Flex Delivery',
    timeWindow: 'Time window',
    serviceMappingType: 'Type',
    service: 'Service',
    vas: 'VAS',
    stopsBeforeShowEta: 'ETA Stops',
    minutesUntilDelivery: 'ETA Min',
    sendAutomaticEtaSmsHeading: 'ETA SMS',
    createNew: 'Create new',
    serviceAndVasCodes: 'Services and VAS Codes',
    successfullyAdded: 'Service mapping was successfully added to the list',
    successfullyUpdated: 'Service mapping was successfully updated',
    successfullyDeleted: 'Service mapping has been successfully deleted',
    editService: 'Edit service',
    createNewService: 'Create new service',
    settingsHeading: 'Settings for service or VAS',
    selectLevel: 'Select level',
    standardLevel: 'Standard',
    customerLevel: 'Customer',
    customerSpecific: 'Customer specific',
    selectServiceType: 'Select type of service',
    vasToolTip: 'Value Added Service ex. ID Control',
    customerNumber: 'Customer Number',
    pickupTimeInSeconds: 'Pickup Time (seconds)',
    pickupTimeSubsequentShipments: 'Pickup Time Subsequent Shipments (seconds)',
    deliveryTimeInSeconds: 'Delivery Time (seconds)',
    deliveryTimeSubsequentShipments: 'Delivery Time Subsequent Shipments (seconds)',
    timeWindowInMinutes: 'Time Window (minutes)',
    stopsLeftHeading: 'When do you want the exact ETA to be shown to the customer?',
    stopsLeftDescription: 'If you leave this field empty, time window will be displayed',
    amountOfStops: 'Amount of stops',
    flexibleDelivery: 'Flexible Delivery',
    flexibleDeliveryDescription: 'Allow recipient to have the option for the packge to be placed outside the door',
    flexibleDeliveryCheckboxText: 'Yes, recipient should be allowed',
    linkTextToService: 'Link text to Service',
    linkTextToServiceDescription: 'If no text is selected, default text will be used',
    defaultText: 'Default Text',
    listOfServicesAndVases: 'List of services and VAS codes',
    searchService: 'Filter service, VAS or customer number',
    customizedText: 'Customized text',
    deleteModalTitle: 'Delete Service Mapping',
    deleteModalDescription: 'You will not be able to recover this action',
    infoModal: {
      title: 'What is shown where in Glow',
      subHeading1: 'Planners and Drivers',
      description1: 'Internal name : The name of the service, example "Home Delivery"',
      description2:
        'Internal description: Extended information about the service, example "This should be carried to the recipients chosen room."',
      subHeading2: 'Customers and recipients',
      description3: 'External name: The name of the service, example "Home Delivery"',
      description4:
        'External description: Extended information about the service, example "Your goods will be carried to a room of your choice".'
    },
    routeOptimizing: 'Route planning',
    routeOptimizingDescription:
      'The information you provide here will override the default time and will effect the route optimization. Default set up for pickups is 15 seconds and for deliveries 180 seconds.',
    settingsRecipientTrackingPage: 'Recipient tracking page',
    etaComponentHeading: 'Display exact ETA on recipient page',
    etaComponentDescription:
      'Enabling this will give you the option to decide when the exact ETA should be shown to the recipient instead of the ETA time window.',
    displayExactEta: 'Yes, display exact ETA',
    stopsLeftSelected: 'Amount of stops left until delivery',
    minutesLeftSelected: 'Amount of minutes left until delivery',
    numberOfMinutes: 'Amount of minutes',
    numberOfStops: 'Amount of stops',
    sendAutomaticEtaSmsTitle: 'Send automatic SMS to the recipient',
    sendAutomaticEtaSmsDescription:
      'Enabling this will also disable the function for the driver to send SMS from the driver app.',
    sendAutomaticEtaSms: 'Send SMS automatic',
    missingNumberOfStops: 'Please enter a valid amount of stops when "Send SMS automatic" is checked',
    missingNumberOfMinutes: 'Please enter a valid amount of minutes when "Send SMS automatic" is checked',
    minutesNotInRange: 'Please set amount of minutes in between {{from}} and {{to}}',
    serviceDeliveryType: 'Service delivery type',
    noServiceDeliveryTypeSelected: 'Not set',
    serviceDeliveryTypes: {
      Delivery: 'Delivery',
      Return: 'Return',
      PickupPoint: 'Pickup Point'
    },
    Delivery: 'Delivery',
    Return: 'Return',
    PickupPoint: 'Pickup Point',
    courierApp: 'Courier app',
    allowCourierDescription: 'Allow courier to accept or reject shipments with this service code',
    allowCourierCheckbox: 'Yes, courier should be allowed'
  },
  matrix: {
    title: 'Matrix',
    pageTitle: 'Matrix Admin',
    name: 'Name',
    filename: 'File name',
    uploaded: 'Uploaded',
    uploadedBy: 'Uploaded by',
    downloadTemplate: 'Download template',
    byUser: 'By user',
    country: 'Country',
    ROUTING: 'Routing',
    LINEHAUL: 'Linehaul',
    HUB: 'Hub',
    DELIVERY: 'Delivery',
    VAS: 'VAS',
    'booking-limits': 'Booking limits',
    searchLabel: 'Search matrix by name or customer number',
    'baggage-delivery': 'Baggage',
    baggage: {
      title: 'Baggage matrices',
      name: 'Baggage {{country}}',
      legend: 'If you want to update existing baggage matrices, you do this by selecting “upload matrix data”.',
      uploadTitle: 'for {{country}}',
      custom: 'Custom DS code matrix',
      uploadCustomTitle: 'for custom DS codes',
      status: 'Status',
      uploadFail:
        '{{label}} was not accepted due to incorrect information. Change information and upload matrix again. ',
      statuses: {
        accepted: 'Accepted',
        pending: 'Pending',
        rejected: 'Rejected'
      }
    },
    bookingLimits: {
      header: '{{name}} - Edit Booking limits',
      rules: '#Rules',
      total: 'Total',
      rank: 'Priority',
      customer: 'Customer',
      customerNo: 'Customer No',
      serviceCode: 'Service',
      timeWindow: 'Time Window',
      selectTimeWindow: 'Select Time Window',
      noTimeWindow: 'No time window',
      noCustomer: 'No customer',
      bookingLimit: 'Booking Limit',
      removeLimit: 'Remove Limit',
      addMoreLimits: 'Add more limits',
      delete: 'Delete booking limits',
      deleteModalDescription: 'You will not be able to recover this action',
      deleted: 'Booking limits was successfully deleted',
      updated: 'Booking limits successfully changed'
    },
    master: 'Master',
    select: 'Select {{matrix}} matrix...',
    overflow: '+{{overflow}} more..',
    vasCodes: 'VAS Codes',
    connectedCustomers: {
      title: 'Connected Customers',
      button: 'See {{connectedCustomers}} Connected Customers',
      search: 'Search for customer name or number',
      subcustomer: 'Subcustomer'
    },
    upload: {
      success: 'Matrix data was successfully uploaded',
      question: 'Upload matrix data',
      text: 'Upload Excel',
      description: 'Upload excel spreadsheet with data for this matrix',
      error: 'Error uploading data to {{name}}. {{error}}'
    },
    delete: {
      question: 'Delete matrix {{name}}',
      description: 'This cannot be undone',
      success: 'Matrix {{name}} was successfully deleted',
      error: 'Error deleting {{name}}. {{error}}'
    },
    new: {
      question: 'Create new matrix',
      description: 'Creates a new matrix',
      success: 'Matrix {{name}} was successfully created',
      error: 'Error creating matrix. {{error}}'
    },
    edit: {
      question: 'Edit matrix {{name}}',
      error: 'Error updating {{name}}. {{error}}',
      success: 'Matrix {{name}} successfully saved'
    },
    type: 'Type',
    day: {
      1: 'Mon',
      2: 'Tue',
      3: 'Wed',
      4: 'Thu',
      5: 'Fri',
      6: 'Sat',
      7: 'Sun'
    },
    leadTime: 'Lead time',
    terminal: 'Terminal',
    terminalFrom: 'Terminal from',
    terminalTo: 'Terminal to',
    postalCode: 'Postal code',
    postalCodeFrom: 'From Postal code',
    postalCodeTo: 'To Postal code',
    offsetDays: 'Offset days',
    city: 'City',
    error: {
      duplicateName: 'Duplicate name',
      duplicateServiceCode: 'Booking limits already defined for this combination of customer and service code',
      duplicateTimeWindow: 'Duplicate time window'
    }
  },
  feedback: {
    title: 'Feedback',
    message: 'Please give us some suggestions on how to improve this page.',
    linkText: 'Send feedback'
  },
  texts: {
    title: 'Texts',
    update: 'Update text',
    name: 'Name of text',
    group: 'Grouping',
    edit: 'Edit',
    delete: 'Delete',
    add: 'Add text',
    reset: 'Reset',
    confirmDelete: 'Are you sure you want to delete the text?',
    added: 'Text added',
    deleted: 'Text deleted',
    updated: 'Text updated',
    tabTitle: 'Texts and translations',
    successfullyAdded: 'Text was successfully added to the list',
    successfullyUpdated: 'Text was successfully updated',
    successfullyDeleted: 'Text was successfully deleted',
    allTextsAndVases: 'List of all texts and translations',
    searchText: 'Search text',
    deleteModalTitle: 'Delete Text',
    deleteModalDescription: 'You will not be able to recover this action',
    createNewText: 'Create new text',
    nameToolTip: 'Name is displayed when linking text to service'
  },

  countries: {
    norway: 'Norway',
    sweden: 'Sweden',
    finland: 'Finland',
    denmark: 'Denmark',
    germany: 'Germany'
  },

  billingType: {
    day: 'day',
    evening: 'evening',
    weekend: 'weekend',
    weekendEvening: 'evening in the weekend'
  },
  /* These must be unique because of how ExcelJS works */
  shipmentsExport: {
    unit: 'Unit',
    exportButton: 'Export to Excel',
    shipmentId: 'Shipment id',
    status: 'Status',
    returned: 'Returned',
    department: 'Department',
    customer: 'Customer',
    customerNumber: 'Customer number',
    date: 'Date',
    pickupEarliest: 'Pickup earliest',
    pickupLatest: 'Pickup latest',
    deliveryEarliest: 'Delivery earliest',
    deliveryLatest: 'Delivery latest',
    serviceCode: 'Service',
    additionalServices: 'Additional services',
    pickupName: 'Pickup',
    pickupAddress: 'Pickup address',
    pickupZipCode: 'Pickup zip code',
    pickupZipArea: 'Pickup area',
    pickupCoordinates: 'Pickup coordinates',
    pickupInstructions: 'Pickup instructions',
    deliveryName: 'Delivery',
    deliveryAddress: 'Delivery address',
    deliveryZipCode: 'Delivery zip code',
    deliveryZipArea: 'Delivery area',
    deliveryCoordinates: 'Delivery coordinates',
    deliveryInstructions: 'Delivery instructions',
    quantity: 'Quantity',
    weight: 'Weight',
    volume: 'Volume',
    customerRef: 'Customer reference',
    pickupContactPerson: 'Pickup contact person',
    pickupPhone: 'Pickup phone',
    deliveryContactPerson: 'Delivery contact person',
    deliveryPhone: 'Delivery phone',
    deliveryEmail: 'Delivery e-mail',
    recipientRef: 'Recipient reference',
    price: 'Price',
    multileg: 'Multileg',
    group: 'Group',
    pickupTime: 'Picked up/ Attempt',
    delivered: 'Delivered/ Attempt',
    deliveryEtaSmsSentAt: 'Del. ETA SMS sent',
    courierName: 'Courier',
    goodsInfo: 'Goods information',
    note: 'Note',
    slotName: 'Slot',
    estimatedArrivalTime: 'Estimated time',
    orderSource: 'Order source'
  },
  importExport: {
    dropText: 'Drop file here to upload',
    export: 'Export',
    exportAddresses: 'Export customer addresses to Excel',
    importExcel: 'Import from Excel',
    imported: 'addresses imported successfully',
    noRecipients: 'No valid recipients found',
    or: 'or',
    recipients: 'recipients',
    selectFile: 'Select file',
    uploadingFile: 'Uploading...'
  },
  customMeasurements: {
    name: 'Name',
    weight: 'Weight',
    height: 'Height',
    length: 'Length',
    width: 'Width',
    heading: 'Custom goods types',
    error: 'Error saving goods type: ',
    confirmDelete: 'Are you sure you want to delete this goods type?',
    new: 'Create new',
    customizeGoodsTypes: 'Customize goods types',
    customGoodsTypes: 'Custom goods types',
    defaultGoodsTypes: 'Default goods types',
    invalidName: 'Name must include at least one letter'
  },
  shdAdmin: {
    successfullyDeleted: 'Time matrix was successfully deleted',
    successfullyUploaded: 'Time matrix was successfully uploaded',
    timeWindowForHomeDelivery: 'Time window for home delivery',
    filterByCustomerData: 'Filter by customer name, subcustomer or customer number',
    uploadTimeMatrixFile: 'Upload time matrix file',
    downloadExcelTemplate: 'Download excel template',
    clickHereOrDragAndDrop: 'Click here or drag and drop',
    allowedFormats: 'Allowed formats are XLS and XLSX',
    existingCustomers: 'Existing customers with SHD',
    customerPriceTooltip: "If end-customer's price should be displayed, set the toggle to on",
    deleteModalTitle: 'Delete time windows',
    deleteModalDescription:
      'If you delete this time window it will remove all SHD time windows for this customer. You will not be able to recover this action.',
    exportToExcel: 'Export to Excel',
    toggleLabel: "Toggle customer's price",
    customerNumber: 'Customer No',
    subCustomer: 'Subcustomer',
    customerName: 'Customer',
    amountOfPostalCodes: 'Amount of postals codes',
    lastUpdatedAt: 'Last updated at',
    showCustomerPrice: 'Show customer price',
    uploadMaximumFileSizeLimit: 'File size cannot be greater than {{size}} KB'
  },
  appSetting: {
    courierWebAppActivate: 'Courier web app activate',
    stripeApiKey: 'Stripe payment api key',
    stripeApiKeyUpdated: 'Stripe payment api key updated',
    stripeApiLegend:
      'In order to receive card payments via Glow, please create or use an existing account with Stripe. When logged in to Stripe, copy the API key from Get started with Stripe / For developers / secret key and paste it here.',
    c2cBookingCustomerNumber: 'C2C default customer number',
    c2cBookingCustomerNumberUpdated: 'C2C default customer number updated',
    c2cBookingCustomerNumberLegend: 'Orders created via C2C Booking will receive this customer number'
  },
  unresolved: {
    unresolvedAddresses: 'Unresolved addresses',
    allResolved: 'All addresses are resolved!',
    address: 'Address',
    noOfShipments: 'No. of shipments',
    shipment: 'shipment',
    shipment_plural: 'shipments',
    shipmentNumber: 'Shipment number',
    customerRef: 'Customer ref',
    senderName: 'Sender name',
    senderPhone: 'Sender phone number',
    recipientName: 'Recipient name',
    recipientPhone: 'Recipient phone',
    searchAndSetLocation: 'Search and set location',
    setALocationToContinue: 'Set a location to continue',
    resolveAddress: 'Resolve address',
    suggestedAddressFound: 'Suggested address found',
    thereIsOneUnresolvedAddress: 'There is {{count}} unresolved address',
    thereIsOneUnresolvedAddress_plural: 'There are {{count}} unresolved addresses'
  },
  optimizing: {
    title: 'Optimizing',
    optimizingId: 'Optimizing Id',
    reqTime: 'Request Time',
    respTime: 'Response Time',
    showOnlyErrors: 'Show only errors',
    department: 'Department',
    userName: 'User',
    graphID: 'Id',
    request: 'Request',
    response: 'Response',
    error: 'Error',
    viewReqResp: 'View',
    status: 'Status',
    totalTime: 'Time',
    selectOptimizingObjective: 'Please select optimizing objective',
    selectOptimizingQuality: 'Please select optimizing quality',
    cancelRequest: 'Cancel request',
    noOptimizations: 'No optimizations for selected query',
    viewRaw: 'View raw',
    quality: {
      STANDARD: 'Standard',
      HIGH: 'High',
      PREMIUM: 'Premium'
    },
    objectives: {
      completion_time: 'Minimum total time of delivery (default)',
      min_vehicles: 'Minimum total number of vehicles',
      work_time: 'Even delivery time',
      number_of_deliveries: 'Even number of deliveries',
      deliveries_and_work_time: 'Even work time and even number of deliveries'
    },
    kbSize: 'KB size of request',
    showAll: 'Show all',
    numberOfPackages: 'Number of packages',
    numberOfVehicles: 'Number of vehicles',
    processingTime: 'Processing time in seconds'
  },
  date: {
    date: 'Date',
    today: 'Today',
    tomorrow: 'Tomorrow',
    yesterday: 'Yesterday',
    from: 'From',
    fromDate: 'From date',
    rangeOptions: {
      '15d': '+/- 15 days',
      '30d': '+/- 30 days',
      custom: 'Custom dates',
      last30d: 'Last 30 days',
      last7d: 'Last 7 days',
      month: 'Month',
      months: 'Months',
      today: 'Today',
      tomorrow: 'Tomorrow',
      yesterday: 'Yesterday'
    },
    to: 'To',
    toDate: 'To date',
    dateAndTime: 'Date and time'
  },
  routeReceipt: {
    printRouteReceiptList: 'Print receipt list',
    noRoutesInfoAvailable: 'No route information available yet'
  },
  homeDeliveryRouteList: {
    receiptList: 'Receipt List',
    shipments: 'Shipments',
    weight: 'Weight',
    volume: 'Volume',
    loadingMeters: 'Loading meters',
    palletFootPrints: 'Pallet footprints',
    sender: 'Sender',
    package: 'Package',
    shipmentNo: 'Shipment no',
    ref: 'Ref',
    recipient: 'Recipient',
    time: 'Time',
    mobile: 'Mobile',
    other: 'Other',
    attemptedDelivery: 'Attempted delivery',
    receipt: 'Receipt',
    dateOfBirth: 'Date of birth',
    signature: 'Signature',
    nameClarification: 'Name clarification',
    goodsLine1: 'Remember to check the goods for visible damage before signing.',
    goodsLine2:
      'If there is visible damage, the driver should register it before you as a recipient signs the shipment.',
    damage: 'Damage',
    missing: 'Missing',
    packageNumber: 'Package number',
    comments: 'Comments'
  },
  invoicingStatus: {
    TO_BE_INVOICED: 'Not invoiced',
    INVOICING_FAILED: 'Invoicing failed',
    CORRECTION_PENDING: 'Correction pending',
    INVOICING_PAUSED: 'Invoicing paused',
    EXCLUDED_FROM_INVOICING: 'Excluded',
    NOT_READY_FOR_INVOICING: 'Not ready',
    INVOICED_OK: 'Invoiced OK',
    ZERO_PRICE: 'Incomplete price'
  },
  invoice: {
    articleCode: 'Article',
    allOrdersInvoice: 'All orders are invoiced',
    allOrdersInvoiceDescription: 'We couldn’t find any uninvoiced orders.',
    handledBy: 'Handled by',
    invoicing: 'Invoicing',
    credit: 'Credit',
    invoice: 'Invoice',
    create: 'Create',
    invoiceOrderAction: 'Invoice order',
    customerNumber: 'Customer number',
    carrierNumber: 'Unit number',
    details: 'Details',
    information: 'Invoice information',
    reference: 'Invoice reference',
    items: 'Items',
    quantity: 'Quantity',
    description: 'Description',
    customerAmount: 'Customer amount',
    carrierAmount: 'Unit amount',
    totalCustomerAmount: 'Total customer amount',
    totalCarrierAmount: 'Total unit amount',
    invoiceDepartment: 'Invoice department',
    invoiceDepartment_plural: 'Invoice departments',
    invoiceDepartments: 'Invoice department(s)',
    dashboard: 'Dashboard',
    invoicingJobsSummary: 'Invoicing jobs summary',
    invoicingStatus: 'Invoicing status',
    invoicingStatusDescription: 'Showing orders from the past 3 months.',
    notInvoicedOrders: 'Not invoiced orders',
    ordersOnPage: 'Showing {{count}} orders on page',
    ordersFoundCount: '{{count}} orders found',
    ordersFoundCount_plural: '{{count}} orders found',
    toBeInvoiced: 'To be invoiced',
    ordersNotInvoiced: 'Orders that are not invoiced yet',
    invoicingFailed: 'Invoicing failed',
    invoicingFailedDescription: 'Orders processed unsuccessfully',
    text: 'Invoice text',
    correctionPending: 'With order correction',
    correctionPendingDescription: 'Orders with pending corrections',
    invoicingPaused: 'Invoicing paused',
    ordersPausedFromInvoiced: 'Orders that are paused from being invoiced',
    invoicingZeroPrice: 'Incomplete price',
    invoicingZeroPriceDescription: 'Orders with non-approved zero-lines',
    sendInvoices: 'Send invoices',
    sendInvoicesDescription:
      'You are about to invoice {{departments}}. Please select the date or date range for the invoicing period.',
    sendInvoiceDateValidationError: 'From date cannot be later than To date',
    sendInvoiceDateValidationErrorOneMonth: 'Max date span is 1 month',
    invoicingInProgress: 'Invoicing for {{departmentName}} is in progress, {{count}} invoicing jobs are remaining',
    invoicingInFinished: 'Invoicing for {{departmentName}} is finished',
    orderSuccessInvoice: 'Order was successfully invoiced',
    orderSuccessInvoice_plural: 'Orders were successfully invoiced',
    orderSuccessNotInvoiced: 'Order was successfully changed to not invoiced',
    orderSuccessNotInvoiced_plural: 'Orders were successfully changed to not invoiced',
    orderSuccessInvoicedOk: 'Order was successfully changed to invoiced OK',
    orderSuccessInvoicedOk_plural: 'Orders were successfully changed to invoiced OK',
    orderSuccessExcluded: 'Order was successfully excluded from invoicing',
    orderSuccessExcluded_plural: 'Orders were successfully excluded from invoicing',
    orderFailedInvoice: 'Invoicing for selected order failed',
    howManyInvoices: 'How many invoices will be sent',
    fetchAmount: 'Fetch amount',
    wantToResend: 'This order has been invoiced already. Are you sure you wan to resend it',
    invoiceSingleOrder: 'You are about to invoice this order. Do you want to continue',
    pauseOrders: 'Pause orders',
    datePeriod: 'Date period',
    ordersWereSuccessfullyPaused: '{{count}} order was successfully paused from invoicing',
    ordersWereSuccessfullyPaused_plural: '{{count}} orders were successfully paused from invoicing',
    releaseOrders: 'Release orders',
    ordersWereSuccessfullyReleased: '{{count}} order was successfully released for invoicing',
    ordersWereSuccessfullyReleased_plural: '{{count}} orders were successfully released for invoicing',
    ordersWereSuccessfullyAcceptedWithIncompletePrice: '{{count}} order was approved with incomplete price',
    ordersWereSuccessfullyAcceptedWithIncompletePrice_plural: '{{count}} orderr were approved with incomplete price',
    addUnit: 'Add unit',
    addUnitDescription: 'Add a unit to your selected orders. This unit is the one that will be self-billed.',
    addUnitToOrders: 'Add unit to orders',
    addUnitToOrder: '{{unit}} was successfully added to {{count}} order',
    addUnitToOrder_plural: '{{unit}} was successfully added to {{count}} orders',
    addUnitBulkDisabled: 'Unit can only be added to orders without unit',
    approveIncompletePrices: 'Approve with incomplete prices',
    manualOverrideTitle: 'Manual override invoicing status',
    manualOverrideModalQuestion:
      'You are about to override the status of {{count}} orders. The selected orders will get status Invoice OK. Are you sure you want to continue?',
    unitId: 'Unit ID',
    bannerOldInvoicePage:
      'On the 26th of August <0>the new invoicing page</0> will be opening by default. We encourage you to start working with the new page. More info on the new page <1>here</1>.',
    acceptIncompletePricesTitle: 'Approve incomplete prices',
    acceptIncompletePricesModalQuestion:
      'You are about to approve {{count}} order with incomplete price. Are you sure you want to continue?',
    acceptIncompletePricesModalQuestion_plural:
      'You are about to approve {{count}} orders with incomplete prices. Are you sure you want to continue?',
    confirmCancelDialog: {
      title: 'You have unsaved changes',
      question: 'You have unsaved changes. Do you wish to continue?',
      abort: 'Stay on page',
      confirm: 'Continue without saving'
    },
    download: 'Download invoice',
    downloadInvoiceTooltip: 'Invoice can only be displayed once issued by external system'
  },
  billingOrder: {
    create: 'Create billing order',
    copy: 'Copy billing order',
    creditMode: 'Credit',
    debitMode: 'Debit',
    edit: 'Edit billing order',
    editCredit: 'Edit credit note',
    createCredit: 'Create credit note',
    credit: 'Credit note',
    done: 'Billing order was successfully created. Shipment ID: {{shipmentId}}',
    doneEdit: 'Billing order was successfully updated. Shipment ID: {{shipmentId}}',
    creditInvoiceText: 'Credit of shipment {{shipmentId}}',
    invalidOrderForEdit:
      'This order cannot be edited. Only billing or credit orders that have not been invoiced can be edited.',
    invalidOrderForCopy: 'Cannot copy credit note.'
  },
  shipment: {
    columnCategories: {
      aboutTheShipment: 'About the shipment',
      goods: 'Goods',
      timeAndDate: 'Time and date',
      planning: 'Planning',
      pickupInformation: 'Pickup information',
      customer: 'Customer',
      deliveryInformation: 'Delivery information',
      originalSender: 'Original sender',
      originalDelivery: 'Original delivery',
      service: 'Service',
      preAdvice: 'Preadvice',
      invoicingInformation: 'Invoicing information'
    },
    shipment: 'Shipment',
    multileg: 'multileg',
    customer: 'Customer',
    customerContact: 'Customer contact',
    customerReference: 'Customer reference',
    customerNumber: 'Customer number',
    route: 'Route',
    service: 'Service',
    additionalServices: 'Additional services',
    quantity: 'Quantity',
    totalWeight: 'Total weight',
    totalVolume: 'Total volume',
    totalLoadingMeters: 'Total loading meter',
    totalPalletFootPrints: 'Total pallet footprints',
    goodsInfo: 'Goods information',
    packages: 'Packages',
    pickupInfo: 'Pickup information',
    pickupDate: 'Pickup date',
    pickupTime: 'Pickup time',
    pickupInstructions: 'Pickup instructions',
    deliveryInfo: 'Delivery information',
    deliveryDate: 'Delivery date',
    deliveryTime: 'Delivery time',
    eta: 'ETA',
    ata: 'ATA',
    receiverReference: 'Receiver reference',
    deliveryInstructions: 'Delivery instructions',
    trackingPage: 'Recipient tracking page',
    internalNote: 'Internal note',
    closeWhileEditing: 'Are you sure you want to close it while editing?\nAll unsaved changes will be lost.',
    name: 'Name',
    address: 'Address',
    zipCode: 'Zip code',
    zipArea: 'City',
    phone: 'Phone',
    secondPhone: '2nd phone',
    email: 'Email'
  },
  bookingTimeWindow: {
    week: 'Week',
    bookButton: 'Book',
    customButton: 'Custom time window',
    removeBookingButton: 'Remove booking',
    bookedInfo: 'Booked {{date}} between {{timeWindow}}',
    notBookedInfo: 'Not booked',
    noBookingYet: 'No booking yet',
    tableHeaderDate: 'Date',
    tableHeaderSlots: 'Available slots',
    serverError: 'An error occurred on the server. Please try again later.',
    previousButton: 'Previous',
    nextButton: 'Next',
    noAvailableDeliveryTimes: 'No available delivery times this month',
    customTimeTitle: 'Set custom time window for shipment',
    customDateLabel: 'Date',
    customTimeWindowLabel: 'Delivery time window',
    backFromCustomTimeButton: 'Back to available times',
    errorEarliestNotBeforeLatest: 'Delivery time earliest must be before latest',
    arrivedAtTerminal: 'Arrived at terminal',
    bookCustomTimeSlot: 'Book Time Slot',
    shipmentBooked: 'Shipment booked',
    shipmentBookedWithCustomTimeWindow: 'Shipment booked with custom time window',
    updateBooking: 'Update Booking',
    removeTimeSlot: 'Remove Time Slot',
    bookingChanged: 'Booking has changed successfully',
    bookingSuccessful: 'Booking was done successfully',
    customTimeWindowAdded: 'Custom time window for shipment was successfully added',
    timeFrom: 'Time from',
    timeTo: 'Time to',
    timeFieldError: 'The earliest delivery time must be before the latest',
    availableTimeSlot: 'Available time slots',
    updateButtonTooltip: 'To update the booking you must first select a new time slot'
  },
  manualOverride: {
    manuallyAddEvents: 'Manually add events',
    selectEvent: 'Select event',
    shipmentEvent: 'Shipment event',
    terminalEvent: 'Terminal event',
    scannedFormDescription: 'Depending on which department you select, different type of arrival scan is triggered.',
    suggestPageReload: 'Something went wrong, please reload the page and try again.',
    eventType: {
      delivered: 'Delivered',
      collected: 'Collected',
      returned: 'Returned',
      deviated: 'Deviated',
      scanned: 'Scanned',
      preadvised: 'Preadvice attempt',
      'order-sorted': 'Sorted',
      'not-arrived-at-distributing-terminal': 'Not arrived'
    },
    selectEventType: 'Select one type of event',
    noEventSelected: 'No event selected',
    selectManifest: 'Select flight',
    noManifestSelected: 'No flight selected',
    disabledEventReason: {
      returned: ' - Deviation required',
      collected: ' - Dispatching required',
      default: ' - Not allowed',
      'order-sorted': 'For H2 orders only',
      'not-arrived-at-distributing-terminal': 'For HD orders only'
    },
    fillDeliverOrReturnEventInfo: 'Fill in name, date and time',
    fillDeviationEventInfo: 'Select deviation and fill in date and time',
    fillGeneralEventInfo: 'Fill in date and time',
    eventMessage: 'Event created',
    allPackagesSelected: 'All packages selected',
    selectPackages: 'Select packages',
    noActiveDriverError: 'No active drivers on unit',
    selectDriver: 'Select driver',
    selectUnit: 'Select unit',
    selectDepartment: 'Override scan department'
  },
  live: {
    onlyDeviated: 'Show only deviated routes',
    bannerOldLivePage:
      '<0>Try out the new Live page</0>, with improved functionalities and new design. We encourage you to use the new live page to get used to it before we fully removing the page.',
    shipment: 'Shipment',
    shipmentFrom: 'From',
    shipmentTo: 'To',
    confirm: 'Confirm',
    unconfirm: 'Unconfirm',
    confirmedBy: 'Confirmed by {{name}} at {{time}}',
    theCourier: 'The courier',
    activeRoutes: 'Active routes',
    routeDeviation: 'Route deviation',
    routeDeviations: 'Route deviations',
    multipleDeviations: 'Multiple deviations',
    recentDeviations: 'Recent deviations',
    noRoutesFound: 'No routes found',
    noDeviationsFound: 'No deviations found',
    consignmentDamage: 'Consignment damage',
    shipmentDeviation: 'Shipment deviation',
    outsideServiceTime: 'Outside service time',
    outsideEstimatedTime: 'Outside estimated time',
    deliveryLocationDeviation: 'Unexpected location',
    eventTextDeviationOne: '{{name}} has a shipment deviation.',
    eventTextDeviationMany: '{{name}} has {{count}} shipment deviations.',
    eventTextDamageOne: '{{name}} has damage on {{count}} shipments.',
    eventTextDamageMany: '{{name}} has damage on a shipment.',
    eventTextServiceTimeWindow: '{{name}} delivered outside the service time window.',
    eventTextEstimatedTimeWindow: '{{name}} delivered outside the estimated time window.',
    eventTextDeviated: '{{name}} has deviated from their route.',
    eventTextDeliveryLocationDeviation:
      '{{name}} delivered/deviated {{distance}} meters away from the expected location.',
    eventHelpDeviation: '{{name}} has a deviation on shipment number {{shipmentId}}.',
    eventHelpDamage: '{{name}} has a damage on shipment number {{shipmentId}}.',
    eventHelpServiceTimeWindow:
      '{{name}} has delivered shipment number {{shipmentId}} at {{actual}}, which is outside of the service time window {{earliest}}-{{latest}}.',
    eventHelpEstimatedTimeWindow:
      '{{name}} has delivered shipment number {{shipmentId}} at {{actual}}, which is outside of the estimated time window {{earliest}}-{{latest}}.',
    eventHelpDriverDeviated: '{{name}} has delivered shipment number {{shipmentId}} in the wrong order.',
    eventHelpDriverDeviatedBefore:
      '{{name}} has delivered shipment number {{shipmentId}} in the wrong order, stopping at delivery #{{actual}} before delivery #{{expected}}.',
    eventHelpDriverDeviatedAfter:
      '{{name}} has delivered shipment number {{shipmentId}} in the wrong order, stopping at delivery #{{actual}} after delivery #{{expected}}.',
    eventHelpDeliveryLocationDeviation: {
      withShipmentId:
        '{{name}} has delivered or deviated shipment number {{shipmentId}} <4>here</4>, {{distance}} meters away from the <9>expected location</9>.',
      withoutShipmentId:
        '{{name}} has delivered or deviated <4>here</4>, {{distance}} meters away from the <9>expected location</9>.'
    },
    filterOngoing: 'Ongoing',
    filterNotStarted: 'Not started',
    filterFinished: 'Finished',
    filterOnlyDeviated: 'Only deviated',
    filterShipmentDeviations: 'Shipment deviations',
    filterShipmentDamage: 'Shipment damage',
    filterEstimatedTime: 'Estimated time',
    filterServiceTime: 'Service time',
    filterRouteDeviations: 'Route deviations',
    filterDeliveryLocationDeviation: 'Unexpected location',
    filterConfirmed: 'Confirmed',
    routeProgress: 'Completed {{complete}} of {{total}}',
    routeDuration: 'From {{from}} to {{to}}',
    routeDelayed: 'Delayed',
    routeAhead: 'Ahead',
    routeOnTime: 'On time',
    routeUnknown: 'Unknown',
    routeDeviationsNone: 'No deviations',
    routeDeviationCount: '{{unhandledDeviations}} of {{totalDeviations}}',
    routeHasHandleDeviations: 'Route has {{count}} handled deviation',
    routeHasHandleDeviations_plural: 'Route has {{count}} handled deviations',
    showHandledDeviations: 'Show handled deviations ({{count}})',
    openRoute: 'See route',
    openShipment: 'See shipment',
    viewRoute: 'View route',
    deviated: 'Deviated',
    offRoute: 'Off route',
    onRoute: 'On route',
    routeStateFilter: 'Route state filter',
    allRoutesStates: 'All route states',
    selectedRouteStates: '{{count}} route state',
    selectedRouteStates_plural: '{{count}} route states',
    noSelectedStates: 'No selected states',
    allDeviationTypes: 'All deviation types',
    selectedDeviationTypes: '{{count}} deviation type',
    selectedDeviationTypes_plural: '{{count}} deviation types',
    noSelectedTypes: 'No selected types',
    viewShipment: 'View shipment',
    selectAllRouteStates: 'Select all route states',
    noRoutesWithDeviations: 'No routes available',
    selectAllDeviationTypes: 'Select all deviation types',
    noDeviationOnRoute: 'No deviations on route',
    noDeviationAvailable: 'No deviations available',
    noDeviationsOnFilter: 'There’s no deviations available for the selected filter. Try a different filter',
    noRoutesOnFilter: 'There’s no routes available for the selected filter. Try a different filter',
    search: 'Search for route name, unit name or driver name',
    departmentFilter: 'Department filter',
    allDepartments: 'All departments',
    deviationTypeFilter: 'Deviation type filter',
    mostDeviations: 'Most deviations',
    latestInTime: 'Latest in time',
    earliestInTime: 'Earliest in time',
    sortOn: 'Sort on',
    unhandled: 'Unhandled',
    deviations: 'deviations'
  },
  zendesk: {
    email: 'Your email:',
    confirmEmail: 'Repeat your email:',
    send: 'Save',
    missingEmail:
      'To use the new support portal, you need to register your Posten or Bring email. You only have to do this once.'
  },
  instantPlannerUnification: {
    message:
      'The sections Instant and Planner are now combined. Please go to Planner to find all your previously Instant functions.',
    linkTitle: 'Read more here.'
  },
  shipmentFormPage: {
    advancedFilter: 'Extended filter',
    selectTable: 'Select table',
    selectCondition: 'Select condition',
    findShipment: 'Find Shipment',
    searchPhraseError: 'You must provide at least 3 characters',
    selectStateLabel: 'Select state',
    selectDateLabel: 'Select date dropdown',
    submit: 'Show results',
    inputPlaceholder: 'Search Shipment ID, Phone Number, Address, Area, Zip Code ...',
    clearButtonLabel: 'clear search phrase button',
    allStates: 'All states',
    states: 'States',
    customDate: 'Custom',
    noDate: 'No date restriction',
    selectFromDate: 'Select from date',
    selectToDate: 'Select to date',
    include: 'Include',
    exclude: 'Exclude',
    selectAdvancedOperator: 'Select advanced operator dropdown',
    ofTheseRequirements: 'of these requirement(s)',
    tableColumn: 'Table column',
    condition: 'Condition',
    searchTerm: 'Search term',
    all: 'ALL',
    any: 'ANY',
    tableDropdown: 'Select table dropdown',
    conditionDropdown: 'Select condition dropdown',
    typeSearch: 'Type in search term',
    addNewRow: 'Add new row',
    deleteRow: 'Delete current row',
    service: 'Service',
    goodsDescription: 'Goods description',
    route: 'Route',
    unit: 'Unit',
    deliveryArea: 'Delivery area',
    deliveryZipcode: 'Delivery zipcode',
    state: 'State',
    today: 'Today',
    tomorrow: 'Tomorrow',
    yesterday: 'Yesterday',
    last30Days: 'Last 30 days',
    plusMinus15Days: '+/- 15 days',
    plusMinus30Days: '+/- 30 days',
    shipmentIds: 'Multiple shipments'
  },
  shipmentResultPage: {
    noResultTitle: 'No results found',
    findYourShipments: 'Find your shipments',
    findYourShipmentsDescription:
      'Type in what you are looking for in the search field above. More options can be found under extended search',
    batchLimitReached: 'Showing a maximum of 2000 shipments, consider narrowing your search',
    withoutDateLimitReached:
      'When searching with no date, we will display a maximum of {{limit}} shipments. Considering narrowing your search.',
    noResultExplanation: 'We coudn’t find any results. Please try searching with another term.',
    searchByShipmentIdsLimitReached:
      'When searching by shipment Ids, we will display a maximum of {{limit}} shipments. Considering narrowing your search.',
    searchError: 'Search failed. Try reloading the page or alter your search parameters'
  },
  shipmentTable: {
    quantity: 'Quantity',
    delEta: 'DEL ETA',
    delDuration: 'DEL D',
    delDurationTitle: 'Time of Delivery Duration',
    delDeliveryFrom: 'DEL F',
    delDeliveryFromTitle: 'Time of Delivery From',
    delDeliveryTo: 'DEL T',
    delDeliveryToTitle: 'Time of Delivery To',
    timeOfDelivery: 'Time of delivery',
    puDuration: 'PU D',
    puDurationTitle: 'Pickup Duration',
    puPickupFrom: 'PU F',
    puPickupFromTitle: 'Pickup From',
    puPickupTo: 'PU T',
    puPickupToTitle: 'Pickup To',
    timeOfPickup: 'Time of Pickup',
    delEtaTitle: 'Estimated Time of Delivery',
    recipientRef: 'Recipient ref',
    loading: 'Loading...',
    pickupName: 'Pickup name',
    deliveryName: 'Delivery name',
    selectAllOnPage: 'Select all on page ({{count}})',
    selectAll: 'Select all ({{count}})',
    deselectAll: 'Deselect all',
    numSelectedShipments: '{{numSelected}} selected',
    createLabels: 'Create labels',
    editShipments: 'Edit shipment information',
    moveShipments: 'Move to a different department',
    yourSearchResult: 'Your search result',
    numberOfShipmentsFound: '{{count}} shipments found',
    orderedDeliveryDate: 'Ordered delivery date'
  },
  shipmentResultActions: {
    confirmDeleteTitle: 'Delete selected shipment?',
    confirmDeleteTitle_plural: 'Delete selected shipments?',
    confirmDuplicateTitle: 'Duplicate shipment?',
    confirmDuplicateButton: 'Duplicate',
    deleteSuccess: 'Shipment was deleted successfully',
    deleteSuccess_plural: 'Shipments were deleted successfully',
    deleteFailure: 'Unable to delete shipment',
    deleteFailure_plural: 'Unable to delete shipments',
    invoiceActions: 'Invoice actions',
    shipmentActions: 'Shipment actions',
    showInvoicePayloadDisableTooltip: 'Shipments needs to be in invoicing status failed or OK'
  },
  tablePagination: {
    goToPage: 'Go to page',
    page: 'Page',
    of: 'of'
  },
  moveDepartmentModal: {
    errorMessage: 'Order: {{orderIds}}, error: {{error}}',
    ordersMovedText: 'Order has been moved to department {{department}}',
    errorMessage_plural: 'Orders: {{orderIds}}, error: {{error}}',
    ordersMovedText_plural: 'Orders has been moved to department {{department}}',
    partialSuccessMessage: '{{count}} of {{fullCount}} shipment was moved',
    title: 'Move selected shipment to another department'
  },
  sendManualDelaySmsModal: {
    errorMessage: '{{error}} {{shipmentIds}}',
    partialSuccessMessage: '{{count}} of {{fullCount}} SMS were sent',
    successMessage: 'Manual delay SMS was sent successfully'
  },
  sendPrePickupSmsModal: {
    successMessage: 'Pre-send tracking SMS was sent successfully',
    'return-order': 'Orders cannot be return orders',
    'invalid-state': 'Orders need to be in state Assigned to route'
  },
  multistopModal: {
    title: 'Group information',
    description: 'Group view for orders that are paid together.',
    debitLines: 'Debit lines',
    notFound: 'No group info found',
    shipmentId: 'Shipment ID',
    deliveryDate: 'DEL date',
    stopAddress: 'Stop address',
    postalCode: 'Postal code',
    noPrice: 'No price information available for group',
    priceUpdated: 'Article prices updated for group'
  },
  shipmentDetails: {
    eventWasAdded: 'Event was added',
    issueSaving: 'There was an issue with saving',
    noSelectedPackages: 'No packages was selected',
    pickupAndDelivery: 'Pickup and delivery',
    whereIsTheOrder: 'Where is the order',
    communicationToRecipient: 'Communication to recipient',
    yesPremium: 'Yes, premium',
    alerts: 'Alerts',
    pickupDeliveryInfo: 'Pickup and delivery information',
    quickEdit: 'Quick edit',
    quickEditTitle: 'Edit Pickup and Delivery Details',
    aboutShipment: 'About the shipment',
    mapLinkText: 'View map coordinates',
    copyShipment: 'Copy shipment',
    editShipment: 'Edit shipment details',
    location: 'Location',
    time: 'Time',
    selectDate: 'Select date',
    manualChangeEvent: 'Manually change event',
    disabledEventReason: {
      returned: 'Deviation required',
      collected: 'Dispatching required',
      default: 'Not allowed'
    },
    selectDeviation: 'Select deviation',
    required: 'Required',
    allPackages: 'All packages',
    packages: 'Packages',
    package: 'Package',
    changeDepartment: 'Change department',
    moveDepartment: 'Move department',
    shipmentMoved: 'Shipment moved',
    shipmentMovedText: 'Shipment {{shipmentId}} has been moved to department {{department}}',
    shipmentMovedGoBack: 'Go back to search page',
    moveShipmentDescription:
      "If you don't have access to the department you will not be able to find the shipment after moving",
    moveShipmentConfirmText: 'Move shipment to another department?',
    addShipmentToSlot: 'Add shipment to {{slot}}?',
    changeSlotConfirm: 'Change slot for shipment to {{slot}}?',
    changeSlot: 'Change slot',
    selectSlot: 'Select slot',
    AddToSlot: 'Add to slot',
    optimisationInProgress: 'Optimizing in progress. You can’t change the slot while the route is optimizing.',
    removeFromSlot: 'Remove from slot',
    removeShipmentFromSlot: 'Remove shipment from slot',
    removeShipmentFromSlotHeading: 'Remove the shipment from this slot?',
    successRemoveFromSlot: 'Shipment was removed from slot',
    successChangeSlot: 'Shipment changed slot',
    successAddToSlot: 'Shipment was added to slot',
    changeSlotDescription: 'Changing slot is done without route optimizing.',
    addToSlotDescription: 'The selected slot will be optimized with the exisitng shipment staying the same order',
    pickupOrDeliveryChange: 'The pickup or delivery has been changed',
    pickupOrDeliveryChangeDescription:
      'The pickup or delivery address have been changed. If you want to change the address back, press either of the button bellow.',
    addressWasUnresolved: 'Address was unresolved',
    eventTimeCannotBeInFuture: 'Event time cannot be in future.'
  },
  terminal: 'Terminal',
  location: 'Location',
  scannedAtDelegatedDepartment: 'Scanned from the delegated department.',
  pickupOrders: {
    acceptOrders: 'Accept orders',
    selected: 'selected',
    handleOrders: 'Handle pickup orders',
    found: 'found',
    accept: 'Accept',
    reject: 'Reject',
    noResultText: 'We could not find any results. Please try searching with another date.',
    last7d: 'Today + past 7 days',
    rejectOptions: {
      notFound: 'Sender not found on pickup address. No more attempts. Pickup closed.',
      terminatesPickupOrder: 'Sender terminates pickup order. No more attempts. Pickup closes.',
      handedOver: 'Sender has handed over pickup to other transporter. No more attempts. Pickup closed.',
      noMoreAttempts: 'Sender has moved. No more attempts. Pickup closed.',
      pickupCorrected: 'Sender has moved. Pickup corrected and sent back to subcontractor.',
      goodsNotReady: 'Goods not ready for pickup. No more attempts. Pickup closed.',
      incorrectBooking: 'Incorrect booking. Pickup terminated.',
      senderRefuses: 'Sender refuses to hand over goods. No more attempts. Pickup closed.',
      addressCorrected: 'Incorrect pickup address. Address corrected.',
      incorrectAddressNoMoreAttempts: 'Incorrect pickup address. No more attempts. Pickup closed.',
      senderNotified: 'Incorrect pickup address. Sender notified.',
      forbiddenGoods: 'Forbidden goods. No more attempts. Pickup closed.',
      timeFrame: "Pickup can't be done within time frame. Pickup order sent back to booking office."
    },
    mustSelectReason: 'You must select a reason for the rejection',
    selectRejectReason: 'Select reason for the rejection*',
    selectReason: 'Select reason',
    commentPlaceholder: 'Optional - provide extra information for the rejection',
    acceptedSuccessToast: 'was successfully accepted',
    rejectedSuccessToast: 'was successfully rejected',
    errorToast: 'Something went wrong',
    rejectOrders: 'Reject orders',
    shipment: '{{count}} shipment',
    shipment_plural: '{{count}} shipments'
  },
  editShipments: {
    pickupShouldBeBeforeDeliveryForAllShipments:
      'Shipment [{{shipmentIds}}] was not eligible because the combination of new and existing times is not valid. Please deselect the failing shipment and try again.',
    pickupShouldBeBeforeDeliveryForAllShipments_plural:
      '{{count}} shipments were not eligible because the combination of new and existing times is not valid. Please deselect the failing shipments and try again. Failed Shipment IDs - {{shipmentIds}}',
    useExistingTimes: 'Use existing times',
    pickupShouldBeBeforeDelivery: 'Pickup time window must be before the delivery time window',
    pickupEarliestShouldBeBeforeLatest: 'The earliest pickup time must be before the latest',
    deliveryEarliestShouldBeBeforeLatest: 'The earliest delivery time must be before the latest',
    success: 'Shipments information was updated successfully',
    pickupDateTime: 'Pickup date and time',
    deliveryDateTime: 'Delivery date and time',
    earliestPickupTime: 'Earliest pickup time',
    latestPickupTime: 'Latest pickup time',
    earliestDeliveryTime: 'Earliest delivery time',
    latestDeliveryTime: 'Latest delivery time',
    pickupName: 'Pickup name',
    pickupPhoneNumber: 'Pickup phone number',
    deliveryName: 'Delivery name',
    deliveryPhoneNumber: 'Delivery phone number',
    pickupInstructions: 'Pickup instructions',
    deliveryInstructions: 'Delivery instructions',
    mixedShipmentsSelected:
      'You have selected shipments with and without a date. Pickup and delivery information is required.',
    fieldNotEditable: 'This information cannot be edited for orders in state Assigned to route or Collected',
    bulkActionDisabledReason: 'Orders in state Deviated, Delivered, Rejected or Returned cannot be edited',
    withoutDateDisabledReason:
      'The combinations of orders in state Assigned to route or Collected and without date cannot be edited'
  },
  shipmentSelectColumn: {
    title: 'Customize table headers',
    addColumnHeader: 'Add column headers',
    addColumnBody: `Select the headings you want to be displayed in the data table. Our tip is to use 10-15 different
    heading in order for the information not to be cut off. The exact amount depends on the size of the
    device you are working on.`,
    orderOfTable: 'Order of table headings',
    orderOfTableDescription: 'Choose the preferred order for the table headers by dragging and dropping.'
  },
  bulkActions: {
    routeReceiptList: {
      disabledTooltip: 'Orders cannot be in state Resolved or Unresolved'
    },
    unassignShipmentFromSlot: 'Unassign shipments from slots',
    unassignShipmentFromSlotDisabledReason: 'Orders need to be in state Assigned to route or Offered',
    forceUnassignShipmentFromSlot: 'Force unassign shipments from slots',
    disabledMoveDepartmentTooltip:
      'Shipments need to be in state Created, Resolved, Unresolved or Rejected to be moved',
    deleteDisableTooltip: 'Shipment can only be deleted if in state Created, Unresolved, Resolved, Offered or Assigned',
    duplicateDisableTooltip:
      'Shipments needs to be in state Deviated or Returned and department type needs to be Courier',
    deleteShipments: 'Delete shipments',
    onlyReleasePausedOrders: 'You can only release paused orders.',
    onlyOverrideTheseInvoicingStatuses: 'You can only override orders with these invoicing statuses: {{statuses}}',
    shipmentForPauseDisableText:
      'Shipments need to be in state with order correction, to be invoiced or invoicing failed',
    creditDisableTooltip: 'You can only credit orders that are in status Invoiced OK',
    createWaybills: 'Create waybills',
    createWaybillsTooltip: 'Waybills can only be created for IKEA shipments',
    createDeliveryNotes: 'Create delivery notes',
    createDeliveryNotesTooltip: 'Delivery Notes can only be created for IKEA shipments',
    viewGroupDisabledTooltip: 'Order must be assigned before you can view and edit its group'
  },
  routeManagement: {
    routeListHelpModal: {
      title: 'Route list',
      manageRoute: 'Create, edit and delete a route',
      manageRouteDesc:
        'The "Route Action" menu allows you to create new routes. To edit or delete an existing route, select "Route Settings."',
      routeDetails: 'Route details',
      routeDetailsDesc:
        'You can access route details by clicking a route. In this section you can adjust the route settings, perform actions such as reversing delivery orders or add and edit map area. Routing settings can only be modified when a route is not yet started. All the added shipments will be displayed on the map to the right.',
      dispatchModal: 'Dispatch modal',
      dispatchModalDesc:
        'The dispatch modal is accessible from Route Details. Simply click "Manually adjust route". If you click here, you will see the modal, which allows you to flex, force, and unassign shipments from the route.'
    },
    unassignedShipmentsHelpModal: {
      title: 'Unassigned shipments list',
      filterAndSorting: 'Filter and sorting',
      filterAndSortingPara1:
        'If you for example want to dispatch shipments from a certain area or customer, you can use the filter available in the top right corner.',
      filterAndSortingPara2:
        'The options visible is based on the content on the page. For example, if the routes only consist of cars and trucks as vehicle types, these will be the available filter options in the modal. The filter applies both for the lista and the map. When a filter is active, a red badge will be displayed.',
      filterAndSortingPara3:
        'The default sorting is on delivery time (earliest to latest), but you can easily change this. When sorting on zip codes, the sorting is done on delivery zip code.',
      shipmentInformation: 'Shipment information',
      shipmentInformationDesc:
        'By clicking on the arrow in the top right on the cards, more information will be displayed. Here you can also access the shipment details page by clicking "View details".',
      bulkActions: 'Bulk actions',
      bulkActionsDesc:
        'When selecting shipments you will activate the available bulk actions - group shipments, move to different department and delete.'
    },
    helpModal: {
      linkToSupportArticle: 'You can <0>read more about how the the Route Management works</0> here.'
    },
    assignBestDriver: 'Auto route to best possible driver',
    assignClosestDriver: 'Assign to closest driver',
    assignToRoute: 'Assign to selected route',
    title: 'Route Management',
    unassignedShipments: 'Unassigned shipments ({{count}})',
    drivers: 'Drivers ({{count}})',
    selectedShipments: '{{count}} selected shipments',
    pickupTimeWindow: 'Pickup time window',
    deliveryTimeWindow: 'Delivery time window',
    autoDispatchTitle: 'Auto assign {{count}} shipments',
    toClosestDrivers: 'to closest driver(s)',
    assignOneDriver: 'Assign all shipment(s) to one driver',
    driverAdded: 'Driver added',
    noDriver: 'No driver',
    disableAssingButton: 'You need to select shipments in order to assign',
    viewTasks: 'View tasks',
    trackLivePage: 'Track on live page',
    viewDetails: 'View details',
    selectAll: 'Select all',
    subcontractor: 'Subcontractor',
    routeTime: 'Route time',
    changeRouteSettings: 'Change route settings',
    createRouteDescription:
      'If you don’t set a name of the route, the name will be auto generated based on the other information you enter.',
    saveAndCreateRoute: 'Save and create new route',
    createRoute: 'Create Route',
    vehicle: 'Vehicle',
    selectVehicle: 'Select vehicle',
    nameOfRoute: 'Name of route',
    selectSubcontractor: 'Select subcontractor',
    setStartTime: 'Set start time',
    setEndTime: 'Set end time',
    routeActions: 'Route actions',
    routeSettings: 'Route settings',
    mustSelectVehicle: 'You must select a vehicle',
    routeCreatedMessage: 'Route successfully saved and added to route list',
    createNewRoute: 'Create new route',
    noRoutes: 'No routes created yet',
    autoRouteTitle: 'Auto route {{count}} shipments',
    lockRoutes: 'Lock all assigned shipments to route',
    editRoute: 'Edit route',
    endTimeValidationError: 'End time needs to be after start time',
    deleteAllRoutes: 'Delete all routes',
    deleteRoute: 'Delete route',
    routeWasDeleted: 'Route was successfully deleted',
    routeWasUpdated: 'Route was successfully updated',
    routeWasUpdated_plural: 'Routes was successfully updated',
    routeWasDeleted_plural: 'Routes was successfully deleted',
    manuallyAdjustRoute: 'Manually adjust route',
    shipmentsNeededToActivate: 'You need to add shipments to activate this action',
    routeInfo: 'Route info',
    shipmentsSelected: '{{count}} shipments selected',
    driverPhoneNumber: 'Driver phone number',
    filterSettings: 'Filter settings',
    shipmentFilter: 'Shipment filter',
    routeFilter: 'Route filter',
    applyFilter: 'Apply filter',
    pickupDeliveryTime: 'Pickup and delivery time',
    completedShipmentsMap: 'See completed shipments on map',
    startTime: 'Start time',
    endTime: 'End time',
    routeStatus: 'Route status',
    filter: 'Filter',
    routeNotEditable: 'Only routes in state Created or Assigned can be edited',
    sort: 'Sort',
    zipCodeFrom: 'Zip code from',
    zipCodeTo: 'Zip code to',
    vehicles: 'Vehicles',
    zipCodeError: 'Invalid zip code',
    clearFilter: 'Clear filter',
    clearFilters: 'Clear filters',
    templates: 'Templates',
    loadTemplate: 'Load template',
    deleteTemplate: 'Delete template',
    saveAsTemplate: 'Save as template',
    saveTemplate: 'Save template',
    templateWasCreated: '{{name}} was successfully created',
    templateWasLoaded: '{{name}} was successfully loaded',
    templateWasDeleted: '{{name}} was successfully deleted',
    loadTemplateSubHeading: 'Select the template you want to add.',
    createANew: 'Create a new',
    orUpdateExisting: 'or update existing',
    deleteTemplateSubHeading: 'Select the template you want to delete.',
    defaultTemplate: 'Default template',
    defaultTemplateSubHeading: 'Select the template you want to use as default',
    setDefaultTemplates: 'Set default template',
    selectDefaultTemplate: 'Select default template for {{department}}',
    defaultTemplates: 'Default templates',
    normalTemplates: 'Normal templates',
    templateDefaultsSet: 'Default templates set',
    selectTemplate: 'Select template',
    selectTemplateToOverride: 'Select template to override',
    nameOfTemplate: 'Name of template',
    templateIsRequired: 'Template must be selected',
    templateOrNameIsRequired: 'Template or name must be selected',
    addMapArea: 'Add map area',
    editMapArea: 'Edit map area',
    areaWasUpdated: 'Map area edited on {{name}} successfully',
    areaWasCreated: 'Map area added to {{name}} successfully',
    areaWasDeleted: 'Map area deleted on {{name}} successfully',
    useMapArea: 'Use map area restrictions',
    areYouDonePlanning: 'Are you done route planning',
    noBackPage: 'No, back to page',
    yesDone: 'Yes, I am done',
    completedRoute: '{{name}} has been completed',
    successfullyReversed: 'Delivery order for {{name}} have been successfully reversed',
    addAreaRoute: 'Add area to route',
    updateAreaRoute: 'Update area for route',
    deleteMapArea: 'Delete map area',
    sendPrePickupSms: 'Pre-send tracking SMS',
    groupSelection: 'Group selection',
    groupRequestSuccess: 'Shipments were successfully grouped',
    ungroupRequestSuccess: 'Shipments were successfully ungrouped',
    groupShipments: 'Group shipments',
    nameOfGroup: 'Name of group',
    shipmentsInGroup: 'Shipments in group',
    disableGroupShipment: 'You can only add shipments to one group at the time',
    noShipmentsInGroup: 'No shipments in group',
    noShipmentsInGroupDescription:
      'You have removed all shipments in the group press <i>Save group</i> to save changes',
    onlyDispatchOneVehicle: 'Can only dispatch to one vehicle at the time',
    noVehicleFoundToDispatch: 'No vehicles found. The cursor needs to be within the marked drop area.',
    dragToVehicle: 'Drag order to vehicle in order to dispatch'
  },
  dispatchModal: {
    title: 'Pickup and deliveries on route {{name}}',
    flex: 'Flex',
    force: 'Force',
    invalidRouteOrder: 'Pickups cannot be before deliveries. Change the route order',
    submitDisabled: 'Re-plan route to activate button',
    replanDisabled: 'Route order needs to be vaild to Re-plan',
    noShipments: 'No shipments on route',
    noShipmentsDescription:
      ' You have unassigned all shipments on the route, press <i>Confirm route</i> to save changes',
    unassignShipmentFromRoute: 'Click to unassign shipment from route',
    unassignBreakFromRoute: 'Click to unassign break from route',
    editBreakOnRoute: 'Click to edit break on route',
    earliestStartOfBreak: 'Earliest start of break',
    latestStartOfBreak: 'Latest start of break'
  },
  vehicle: {
    createNewVehicle: 'Create new vehicle',
    createAndEditVehicle: 'Create and edit vehicles',
    listAllVehicle: 'List all vehicles in this department',
    weightCapacity: 'Weight capacity',
    volumeCapacity: 'Volume capacity',
    maxRangeKm: 'Max range',
    deleteSuccessMessage: 'Vehicle was successfully deleted',
    createdSuccessMessage: 'Vehicle was successfully created',
    updatedSuccessMessage: 'Vehicle was successfully updated',
    deleteVehicle: 'Delete vehicle',
    notRecover: 'You will not be able to recover this action',
    createVehicle: 'Create vehicle',
    editVehicle: 'Edit {{name}}',
    vehicleInformation: 'Vehicle information',
    selectFuelType: 'Select fuel type',
    selectEuroClass: 'Select euro class',
    settings: 'Settings',
    activeDriver: 'Active driver',
    noVehicleControl: 'No vehicle control',
    slower: 'Slower',
    slow: 'Slow',
    normal: 'Normal',
    faster: 'Faster',
    speedFactorVehicle: 'Speed factor of the vehicle',
    driverSpeed: 'Driver speed',
    serviceSpeed: 'Service speed',
    defaultStartLocation: 'Default start location is the department address',
    specifyEndLocation: 'Specify end location',
    earliest: 'Earliest',
    latest: 'Latest'
  },
  lmEvents: {
    heading: 'LM Events',
    event: 'Event',
    lmEventReasonAction: 'LM Event-Reason-Action',
    eventTime: 'Event Time',
    eventSentAt: 'Event Sent At',
    lmPayload: 'LM Payload',
    view: 'View',
    enterShipmentId: 'You must enter a shipment ID'
  },
  cms: {
    cms: 'CMS',
    publicationDate: 'Publication date',
    lastEdited: 'Last edited',
    dateForUnpublish: 'Date for unpublish',
    createNewBanner: 'Create new banner',
    createNewWhatsThisModal: "Create new What's this modal",
    publish: 'Publish',
    schedule: 'Schedule',
    unpublish: 'Unpublish',
    savePublish: 'Save and publish',
    saveAsDraft: 'Save as draft',
    saveAsDraftUnpublish: 'Save as draft and unpublish',
    noBannersFound: 'No banners found',
    noModalsFound: 'No modals found',
    DRAFT: 'Draft',
    PUBLISHED: 'Published',
    SCHEDULED: 'Scheduled',
    content: 'Content',
    nameOfBanner: 'Name of banner',
    placementOfBanner: 'Placement of banner',
    placementOfBannerDescription:
      'Paste the Glow-url you want to add a banner to. If you want to publish a banner for all departments or specific ones, you can either press the Add new link button or modify the link itself.',
    urlToPageInGlow: 'Url to page in Glow',
    addUrl: 'Add url',
    typeOfBanner: 'Type of banner',
    typeOfBannerDescription: 'Warning (orange), neutral (grey) or error (danger)',
    bannerNeutral: 'Neutral (grey)',
    bannerWarning: 'Warning (orange)',
    bannerError: 'Error (danger)',
    links: 'Links',
    linksDescription:
      'The article link will be placed directly after the body text. If you add a "Go back to old page" link it will be place before the body text.',
    goBackToOldPageLink: 'Go back to old page link',
    messageInBanner: 'Message in banner',
    messageInBannerDescription:
      'English is required. If no other languages is selected, English will be fallback used on all the pages',
    addAdditionalLanguages: 'Add additional languages',
    bannerText: 'Text',
    nameOfLink: 'Name of link',
    englishText: 'English text',
    englishLinkText: 'English: name of link',
    selectLanguage: 'Select language',
    addNewLanguage: 'Add new language',
    deleteBanner: 'Delete {{name}}',
    bannerWasSaved: '{{name}} was successfully saved',
    bannerWasPublished: '{{name}} was successfully published',
    bannerWasScheduled: '{{name}} was successfully scheduled',
    articleLink: 'Article link',
    goBackToOldPage: 'Go back to old page',
    previousPageNote: 'Note: use *department-id* and *date* wildcards in this field',
    selectTime: 'Select time',
    setStartDate: 'Set start date for banner',
    setEndDate: 'Set end date for banner',
    endTimeIsBeforeStartTime: 'End time can’t be earlier than the start time',
    endDateIsBeforeStartDate: 'End date can’t be earlier than the start date',
    oneBannerPerPageError: 'Only one banner per page is allowed. Url overlaps with existing banners: {{banners}}',
    mustProvideUrl: 'You must provide an url',
    mustProvideNameOfBanner: 'You must provide name of banner',
    mustProvideNameOfModal: 'You must provide name of modal',
    mustProvideEnglishText: 'You must provide English text',
    mustProvideArticleLink: 'You must provide article link',
    mustProvideNameOfArticleLink: 'You must provide name of the article link',
    mustProvideTime: 'You must provide a time',
    mustProvideDate: 'You must provide a date',
    invalidArticleLink: 'You must provide a valid article link, e.g. https://example.com',
    errorDuplicateBanner: 'Banner name must be unique. A banner with this name already exist.',
    draftScheduleTooltip: 'Clear your scheduling to save as draft',
    banner: 'Banner',
    listOfBanners: 'List of banners',
    whatsThisModal: "What's this modal",
    whatsThis: "What's this",
    listOfWhatsThisModals: "List of what's this modals",
    nameOfWhatsThisModal: "Name of what's this modal",
    descriptionWhatsThisModal:
      'The name must be unique. A tips is to name the what’s this modal depending on its placement, for example Admin Pre-advice or Planner Live',
    nameOfModal: 'Name of modal',
    pageLinkOfModal: 'Page the modal should be displayed on',
    pageLinkOfModalDescription:
      'Paste the Glow-url you want to add the modal to. Remember to change the department id to a * in order for the modal to be published on all departments',
    linkToPageGlow: 'Link to page in Glow',
    modalPreviewEmptyTitle: 'No information yet',
    modalPreviewEmptyDescription: 'You need to add information in order to get a preview of the modal',
    addTextForModal: 'Add text for modal',
    addTextForModalDescription:
      'English is required and will be fallback used on all the modals if you don’t write any information for the other languages. Click on the what’s this link to get tips on how you style the link.'
  },
  validationError: {
    provideEmail: 'You must provide an email',
    provideValidEmail: 'You must provide a valid email',
    youMustSelectCustomer: 'You must select a customer'
  },
  manageReturns: {
    title: 'Manage returns',
    typesOfReturns: 'Types of returns',
    ordersOverAPeriod: 'Showing orders from the past month.',
    pickedUpByCustomer: 'Picked up by customer',
    pickedUpByCustomerDescription: 'Returns to be picked up by the customer',
    transferToDifferentTerminal: 'Transfer to different terminal',
    transferToDifferentTerminalDescription: 'Returns to be send to a different terminal',
    environmentalReturns: 'Environmental returns',
    environmentalReturnsDescription: 'Returns for recycling, gathered in a container',
    returnForDelivery: 'Return for delivery',
    returnForDeliveryDescription: 'Returns to be routed and delivered',
    MANUAL_RETURNS: 'Manual returns',
    manualReturnsDescription: 'Order where the return leg is not created yet',
    returnToSenderTitle: 'Do you want to return the shipments to the sender',
    manuallyMarkAsHandled: 'Manually mark as handled',
    manuallyMarkAsHandledTooltip: 'When marking a shipment as handled the shipment disappear from the list',
    ordersFoundCount: '{{count}} order found',
    ordersFoundCount_plural: '{{count}} orders found',
    PICKED_UP_BY_CUSTOMER: 'Picked up by customer',
    TRANSFER_TO_DIFFERENT_TERMINAL: 'Transfer to different terminal',
    ENVIRONMENTAL_RETURNS: 'Environmental returns',
    RETURN_FOR_DELIVERY: 'Return for delivery',
    ordersAlreadyHandled: 'All orders are handled',
    ordersAlreadyHandledDescription: "We couldn't find any unhandled orders.",
    manuallyMarkAsHandledModal: {
      title: 'Manually mark as handled',
      shipmentsCount: '{{count}} shipment',
      shipmentsCount_plural: '{{count}} shipments',
      description:
        'When marking the shipment(s) as handled they will disappear from the list. You fill not be able to undo this action. Shipment(s) can be found on the search page.',
      confirm: 'Mark as handled'
    },
    manuallyMarkAsHandledSuccess: '{{count}} shipment was marked as handled',
    manuallyMarkAsHandledSuccess_plural: '{{count}} shipments was marked as handled',
    returnToSenderSuccess: '{{count}} shipment was return to sender',
    returnToSenderSuccess_plural: '{{count}} shipments was returned to sender',
    selectCustomer: 'Select customer',
    customer: 'Customer',
    customer_plural: 'Customers',
    allCustomers: 'All Customers',
    selectDestination: 'Select destination',
    destination: 'End destination',
    destinationDIP: 'Destination DIP',
    returnShipmentColumns: 'Return information',
    allDestinations: 'All Destinations',
    noDate: 'All',
    zeroDays: '0 days',
    oneTo6Days: '1–6 days',
    moreThan7Days: '> 7 days',
    moreThan15Days: '> 15 days',
    moreThan30Days: '> 30 days',
    customerDate: 'Custom',
    daysOnTerminal: 'Days on terminal',
    printList: 'Print list',
    returnToSender: 'Return to sender',
    returnToSenderQueued: 'Return to sender job is enqueued',
    returnToSenderConfirmation: 'Do you want to return this shipment to the sender?',
    returnToSenderFailed: 'Could not return shipment to sender',
    returnToSenderDisabledTooltip: "Shipment cannot be returned if it's already part of a multileg group",
    createReturnLabel: 'Create return label'
  },
  apolloAdmin: {
    title: 'Integrations',
    emptyTitle: 'No request displayed',
    emptyDescription: 'Select which date you want to view and press search.',
    reqType: 'Request type',
    routeId: 'Route ID',
    routeName: 'Route name'
  },
  preAdviceFollowup: {
    title: 'Manage preadvice',
    toBeHandled: 'To be handled',
    failedOrders: 'Failed orders',
    failedOrdersDescription: 'Recipient haven’t got any notification',
    manualPreAdvice: 'Manual preadvice',
    manualPreAdviceDescription: 'Orders with a manual trigger on the admin page',
    preAdviceLocked: 'Preadvice Locked',
    preAdviceLockedDescription: 'Orders that’s on hold and are awaiting action',
    missingRules: 'Missing rules',
    missingRulesDescription: 'Orders are missing a preadvice rule.',
    inProgress: 'In progress',
    inProgressDescription: 'Orders with preadvicing in progress',
    ordersFoundCount: '{{count}} order found',
    ordersFoundCount_plural: '{{count}} orders found',
    FAILED_ORDERS: 'Failed orders',
    MANUAL_PRE_ADVICE: 'Manual preadvice',
    PRE_ADVICE_LOCKED: 'Preadvice locked',
    MISSING_RULES: 'Missing rules',
    IN_PROGRESS: 'In progress',
    ordersAlreadyHandled: 'All orders are handled',
    ordersAlreadyHandledDescription: "We couldn't find any unhandled orders.",
    updatePhoneNumber: 'Change or add phone number',
    updateEmailAddress: 'Change or add email address',
    removeShipmentFromPreAdvice: 'Remove shipment from preadvice',
    bookDateAndTime: 'Book date and time',
    manuallyPreAdvice: 'Manual preadvice',
    unlockPreAdvice: 'Unlock preadvice',
    lockPreAdvice: 'Lock preadvice',
    triggerPreAdvice: 'Trigger preadvice',
    selectedCustomers: '{{count}} customer',
    selectedCustomers_plural: '{{count}} customers',
    allCustomers: 'All Customers',
    selectRowWithOrderId: 'Select row with order id {{id}}',
    updateRecipientPhoneNumber: 'Update phone number',
    phoneNumberUpdated: 'Phone number has been updated for Shipment ID: {{shipmentId}}',
    phoneNumberUpdatedAndPreAdviceSent: 'Phone number updated and preadvice sent for Shipment ID: {{shipmentId}}',
    samePhoneNumberAlreadySet: 'Same phone number is already set on the shipment.',
    updateRecipientEmailAddress: 'Update email address',
    emailAddressUpdated: 'Email address has been updated for Shipment ID: {{shipmentId}}',
    emailAddressUpdatedAndPreAdviceSent: 'Email address updated and preadvice sent for Shipment ID: {{shipmentId}}',
    sameEmailAddressAlreadySet: 'Same email address is already set on the shipment.',
    manuallyPreAdvicedSuccess: 'Successfully triggered preadvice for {{count}} shipments',
    nrOfSelected: '{{count}} selected',
    sendPreAdvice: 'Send preadvice',
    unlockPreAdviceSuccess: 'Successfully unlocked preadvice for {{count}} shipments',
    unlockPreAdviceAndPreAdviceSent: 'Successfully unlocked preadvice and sent preadvice for {{count}} shipments',
    lockPreAdviceSuccess: 'Successfully locked preadvice for {{count}} shipments',
    saveAndSendPreAdvice: 'Save and send preadvice',
    finishPreAdviceFollowupSuccess: 'Successfully removed {{count}} shipments from preadvice'
  },
  articles: {
    title: 'Articles',
    number: 'Article number',
    name: 'Article name',
    quantity: 'Quantity',
    shipmentArticles: 'Shipment articles',
    noArticlesPresent: 'No articles present'
  },
  deliveryNote: {
    title: 'Delivery Note',
    vatNumber: 'VAT no',
    orderNumber: 'Order number',
    sender: 'Sender',
    receiver: 'Receiver',
    phone: 'Phone',
    shipmentId: 'Shipment ID',
    articles: 'Articles',
    orderDate: 'Order Date',
    deliveryDate: 'Delivery Date',
    deliveryTime: 'Delivery Time',
    printedDate: 'Printed Date',
    receiversReference: "Receiver's reference",
    receiversReferenceNote: 'Please use this number in all contact with us',
    articleNumber: 'Number',
    articleName: 'Name',
    articlePrice: 'Price per unit',
    articleQuantity: 'Quantity',
    numberOfPackages: 'Number of packages',
    totalWeight: 'Total weight',
    totalVolume: 'Total volume',
    print: 'Print delivery note'
  },
  userRolesAdmin: {
    userRoles: 'User roles',
    successCreateMessage: '{{name}} was successfully created',
    successUpdateMessage: '{{name}} was successfully updated',
    saveRole: 'Save role',
    editRole: 'Edit {{name}}',
    createNewRole: 'Create new role',
    nameOfRole: 'Name of role',
    nameRequired: 'Name is required',
    descriptionRequired: 'Description is required',
    descriptionLabel: 'Description (max 240 characters)',
    iconLabel: 'Select which icon you want for the user role',
    selectRoleTitle: 'Select access rights for role',
    emptyState: 'No roles found for search phrase',
    deleteConfirm: 'Are you sure you want to delete {{name}}?',
    deleteSuccess: 'Role {{name}} was successfully deleted',
    settings: 'Settings',
    accessRights: 'Access rights',
    settingsTitle: 'Settings for role',
    settingsDescription: 'English is required. If no other languages is selected, English will be fallback',
    additionalLanguagesTitle: 'Add additional languages',
    customerPortalAccess: 'Customer Portal access',
    orderAttributes: 'Order attributes',
    findApiOrder: 'Find API Order',
    optimus: 'Optimus',
    noAccessToApiOrder: 'No access to Find API Order',
    noAccessToOptimus: 'No access to Optimus',
    optimusViewDescription: 'User can view services on departments and customers, but cannot make changes',
    optimusFullDescription: 'User can add and edit services for departments and customers',
    ordersApiSearchRestrictedDescription: 'User can search for orders from departments or customers it has access to',
    ordersApiSearchFullDescription: 'User can search for orders from all departments and customers',
    addRole: 'Add role',
    addedRole: 'Added role',
    removeRole: 'Remove role',
    defaultForUserRole: 'Use as default role (optional)',
    importExport: 'Import / Export',
    importExportExtendedDescription: 'Including access to Extended Courier report.',
    importExportExtendedSaaSDescription: 'Including access to End of day report.'
  },
  webhook: {
    messages: {
      title: 'Webhook Messages',
      resendConfirmation: 'Webhook message resent'
    },
    resend: 'Resend',
    requestedAfter: 'Requested after',

    messageDetails: {
      title: 'Webhook Message',
      generalHeading: 'General information',
      requestHeading: 'Request information',
      responseHeading: 'Response information',
      loading: 'Fetching webhook message...',
      copyTooltip: 'Copy payload',
      copied: 'Payload has been copied'
    },
    messageId: 'Message ID',
    messageCreatedAt: 'Message created at',
    eventType: 'Event type',
    packageId: 'Package ID',
    shipmentId: 'Shipment ID',
    subscriptionUrl: 'Subscription URL',
    subscriptionId: 'Subscription ID',
    request: {
      heading: 'Request information',
      at: 'Request at',
      after: 'Request at',
      retry: 'Retry of message',
      body: 'Request body'
    },
    response: {
      heading: 'Response information',
      at: 'Response at',
      status: 'Response status',
      description: 'Response description',
      body: 'Response body'
    },
    responseCategories: {
      Informational: 'Informational (100-199)',
      Successful: 'Successful (200-299)',
      Redirect: 'Redirect (300-399)',
      ClientError: 'Client Error (400-499)',
      ServerError: 'Server Error (500-599)',
      UnknownError: 'Unknown Error',
      Pending: 'Pending'
    }
  },
  roles: {
    ADMIN_OPTIMIZING: 'Optimizing',
    ADMIN_LM_EVENTS: 'LM events',
    ADMIN_NUMBER_SERIES: 'Number Series',
    ADMIN_TEXTS: 'Services',
    ADMIN_JOBS: 'Jobs Monitoring',
    ADMIN_PRE_ADVICE: 'Preadvice',
    ADMIN_SETTINGS: 'Settings',
    ADMIN_SHD: 'SHD',
    ADMIN_CUSTOMER: 'Light access',
    ADMIN_CUSTOMER_GROUP: 'Customer Groups',
    ADMIN_HOLIDAYS: 'Holidays',
    ADMIN_MATRIX: 'Matrix',
    ADMIN_USERS: 'Users',
    ADMIN_DEPARTMENT: 'Departments',
    ADMIN_DEPARTMENT_GROUP: 'Department Groups',
    ADMIN_CUSTOMER_ALL: 'Full access',
    OPTIMUS_VIEW: 'View access to Optimus',
    OPTIMUS_EDIT: 'Full access to Optimus',
    ORDERS_API_SEARCH_RESTRICTED: 'Light search access to Find API Order',
    ORDERS_API_SEARCH_FULL: 'Full search access to Find API Order',
    CUSTOMER_PORTAL: 'Customer access to Customer Portal',
    CUSTOMER_PORTAL_ADMIN: 'Admin access to Customer Portal',
    AIR_EXPRESS_ADMIN_AIRLINES: 'Airlines',
    AIR_EXPRESS_ADMIN_AIRPORTS: 'Airports',
    AIR_EXPRESS_ADMIN_CUSTOMS_AGENTS: 'Customs Agent',
    AIR_EXPRESS_ADMIN_FLIGHT_SEARCH: 'Flight Search',
    AIR_EXPRESS_ADMIN_HANDLERS: 'Handlers',
    AIR_EXPRESS_ADMIN_PRE_ADVICE: 'Pre Advice',
    AIR_EXPRESS_ADMIN_SCHEDULES: 'Schedules',
    AIR_EXPRESS_ADMIN_TERMINALS: 'Terminals',
    AIR_EXPRESS_ADMIN_UPLOADS: 'Uploads',
    AIR_EXPRESS_BOOKING: 'Booking',
    AIR_EXPRESS_CUSTOMS: 'Customs',
    AIR_EXPRESS_EXPORT: 'Export',
    AIR_EXPRESS_INVOICING: 'Invoicing',
    AIR_EXPRESS_MANIFEST: 'Manifest',
    AIR_EXPRESS_RECURRING: 'Recurring',
    AIR_EXPRESS_SHIPMENTS: 'Shipments',
    VIEW_PRICE: 'Can see prices',
    VIEW_PRICE_EVENTS: 'Can see price events',
    BOOK_SHIPMENT: 'Can book shipments',
    PLANNER_ADDRESSES: 'Addresses',
    PLANNER_BILLING_ORDERS: 'Billing orders',
    PLANNER_BOOKING: 'Booking',
    PLANNER_CORRECTIONS: 'Corrections',
    PLANNER_COURIERS: 'Couriers',
    PLANNER_DISPATCHING: 'Dispatching',
    PLANNER_IMPORT_EXPORT: 'Limited access to reports',
    PLANNER_IMPORT_EXPORT_EXTENDED: 'Access to all reports',
    PLANNER_INVOICING: 'Invoicing',
    PLANNER_LIVE: 'Live',
    PLANNER_MANAGE_RETURNS: 'Manage returns',
    PLANNER_PICKUP_ORDERS: 'Pickup orders',
    PLANNER_RECURRING_ORDERS: 'Recurring',
    PLANNER_ROUTE_MANAGEMENT: 'Route management',
    PLANNER_ROUTE_PLANNING: 'Route planning',
    PLANNER_SHIPMENTS: 'Shipments',
    PLANNER_UNITS: 'Units',
    PLANNER_UNRESOLVED: 'Unresolved',
    PLANNER_VEHICLES: 'Vehicles',
    PLANNER_PRE_ADVICE: 'Preadvice',
    ADMIN_ALCOHOL_DELIVERY_EDUCATION: 'Can add Alcohol delivery education for couriers'
  }
}

export default en
