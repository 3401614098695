const fi = {
  language: 'fi',
  userRoles: {
    admin: 'Admin',
    planner: 'Normaali',
    read_only_planner: 'Lukuoikeus',
    plus_planner: 'Plus',
    partner: 'Partneri',
    customer: 'Asiakas',
    customs_agent: 'Tulliagentti',
    handler: 'Käsittelijä'
  },
  application: {
    requiredLocation: 'Sijainti on pakollinen tieto',
    recipient: 'Vastaanottaja',
    save: 'Tallenna',
    update: 'Päivitä',
    required: '*Pakollinen kenttä',
    requiredShort: '*Pakollinen tieto',
    search: 'Etsi',
    logOut: 'Kirjaudu ulos',
    proceed: 'Jatka',
    courier: 'Lähetti',
    couriers: 'Lähetit',
    consignments: 'Lähetykset',
    status: 'Tila',
    address: 'Osoite',
    estimated: 'Arviolta',
    adjusted: 'Tarkistaa',
    actual: 'Nykyinen',
    funeral: 'Hautajaiset',
    tracking: 'Seuranta',
    trackingPage: 'Vastaanottajan seurantasivu',
    delivery: 'Toimitus',
    phoneNumberShort: 'Puhelin',
    yes: 'Kyllä',
    no: 'Ei',
    httpErrorToString: 'HTTP virhe: koodi {{status}} {{statusText}}',
    anErrorOccurred: 'Tapahtui virhe!',
    noAccessTitle: 'Ei pääsyä',
    noAccessMessage: 'Sinulla ei ole oikeutta käyttää tätä toimintoa',
    autoComplete: 'Etsi paikkoja...',
    autoCompleteWithoutDots: 'Etsi paikkoja',
    totalWeight: 'Kokonaispaino',
    totalVolume: 'Kokonaisvolyymi',
    title: {
      root: 'Tervetuloa',
      planner: 'Reitin suunnittelutyökalu',
      plannerLive: 'Suunnittelutyökalu - Live',
      plannerLiveSlot: 'Suunnittelutyökalu - Live - Toimitusajankohta',
      plannerCouriers: 'Suunnittelutyökalu - Lähetit',
      plannerImport: 'Suunnittelutyökalu - Tuo',
      plannerUnresolved: 'Suunnittelutyökalu  - Selvittämätön',
      plannerConsignments: 'Suunnittelutyökalu  - Lähetykset',
      plannerSearch: 'Suunnittelutyökalu   - Etsi',
      plannerPlanning: 'Suunnittelutyökalu  - Sunnittelu',
      plannerInvoicing: 'Suunnittelutyökalu  - Laskutus',
      plannerVehicle: 'Suunnittelutyökalu - Vehicles',
      report: 'Raportit',
      customerDepartmentLive: 'Live-näkymä',
      recipient: 'Lähetyksen seuranta - seuranta - Bring',
      instant: 'Kuriiri',
      instantBooking: 'Tilaus',
      h2Booking: 'H2 Tilaus',
      orderConfirmation: 'Tilausvahvistus',
      customerPortal: 'Asiakasportaali',
      instantMap: 'Pikakartta',
      recurringOrder: 'Vakio tilaukset',
      corrections: 'Tilauksen virheiden korjaus',
      jobs: 'Työpaikkojen seuranta',
      holidays: 'Lomat',
      shipmentLabels: 'Osoitekortit',
      addresses: 'Osoitteet',
      airexpressExportData: 'Exporter les données AirExpress',
      airexpressRecurringOrder: 'Air Express Vakio tilaukset',
      airexpressInvoicing: 'Air Express Laskutus',
      routeReceiptList: 'Kuittauslista',
      loadList: 'Lastauslista',
      airexpressAdminTerminals: 'Terminaalit'
    },
    language: { en: 'Englanti', nb: 'Norja', sv: 'Ruotsi', dk: 'Tanska', fi: 'Suomi' },
    country: {
      AllCountry: 'Kaikki maat',
      Country: 'Maa',
      AT: 'Itävalta',
      BE: 'Belgia',
      BA: 'Bosnia ja Hertsegovina',
      HR: 'Kroatia',
      CZ: 'Tšekki',
      DK: 'Tanska',
      GB: 'Yhdistynyt kuningaskunta',
      EE: 'Viro',
      FO: 'Färsaaret',
      FI: 'Suomi',
      FR: 'Ranska',
      DE: 'Saksa',
      GR: 'Kreikka',
      HU: 'Unkari',
      IS: 'Islanti',
      IE: 'Irlanti',
      IT: 'Italia',
      LT: 'Liettua',
      LU: 'Luxemburg',
      MK: 'Pohjois-Makedonia',
      NL: 'Alankomaat',
      NO: 'Norja',
      PL: 'Puola',
      PT: 'Portugali',
      RU: 'Venäjä',
      ES: 'Espanja',
      SE: 'Ruotsi',
      CH: 'Sveitsi',
      UA: 'Ukraina'
    },
    timeDistance: 'Suunniteltu aika ja välimatka',
    driverReturnToAddress: 'Älä anna palautusosoitetta',
    plannedTime: 'Suunniteltu aika',
    plannedDistance: 'Suunniteltu etäisyys',
    plannedWeight: 'Suunniteltu paino',
    distance: 'Välimatka'
  },
  simpleSearch: { link: 'Linkki' },
  button: {
    edit: 'Muokkaa',
    back: 'Takaisin',
    cancel: 'Peruuta',
    done: 'Valmis',
    clear: 'Tyhjennä',
    close: 'Sulje',
    save: 'Tallenna',
    delete: 'Poista',
    new: 'Luo uusi',
    search: 'Etsi',
    add: 'Lisää',
    remove: 'Poista',
    fullEdit: 'Muokkaa tilauksella',
    continue: 'Jatka'
  },
  modals: {
    preplan: {
      create: 'Suunnittele tilaus',
      change: 'Vaihda valittu kuljettaja',
      shipmentInfo: 'Suunnittele tilaus {{shipmentId}} ({{pickupName}} {{deliveryName}})',
      pickDriver: 'Valitse kuljettaja..',
      unpreplan: 'Peru Suunnittelu',
      preplan: 'Suunnittele',
      error: 'Suunnittelua ei pystytä toteuttamaan',
      notification: {
        preplanned: 'Suunniteltu',
        unpreplanned: 'Suunnittelematon',
        preplannedMessage: 'Tilaus suunniteltiin {{courierId}}',
        unpreplannedMessage: 'Tilauksen suunnittelu peruttu'
      }
    },
    maps: {
      save: 'Tallenna',
      cancel: 'Peruuta',
      delete: 'Poista',
      editMode: 'Muokkaa',
      mapArea: '{{routeName}} kartta-alue',
      saved: 'Tallennettu',
      deleted: 'Poistetut'
    }
  },
  consignment: {
    onlyReturnPreAdvice: 'Palauta lähettäjälle',
    consignmentDetails: 'Lähetyksen tiedot',
    search: 'Etsi lähetyksiä',
    noShipmentsFound: 'Ei haku tuloksia',
    clickButtonToSearch: 'Paina haku painiketta suorittaaksesi haku',
    consignmentsForDate: 'Lähetykset {{date}}',
    showOnlyEditable: 'Vain muokattavissa',
    showOnlyNotAssigned: 'Ei annettu toimitettavaksi',
    withoutDate: 'Ilman päivämäärää',
    extendedSearch: 'Laajennettu haku',
    showDeleted: 'Näytä poistetut',
    showDeviated: 'Näytä poikkeama',
    deletedBy: 'Poistanut',
    deletionReason: 'Syy',
    numberOfConsignments: 'Löydetty {{number}} lähetystä',
    showAll: 'Kaikki',
    delivery: 'Toimitus',
    pickup: 'Nouto',
    pickups: '{{count}} nouto',
    pickups_plural: '{{count}} noutoa',
    edit: 'Muokkaa',
    deleteOrders: 'Poista',
    duplicateOrder: 'Kopioida',
    copyOrder: 'Kopioi',
    editOrder: 'Muokkaa',
    deletedOrderNotCopyable: 'Poistettua tilausta ei voida kopioida koska tilauksen tiedot ovat puutteelliset',
    restoreOrder: 'Palauta',
    deletedOrderNotRestorable: 'Poistettua tilausta ei voida palauttaa puuttuvien tietojen takia',
    deleteConfirmation: 'Haluatko varmasti poistaa tämän lähetyksen?',
    deleteMultipleConfirmation: 'Oletko varma että haluat poistaa valitut lähetykset?',
    copyConfirmation: 'Haluatko varmasti kopioida tämän lähetyksen?',
    editConsignment: 'Muokkaa lähetystietoja',
    searchShipments: 'Etsi tilaus:',
    searchCustomers: 'Etsi asiakas:',
    filterBy: 'Suodata:',
    limitedShipmentsShownMessage: 'Näyttää enimmäis määrän {{count}} tilausta, tarkenna hakua',
    limitedUnresolvedAddressesShownMessage: 'Näytetään enimmäiset {{count}} epäselvää osoitetta',
    bulkActions: 'Massa toiminta',
    bulkActionsMultiple: 'Massa toiminta ({{count}})',
    addToRecurringGroup: 'Lisää tilaus vakio ryhmään',
    moveShipmentsToDifferentDepartment: 'Siirrä toiseen osastoon',
    addEvents: 'Lisää tapahtuma',
    disabledEditDeleteHelperText:
      'Tilauksen tietoja ei voi muuttaa, eikä sitä voi poistaa. Tehdäksesi muutoksia ole yhteydessä Bring asiakaspalveluun.',
    editPickupConsignment: 'Päivitä noutotietoja',
    editDeliveryConsignment: 'Päivitä toimitustietoja',
    editPickupAndDeliveryTime: 'Päivitä nouto ja toimitus aikoja',
    editConsignmentLocation: 'Sijainti',
    editConsignmentTime: 'Aika',
    editConsignmentName: 'Nimi',
    editConsignmentPhone: 'Puhelinnumero',
    editConsignmentInstructions: 'Ohjeet',
    earliestPickupTime: 'Aikaisin noutoaika *',
    latestPickupTime: 'Myöhäisin noutoaika *',
    earliestDeliveryTime: 'Aikaisin toimitusaika',
    latestDeliveryTime: 'Myöhäisin toimitusaika',
    enterPickupInstructions: 'Anna nouto-ohjeet',
    enterDeliveryInstructions: 'Anna toimitusohjeet',
    enterDeliveryName: 'Anna toimituksen nimi',
    enterPickupName: 'Annan noudon nimi',
    enterPickupPhone: 'Anna noudosta vastaavan puhelinnumero',
    enterDeliveryPhone: 'Anna toimituksesta vastaavan puhelinnumero',
    enterSpecificationSize: 'Anna specification size',
    editSpecificationSize: 'Muokkaa specification size',
    enterSpecificationDescription: 'Kirjoita kuvaus',
    editSpecificationDescription: 'Muokkaa kuvausta',
    editPickupInfoDescription: 'Voidaksesi muokata tietoja sinun pitää valita jompikumpi yllä olevista vaihtoehdoista',
    updateLocationFor: 'Päivitä {{count}} lähetyksen tiedot',
    updateLocationFor_plural: 'Päivitä {{count}} lähetyksen noutotiedot',
    comment: 'Kommentti',
    show: 'Näytä',
    filter: 'Suodata lähetyksiä',
    state: 'Tila',
    estimatedDelivery: 'Arvioitu toimitus',
    requestedTimePickup: 'Toivottu noutoaika',
    requestedTimeDelivery: 'Toivottu toimitusaika',
    timeNotSet: 'Ei valittu',
    customerReference: 'Asiakkaan viite',
    shipmentId: 'Lähetystunnus',
    packageId: 'Paketin tunnus',
    noCourier: 'Ei lähettiä',
    noSlot: 'Ei sopivaa toimitusaikaa',
    showEvents: 'Näytä tapahtumat →',
    hideEvents: 'Piilota tapahtumat ←',
    events: 'Tapahtumat',
    send: 'Lähetä',
    sendMessage: 'Lähetä viesti',
    sendCommentFailed: 'Kommentin tallentaminen epäonnistui',
    recipient: 'Vastaanottaja',
    unresolved: 'Selvittämättömät lähetykset',
    description: 'Kuvaus',
    pickupAddress: 'Nouto-osoite',
    numberOfPackages: 'Pakettien lukumäärä',
    registeredPickupAddress: 'Rekisteröity nouto-osoite',
    pickupZipCode: 'Postinumero',
    pickupZipArea: 'Alue',
    pickupLocation: 'Sijainti',
    deliveryAddress: 'Toimitusosoite',
    deliveryZipCode: 'Postinumero',
    deliveryZipArea: 'Alue',
    deliveryLocation: 'Sijainti',
    registeredDeliveryAddress: 'Rekisteröity toimitusosoite',
    updated: 'Lähetys on päivitetty',
    moved: 'Lähetys on siirretty',
    unresolvedMessage: 'Emme pystyneet paikantamaan {{count}} lähetyksen osoitetta, ole hyvä ja lisää osoitteet.',
    unresolvedMessage_plural:
      'Emme pystyneet paikantamaan {{count}} lähetyksen osoitetta, ole hyvä ja lisää osoitteet.',
    foundUnresolvedMessage:
      'Löysimme seuraavat voimassa olevan osoitteet tälle lähetykselle. Jos ne ovat oikein, paina päivitä. Muussa tapauksessa etsi oikea osoite alla olevalta kartalta.',
    remove: 'Poista',
    alterSlot: 'Muuta toimitusajankohtaa (ilman optimointia)',
    alterSlotAndReoptimize: 'Muuta toimitusajankohtaa (optimoi uusi aika samalle tilaukselle)',
    alterSlotNotAvailable: 'Ei saatavana optimoinnin aikana',
    optimizingNewSlot: 'Optimoi lähetyksen uuteen ajankohtaan',
    selectSlot: 'Valitse uusi toimitusajankohta',
    slotSelect: 'Valitse toimitusajankohta',
    confirmSlotChange: 'Vahvista toimitusajankohdan muutos',
    pickupInstructions: 'Nouto-ohjeet',
    deliveryInstructions: 'Toimitusohjeet',
    specificationDescription: 'Kuvaus',
    tracking: 'Seuranta',
    recipientPhone: 'Vastaanottajan puhelinnumero',
    customerRef: 'Asiakkaan viite',
    service: 'Palvelu',
    message: {
      CREATED: 'Luotu',
      ASSIGNED: 'Sijoitettu ajankohtaan',
      UNASSIGNED: 'Ei annettu toimitettavaksi',
      COLLECTED: 'Noudettu',
      DELIVERED: 'Toimitettu',
      DEVIATED: 'Poikkeama',
      COMMENT: 'Kommentti',
      RESOLVED: 'Ratkaistu',
      UNRESOLVED: 'Ei ratkaistu',
      OFFERED: 'Tarjottu',
      REJECTED: 'Hylätty',
      ACCEPTED: 'Hyväksytty',
      RETURNED: 'Palautettu',
      DELETED: 'Poistetut'
    },
    manualOverrideTitle: 'Manuaalinen ohitus',
    adjustTabTitle: 'Muokata',
    mapPanelTitle: 'Kartta',
    recipientName: 'Vastaanottajan nimi',
    receivedBy: 'Vastaanottanut',
    manuallyChangeLocations: 'Sijainnit muutettu manuaalisesti',
    manualOverrideDeviationTimeConfirm: 'Hyväksy manuaalinen poikkeama valituille tilauksille',
    manualOverrideDeliveryTimeConfirm: 'Hyväksy manuaalinen toimitus valituille tilauksille',
    onManuallyChangeLocationsConfirm: 'Oletko varma että haluat tehdä tilauksista epäselvän?',
    unresolveDeliveryAddress: 'Siirrä vienti-osoite epäselväksi',
    unresolvePickupAddress: 'Siirrä nouto-osoite epäselväksi',
    reference: 'Viite',
    shipmentIdNumber: 'Ei',
    shipmentDetails: 'Lähetyksen tiedot',
    customer: 'Asiakas',
    subcustomer: 'Asiakasnumero',
    dateAndTime: 'Päivä ja ajat',
    filterCustomer: 'Suodata asiakas',
    searchShipmentDateError: 'Anna aikaväliksi alle 120 päivää',
    searchPhraseLength: 'Haun täytyy olla vähintään {{minLength}} kirjainta pitkä.',
    moveOrder: 'Siirrä lähetys',
    moveOrderTitle: 'Siirrä lähetys toiselle osastolle',
    moveOrderDefault: 'Valitse osasto',
    label: 'Etiketti',
    createLabel: 'Luo etiketti',
    createLabelShipment: 'Luo etiketti (lähetys)',
    createMultipleLabel: 'Luo etiketti ({{count}})',
    viewLabel: 'Näytä etiketti',
    generateLabelErrorDescription: 'Osoitekortteja ei voitu luoda',
    generatingLabels: 'Odota hetki. Osoitekortteja luodaan',
    labelsGeneratedSuccessfully: 'Osoitekortit luotu onnistuneesti',
    vas: 'Lisäarvoa tuottavat palvelut (VAS)',
    price: 'Hinta',
    orderSources: { glow_customerBooking: 'GLOW Asiakkaan tilaus', glow_recurring: 'Vakio tilaukset' },
    shipmentSyncStatus: { yes: 'Kyllä', no: 'Ei' },
    unresolvedSuccessfully: 'Lähetys on selvitetty onnistuneesti.',
    wrongCoordinates: 'Väärät koordinaatit?',
    moveShipmentToUnresolved: 'Siirrä lähetys ”Epäselviin” jotta voit korjata koordinaatit.',
    mapUnavailable: 'Kartta tulee näkyviin kun lähetys on selvitetty.',
    selectOtherActions: 'Valitse muut toimet'
  },
  eventsList: { timeWindow: 'Aikaikkuna', from: 'Alkaen', to: 'Asti' },
  consignmentEvent: {
    message: {
      comment: 'Kommentti',
      created: 'Luotu',
      completed: 'Valmis',
      assigned: 'Valittu lähetti',
      plannedOnRoute: 'Suunniteltu reitille',
      plannedOnRouteMoreInfo: 'Suunniteltu reitille {{routeName}} ({{unitName}})',
      delivered: 'Toimitettu',
      collected: 'Noudettu',
      returned: 'Palautettu',
      deviated: 'Poikkeama',
      damaged: 'Vaurioitunut',
      notification: 'Ilmoitus',
      slotChanged: 'Toimitusajankohta muutettu manuaalisesti',
      unassigned: 'Ei annettu toimitettavaksi',
      rejected: 'Hylätty',
      image: 'Kuva',
      error: 'Virhe',
      started: 'Aloitettu',
      stopped: 'Lopetettu',
      accepted: 'Hyväksytty',
      offered: 'Tarjottu',
      unitName: 'Yksikkö',
      driverName: 'Kuljettaja',
      'delivery-id-check': 'ID:n tarkistus',
      'delivered-manually-overridden': 'Toimitus manuaalisesti ohitettu',
      'deviated-manually-overridden': 'Poikkeama manuaalisesti ohitettu',
      'collected-manually-overridden': 'Nouto manuaalisesti ohitettu',
      'pickup-location-changed': 'Noutoaika muutettu',
      'delivery-time-estimated': 'Päivitetty toimitusaika',
      'slot-manually-changed': 'Toimitusajankohta manuaalisesti ohitettu',
      'pickup-time-changed': 'Noutoaika muutettu',
      'manually-change-location': 'Sijainnit manuaalisesti ohitettu',
      'pickup-consignment-data-changed': 'Noutotietoja muutettu',
      'delivery-consignment-data-changed': 'Toimitustietoja muutettu',
      'specification-size-data-changed': 'Specification Size muutettu',
      'specification-description-data-changed': 'Kuvausta muutettu',
      'digital-identity-verified': 'Vastaanottajan henkilöllisyys varmistettu digitaalisesti',
      'digital-identity-verification-failed': 'Ei voitu vahvistaa vastaanottajan henkilöllisyyttä digitaalisesti',
      'flex-delivery-ordered': 'Yksinkertaistettu toimitus tilattu vastaanottajan toimesta',
      'flex-delivery-ordered-by-sender': 'Yksinkertaistettu toimitus tilattu lähettäjän toimesta',
      'flex-delivery-confirmation-sms-sent': 'Flex toimitus vahvistus viesti lähetetty',
      'return-to-pickup-point-delivery-deviated': 'Toimitetaan toimituspisteeseen',
      recipientName: 'Vastaanottajan nimi',
      receivedBy: 'Vastaanottanut',
      'reject-order-correction': 'Tilauksen korjaus hylätty',
      'accept-order-correction': 'Tilauksen korjaus hyväksytty',
      'handed-over-to-other-unit': 'Välitetty toiselle yksikölle',
      deliveredWithoutSignature: 'Toimitettu ilman allekirjoitusta',
      deliveredWithoutSignatureReason: {
        left_package_outside: 'Toimitettu ohjeiden mukaisesti',
        did_not_get_signature: 'Toimitettu henkilölle'
      },
      'returned-manually-overridden': 'Toimitus manuaalisesti ohitettu',
      scanned: 'Saapunut terminaaliin',
      'arrived-at-terminal': 'Saapunut jakeluterminaaliin',
      'order-sorted': 'Lajiteltu',
      'pickup-eta-sms-sent': 'Arvioitu noutoaika lähetetty',
      'handed-in-at-posten-terminal': 'Saapunut terminaalill',
      'pickup-sms-sent': 'Seuranta tekstiviesti lähetetty',
      'pre-pickup-sms-sent': 'Seuranta SMS lähetetty',
      'pickup-address-unresolved': 'Nouto-osoite on siirretty epäselviin',
      'delivery-address-unresolved': 'Vienti osoite on siirretty epäselviin',
      'upcoming-pickup-sms-sent': 'Tulevan toimituksen SMS lähetetty',
      'order-sorted-same-day': 'Päivämäärä asetettu tälle päivälle terminaali sovelluksella',
      'signature-sms-sent': 'Allekirjoitus sms lähetetty',
      'flex-delivery-cancelled': 'Simplified delivery peruttu',
      'delivery-time-ordered': 'Vastaanottaja on muuttanut toimituspäivän',
      'delivery-time-ordered-internal': 'Muuttanut toimituspäivän',
      'delivery-time-updated-from-hf-sync': 'Toimitusaika synkronoitu HF:stä',
      'planned-delivery-date-ordered': 'Suunniteltu toimitus päivä tilattu',
      'delay-sms-sent': 'Tekstiviesti liittyen myöhästyneeseen toimitukseen lähetetty',
      'route-planning-complete': 'Reitti valmis',
      'signature-delivered': 'Vastaanottajan allekirjoitus',
      showPicture: 'Näytä kuva',
      'external-address-wash': 'Toimitustietoja muutettu',
      'order-note-changed': 'Muistiinpanot muutettu',
      'order-data-changed': 'Järjestys muutettu',
      'order-moved-to-department': 'Siirretty osastojen välillä',
      'order-moved-to-department-with-data': 'Lähetys siirretty osastosta',
      'package-measurements-changed': 'Paketin koon muuttaminen',
      'driver-deviated-from-route': 'Kuljettaja poikkesi reitiltä',
      'delivered-outside-service-time-window': 'Toimitus tai poikkeama on palvelu ajan ulkopuolella',
      'delivered-outside-estimated-time-window': 'Toimitus tai poikkeama on arvioidun aikaikkunan ulkopuolella',
      'seen-by-recipient': 'Vastaanottaja on nähnyt seuranta sivun',
      'first-estimated-times-seen': 'Vastaanottaja on nähnyt arvioidun ajan',
      'pre-advice-return-sms-sent': 'Preadvice RETURN-SMS sent',
      'delivery-location-deviation': 'Toimitus tai poikkeama ei odotetussa paikassa',
      'delivery-sms-sent': 'Toimitus SMS lähetetty',
      'parcelbox-allocated': 'Paikka varattu pakettilaatikossa',
      'apartment-information-updated': 'Asunnon tiedot päivitetty',
      'claim-registered-email-sent': 'Sähköposti viallisista tai puuttuvista tuotteista lähetetty',
      'preliminary-loaded': 'Alustavasti lastattu',
      'manual-price-override': 'Hinta päällekirjoitettu',
      'incomplete-price-approved': 'Epätäydellinen hinta hyväksytty',
      'incomplete-price-unapproved': 'Puutteellinen hinnan hyväksyntä peruttu'
    },
    rejectedReason: {
      reason: 'Syy',
      private: 'Yksityinen asia',
      notEnoughTime: 'En pysy aikataulussa',
      notEnoughSpaceOnVehicle: 'Liian suuri lähetys',
      other: 'Muu'
    },
    idCheck: {
      idCheckFailed: 'ID:n tarkistus epäonnistui, mutta toimitettiin {{idCheckFailedResult}}',
      idCheckSummaryOther: 'Muu ({{otherType}}), syntymäaika {{birthDate}}',
      idCheckSummary: '{{idCheckType}}, syntymäaika {{birthDate}}'
    },
    damaged: {
      damageType: 'Vaurion tyyppi',
      causeCode: 'Poikkeama koodi',
      courierInput: 'Kuljettajan merkintä',
      showPictureLink: 'Näytä kuva {{idx}}',
      type: { A: 'Vahingoittunut paketti/pakkaus', D: 'Paine/puristus vahinko', E: 'Tuhoutunut lähetys' }
    },
    changedFrom: 'Muutettu -',
    blankText: '(Tyhjä)'
  },
  numberSeries: { numberSeries: 'Numerosarjat' },
  preadvice: {
    hdDepartments: 'HD Departments',
    noPoliciesFound: 'No Preadvice policies',
    policies: 'Preadvice policies',
    policy: { new: 'New preadvice policy', edit: 'Edit preadvice policy', targets: 'Customer-/department connections' }
  },
  holidays: { holidays: 'Lomat', addHoliday: 'Lisää uusi pyhäpäivä', country: 'Maa', type: 'Tyyppi', date: 'Päivä' },
  department: {
    departmentTitle: 'Osasto',
    department: 'osasto',
    department_plural: 'osastot',
    departmentWithCount: '{{count}} osasto',
    departmentWithCount_plural: '{{count}} osastoa',
    departmentGroups: 'Osasto ryhmät',
    numberOfDepartmentGroups: 'Osasto ryhmä',
    numberOfDepartments: 'Määrä osastoa',
    search: 'Etsi osastoja',
    noDepartmentName: 'Nimi puuttuu',
    noDepartmentId: 'ID puuttuu',
    noCouriers: 'Ei lähettejä',
    courierCount_plural: '{{count}} lähettiä',
    courierCount: '{{count}} lähetti',
    noCourier: 'Ei lähettejä',
    couriers: 'Lähettien lukumäärä',
    plannerCount_plural: '{{count}} reitin suunnittelutyökalua',
    plannerCount: '{{count}} reitin suunnittelutyökalu',
    noPlanner: 'Ei reitin suunnittelutyökaluja',
    planners: 'Reitin suunnittelutyökalujen määrä',
    choose: 'Valitse osasto',
    new: 'Uusi osasto',
    alystraId: 'Alystra ID',
    description: 'Kuvaus',
    name: 'Nimi',
    created: 'Osasto luotu',
    updated: 'Osasto päivitetty',
    departmentType: 'Osastotyyppi',
    courier: 'Lähetti',
    express: 'Pikatoimitus',
    hd: 'Kotitoimitus',
    other: 'Muu',
    all: 'Kaikki',
    deliveryDisplayType: 'Toimitusajan näyttö',
    terminalName: 'Terminaalin nimi',
    terminalNameTooltip: 'Jos nouto ja osaston nimi eroavat toisistaan, täytä nämä tiedot',
    address: 'Osoite',
    zipCode: 'Posti nro',
    zipArea: 'Alue',
    orderBillingType: {
      title: 'Tilauksen laskutustyyppi',
      selectBillingType: 'Valitse Laskutustyyppi',
      customerBillingNumber: 'Asiakkaan laskutusnumero',
      addMoreOrderBillingInfo: 'Lisää uusi tilauksen laskutustyyppi'
    },
    departmentId: 'Osasto Id',
    autoInvoiceTitle: 'Automaattinen laskutus',
    autoInvoiceEnable: 'Aseta automaattinen laskutus',
    autoInvoiceTime: 'Aika',
    autoInvoiceOffset: {
      legend: 'Mitkä tilaukset tulee laskuttaa',
      oneDayEarlier: 'Toimitettu 1 päivää sitten',
      twoDaysEarlier: 'Toimitettu 2 päivää sitten',
      threeDaysEarlier: 'Toimitettu 3 päivää sitten',
      fourDaysEarlier: 'Toimitettu 4 päivää sitten',
      fiveDaysEarlier: 'Toimitettu 5 päivää sitten'
    }
  },
  departmentGroup: {
    departmentGroup: 'Osasto ryhmä',
    name: 'Nimi',
    deleteConfirm: 'Oletko varma että haluat poistaa osaston tämän ryhmän?',
    edit: 'Muokkaa osasto ryhmä'
  },
  deliveryDisplayType: { instant: 'Kuriiri', customized: 'Räätälöity' },
  menu: {
    defaultDisabled: 'Valitse yksi asiakastunnus nähdäksesi valikon',
    home: 'Alkuun',
    admin: 'Hallinto',
    planner: 'Reitin suunnittelutyökalu',
    report: 'Raportit',
    customer: 'Asiakas',
    apiOrder: 'Etsi API-tilaus',
    priceMotor: 'Hinnoittelutyökalu'
  },
  planner: {
    chooseYourDepartment: 'Valitse osastosi',
    unassignedShipments: 'Varaamattomat lähetykset',
    filterCustomers: 'Suodata asiakkaita',
    filterServices: 'Suodata palvelut',
    autoRoute: 'Automaattireitti',
    saved: 'Tallennettu',
    allUserRoles: 'Kaikki käyttäjäroolit',
    userRolesCount_plural: '{{count}} käyttäjäroolia',
    createNewUser: 'Luo uusi käyttäjä',
    userRolesCount: '{{count}} käyttäjärooli',
    role: 'Rooli',
    searchForNameOrNumber: 'Hae nimeä tai puhelinnumeroa',
    noPlannersFound: 'Suunnittelijoita ei löytynyt',
    typeOfRole: 'Käyttäjäroolin tyyppi',
    selectUserRole: 'Valitse käyttäjärooli',
    users: 'Käyttäjät',
    connectDepartment: 'Yhdistä osasto käyttäjään',
    banner: {
      tryNewRoutePlanner:
        '1. toukokuuta, <0>reitinsuunnittelija 2.0</0> on ainoa saatavilla oleva reitinsuunnittelija. Jos et ole käyttänyt reitinsuunnittelijaa aiemmin, kannustamme sinua aloittamaan sen käytön nyt. <1>Lue lisää Zendeskistä</1> ja muista asiaan liittyvistä artikkeleista saadaksesi yleiskuvan siitä, miten se toimii.'
    },
    totalTitle: 'Paketit',
    pickList: 'Valintalista',
    export: 'Siirto laskutukseen',
    deleteSlot: 'Poista',
    editSlot: 'Muokkaa',
    deleteSlotConfirmDialogText: 'Oletko varma, että haluat poistaa tämän toimitusajankohdan?',
    deleteFilteredSlots: 'Poista kaikki paikat',
    deleteAllSlotsConfirmDialogText: 'Haluatko varmasti poistaa kaikki paikat?',
    created: 'Reitin suunnittelutyökalu luotu',
    updated: 'Reitin suunnittelutyökalu päivitetty',
    removeDepartment: 'Poista osasto {{name}}',
    connectAirlineTerminal: 'Yhdistä lentoterminaali(t) käyttäjään',
    connectedAirlineTerminals: 'Yhdistetyt lentoterminaalit ({{count}})',
    noConnectedTerminals: 'Ei yhdistettyjä terminaaleja vielä',
    removeTerminal: 'Poista terminaali {{name}}',
    searchTerminals: 'Etsi lentoterminaaleja',
    youMustSelectTerminal: 'Sinun on valittava lentoterminaali',
    youMustProvideName: 'Sinun on annettava käyttäjän nimi',
    youMustProvidePhonenumber: 'Sinun on annettava puhelinnumero',
    routePlanning: 'Reitin suunnittelu',
    routePlanningConsignmentsAssigned: 'annettu toimitettavaksi',
    routePlanningRangeDescription: 'Suodata lähetyksiä toimitusajan perusteella',
    unresolvedConsignments: 'Epäselvät',
    consignments: 'Haku',
    couriers: 'Lähetit',
    invoicing: 'Laskutus',
    planner: 'Reitin suunnittelutyökalu',
    planners: 'Reitin suunnittelutyökalut',
    noPlanners: 'Ei reitin suunnittelutyökaluja',
    missingId: 'ID puuttuu',
    missingName: 'Nimi puuttuu',
    search: 'Etsi',
    searchForPlanner: 'Etsi reitin suunnittelutyökaluja',
    name: 'Nimi',
    phoneNumber: 'Puhelinnumero',
    selectDepartments: 'Valitse osastot',
    createSlot: 'Luo',
    editSlotForm: 'Muokkaa toimitusajankohtaa',
    routeUpdated: 'Toimitusajankohdan tiedot päivitetty',
    createSlotFormName: 'Toimitusajankohdan nimi',
    createSlotFormUnit: 'Yksikkö',
    createSlotFormCourier: 'Lähetti',
    createSlotFormSlotMode: 'Toimitusajankohdan tila',
    createSlotFormSlotModePickup: 'Piirrä noutoalue kartalle',
    createSlotFormSlotModeDelivery: 'Piirrä toimitusalue kartalle',
    createSlotFormSlotModeManual: 'Valitse lähetykset manuaalisesti',
    createSlotFormTimeRange: 'Aikaväli',
    createSlotFormStartLocation: 'Aloituspaikka',
    createSlotFormEndTime: 'Päättymisaika',
    createSlotFormEndLocation: 'Lopetuspaikka',
    createSlotFormSlotColor: 'Toimitusajankohdan väri',
    createSlotFormTimeRangeTooBig: 'Aikaväli on liian suuri. Enimmäisaika on {{hours}} tuntia.',
    createSlotFormFilterCustomers: 'Suodata asiakkaita',
    createSlotFormFilterServices: 'Suodata palveluita (jos yhtään ei rastita, kaikki on valittu)',
    slotFilterInfo:
      'Tämä toimitusajankohta on suodatettu, mikä tarkoittaa, että vain valitulle/-tuille asiakkaalle/-kaille kuuluvat lähetykset ja valittu/-tut palvelu/-t voidaan yhdistää toimitusaikaan.',
    slotFilteredCustomer: 'Toimitusajankohtaan sisältyy VAIN seuraavien asiakkaiden tilaukset',
    slotFilteredService: 'Toimitusajankohtaan sisältyy VAIN seuraavia palveluita sisältävät tilaukset',
    onlyShowApplicable:
      'Näytä vain sellaiset tilaukset, jotka sopivat tähän toimitusajankohtaan asiakkaan/palveluiden suodatuksen perusteella',
    optimizeButtonLabel: 'Optimoi ja määrittele reitit',
    optimizeSlotButtonLabel: 'Optimoi toimitusajankohta uudelleen',
    reversedOptimizeSlotButtonLabel: 'Käänteinen toimitusjärjestys',
    optimizingSince: 'Optimointi käynnissä, alkanut klo {{startTime}}',
    alterSlotIdOnConsignment: 'Oletko varma, että haluat muuttaa tämän lähetyksen toimitusajankohtaa?',
    addToRoute: 'Lisää reittiin...',
    pickup: 'Nouto',
    delivery: 'Toimitus',
    slot: 'Toimitusajankohta',
    notAssigned: 'Ei annettu toimitettavaksi',
    readOnly: 'Vain luku -oikeus',
    normal: 'Normaali',
    partner: 'Kumppani',
    readOnlyAccessPlannersWithCount: '{{count}} suunnittelijat (lukuoikeudet)',
    plusPlannersWithCount: '{{count}} plus suunnittelijat (admin)',
    normalPlannersWithCount: '{{count}} suunnittelijat (perusoikeudet)',
    saveTemplate: 'Tallenna sivupohjana',
    loadTemplate: 'Lataa sivupohjasta',
    routeListForFilteredSlots: 'Kuittauslista (tulosta kaikki)',
    loadListForFilteredSlots: 'Lastauslista (tulosta kaikki)',
    sendPrePickupSmsesToAll: 'Ennen lähetyksen seurantatekstiviesti',
    alertBeforeSendingPrePickupSms:
      'Haluatko varmasti lähettää seurantatekstiviestin kaikille tämän päivän lähtö- ja saapumisaikoille?',
    createTemplateFormName: 'Sivupohjan nimi',
    createOrUpdateTemplateDescription:
      'Voidaksesi tallentaa sinun on JOKO annettava sivupohjalle uniikki nimi tai valittava jo luotu sivupohja päivitettäväksi.',
    newTemplate: 'Luo uusi sivupohja',
    overwriteTemplate: 'Kirjoita sivupohjan yli',
    templateSaved: 'Sivupohja on tallennettu',
    updateTemplateDescription: 'Valitse jo luotu sivupohja päivitettäväksi',
    updateTemplateConfirm: 'Oletko varma, että haluat päivittää tämän sivupohjan?',
    selectOrDeleteTemplateDescription: 'Valitse käytettävä tai poistettava sivupohja',
    selectTemplate: 'Valitse sivupohja',
    deleteTemplate: 'Poista',
    selectTemplateDescription: 'Valitse sivupohja:',
    templateSelected: 'Sivupohja on otettu käyttöön',
    templateDeleted: 'Sivupohja on poistettu',
    templateDeleteConfirm: 'Oletko varma, että haluat poistaa tämän sivupohjan?',
    missingDeliveryMarker: 'Toimitusosoite tai sijainti ei kelpaa',
    missingPickupMarker: 'Nouto-osoite tai sijainti ei kelpaa',
    unassignConsignmentFromSlot: 'Poista lähetys toimitusajankohdasta',
    unassignBulkConsignmentsFromSlotsAlert: 'Poista lähetykset niille merkatulta reitistä?',
    unassignedFromSlot: 'Lähetys on nyt poistettu toimitusajankohdasta',
    unassignBulkConsignmentsFromSlots: 'Lähetykset on nyt poistettu niille merkatulta reitiltä',
    unassignTitle: 'Poista tämä lähetys toimitusajankohdasta',
    unassignButton: 'Poista toimitusajankohdasta',
    forceUnassignButton: 'Pakota poisto reitiltä',
    moveOrder: 'Oletko varma, että haluat siirtää tätä lähetystä?',
    driverName: 'Lähetit',
    filters: {
      assigned: 'Annettu toimitettavaksi',
      unassigned: 'Ei  annettu toimitettavaksi',
      pickups: 'Noudot',
      deliveries: 'Toimitukset'
    },
    saveSelected: 'Tallenna valitut ({{count}})',
    showSaved: 'Näytä tallennetut ({{count}})',
    showAll: 'Näytä kaikki',
    clearSaved: 'Tyhjennä tallennetut',
    sendPrePickupSms: 'Ennakko lähetä seuranta SMS vastaanottajalle',
    sendSms: 'Lähetä tekstiviesti',
    sendingSmsText: 'Lähetetään {{count}} seuranta SMS',
    prePickupSmsSent: '{{sentCount}} / {{totalCount}} ennakkoviestiä lähetetty',
    manualDelaySms: { disabledTooltip: 'Tilaukset eivät voi olla tilassa Toimitettu' },
    h2: { booking: 'H2 Tilaus', vasHelperText: 'Käytä VAS 1158 kun MBH' },
    loadList: {
      header: 'Reitti yhteenveto',
      listName: 'Lastauslista',
      unknownRouteName: 'Tuntematon reitin nimi',
      unknownUnitName: 'Tuntematon kuljettajan nimi',
      noDataText: 'Täällä ei ole mitään nähtävää'
    },
    locationList: { listName: 'Sijaintiluettelo' },
    assignedUnits: 'Määrätyt yksiköt',
    unassignedUnits: 'Määrittämättömät yksiköt',
    noUnitSelected: 'Yksikköä ei ole valittu',
    unitAssignmentCount: 'Yksikkö määrätty {{count}} reitille'
  },
  plannerDashboard: {
    vehicleTypes: {
      car: 'Henkilöauto',
      bicycle: 'Polkypyörä',
      truck: 'Kuorma-auto',
      van: 'Pakettiauto',
      motorcycle: 'Moottoripyörä',
      cargobike: 'Rahtipyörä'
    }
  },
  updateServiceLevel: {
    menuItem: 'Vaihda palvelutaso',
    selectOption: 'Valitse palvelutaso',
    heading: 'Vaihda palvelutaso',
    confirmation: 'Palvelutaso päivitetty',
    subtext: 'Vaihda palvelutaso {{count}} lähetykselle',
    subtext_plural: 'Vaihda palvelutaso {{count}} lähetykselle',
    failed: {
      title: 'Palvelutason vaihto epäonnistui',
      message: 'Palvelutason vaihto epäonnistui {{count}} lähetykselle',
      message_plural: 'Palvelutason vaihto epäonnistui {{count}} lähetykselle'
    }
  },
  manualPreAdvice: {
    subtext: 'Change service level for {{count}} shipment',
    subtext_plural: 'Change service level for {{count}} shipments'
  },
  setPickupDeliveryDuration: {
    menuItem: 'Aseta nouto/vienti aika',
    heading: 'Aseta nouto ja vienti aika',
    subtext: 'Aseta nouto ja vienti aika {{count}} lähetykselle',
    subtext_plural: 'Aseta nouto ja vienti aika {{count}} lähetykselle',
    pickupDuration: 'Nouto aika',
    deliveryDuration: 'Vienti aika',
    confirmation: 'Aika päivitetty',
    tooltipTitle: 'Kestoja syötettäessä, maksimimäärä minuutteja ja sekunteja on 59'
  },
  importExportOrders: {
    importSuccess: 'Kaikki laskentataulukon tilaukset lisätään tai päivitetään järjestelmään',
    importSuccessTitle: 'Menestys',
    importOrdersHeading: 'Tuo tilaus',
    importStatus: 'Tällä hetkellä jonossa on {{imports}} tuontia, yhteensä {{orders}} tilausta',
    exportForInvoice: 'Export for Invoicing',
    fileTemplateLink: 'Lataa Excel-malli',
    uploadFile: 'Tuo tilaus',
    uploadingMessage: 'Ladataan tiedostoa ja tuodaan tilauksia...',
    needToSelectFile: 'Sinun on valittava tiedosto',
    exportSuccess: 'Kaikki tilaukset valitulta ajanjaksolta ladataan laskentataulukkoon',
    exportSuccessTitle: 'Menestys',
    dateFormat: 'PP.KK.VVVV',
    testDataWarning: 'Älä käytä henkilökohtaisia ​​tietoja testiympäristössä',
    testDataInfo:
      'Tämä on qa / testiympäristö, etkä saa käyttää tuotantotietoja testeissä ilman asianomaisten käyttäjien nimenomaista suostumusta',
    fromDate: 'Päivämäärästä',
    toDate: 'Päivämäärään',
    fromDateNotAfterToDate: 'Ei voi olla myöhäisempi kuin päivämäärää',
    toDateNotBeforeFromDate: 'Ei voi olla aikaisempi kuin päivämäärästä alkaen',
    removeControlColumns: 'Poista ohjaussarakkeet',
    importResults: 'Tuo tulokset',
    fileName: 'tuotu tiedosto',
    processedAt: 'Processed',
    createdShipmentId: 'Luotu toimitustunnus',
    date: 'Päivämäärä',
    exportShipments: 'vientialukset',
    createLabels: 'Luo tunnisteita',
    selectSlot: 'Valitse reitti',
    selectExportLabel: 'Valitse, mitä haluat viedä',
    allOrders: 'Kaikki tilaukset',
    deviatedOrders: 'Poikkeamatilaus',
    all: 'Kaikki',
    noSelection: 'Ei valintaa',
    selectFile: 'Valitse tiedosto',
    noFileSelected: 'Ei tiedostoa',
    updateSelectedFile: 'Päivitä tiedosto',
    jobList: {
      title: 'Edellinen vienti',
      content: 'sisältö',
      created: 'Luotu',
      user: 'Käyttäjä',
      dates: 'Päivämäärä',
      download: 'Ladattu',
      exporting: 'Vietään...',
      exportFailed: 'Vienti epäonnistui',
      updated: 'Updated',
      previousImports: 'Aiemmat tuonnit',
      fileName: 'Tiedoston nimi',
      numberOfOrders: 'Tilausten määrä',
      imported: 'Tuotu',
      viewOrders: 'Näytä tilaukset',
      lastImports: 'Luetellaan viimeiset {{count}} tuontia'
    },
    exportStartedNotification: 'Vienti aloitettu',
    exportStartedNotificationMessage: 'Raporttisi on saatavilla heti, kun se on käsitelty.',
    exportFailedNotification: 'Vienti epäonnistui, yritä myöhemmin uudelleen',
    refreshData: 'Päivitä tiedot olemassa olevaan raporttiin',
    refreshDataDescription:
      'Valitse tämä vaihtoehto, jos olet jo luonut olemassa olevan raportin tietyille päivämäärille',
    types: {
      SHIPMENT_EXPORT: 'Toimitukset',
      AIREXPRESS_SHIPMENTS: 'Vie lähetykset (sisältää AirExpress datan)',
      COURIER_WORK_REPORT: 'Kuljettajan työraportti',
      SOCIAL_CONTROL: 'Sosiaalinen valvonta',
      LOCATION_LIST: 'Sijaintiluettelo',
      ORDER_EXPORT: 'Tilauksen vienti',
      ORDER_DEVIATION_EXPORT: 'Tilaus (poikkeama)',
      ORDER_EXPORT_ANONYMOUS: 'Tilaus (anonyymit tiedot)',
      ORDER_PARTNER_EXPORT: 'Tilaus (kumppaneille)',
      DEVIATION_EVENTS: 'Poikkeamatapahtumat'
    },
    exportTitles: {
      SHIPMENT_EXPORT: 'Vie lähetykset',
      AIREXPRESS_SHIPMENTS: 'Lataa lähetykset(sisältää AirExpress datan)',
      COURIER_WORK_REPORT: 'Kuljettajan työraportti',
      SOCIAL_CONTROL: 'Vie sosiaalinen hallinta',
      LOCATION_LIST: 'Vie sijaintiluettelo',
      ORDER_EXPORT: 'Vie tilaus',
      ORDER_DEVIATION_EXPORT: 'Vie tilaus poikkeamalla',
      ORDER_EXPORT_ANONYMOUS: 'Vie tilaus nimettömänä',
      ORDER_PARTNER_EXPORT: 'Vie kumppanitilaus',
      DEVIATION_EVENTS: 'Vie poikkeamatapahtumat'
    },
    exportDescriptions: {
      AIREXPRESS_SHIPMENTS: 'Tämä sisältää AirExpress datan, normaalin datan lisäksi',
      ORDER_EXPORT:
        'Jos haluat käyttää dataa testiympäristöissä, suosittelemme "Tilaa (anonymisoitu data)"-vaihtoehtoa',
      ORDER_DEVIATION_EXPORT:
        'Jos haluat käyttää dataa testiympäristöissä, suosittelemme "Tilaa (anonymisoitu data)" -vaihtoehtoa',
      ORDER_EXPORT_ANONYMOUS: 'Voidaan käyttää, jos tietoja tuodaan testiympäristöön',
      ORDER_PARTNER_EXPORT: 'Muokattu Excel-tiedosto, jota voidaan käyttää Opterin tuontiin'
    }
  },
  unit: {
    new: 'Uusi unit',
    edit: 'Muokkaa unit',
    addCourier: 'Lisää lähetti',
    hideAddCourier: 'Piilota lisää lähetti',
    requiresCourierUsers: 'Pitäisi olla vähintään yksi kuriiri',
    created: 'Unit on luotu',
    updated: 'Unit on päivitetty',
    search: 'Etsi yksiköitä muilta osastoilta...',
    unitsInCurrentDepartment: 'Yksiköt nykyisessä osastossa',
    unitsInOtherDepartments: 'Muiden osastojen yksiköt',
    addCourierToUnit: 'Save courier to unit'
  },
  courier: {
    isActive: 'On aktiivinen',
    canLogIn: 'Voi kirjautua sisään',
    secureLoginEnabled: 'BankID Kirjautuminen',
    recipientName: 'Vastaanottajan nimi',
    created: 'Lähetti on luotu',
    updated: 'Lähetti on päivitetty',
    courier: 'Lähetti',
    couriers: 'Lähetit',
    noCouriers: 'Ei lähettejä',
    courierWithCount: '{{count}} lähetti',
    courierWithCount_plural: '{{count}} lähettiä',
    numberOfCouriers: 'Löydetty {{number}} lähetit',
    multipleDrivers: 'Useampi kuljettaja',
    missingId: 'ID puuttuu',
    missingName: 'Nimi puuttuu',
    search: 'Etsi lähettejä',
    new: 'Uusi lähetti',
    edit: 'Muokkaa lähettejä',
    name: 'Nimi',
    alystraId: 'Alystra ID',
    phoneNumber: 'Puhelinnumero',
    capacity: 'Kapasiteetti',
    weightCapacity: 'Painokapasiteetti (kg)',
    volumeCapacity: 'Tilavuuskapasiteetti (m³)',
    maxRangeKm: 'Toimintasäde (km)',
    color: 'Väri',
    resetColor: 'nollaa väri',
    speedFactor: 'Nopeustekijä',
    speedFactorExplanation: 'alhainen = hitaampi',
    serviceTimeFactor: 'Palveluaikatekijä',
    serviceTimeFactorExplanation: 'alhainen = nopeampi',
    defaultStopLocation: 'Oletukseksi asetettu pysähdyspaikka',
    defaultStartLocation: 'Oletukseksi asetettu aloituspaikka',
    slotNumDeliveries: '{{count}} toimitus',
    slotNumDeliveries_plural: '{{count}} toimitusta',
    slotNumPickups: '{{count}} nouto',
    slotNumPickups_plural: '{{count}} noutoa',
    deliverPerformButtonLabel: 'Toimittaa',
    handoverPerformButtonLabel: 'Luovuttaa',
    deviationPerformButtonLabel: 'Poikkeama',
    deliverWithoutSignatureButtonLabel: 'Ilman allekirjoitusta',
    deliverWithSignatureButtonLabel: 'Allekirjoitus',
    deviateNotAtHomeButtonLabel: 'Ei kotona',
    deviateLocNotFoundButtonLabel: 'Sijaintia ei löytynyt',
    deviateRecipientDeclinedButtonLabel: 'Vastaanottaja ei halunnut sitä',
    deviateTimeWindow: 'Aika ei riittänyt toimittamaan pyydetyssä aikaikkunassa',
    addressNotFound: 'Osoitetta ei löytynyt',
    deliverySpotUnavailable: 'Sovittu toimitus paikka ei ole mahdollinen',
    deviation: {
      'H-12': 'Lähetys yritettiin toimittaa, mutta vastaanottaja ei ollut paikalla',
      'H-11': 'Paketissa toimitusyritys, mutta osoitetta ei löytynyt',
      'H-10': 'Paketissa toimitusyritys, mutta vastaanottajaa ei tavoitettu osoitteesta',
      'H-13': 'Lähetys yritettiin toimittaa, mutta sen vastaanottamisesta kieltäydyttiin',
      '7-10': 'Tavaroita ei voitu noutaa puutteellisen osoitteen takia',
      '7-12': 'Lähettäjä ei ollut tavoitettavissä. Tavaroita ei ole noudettu',
      '7-82': 'Tavarat eivät olleet valmiit noudettavaksi',
      'V-41-K': 'Lähetys on viivästynyt, mutta se toimitetaan eteenpäin mahdollisimman pian',
      'H-07-R': 'Epäilyttävä, tai päihtynyt vastaanottaja',
      'H-07-Z': 'Epäilyttävä, tai päihtynyt vastaanottaja',
      'H-08-Z': 'Alaikäinen henkilö',
      'H-09-Z': 'Henkilöllisyystodistus puuttuu',
      'VI-16-X': 'Aika ei riittänyt toimittamaan pyydetyssä aikaikkunassa',
      'H-11-Z': 'Osoitetta ei löytynyt',
      'H-BL-A': 'Sovittu toimitus paikka ei ole mahdollinen'
    },
    completionProgressCounter: '{{numCompleted}}/{{numTotal}} valmiina',
    lockCode: 'Ovikoodi',
    flexDelivery: 'Simplified delivery -toimitus',
    fuelType: 'Polttoainetyyppi',
    fuelTypes: {
      diesel: 'Dieseli',
      gasoline: 'Bensiini',
      natural_gas: 'Maakaasu',
      ethanol: 'Etanoli',
      bio_gas: 'Biokaasu',
      electric: 'Sähkö',
      bicycle: 'Polkupyörä',
      hydrogen: 'Vety'
    },
    fuelCategory: 'Polttoaineluokka',
    fuelCategories: {
      fossil: 'Fossiilinen polttoaine',
      fossil_free: 'Fossiilivapaa polttoaine',
      zero_emissions: 'Päästöt nolla'
    },
    latestLogin: 'Viimeisin kirjautuminen',
    latestVersion: 'Uusin versio',
    failedSocialControl: 'Epäilyttävä, tai päihtynyt vastaanottaja',
    failedIdControl: 'Henkilöllisyystodistus puuttuu',
    failedAgeControl: 'Alaikäinen henkilö',
    incompletePickupAddress: 'Tavaroita ei voitu noutaa puutteellisen osoitteen takia.',
    custNotAvailableAtPickupAddress: 'Lähettäjä ei ollut tavoitettavissä. Tavaroita ei ole noudettu.',
    goodsNotReadyAtPickupForCust: 'Tavarat eivät olleet valmiit noudettavaksi.',
    shipmentNotAvailableAtTerminal: 'Lähetys on viivästynyt, mutta se toimitetaan eteenpäin mahdollisimman pian.',
    registrationNumber: 'Rekisterinumero',
    commercialTrafficEligibility: 'Kaupallinen liikenne kelpoisuus',
    vehicleBrand: 'Autonmerkki',
    vehicleModel: 'Autonmalli',
    vehicleControl: 'Ajoneuvon tarkastus',
    vehicleControlComment: 'Ajoneuvon tarkastuksen kommentti',
    euroClass: 'Euro luokka',
    alcoholDeliveryEducation: 'Alkoholin kuljetus koulutus',
    approved: 'Hyväksytty',
    notApproved: 'Ei Hyväksytty',
    couriersInCurrentDepartment: 'Kyseisen osaston kuriirit',
    editCourier: 'Muokkaa kuriiria:',
    courierUserUpdated: 'Kuriirin tiedot päivitetty onnistuneesti.',
    courierScanEnabled: 'Skannaa'
  },
  slot: {
    minutesDelay: 'minuuttia viivästynyt',
    minutesAhead: 'minuuttia aikaisessa',
    minutes: 'minuuttia',
    time: 'Aika',
    created: 'Luotu',
    assigned: 'Annettu toimitettavaksi',
    lock: 'Lukko',
    lockDescription:
      'Kun toimitusajankohta on lukittu, sitä ei voi käyttää enää tulevissa reitin optimoinneissa, eikä sitä voi muokata.',
    toggleVisibleSlotDescription: 'Piilota toimitusajankohta.',
    inProgress: 'Käynnissä',
    onHold: 'Odottaa',
    complete: 'Valmis',
    unknownState: 'Tuntematon tila',
    courierSelect: 'Valitse lähetti',
    unitSelect: 'Valitse yksikkö',
    printableSlotList: 'Tulostettava toimitusaikalista',
    followsRoute: 'Seuraa',
    doesNotFollowRoute: 'Poiketa',
    hasLocation: 'Näkyvissä',
    hasNoLocation: 'Ei näkyvissä',
    showActiveSlots: 'Näytä käynnissä olevat',
    showSlotNotStarted: 'Näytä ei aloitetut',
    showCompletedSlot: 'Näytä valmiit',
    showPerfectSlot: 'Näytä täydelliset',
    filterSlots: 'Suodata toimitusajankohtia',
    planningCompleted: 'Valmis',
    addMapArea: 'Valitse alue',
    editMapArea: 'Muokkaa alue'
  },
  login: {
    heading: 'Kirjaudu sisään',
    phone:
      "Lisää Glow'hun rekisteröity puhelinnumerosi mukaan lukien maanumero (esim. +358...), niin lähetämme sinulle SMS-tokenin.",
    code: 'Token lähetetty, jos tälle numerolle on rekisteröity käyttäjä. Se on voimassa 5 minuutia. Anna token.',
    requestNewCode: 'Pyydä toista koodia',
    verifyFailed:
      'Koodi tai puhelinnumero on väärin, tai liian monta kertaa mennyt väärin. Yritä uudestaan tai pyydä uusi koodi.',
    requestFailed: 'Tokenia ei voitu lähettää, yritä uudelleen.',
    verifyTokenButton: 'Vahvista koodi',
    requestTokenButton: 'Pyydä koodi',
    privacyLink: 'Tietosuojakäytäntö',
    passwordWrong: 'Virheellinen salasana',
    customer: 'Olen asiakas',
    forgotPassword: 'Unohditko salasanasi?',
    tryApp: 'Kokeile uutta sovellusta!',
    appDownload: 'Lataa kuljettajan sovellus täältä, niin saat käyttöösi enemmän toimintoja!',
    appInfo:
      'Oletko kuljettaja, jolla on Android-puhelin? Lataa kuljettajan sovellus <1>täältä</1>, niin saat käyttöösi enemmän toimintoja!',
    webApp: 'Web-sovellus',
    note: 'Huomautus! Käytä vain kuriirisovelluksen web-versiota, jos et jostain syystä käytä iOS- ja Android-sovelluksia',
    resendToken: 'Lähetä uudelleen',
    submitPassword: 'Lähetä salasana',
    loginAsCustomer: 'Kirjaudu sisään asiakkaana',
    loginAsPlanner: 'Kirjaudu sisään suunnittelijana',
    token: 'Koodi',
    password: 'Salasana',
    tokenRequired: 'Koodi vaaditaan',
    forgotPasswordDescription:
      'Jatkaaksesi sinun on asetettava salasana. Lähetämme sinulle sähköpostin, jossa on linkki tämän asettamiseksi. Jos sähköpostisi on muuttunut, sinun on otettava yhteyttä ylläpitäjään sen muuttamiseksi.',
    sendEmailDescription:
      'Jatkaaksesi tarvitsemme sähköpostisi. Lähetämme sinulle vahvistuslinkin annettuun osoitteeseen. Tällä linkillä voit asettaa 2-vaiheisen salasanasi.',
    emailFormatValidation: 'Sähköpostin on oltava kelvollisessa muodossa',
    sendEmailSuccess: 'Sähköpostin pitäisi saapua pian',
    sendEmailFailed: 'Sähköpostin lähetys epäonnistui, yritä uudelleen',
    setYourPassword: 'Aseta salasanasi',
    sendEmail: 'Lähetä sähköposti',
    passwordsDontMatch: 'Salasanat eivät täsmää',
    passwordNotMeetRequirements: 'Salasana ei täytä vaatimuksia',
    passwordRequired: 'Salasana vaaditaan',
    newPassword: 'Uusi salasana',
    repeatPassword: 'Toista salasanasi',
    lowercaseLetter: 'Pieni kirjain',
    uppercaseLetter: 'Iso kirjain',
    numberCharacter: 'Numero',
    minimumCharacters: 'Vähintään 15 merkkiä',
    passwordDescription: 'Voit nyt asettaa salasanasi, sen on sisällettävä seuraavat'
  },
  error: {
    '404Title': 'Sivua ei löydy',
    '404Message': 'Emme löydä etsimääsi sivua',
    '500Message': '{{error}} - Pahoittelut, emme löydä tietojasi... yritämme uudelleen minuutin kuluttua...',
    title: 'Jotain meni vikaan!',
    dateRequiredWhenSecureLogin: '*Vaadittu jos kirjautuminen pankkitunnuksella on aktivoitu',
    dateNotValid: 'Virheellinen päivämäärä',
    errorMessage:
      'Olemme pahoillamme! Tämä ei sujunut kuten piti – olemme ilmoittaneet virheestä järjestelmänkehittäjille...',
    errorClickToReload: 'Klikkaa tästä ladataksesi sivun uudelleen',
    phoneNumber: 'Väärä puhelinnumero! Oikea on +358 12345678.',
    phoneNumberPrefix: 'Sinun täytyy antaa maanumero, esim. +358...',
    somethingWentWrong: 'Jokin meni vikaan tallennettaessa...',
    notAValidNumber: 'Virheellinen numero',
    notAValidAlphaNumeric: 'Täytyy olla numeroita ja/tai kirjaimia',
    notAValidDate: 'Virheellinen päivämäärä (vvvv-kk-pp)',
    notAValidTime: 'Virheellinen kellonaika (hh:mm)',
    notAValidDuration: 'Täytyy olla kelpaava aika (mm:ss)',
    notAValidZipCode: 'Ei kelvollinen postinumero',
    notAValidTimezone: 'Aikavyöhyke ei ole kelvollinen. Yrittää "Europe/Helsinki" tai samankaltainen',
    somethingWentWrongOnTheServer: 'Jokin meni palvelimella vikaan...',
    somethingWentWrongWithTheRequest: 'Pyynnössä oli jokin vika...',
    somethingWentWrongWithTheResponse: 'Vastauksessa oli jotain vikaa..',
    selectAtLeastOne: 'Sinun täytyy valita vähintään yksi vaihtoehto',
    timeInWrongFormat: 'Aika ei ole mahdollinen'
  },
  loading: 'Yritämme paikantaa dataasi...',
  newAppVersion: 'Glow just got a little better, we are fetching the new version now...',
  loadingReceiptList: 'Kuittilistoja luodaan...',
  invoicing: { departmentInvoicingInProgress: 'Laskutus käynnissä...' },
  months: {
    january: 'Tammikuu',
    february: 'Helmikuu',
    march: 'Maaliskuu',
    april: 'Huhtikuu',
    may: 'Toukokuu',
    june: 'Kesäkuu',
    july: 'Heinäkuu',
    august: 'Elokuu',
    september: 'Syyskuu',
    october: 'Lokakuu',
    november: 'Marraskuu',
    december: 'Joulukuu'
  },
  weekdays: {
    monday: 'Maanantai',
    tuesday: 'Tiistai',
    wednesday: 'Keskiviikko',
    thursday: 'Torstai',
    friday: 'Perjantai',
    saturday: 'Lauantai',
    sunday: 'Sunnuntai'
  },
  weekdaysShort: {
    sunday: 'Su',
    monday: 'Ma',
    tuesday: 'Ti',
    wednesday: 'Ke',
    thursday: 'To',
    friday: 'Pe',
    saturday: 'La'
  },
  maps: { addressLookup: 'Osoitehaku' },
  notifications: {
    dismiss: 'Hylkää',
    dismissAll: 'Hylkää kaikki',
    error: 'Virhe',
    reload: 'Päivitä',
    reloadCourier: 'Paina tästä päivittääksesi näytön',
    navigate: 'Navigoi sivulle',
    networkError:
      'Vaikuttaa siltä, että tällä hetkellä on verkko-ongelmia, ne voivat olla tilapäisiä. Sivu tulee joka tapauksessa päivittää.',
    networkErrorCourier: 'Olet ollut offline-tilassa, ole hyvä ja päivitä sivu.'
  },
  grid: {
    columns: {
      department: 'Osasto',
      deadline: 'Aikaraja',
      failedReason: 'Virheviesti',
      name: 'Nimi',
      senderName: 'Lähettäjän nimi',
      puearly: 'N',
      pulate: 'N',
      delearly: 'T',
      dellate: 'T',
      zip: 'Posti nro',
      type: 'Tyyppi',
      estimated: 'Arvioitu aika',
      timeWindow: 'aikaikkuna',
      onCar: 'autossa',
      frequency: 'Toistuvuus',
      startDate: 'Aloituspäivä',
      endDate: 'Päättymispäivä',
      from: 'Alkaen',
      to: 'Asti',
      days: 'Päivät',
      holidays: 'Pyhät',
      startdate: 'Aloitus päivämäärä',
      enddate: 'Lopetus päivämäärä',
      orders: 'Tilaukset',
      id: 'Tilausnumero',
      shipmentId: 'Tilausnumero',
      customer: 'Asiakas',
      pickup: 'Nouto',
      delivery: 'Toimitus',
      service: 'Palvelu',
      address: 'Osoite',
      zipcode: 'Posti nro',
      area: 'Alue',
      weight: 'Paino',
      volume: 'Tilavuus{{unit}}',
      qty: 'Kpl',
      preplanned: 'Kuljettaja',
      group: 'Ryhmä',
      totalOrders: 'Tilauksia yhteensä',
      ordersFailed: 'Tilauksia epäonnistunut',
      date: 'Päivämäärä',
      finished: 'Valmis',
      failed: 'Epäonnistunut',
      ordersInvoiced: 'Tilauksia laskutettu',
      start: 'Aloitus',
      stop: 'Lopetus',
      pickupContactPerson: 'Nouto yhteyshenkilö',
      pickupPhone: 'Nouto puhelin',
      deliveryContactPerson: 'Toimitus yhteyshenkilö',
      deliveryPhone: 'Toimitus puhelin',
      contactPerson: 'Yhteyshenkilö',
      phone: 'Puhelin',
      deliveryReferencePlaceholder: 'Vastaanottajan viite',
      specificationDescriptionPlaceholder: 'Tiedot tavarasta',
      groupName: 'Ryhmän nimi',
      customerNumber: 'Asiakasnumero',
      orderNote: 'Muistiinpanot',
      returned: 'Palautettu',
      price: 'Hinta',
      slotName: 'Toimitusajankohta',
      deliveryEtaSmsSentAt: 'Toimitusaika SMS lähetetty',
      trip: 'Matka',
      addresses: {
        title: 'Osoitteet',
        search: 'Etsi osoite',
        searchAddresses: 'Etsi osoitteita',
        filterAddresses: 'Suodata osoitteita',
        deleteConfirmation: 'Oletko varma että haluat poistaa tämän osoitteen?',
        deleteMultipleConfirmation: 'Oletko varma että haluat poistaa kaikki valitut osoitteet?',
        deleteSuccess: 'Osoite poistettu',
        deleteMultipleSuccess: 'Osoitteet poistettu',
        delete: 'Poista',
        name: 'Nimi',
        phone: 'Puhelinnumero',
        address: 'Osoite',
        zipArea: 'Kaupunki',
        zipCode: 'Postinumero',
        country: 'Maa',
        contactPerson: 'Yhteyshenkilö',
        instructions: 'Ohjeet',
        deleteAddress: 'Poista osoite',
        deleteMultipleAddresses: 'Poista osoitteet ({{count}})',
        loc: 'S',
        locTitle: 'Sijainti'
      },
      orderGrid: { extraInfo: 'Lisätieto' },
      dispatch: { id: 'Tilausnumero' },
      orderSource: 'Tilaustapa',
      postalCodes: '# postinumero',
      displayEndCustomerPriceShd: 'Näytä asiakashinta',
      courier: 'Lähetti',
      plannedDeliveryDate: 'Suunniteltu aika',
      orderedDeliveryDate: 'Tilaus päivä',
      lastUpdatedAt: 'Viimeksi päivitetty',
      route: 'Reitti',
      instructions: 'Ohjeet',
      pickupDuration: 'Nouto aika',
      deliveryDuration: 'Vienti aika',
      originalPickupAddress: 'Alkuperäinen lähettäjän osoite',
      originalPickupZipCode: 'Alkuperäinen lähettäjän postinumero',
      originalPickupZipArea: 'Alkuperäinen lähettäjän kaupunki',
      originalPickupCountry: 'Alkuperäinen lähtömaa',
      originalDeliveryAddress: 'Alkuperäinen toimitusosoite',
      originalDeliveryZipCode: 'Alkuperäinen toimituksen postinumero',
      originalDeliveryZipArea: 'Alkuperäinen toimituskaupunki',
      originalDeliveryCountry: 'Alkuperäinen toimitusmaa',
      packagesArrivedAtDip: 'Saapunut',
      packagesCollected: 'Noudettu',
      packagesDelivered: 'Toimitettu',
      originalDeliveryEarliest: 'Alkuperäinen T',
      originalDeliveryLatest: 'Alkuperäinen T',
      preAdvices: 'Preadvices',
      preAdviceScheduledDate: 'Preadvice scheduled',
      hasPreAdviceSettings: 'Preadvice settings apply',
      arrivedAtHub: 'Arrived at hub'
    }
  },
  openBooking: {
    pickupReferencePlaceholder: 'Asiakkaan viite – Tämä kenttä näkyy asiakkaan laskussa',
    customerContactPlaceholder: '$t(instant.booking.customerContact) – Tämä kenttä näkyy asiakkaan laskussa',
    invoiceInfoPlaceholder: 'Laskutustiedot – Tämä kenttä näkyy asiakkaan laskussa',
    pickupTimePlaceholder: 'Aikaisin noutoaika'
  },
  instant: {
    title: 'Ajojärjestely',
    openMap: 'Avoin kartta',
    group: 'Ryhmä valittu',
    editGroup: 'Muokkaa ryhmää',
    groupName: 'Ryhmän nimi',
    groupOrders: 'Ryhmän tilaukset',
    viewGroup: 'Näytä ryhmä',
    ordersToGroup: 'Lisää tilaukset ryhmään',
    fixedOrder: 'Korjattu toimitus',
    paidTogether: 'Maksetaan yhdessä',
    saveGroup: 'Tallenna Ryhmä',
    ungroup: 'Pura ryhmä',
    removeSelected: 'Poista valitut',
    noCourierSelected: 'Lähettiä ei valittu',
    noOrderSelected: 'Tilausta ei valittu',
    workingHours: 'Työtunnit',
    chatButton: 'Chatti kuljettajan kanssa',
    offeredShipments: 'Tarjotut lähetykset',
    rejectedShipments: 'Hylätyt lähetykset',
    rejectedShipmentsSnackbar: '"{{count}}" {{name}} hylkäämä lähetys',
    rejectedShipmentsSnackbar_plural: '"{{count}}" {{name}} hylkäämää lähetystä',
    toBeDone: 'Tulevat noudot ja toimitukset',
    finishedRoutePoints: 'Hoidetut noudot ja toimitukset',
    suggestedRouteApplied: 'Ehdotettu reitti on otettu käyttöön',
    suggestedRouteCancelled: 'Ehdotettu reitti on peruttu',
    reorderValidationError: 'Ehdotettu reitti on virheellinen',
    planSlot: 'Reittisuunnitelma',
    columnSettings: { reset: 'Nollaa', settings: 'Asetukset', hideColumns: 'Piilota sarakkeet' },
    confirmation: {
      heading: 'Tilausvahvistus',
      from: 'Nouto',
      to: 'Toimitus',
      service: 'Palvelu',
      pickup: 'Alkaen',
      delivery: 'Asti',
      shipmentNumber: 'Lähetyksen numero',
      printLabel: 'Tulosta etiketti',
      copyOrder: 'Kopioi tilaus',
      editOrder: 'Muokkaa tilausta'
    },
    goods: {
      letter: 'Kirje',
      bag: 'Pussi',
      smallbox: 'Pieni laatikko',
      mediumbox: 'Keskikokoinen laatikko',
      largebox: 'Iso laatikko',
      tube: 'Putkilo',
      quarterpallet: '1/4 lava',
      halfpallet: 'Teholava',
      fullpallet: 'Lava',
      suitcase: 'Matkalaukku',
      movingbox: 'Muuttolaatikko',
      trolley: 'Rullakko',
      parcel: 'Paketti'
    },
    instantGrid: {
      editColumnTooltip: 'Muokkaa tilausta',
      deleteColumnTooltip: 'Poista',
      unassignColumnTooltip: 'Poista kuljettajalta',
      dragColumnTooltip: 'Klikkaa ja vedä antaaksesi tehtävän lähetille',
      filtersActive: 'Suodatin päällä',
      clearFilters: 'Tyhjennä suodattimet',
      filterPlaceholder: 'suodata <enter>'
    },
    booking: {
      addPackage: 'Lisää uusi paketti',
      removePackageRow: 'Poista paketti',
      cannotRemoveLastPackageRow: 'Viimeistä pakettia ei voi poistaa',
      copyPackageRow: 'Kopioi paketti',
      title: 'Tilaus',
      header: 'Tilaus',
      headerCreate: 'Uusi tilaus',
      headerEdit: 'Muokkaa tilausta',
      headerCopy: 'Kopioi tilaus',
      headerRestore: 'Palauta tilaus',
      customer: 'Asiakas',
      customerPlaceholder: 'Asiakastunnus',
      invalidSubcustomer: 'Subcustomer nimi on virheellinen',
      shipmentOrPackageId: 'Lähetys tai paketti ID',
      creditBlockedCustomer: 'Ei luottotietoja',
      sender: 'Lähettäjä',
      setAsDefaultAddress: 'Aseta oletusosoitteeksi',
      defaultAddressUpdated: 'Oletusosoite päivitetty',
      recipient: 'Vastaanottaja',
      pickupNamePlaceholder: 'Nimi',
      pickupAddressPlaceholder: 'Osoite',
      pickupZipCodePlaceholder: 'Postinumero',
      pickupZipAreaPlaceholder: 'Kaupunki',
      pickupCountryPlaceholder: '-- Valitse maa --',
      pickupPhoneNumberPlaceholder: 'Puhelin',
      pickupContactPerson: 'Yhteyshenkilö',
      customerReference: 'Asiakkaan viite',
      pickupReferencePlaceholder: 'Asiakkaan viite – Tämä kenttä näkyy asiakkaan laskussa',
      pickupInformationPlaceholder: 'Noutotiedot',
      customerContact: 'Asiakaskontakti',
      customerContactPlaceholder: '$t(instant.booking.customerContact) – Tämä kenttä näkyy asiakkaan laskussa',
      customerInfoPlaceholder: 'Yleiset asiakaskohtaiset tiedot',
      customerInfoHelper:
        'Tämä tieto on liitetty asiakkaaseen. Tehdyt muutokset näkyvät kaikissa asiakkaan tilauksissa.<br><br>Jos haluat lisätä tietoa vain tähän tilaukseen, käytä ”Lisätietoja” kohtaa.',
      deliveryNamePlaceholder: 'Nimi',
      deliveryAddressPlaceholder: 'Osoite',
      deliveryZipCodePlaceholder: 'Postinumero',
      deliveryZipAreaPlaceholder: 'Kaupunki',
      deliveryCountryPlaceholder: '-- Valitse maa --',
      deliveryPhoneNumberPlaceholder: 'Puhelin',
      deliverySecondPhoneNumberPlaceholder: 'Phone 2',
      deliveryReferencePlaceholder: 'Vastaanottajan viite',
      deliveryInformationPlaceholder: 'Toimitustiedot',
      orderNote: 'Muistiinpanot',
      clearData: 'Tyhjennä',
      dimensions: 'Tavara',
      weightPlaceholder: 'Paino (kg)',
      lengthPlaceholder: 'Pituus (cm)',
      widthPlaceholder: 'Leveys (cm)',
      heightPlaceholder: 'Korkeus (cm)',
      service: 'Palvelu',
      serviceCode: 'Palvelukoodi',
      servicePlaceholder: 'Palvelukoodi',
      serviceSelect: 'Valitse palvelu',
      serviceSelectDisabled: 'Palvelujen vaihtaminen HD-tilauksissa on tehtävä HappyFlow-muodossa',
      goodsEditDisabled: 'HD-tilausten tuotteisiin tehtävät muutokset on tehtävä HappyFlow´ssa',
      standardServices: '-- PERUSPALVELUT --',
      customizedServices: '-- ASIAKASKOHTAISET PALVELUT --',
      specificationDescription: 'Tavara',
      specificationDescriptionPlaceholder: 'Tiedot tavarasta',
      invoiceInfo: 'Laskutustiedot',
      invoiceInfoPlaceholder: 'Laskutustiedot – Tämä kenttä näkyy asiakkaan laskussa',
      manualInvoiceInfoPlaceholder:
        'Manuaalinen laskunkäsittely (jos tähän kenttään on täytetty laskutustiedot, tilausta ei laskuteta automaattisesti)',
      pickup: 'Nouto',
      delivery: 'Toimitus',
      pickupDatePlaceholder: 'Noutopäivä',
      pickupTimePlaceholder: 'Noutoaika',
      deliveryDatePlaceholder: 'Toimituspäivä',
      deliveryTimePlaceholder: 'Toimitusaika',
      deliveryContactPerson: 'Yhteyshenkilö',
      restoreButton: 'Palauta',
      orderButton: 'Tilaus',
      editButton: 'Päivitä',
      copyButton: 'Tilaus',
      created: 'Tilaus on rekisteröity',
      edited: 'Tilaus on päivitetty',
      quickSelect: 'Nopea valinta',
      volume: 'Volyymi',
      totalVolume: 'Kokonaisvolyymi {{volume}}',
      totalWeight: 'Kokonaispaino {{weight}}',
      removeItem: 'Poista',
      copyItem: 'Kopioi',
      addItem: 'Lisää',
      calculatePrice: 'Laske hinta',
      priceExplanations: {
        header: 'Hinnan selitys',
        article: 'Artikla: {{article}}',
        priceListName: 'Hinta lista:: {{pricelist}}',
        explanations: 'Selitykset:',
        noInfo: 'Hintatietoja ei ole saatavilla'
      },
      prePlannedToCourier: 'Valitse lähetti',
      automaticDepartment: 'Automaattinen',
      departmentLabel: 'Osasto',
      emailNotifications: 'Sähköposti ilmoitukset',
      eventRegistered: 'Rekisteröity',
      eventPickup: 'Nouto',
      eventDelivery: 'Toimitus',
      emailAddress: 'Sähköpostiosoite',
      emailRecipientText: 'Seuraavat sähköpostiosoitteet vastaanottavat ilmoituksia:',
      invalidEmail: 'Väärä sähköpostiosoite',
      editIndicator: 'Muokkaa',
      updateIndicator: 'Päivitä',
      copyIndicator: 'Kopioi',
      quantity: 'Määrä',
      additionalServices: 'Lisäpalvelut',
      paymentInformation: 'Maksutiedot',
      paidTogetherLabel: 'Maksetaan samasta?',
      groupingLabel: 'Lähetysten ryhmittäminen',
      groupIdPlaceholder: 'Ryhmän id',
      groupOrderPlaceholder: 'Ryhmän tilausnumero',
      addRecipient: 'Lisää ylimääräinen vastaanottaja',
      addPickup: 'Lisää ylimääräinen lähettäjä',
      customerPrice: 'Asiakas hinta',
      resourcePrice: 'Kuljettajan hinta',
      priceList: 'Hinta lista',
      manualPrice: 'Manuaalinen',
      total: 'Kaikki yhteensä',
      vehicleTypes: {
        car: 'Henkilöauto',
        bicycle: 'Polkypyörä',
        truck: 'Kuorma-auto',
        van: 'Pakettiauto',
        motorcycle: 'Moottoripyörä',
        cargobike: 'Rahtipyörä'
      },
      date: 'Päivämäärä',
      unNumber: 'YK-numero',
      hazardous: 'Luokka',
      group: 'Ryhmä',
      netWeight: 'Nettopaino',
      name: 'Nimi',
      restrictions: 'Rajoitukset',
      totalPoints: 'Kokonaispisteet'
    },
    courierSorting: { title: 'Suodatusperuste', alystraId: 'Kuljettajanumero', empty: 'Tyhjä', full: 'Täynnä' },
    courierFilter: {
      title: 'Lähettien suodatusperuste',
      all: 'Näytä kaikki',
      inactive: 'Piilota passiivinen',
      offline: 'Piilota offline',
      both: 'Piilota passiivinen ja offline-tilassa'
    },
    shipmentFilter: {
      title: 'Suodata lähetyksiä',
      showAll: 'Näytä kaikki',
      notCollected: 'Noutamatta',
      hideAll: 'Piilota kaikki'
    },
    offer: {
      carEmpty: 'Auto on parhaillaan tyhjä',
      suggestedDelivery: 'Ehdotetty toimitus järjestys',
      newShipments: 'Uudet tilaukset',
      manualRoute: 'Manuaalinen reitti',
      confirmRoute: 'Hyväksy reitti',
      assigning: 'Määrää',
      shipmentsTo: 'lähetystä kuljettajalle',
      shipmentTo: 'lähetys kuljettajalle'
    },
    useFlexible: 'Käytä joustavia toimitus aikoja',
    forceOptimizing: 'Pakota annettu toimitus järjestys',
    replanRoute: 'Suunnittele reitti uudelleen',
    recurring: {
      title: 'Vakiot',
      successMessage: '{{number}} lähetystä lisättiin valittuihin toistuviin ryhmiin',
      recurringOrders: 'Vakio tilaukset',
      includedOrders: 'Tilaukset sisältyvät aikatauluun',
      details: 'Henkilötiedot',
      editOriginal: 'Muokkaa alkuperäistä',
      copyOriginal: 'Kopioi alkuperäinen',
      notStarted: 'Ei alkanut',
      executing: 'Esittäen...',
      ended: 'Päättynyt',
      status: 'Tila',
      pending: 'Vireillä',
      failedOrders: 'Epäonnistuneet tilaukset',
      name: 'Nimi',
      frequency: 'Toistuvuus',
      frequencies: { weekly: 'Joka viikko', second_week: 'Joka toinen viikko', third_week: 'Joka kolmas viikko' },
      daysOfWeek: 'Viikonpäivät',
      includeHolidays: 'sisällytä lomat',
      startDate: 'Aloituspäivä',
      endDate: 'Päättymispäivä',
      save: 'Tallenna',
      fromDate: 'Alkamispäivä',
      confirmDelete: 'Haluatko varmasti poistaa valitun(-t) toistuvan(-t) kuljetuksen(-t)?',
      from: 'Alkaen',
      createOrders: 'Luo tilaukset',
      showResult: 'Näytä tulos',
      startdate: 'Aloitus päivämäärä',
      enddate: 'Lopetus päivämäärä',
      orders: 'Tilaukset',
      failed: 'Epäonnistunut',
      reset: 'nollata',
      confirmReset: 'Haluatko varmasti nollata {{date}} toistuvat tilaukset?'
    },
    corrections: {
      title: 'Muutokset',
      headline: 'Tilauksen muutokset ajalta {{date}}',
      accepted: 'Tilauksen muutos hyväksytty',
      rejected: 'Tilauksen muutos hylätty',
      approve: 'HYVÄKSY',
      decline: 'HYLKÄÄ',
      shipmentId: 'Tilausnumero',
      courier: 'Kuljettaja',
      from: 'Mistä',
      to: 'Mihin',
      details: 'Tietoja',
      pickupWaitingTime: 'Noudon odotusaika',
      deliveryWaitingTime: 'Toimituksen odotusaika',
      pickupLoadingTime: 'Noudettu',
      deliveryLoadingTime: 'Toimitettu',
      comment: 'Kommentti',
      weight: 'Paino',
      volume: 'Tilavuus',
      packages: 'Pakettimäärä',
      serviceCode: 'Palvelun koodi'
    },
    suggestedUnits: {
      unitName: 'Laitteen nimi',
      driverName: 'Kuljettajan nimi',
      slotName: 'Reitti',
      timeAtLocation: 'Aika kohteessa',
      distanceFromLocation: 'Etäisyys kohteesta',
      shipmentInformation: 'Lähetystiedot',
      suggestedVehicles: 'Suositellut ajoneuvot',
      suggestedVehiclesOnPickup: 'Ehdotetut ajoneuvot noutoon',
      suggestedVehiclesOnDelivery: 'Ehdotetut ajoneuvot toimitukseen',
      suggestedUnits: 'Ehdotetut yksiköt',
      noSuggestionsAvailable: 'Ei ehdotuksia saatavilla',
      pickupTime: 'Nouto Aika',
      deliveryTime: 'Toimitus Aika',
      filterBy: 'Rajoita'
    }
  },
  errors: {
    DEPARTMENT_INVALID_ALYSTRA_ID: 'Osasto virheellinen alystra id',
    ENTITY_DOES_NOT_EXIST: 'Kohdetta ei ole olemassa',
    INVALID_DATE_RANGE: 'Virheellinen ajanjakso',
    INVALID_DELIVERY_LATITUDE: 'Virheellinen toimituksen leveysaste',
    INVALID_DELIVERY_LONGITUDE: 'Virheellinen toimituksen pituusaste',
    INVALID_LATITUDE: 'Virheellinen leveysaste',
    INVALID_LONGITUDE: 'Virheellinen pituusaste',
    INVALID_OR_MISSING_TIME: 'Virheellinen tai puuttuva aika',
    INVALID_PHONE_NUMBER: 'Virheellinen puhelinnumero',
    DUPLICATE_PHONE_NUMBER: 'Sama puhelinnumero!',
    INVALID_PICKUP_LATITUDE: 'Virheellinen noudon leveysaste',
    INVALID_PICKUP_LONGITUDE: 'Virheellinen noudon pituusaste',
    INVALID_SLOT_TYPE: 'Virheellinen toimitusaikatyyppi',
    MISSING_ADDRESS: 'Osoite puuttuu',
    MISSING_ALYSTRA_ID: 'Alystra id puuttuu',
    MISSING_AREA: 'Alue puuttuu',
    MISSING_CAPACITY: 'Kapasiteetti puuttuu',
    MISSING_COLOR: 'Väri puuttuu',
    MISSING_CONSIGNMENT_INPUTS: 'Tavaraerää ei syötetty',
    MISSING_CONSIGNMENT: 'Tavaraerä puuttuu',
    MISSING_CONSIGNMENTS: 'Tavaraerät puuttuvat',
    MISSING_COURIER_ID: 'Lähetin id puuttuu',
    MISSING_COURIER: 'Sinun täytyy valita lähetti',
    MISSING_CREATED_AT: 'Luontitiedot puuttuvat',
    MISSING_CUSTOMER_ID: 'Asiakastunnus puuttuu',
    MISSING_CUSTOMER_REF: 'Asiakkaan viite puuttuu',
    MISSING_DATE: 'Päivä puuttuu',
    MISSING_DAYS: 'Päivät puuttuvat',
    MISSING_DEPARTMENT: 'Osasto puuttuu',
    MISSING_DESCRIPTION: 'Kuvaus puuttuu',
    MISSING_DEVIATION_TEXT: 'Poikkeaman teksti puuttuu',
    MISSING_END_LOCATION_LATITUDE: 'Päätepisteen leveysaste puuttuu',
    MISSING_END_LOCATION_LONGITUDE: 'Päätepisteen pituusaste puuttuu',
    MISSING_EVENT_DATA: 'Tapahtumadata puuttuu',
    MISSING_EVENT_TYPE: 'Tapahtumatyyppi puuttuu',
    MISSING_FILE_NAME: 'Tiedostonimi puuttuu',
    MISSING_FROM_DATE: 'Alkamispäivä puuttuu',
    MISSING_ID: 'ID puuttuu',
    MISSING_LOCATION_LATITUDE: 'Sijainnin leveysaste puuttuu',
    MISSING_LOCATION_LONGITUDE: 'Sijainnin pituusaste puuttuu',
    MISSING_LOCATION_POSITION: 'Sijainnin paikka puuttuu',
    MISSING_LOCATION_TIME: 'Sijainnin aika puuttuu',
    MISSING_NAME: 'Nimi puuttuu',
    MISSING_GROUP: 'Ryhmittely puuttuu',
    MISSING_PACKAGE_ID: 'Paketin tunnus puuttuu',
    MISSING_PHONE: 'Puhelinnumero puuttuu',
    MISSING_PLANNER_ID: 'Suunnittelutyökalun id puuttuu',
    MISSING_SERVICE_CODE: 'Palvelukoodi puuttuu',
    MISSING_SHIPMENT_ID: 'Lähetytunnus puuttuu',
    MISSING_SLOT_ID: 'Toimitusajankohdan ID puuttuu',
    MISSING_SLOT_TYPE: 'Toimitusajankohdan tyyppi puuttuu',
    MISSING_SMS_TOKEN: 'SMS-token puuttuu',
    MISSING_START_LOCATION_LATITUDE: 'Aloituspisteen leveysaste puuttuu',
    MISSING_START_LOCATION_LONGITUDE: 'Aloituspisteen pituusaste puuttuu',
    MISSING_TO_DATE: 'Päättymispäivä puuttuu',
    MISSING_ZIP_AREA: 'Postinumeroalue puuttuu',
    MISSING_ZIP_CODE: 'Postinumero puuttuu',
    MISSING_COUNTRY_CODE: 'Maatunnus puuttuu',
    NO_SUCH_USER: 'Ei käyttäjää',
    PARAM_NOT_OF_EXPECTED_TYPE: 'Parametri ei odotettua tyyppiä',
    PARAM_NULL_NOT_ALLOWED: 'Parametri nolla ei ole sallittu',
    QUERY_NAME_MISSING: 'Kyselyn nimi puuttuu',
    QUERY_PARAMS_MISSING: 'Kyselyn parametrit puuttuvat',
    USER_NOT_EXIST_OR_INCORRECT_ROLE: 'Käyttäjää ei ole tai rooli on väärä',
    USER_EXIST_BUT_INCOMPATIBLE_ROLE: 'Käyttäjä on jo olemassa ja hän on toisessa roolissa',
    SLOT_CANNOT_BE_STARTED: 'Toimitusajankohtaa ei voi luoda',
    SLOT_CANNOT_BE_DELETED: 'Toimitusajankohtaa ei voi poistaa',
    COURIER_NOT_PART_OF_DEPARTMENT: 'Lähetti ei kuulu osastoon',
    CONSIGNMENT_IN_INCORRECT_STATE_CANNOT_UPDATE: 'Tavaraerää ei voi päivittää virheellisessä tilassa',
    ORDER_DOES_NOT_EXISTS: 'Tilausta ei ole olemassa',
    OPTIMIZE_ALREADY_RUNNING: 'Optimointi on jo käynnissä',
    CANNOT_CHANGE_ORDER: 'Tilausta ei voi muuttaa',
    NO_ACCESS: 'Ei pääsyä',
    NO_RELEVANT_CONSIGNMENTS_OR_SLOTS: 'Ei relevanttia tavaraerää annettuihin toimitusajankohtiin',
    NO_RELEVANT_CONSIGNMENTS: 'Ei relevanttia tavaraerää annettuihin toimitusajankohtiin',
    NO_SUCH_QUERY: 'Kyselyä ei ole olemassa',
    EXCEL_MISSING_SHIPMENT_ID: 'Lähetystunnus puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_CUSTOMER_REF: 'Asiakkaan viite puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_CUSTOMER_ID: 'Asiakastunnus puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_DEPARTMENT: 'Osasto puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_PACKAGE_ID: 'Paketin tunnus puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_NAME: 'Nimi puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_INVALID_PHONE: 'Virheellinen puhelinnumero solussa {{col}} rivillä {{row}}',
    EXCEL_MISSING_ADDRESS: 'Osoite puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_ZIP_AREA: 'Postinumero puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_ZIP_CODE: 'Kaupunki puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_FROM_DATE: 'Alkamispäivä puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_TO_DATE: 'Päättymispäivä puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_INVALID_FROM_DATE: 'Alkamispäivä ei voi olla tätä päivää aikaisempi. Löytyy solusta {{col}} riviltä {{row}}',
    EXCEL_INVALID_TO_DATE: 'Päättymispäivä ei voi olla ennen tätä päivää. Löytyy solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_NOTIFICATION_SMS: 'Huomautus-SMS puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_SIZE: 'Koko puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_ORDERS: 'Laskentataulukossa ei ole tilauksia.',
    EXCEL_MISSING_COUNTRY: 'Maatunnus tai nimi puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_NO_ACCESS_DEPARTMENT: 'Ei pääsyä osastoon "{{dept_ref}}" rivin {{row}} mukaisesti',
    EXCEL_MISSING_DESCRIPTION: 'Kuvaus puuttuu solusta {{col}} riviltä {{row}}',
    EXCEL_MISSING_PICKUP_INSTRUCTIONS: 'Noudon kuvaus puuttuu  solusta"{{cell}}" riviltä {{row}}',
    EXCEL_MISSING_DELIVERY_INSTRUCTIONS: 'Toimituksen kuvaus puuttuu solusta "{{cell}}" riviltä {{row}}',
    MISSING_PICKUP_LOCATION_AND_TIME: 'Noudon sijainti ja aika puuttuvat',
    MISSING_EARLIEST_PICKUP_DATE: 'Aikaisin noutoaika puuttuu',
    MISSING_LATEST_PICKUP_DATE: 'Myöhäisin noutoaika puuttuu',
    MISSING_EARLIEST_DELIVERY_DATE: 'Aikaisin toimituspäivä puuttuu',
    MISSING_LATEST_DELIVERY_DATE: 'Myöhäisin toimituspäivä puuttuu',
    INVALID_EARLIEST_PICKUP_DATE: 'Virheelinen noutopäivä',
    INVALID_LATEST_PICKUP_DATE: 'Virheelinen noutopäivä',
    INVALID_EARLIEST_DELIVERY_DATE: 'Virheellinen toimituspäivä',
    INVALID_LATEST_DELIVERY_DATE: 'Virheellinen toimituspäivä',
    CANNOT_MODIFY_ORDERS_FROM_DIFFERENT_SOURCE: 'Excelin API-tilauksia ei voi muokata',
    PICKUP_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST: 'Noutoajan tulee olla ennen toimitusaikaa',
    DELIVERY_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST: 'Aikaisin vientiaika täytyy olla ennen myöhäisintä vientiaikaa',
    CANNOT_DELIVER_ON_A_HOLIDAY: 'Toimitus ei ole mahdollista pyhinä',
    MISSING_TEMPLATE_ID: 'Sivupohjan ID puuttuu',
    TEMPLATE_DOES_NOT_EXIST: 'Sivupohjaa ei ole olemassa',
    TEMPLATE_CANNOT_BE_DELETED: 'Sivupohjaa ei voi poistaa',
    MISSING_TEMPLATE_SLOTS: 'Sivupohjasta puuttuu toimitusajankohta',
    TEMPLATE_NAME_ALREADY_EXISTS: 'Tämän niminen sivupohja on jo olemassa',
    COURIER_ALREADY_EXISTS: 'Lähetti, jolla on tämä puhelinnumero, on jo olemassa',
    ORDER_EXIST_FOR_SERVICE: 'Palvelua ei voi poistaa, koska tälle palvelulle on tilauksia',
    NO_SERVICE_FOUND: 'Lisää palvelu ennen asiakkaan mappauksen lisäämistä',
    CANNOT_DELETE_AS_SERVICE_EXIST: 'Poista asiakaspalvelun mappaus ennen palvelun poistamista',
    SLOT_IN_INCORRECT_STATE_CANNOT_UPDATE: 'Toimitusajankohta ei ole oikeassa tilassa tätä päivitystä varten',
    SLOT_CANNOT_BE_EDITED: 'Toimitusajankohta ei ole oikeassa tilassa tätä päivitystä varten',
    INVALID_POSTCODE_AND_COUNTRY_CODE_CANNOT_BE_HANDLED: 'Virheellinen postinumero ja maatunnus',
    INVALID_BASIC_SERVICE_CODE: 'Virheellinen peruspalvelukoodi',
    INVALID_PICKUP_DATE_EARLIEST_FORMAT: 'Virheellinen aikaisimman noutoajan formaatti',
    INVALID_PICKUP_DATE_LATEST_FORMAT: 'Virheellinen myöhäisimmän noutoajan formaatti',
    INVALID_DELIVERY_DATE_LATEST_FORMAT: 'Virheellinen myöhäisimmän toimituspäivän formaatti',
    INVALID_DELIVERY_DATE_EARLIEST_FORMAT: 'Virheellinen aikaisimman toimituspäivän formaatti',
    INVALID_POSTAL_CODE: 'Virheellinen postinumero',
    INVALID_MEASUREMENT_UNIT: 'Virheellinen mittayksikkö',
    MISSING_CONSIGNMENT_SET: 'Tavaraerän asettaminen puuttuu',
    MISSING_UPDATE_INDICATOR: 'Päivitysindikaattori puuttuu',
    MISSING_CONSIGNMENT_ID: 'Tavaraerän ID puuttuu',
    MISSING_REFERENCE_NO: 'Viitteen ID puuttuu',
    MISSING_PACKAGE: 'Paketti puuttuu',
    MISSING_DELIVERY_DATE_EARLIEST: 'Aikaisin toimituspäivä puuttuu',
    MISSING_DELIVERY_DATE_LATEST: 'Myöhäisin toimituspäivä puuttuu',
    MISSING_CONSIGNEE: 'Tavaran vastaanottaja puuttuu',
    MISSING_CONSIGNOR: 'Tavaran lähettäjä puuttuu',
    MISSING_POSTAL_CODE: 'Postinumero puuttuu',
    MISSING_CITY: 'Postinumeroalue puuttuu',
    MISSING_PICKUP_DATE_LATEST: 'Myöhäisin noutoaika puuttuu',
    MISSING_PICKUP_DATE_EARLIEST: 'Aikaisin noutoaika puuttuu',
    MISSING_CONSIGNOR_PARTY_ID: 'Tavaran lähettäjän tyyppitunnus puuttuu',
    INVALID_CUSTOMER_NO_MAPPING: 'Virheellinen asiakasnumeron mappaus',
    INVALID_ROUTING: 'Virheellinen reititys',
    NO_SERVICE_MATCHING_SERVICE_CODE: 'Ei palvelukoodiin sopivaa palvelua',
    NO_SERVICE_MATCHING_DAY_OF_WEEK: 'Ei viikonpäivään sopivaa palvelua',
    NO_SERVICE_MATCHING_PICKUP_AND_DELIVERY_TIMES: 'Ei nouto- ja toimitusaikoihin sopivaa palvelua',
    ORDER_WAS_AFTER_CUTOFF_TIME: 'Tilaus tehtiin vastaanottamisajan päätyttyä',
    OPTIMUS_SERVICE_MUST_HAVE_PICKUP_AND_DELIVERY_TIMES: 'Nouto- ja toimitusajat pakollisia',
    ORDER_CANNOT_BE_MOVED: 'Lähetystä ei voi siirtää, sitä ei saa sijoittaa toimitusajankohtaan',
    MISSING_DESTINATION_DEPARTMENT_ID: 'Määräpaikan osasto puuttuu',
    SHIPMENT_NOT_FOUND: 'Lähetystä ei löydy',
    DUPLICATE_EXCEL_IMPORT:
      'Sama tiedostonimi on jo tuotu tähän osastoon tänään. Näet tuonnin tulokset <0>täällä</0>. Muuta tiedostonimi, jos haluat tuoda uudelleen.',
    SHIPMENT_INVALID_QUERY:
      'Sesongin aikana haku mahdollisuuksia on rajoitettu toiminnan varmistamiseksi. Voit etsiä kaikki lähetykset yhdeltä päivältä tai lisää haku parametrejä.',
    DURATION_IS_MORE: 'Ole hyvä ja rajoita hakuasi {{maxDuration}} päivälle.',
    ADDRESS_CANNOT_BE_DELETED: 'Osoitetta käytetään oletus osoitteena joten sitä ei voida poistaa.',
    UNKOWN_ERROR: 'Muutoksia ei voitu tallentaa',
    INCORRECT_STATES_OR_ASSIGNED_TO_SLOT: 'Virheellinen tila tai määrätty paikkaan',
    CANNOT_MOVE_ORDERS: 'Tilauksia ei voi siirtää',
    DESTINATION_DEPARTMENT_INELIGIBLE: 'Määräysosasto ei kelpaa',
    DEPARTMENT_MISSING_ADDRESS_DETAILS: 'Osastolta puuttuu osoitetietoja',
    MISSING_DELIVERY_PHONE_NUMBER: 'Puhelinnumero puuttuu',
    INVALID_DELIVERY_PHONE_NUMBER: 'Puhelinnumero ei ole kelvollinen',
    ROLE_IN_USE: 'Roolia ei voida poistaa, koska se on käytössä.',
    INVALID_NAME: 'Roolin nimen on oltava yksilöllinen. Rooli tällä nimellä on jo olemassa.',
    TEXT_CANNOT_BE_DELETED:
      'Tekstiä käytetään palvelussa, eikä sitä voi poistaa. Irrota teksti palvelusta poistaaksesi sen.'
  },
  customer: {
    showAll: 'Näytä kaikki asiakastunnukset',
    customer: 'Asiakas',
    groups: {
      title: 'Asiakasryhmiä',
      titleWithCount: '{{count}} asiakasryhmää',
      name: 'Ryhmän nimi',
      description: 'Kuvaus',
      numberOfCustomers: 'Asiakkaiden määrä',
      none: 'Ei asiakasryhmiä',
      new: 'Uusi asiakasryhmä',
      edit: 'Muokkaa asiakasryhmää',
      searchCustomer: 'Etsi asiakas',
      members: 'Asiakkaat ryhmässä',
      save: 'Tallenna',
      deleteConfirm: 'Oletko varma, että haluat poistaa asiakasryhmän?',
      deleted: 'Asiakasryhmä poistettu'
    },
    customerTitle: 'Asiakkaat',
    customerNo: 'Asiakasnumero',
    name: 'Nimi',
    emailToSender: 'Sähköposti lähettäjälle',
    deviations: 'Poikkeamat',
    add: 'Lisätä',
    noOrders: "Ei tilauksia Glow'ssa",
    noOrdersDescription: "Glow'ssa  ei ole tilauksia niillä asiakkailla, joihin sinulla on pääsy.",
    choose: 'Ole hyvä ja valitse asiakas',
    search: 'Etsi',
    customerNumber: 'Asiakasnumero',
    searchHint:
      'Etsi lähetyksiä lähetystunnuksella, asiakkaan viitteellä, paketin tunnuksella, toimituksen nimellä tai osoitteella.',
    senderName: 'Lähettäjän nimi',
    addSenderName: 'Lisää lähettäjän nimi',
    senderNameAdded: 'Lähettäjän nimi lisätty',
    billingCustomerNumber: 'Laskutusasiakasnumero',
    addBillingCustomerNumber: 'Lisää Laskutusasiakasnumero',
    billingCustomerNumberAdded: 'Laskutusasiakasnumero Lisätty',
    searchPage: {
      fromDate: 'Alkamispäivä',
      toDate: 'Päättymispäivä',
      today: 'Tänään',
      tomorrow: 'Huomenna',
      yesterday: 'Eilinen',
      last30days: 'Viimeiset 30 päivää',
      plusMinus15Days: '+/- 15 päivää',
      plusMinus30Days: '+/- 30 päivää'
    },
    smsText: { title: 'SMS Text', addEditSmsText: 'Add/ Edit SMS text', smsTypeTexts: { delivery: 'Toimitus SMS' } },
    showNotCollected: 'Ei noudettu',
    booking: {
      pickupReady: 'Nouto valmis',
      pickup: 'Nouto',
      delivery: 'Toimitus',
      recipientPrice: 'Tilaajan hinta:',
      customerContactPlaceholder: 'Tilaajan nimi (Etu ja sukunimi) – Tämä kenttä näkyy asiakkaan laskussa'
    },
    email: {
      emailAddress: 'E-mail address',
      true: 'yes',
      false: 'no',
      informationToCustomer: 'Tiedot asiakkaalle',
      returnToCustomer: 'Palautus asiakkaalle',
      addEmailToList: 'Lisää sähköposti listalle',
      registeredEmailSubscription: 'Rekisteröity sähköpostitilaus',
      updateEmail: 'Päivitä sähköposti',
      noRegisteredEmails: 'Ei rekisteröityjä sähköposteja',
      detailsSavedSuccessfully: 'Sähköposti lähettäjälle tallennettiin onnistuneesti'
    },
    customerDeviations: { deviationType: 'Poikkeaman syy' },
    customerInfo: 'Asiakastiedot',
    customerName: 'Asiakkaan Nimi',
    invoicingAddress: 'Laskutusosoite',
    organisationNumber: 'Organisaation Numero',
    visitingAddress: 'Käyntiosoite',
    creditBlockedFrom: 'Luotto estetty päivämäärästä',
    creditBlockedFromInfo: 'Estä tämän asiakkaan luotto valitusta päivämäärästä alkaen',
    from: 'Päivämäärä',
    address: 'Osoite',
    zipCode: 'Postinumero',
    zipArea: 'Paikka',
    country: 'Maa',
    edit: 'Muokata {{name}}',
    selectCountry: 'Valitse Maa',
    addNewCustomer: 'Lisää uusi asiakas',
    bookingPageModalHeading: 'Varaus Asiakasportaalissa',
    bookingPageModalDescription:
      'Kytke tilaus mahdollisuus pois päältä, jos asiakas ei saa tilata lähetyksiä tällä asiakastunnuksella Glow asiakas portaalissa.',
    bookingActive: 'Päällä',
    bookingInactive: 'Pois päältä',
    brandedTrackingActive: 'Päällä',
    brandedTrackingInactive: 'Pois päältä',
    bookingPageAvailable: 'Päällä',
    bookingPageHidden: 'Pois päältä'
  },
  serviceMapping: {
    serviceName: 'Palvelun nimi',
    pickupTime: 'Noutoaika',
    deliveryTime: 'Toimitusaika',
    internalName: 'Sisäinen nimi',
    internalDescription: 'Sisäinen kuvaus',
    externalName: 'Ulkoinen nimi',
    externalDescription: 'Ulkoinen kuvaus',
    alystraId: 'Alystra ID',
    allowFlexDelivery: 'Salli Flex-toimitus',
    timeWindow: 'Aikaikkuna',
    courierApp: 'Kuriirisovellus',
    allowCourierDescription: 'Salli kuriirin hyväksyä tai hylätä lähetykset tällä palvelukoodilla',
    allowCourierCheckbox: 'Kyllä, kuriirin tulisi saada lupa siihen',
    serviceDeliveryType: 'Palvelutyyppi',
    noServiceDeliveryTypeSelected: 'Ei määritelty',
    serviceDeliveryTypes: {
      Delivery: 'Toimitus',
      Return: 'Palautus',
      PickupPoint: 'Noutopiste'
    }
  },
  feedback: {
    title: 'Palaute',
    message: 'Olemme kiitollisia ehdotuksista tämän sivun parantamiseksi.',
    linkText: 'Lähetä palautetta'
  },
  texts: {
    title: 'Tekstit',
    update: 'Päivitä teksti',
    group: 'Ryhmittely',
    edit: 'Muokkaa',
    delete: 'Poista',
    add: 'Lisää teksti',
    reset: 'Resetoi',
    confirmDelete: 'Haluatko varmasti poistaa tekstin?',
    added: 'Teksti lisätty',
    deleted: 'Teksti poistettu',
    updated: 'Teksti päivitetty',
    nameToolTip: 'Nimi näytetään, kun tekstiä linkitetään palveluun'
  },
  countries: { norway: 'Norja', sweden: 'Ruotsi', finland: 'Suomi', denmark: 'Tanska', germany: 'Saksa' },
  shipmentsExport: {
    unit: 'Yksikkö',
    exportButton: 'Vie Excel-tiedostoon',
    shipmentId: 'Lähetystunnus',
    status: 'Tila',
    returned: 'Palautettu',
    department: 'Osasto',
    customer: 'Asiakas',
    customerNumber: 'Asiakasnumero',
    date: 'Päivämäärä',
    pickupEarliest: 'Aikaisin noutoaika',
    pickupLatest: 'Myöhäisin noutoaika',
    deliveryEarliest: 'Aikaisin toimitusaika',
    deliveryLatest: 'Myöhäisin toimitusaika',
    serviceCode: 'Palvelu',
    additionalServices: 'Lisäpalvelut',
    pickupName: 'Nouto',
    pickupAddress: 'Nouto-osoite',
    pickupZipCode: 'Nouto-osoitteen postinumero',
    pickupZipArea: 'Nouto alue',
    pickupCoordinates: 'Noutokoordinaatit',
    pickupInstructions: 'Nouto-ohjeet',
    deliveryName: 'Toimitus',
    deliveryAddress: 'Toimitus osoite',
    deliveryZipCode: 'Toimitus osoitteen postinumero',
    deliveryZipArea: 'Toimitus alue',
    deliveryCoordinates: 'Toimituskoordinaatit',
    deliveryInstructions: 'Toimitusohjeet',
    quantity: 'Määrä',
    weight: 'Paino',
    volume: 'Tilavuus',
    customerRef: 'Asiakkaan viite',
    pickupContactPerson: 'Nouto yhteyshenkilö',
    pickupPhone: 'Nouto puhelin',
    deliveryContactPerson: 'Toimitus yhteyshenkilö',
    deliveryPhone: 'Toimitus puhelin',
    deliveryEmail: 'Toimitussähköposti',
    recipientRef: 'Vastaanottajan viite',
    price: 'Hinta',
    group: 'Ryhmän',
    pickupTime: 'Noudettu/ Yritetty',
    delivered: 'Toimitettu/ Yritetty',
    deliveryEtaSmsSentAt: 'Toimitusaika SMS lähetetty',
    courierName: 'Lähetti',
    goodsInfo: 'Tiedot tavarasta',
    note: 'Huomautus',
    slotName: 'Toimitusajankohta',
    estimatedArrivalTime: 'Arviolta',
    orderSource: 'Tilaustapa'
  },
  importExport: {
    dropText: 'Pudota tiedosto tähän ladataksesi',
    export: 'Siirrä',
    exportAddresses: 'Siirrä asiakkaan osoitteet exceliin',
    importExcel: 'Tuo Excel-tiedostosta',
    imported: 'tuodut osoitteet',
    noRecipients: 'Ei kelvollisia vastaanottajia',
    or: 'tai',
    recipients: 'vastaanottajat',
    selectFile: 'Valitse tiedosto',
    uploadingFile: 'Lataaminen...'
  },
  customMeasurements: {
    name: 'Nimi',
    weight: 'Paino',
    height: 'Korkeus',
    length: 'Pituus',
    width: 'Leveys',
    heading: 'Mukautetut tavarat',
    error: 'Tallennus epäonnistui: ',
    confirmDelete: 'Oletko varma, että haluat poistaa tämän tavaratyypin?',
    new: 'Luo uusi',
    customizeGoodsTypes: 'Mukautettu tavaran tyyppi',
    customGoodsTypes: 'Mukautetut tavarat',
    defaultGoodsTypes: 'Esitallennetut tavarat',
    invalidName: 'Nimessä on oltava vähintään yksi kirjain'
  },
  unresolved: {
    unresolvedAddresses: 'Selvittämättömiä osoitteita',
    allResolved: 'Selvittämättömiä osoitteita ei löytynyt!',
    address: 'Osoite',
    noOfShipments: 'Lähetysten määrä',
    shipment: 'lähetys',
    shipment_plural: 'lähetykset',
    shipmentNumber: 'Lähetyksen numero',
    customerRef: 'Asiakkaan viite',
    senderName: 'Lähettäjän nimi',
    senderPhone: 'Lähettäjän puhelinnumero',
    recipientName: 'Vastaanottajan nimi',
    recipientPhone: 'Vastaanottajan puhelinnumero',
    searchAndSetLocation: 'Etsi ja kohdista osoite',
    setALocationToContinue: 'Kohdista osoite jatkaaksesi',
    resolveAddress: 'Selvitä osoite',
    suggestedAddressFound: 'Ehdotettu osoite',
    thereIsOneUnresolvedAddress: '{{count}} selvittämätön osoite',
    thereIsOneUnresolvedAddress_plural: '{{count}} selvittämätöntä osoitetta'
  },
  optimizing: {
    reqTime: 'Pyyntöaika',
    respTime: 'Vastausaika',
    department: 'Osasto',
    userName: 'Käyttäjä',
    request: 'Pyyntö',
    response: 'Vastaus',
    error: 'Virhe',
    viewReqResp: 'Näytä',
    status: 'Tila',
    totalTime: 'Aika'
  },
  date: { date: 'Päivämäärä', today: 'Tänään', tomorrow: 'Huomenna', yesterday: 'Eilen', dateAndTime: 'Päivä ja ajat' },
  routeReceipt: {
    printRouteReceiptList: 'Tulosta kuittauslista',
    noRoutesInfoAvailable: 'Reittitietoja ei ole saatavilla'
  },
  homeDeliveryRouteList: {
    receiptList: 'Kuittauslista',
    shipments: 'Toimitukset',
    weight: 'Paino',
    volume: 'Tilavuus',
    loadingMeters: 'Latausmittarit',
    palletFootPrints: 'Lava jalanjäljet',
    sender: 'Lähettäjä',
    package: 'Lähetys',
    shipmentNo: 'Toimitusnumero',
    ref: 'Viite',
    recipient: 'Vastaanottaja',
    time: 'Aika',
    mobile: 'Puhelinnumero',
    other: 'Muuta',
    attemptedDelivery: 'Poikkeamat',
    receipt: 'Allekirjoitus',
    dateOfBirth: 'Syntymäaika',
    signature: 'Allekirjoitus',
    nameClarification: 'Nimi',
    goodsLine1: 'Muista tarkastaa lähetys näkyviltä vaurioilta ennen allekirjoittamista',
    goodsLine2: 'Jos näkyviä vaurioita löytyy, kuljettajan tulee merkitä ne ennen vastaanottajan allekirjoitusta',
    damage: 'Vaurio',
    missing: 'Puuttuu',
    packageNumber: 'Lähetysnumero',
    comments: 'Kommentti'
  },
  shipment: {
    columnCategories: {
      aboutTheShipment: 'Tietoa lähetyksestä',
      goods: 'Tavarat',
      timeAndDate: 'Aika ja päivämäärä',
      planning: 'Suunnittelu',
      pickupInformation: 'Noutotiedot',
      customer: 'Asiakas',
      deliveryInformation: 'Toimitustiedot',
      originalSender: 'Alkuperäinen lähettäjä',
      originalDelivery: 'Alkuperäinen toimitus',
      service: 'Palvelu',
      preAdvice: 'Esi-ilmoitus',
      invoicingInformation: 'Laskutustiedot'
    },
    shipment: 'Lähetys',
    customer: 'Asiakas',
    customerContact: 'Asiakaskontakti',
    customerReference: 'Asiakkaan viite',
    customerNumber: 'Asiakasnumero',
    route: 'Reitti',
    service: 'Palvelu',
    additionalServices: 'Lisäpalvelut',
    quantity: 'Määrä',
    totalWeight: 'Kokonaispaino',
    totalVolume: 'Kokonaisvolyymi',
    totalLoadingMeters: 'Kokonaislatausmittarit',
    totalPalletFootPrints: 'Kokonais lava jalanjäljet',
    goodsInfo: 'Tiedot tavarasta',
    packages: 'Pakettimäärä',
    pickupInfo: 'Noutotiedot',
    pickupDate: 'Noutopäivä',
    pickupTime: 'Noutoaika',
    pickupInstructions: 'Nouto-ohjeet',
    deliveryInfo: 'Toimitustiedot',
    deliveryDate: 'Toimituspäivä',
    deliveryTime: 'Toimitusaika',
    receiverReference: 'Vastaanottajan viite',
    deliveryInstructions: 'Toimitusohjeet',
    trackingPage: 'Vastaanottajan seurantasivu',
    internalNote: 'Muistiinpanot',
    closeWhileEditing:
      'Oletko varma että haluat sulkea tämän kesken muutoksen?\nKaikki tallentamattomat muutokset häviävät.',
    name: 'Nimi',
    address: 'Osoite',
    zipCode: 'Postinumero',
    zipArea: 'Kaupunki',
    phone: 'Puhelin',
    secondPhone: 'Puhelin 2',
    email: 'Sähköposti'
  },
  bookingTimeWindow: {
    week: 'Viikko',
    bookButton: 'Tilaa',
    customButton: 'Muokattu aikaikkuna',
    removeBookingButton: 'Poista tilaus',
    bookedInfo: 'Tilattu {{date}} aikavälille {{time window}}',
    notBookedInfo: 'Ei tilattu',
    tableHeaderDate: 'Päivämäärä',
    tableHeaderSlots: 'Saatavilla olevat aikavälit',
    serverError: 'Tapahtui virhe. Yritä myöhemmin uudelleen.',
    previousButton: 'Takaisin',
    nextButton: 'Seuraava',
    noAvailableDeliveryTimes: 'Ei saatavilla olevia toimitusaikoja tässä kuussa',
    customTimeTitle: 'Aseta muokattu aikaikkuna lähetykselle',
    customDateLabel: 'Päivämäärä',
    customTimeWindowLabel: 'Toimituksen aikaikkuna',
    backFromCustomTimeButton: 'Takaisin vapaisiin toimitusaikoihin',
    errorEarliestNotBeforeLatest: 'Aikaisin toimitusaika ei voi olla ennen myöhäisintä toimitusaikaa',
    arrivedAtTerminal: 'Saapunut terminaaliin',
    bookCustomTimeSlot: 'Varaa aikaväli',
    shipmentBooked: 'Lähetys varattu',
    shipmentBookedWithCustomTimeWindow: 'Lähetys varattu mukautetulla aikavälillä',
    updateBooking: 'Päivitä varaus',
    removeTimeSlot: 'Poista aikaväli',
    bookingChanged: 'Varaus on muutettu onnistuneesti',
    bookingSuccessful: 'Varaus tehtiin onnistuneesti',
    customTimeWindowAdded: 'Mukautettu aikaväli lähetystä varten lisättiin onnistuneesti',
    timeFrom: 'Kello alkaen',
    timeTo: 'Kello asti',
    timeFieldError: 'Varhaisimman toimitusajan on oltava ennen viimeisintä',
    availableTimeSlot: 'Saatavilla olevat aikavälit',
    updateButtonTooltip: 'Varauksen päivittämiseksi sinun on ensin valittava uusi aikaväli'
  },
  manualOverride: {
    scannedFormDescription: 'Valitsemasi osasto käynnistää saapumisskannauksen tyypin.',
    eventType: {
      delivered: 'Toimitettu',
      collected: 'Noudettu',
      returned: 'Palautettu',
      deviated: 'Poikkeama',
      scanned: 'Skannattu',
      preadvised: 'Preadvice yritys'
    },
    selectEventType: 'Valitse tapahtuma',
    noEventSelected: 'Ei valittua tapahtumaa',
    selectManifest: 'Valitse lento',
    noManifestSelected: 'Lentoa ei ole valittu',
    disabledEventReason: {
      returned: ' - Poikkeama tarvitaan',
      collected: ' - Ajojärjestystä tarvitaan',
      default: ' - Ei sallittu'
    },
    fillDeliverOrReturnEventInfo: 'Täytä nimi, päivä ja aika',
    fillDeviationEventInfo: 'Valitse poikkeama ja täytä päivä ja aika',
    fillGeneralEventInfo: 'Täytä nimi, päivä ja aika',
    eventMessage: 'Tapahtuma luotu',
    allPackagesSelected: 'Kaikki paketit valittuja',
    noActiveDriverError: 'Ei aktiivisia kuljettajia yksikölle',
    selectPackages: 'Valitse paketit',
    selectDriver: 'Valitse kuljettaja',
    selectUnit: 'Valitse yksikkö',
    selectDepartment: 'Valita osasto'
  },
  zendesk: {
    email: 'Sähköposti:',
    confirmEmail: 'Sähköposti uudelleen:',
    send: 'Tallenna',
    missingEmail:
      'Uuden tuki portaalin käyttöä varten, sinun täytyy rekisteröidä Posten tai Bring sähköpostisi. Tämä tarvitsee tehdä vain kerran.'
  },
  instantPlannerUnification: {
    message:
      'Osiot Kuriiri ja Reitin suunnittelutyökalu ovat nyt yhdistetty. Ole hyvä ja mene reitin suunnittelutyökalu osioon löytääksesi kuriiri toimintosi.',
    linkTitle: 'Lue lisää täältä.'
  },
  shipmentFormPage: {
    findShipment: 'Etsi lähetystä',
    searchPhraseError: 'Sinun on annettava vähintään 3 merkkiä',
    selectStateLabel: 'Valitse tila pudotusvalikosta',
    selectDateLabel: 'Valitse päivämäärä pudotusvalikosta',
    submit: 'Näytä tulokset',
    inputPlaceholder: 'Hae lähetystunnusta, puhelinnumeroa, osoitetta, aluetta, postinumeroa ...',
    clearButtonLabel: 'Tyhjennä hakuphrase-painike',
    states: 'Tilat',
    allStates: 'Kaikki tilat',
    customDate: 'Mukautettu',
    noDate: 'Ei päivämäärärajoitusta',
    selectFromDate: 'Valitse päivämäärä',
    selectToDate: 'Valitse päivämäärä',
    include: 'Sisällytä',
    exclude: 'Poissulkea',
    selectAdvancedOperator: 'Valitse lisäoperaattori-pudotusvalikko',
    ofTheseRequirements: 'näistä vaatimuksista',
    tableColumn: 'Taulukon sarake',
    condition: 'Kunto',
    searchTerm: 'Hakusana',
    all: 'KAIKKI',
    any: 'MIKÄ TAHANSA',
    tableDropdown: 'Valitse taulukko-pudotusvalikko',
    conditionDropdown: 'Valitse kunto-pudotusvalikko',
    typeSearch: 'Kirjoita hakutermi',
    addNewRow: 'Lisää uusi rivi',
    deleteRow: 'Poista nykyinen rivi',
    service: 'Palvelu',
    goodsDescription: 'Tavarakuvaus',
    route: 'Reitti',
    unit: 'Yksikkö',
    deliveryArea: 'Toimitusalue',
    deliveryZipcode: 'Toimituspostinumero',
    state: 'Osavaltio',
    shipmentIds: 'Useita lähetyksiä'
  },
  shipmentResultPage: {
    noResultTitle: 'Ei tuloksia',
    batchLimitReached: 'Näytetään enintään 2000 lähetystä, harkitse hakusi supistamista',
    withoutDateLimitReached:
      'Etsittäessä ilman päivämäärää, näytämme enintään {{limit}} lähetystä. Harkitse hakusi rajaamista.',
    noResultExplanation: 'Emme löytäneet tuloksia. Yritä hakea toisella hakusanalla.'
  },
  shipmentResultActions: {
    confirmDeleteTitle: 'Poista lähetys?',
    confirmDeleteTitle_plural: 'Poistetaanko lähetys?',
    confirmDuplicateTitle: 'Kopioi lähetys?',
    confirmDuplicateButton: 'Kopioi',
    deleteSuccess: 'Lähetys poistettiin onnistuneesti',
    deleteSuccess_plural: 'Lähetys poistettiin onnistuneesti',
    deleteFailure: 'Lähetystä ei voitu poistaa',
    deleteFailure_plural: 'Lähetystä ei voi poistaa'
  },
  shipmentTable: {
    quantity: 'Määrä',
    delEta: 'LÄH ARV',
    delDuration: 'LÄH K',
    delDurationTitle: 'Toimituksen kesto',
    delDeliveryFrom: 'LÄH A',
    delDeliveryFromTitle: 'Toimitusaika alkaen',
    delDeliveryTo: 'LÄH L',
    delDeliveryToTitle: 'Toimitusaika asti',
    timeOfDelivery: 'Toimituksen aika',
    puDuration: 'NOUTO K',
    puDurationTitle: 'Noudon kesto',
    puPickupFrom: 'NOUTO A',
    puPickupFromTitle: 'Noudon aika alkaen',
    puPickupTo: 'NOUTO L',
    puPickupToTitle: 'Noudon aika asti',
    timeOfPickup: 'Noudon aika',
    delEtaTitle: 'Arvioitu toimitusaika',
    recipientRef: 'Vastaanottajan viite',
    loading: 'Ladataan...',
    pickupName: 'Noutopaikan nimi',
    deliveryName: 'Toimituspaikan nimi',
    selectAllOnPage: 'Valitse kaikki sivulla ({{count}})',
    selectAll: 'Valitse kaikki ({{count}})',
    deselectAll: 'Poista kaikki valinnat',
    numSelectedShipments: '{{numSelected}} valittu',
    createLabels: 'Luo tarrat',
    editShipments: 'Muokkaa lähetystietoja',
    moveShipments: 'Siirrä toiseen osastoon',
    yourSearchResult: 'Hakutuloksesi',
    numberOfShipmentsFound: '{{count}} lähetystä löydetty'
  },
  tablePagination: {
    goToPage: 'Siirry sivulle',
    page: 'Sivu',
    of: 'tai'
  },
  moveDepartmentModal: {
    errorMessage: 'Tilaus(t): {{orderIds}}, virhe: {{error}}',
    errorMessage_plural: 'Tilaus/tilaukset: {{orderIds}}, virhe: {{error}}',
    ordersMovedText: 'Tilaus(t) {{orderIds}} on siirretty osastolle {{department}}',
    ordersMovedText_plural: 'Tilaus/tilaukset {{orderIds}} on siirretty osastolle {{department}}',
    partialSuccessMessage: '{{count}} / {{fullCount}} lähetystä siirrettiin',
    title: 'Siirrä valitut lähetykset toiseen osastoon'
  },
  sendManualDelaySmsModal: {
    errorMessage: '{{error}} {{shipmentIds}}',
    partialSuccessMessage: '{{count}}/{{fullCount}} viestiä lähetettiin',
    successMessage: 'Käsikäyttöinen viiveviesti lähetettiin onnistuneesti'
  },
  multistopModal: {
    title: 'Ryhmäinformaatio',
    description: 'Ryhmä näkymä tilauksille, jotka maksetaan yhdessä.',
    debitLines: 'Velkalinjat',
    notFound: 'Ryhmäinformaatiota ei löytynyt',
    shipmentId: 'Lähetystunnus',
    deliveryDate: 'Toimituspäivä',
    stopAddress: 'Pysäytysosoite',
    postalCode: 'Postinumero',
    noPrice: 'Ei hintatietoja saatavilla ryhmälle',
    priceUpdated: 'Ryhmän hinnat on päivitetty'
  },
  shipmentDetails: {
    eventWasAdded: 'Tapahtuma lisättiin',
    issueSaving: 'Tallennuksessa ilmeni ongelma',
    noSelectedPackages: 'Paketteja ei valittu',
    pickupAndDelivery: 'Nouto ja toimitus',
    whereIsTheOrder: 'Missä tilaus on',
    communicationToRecipient: 'Viestintä vastaanottajalle',
    yesPremium: 'Kyllä, premium',
    alerts: 'Hälytykset',
    pickupDeliveryInfo: 'Noudon ja toimituksen tiedot',
    quickEdit: 'Pikamuokkaus',
    quickEditTitle: 'Muokkaa nouto- ja toimitustietoja',
    aboutShipment: 'Lähetyksestä',
    mapLinkText: 'Näytä karttakoordinaatit',
    copyShipment: 'Kopioi lähetys',
    editShipment: 'Muokkaa lähetystietoja',
    location: 'Sijainti',
    time: 'Aika',
    selectDate: 'Valitse päivämäärä',
    manualChangeEvent: 'Muuta tapahtumaa manuaalisesti',
    disabledEventReason: {
      returned: 'Palautettu: Poikkeama vaaditaan',
      collected: 'Noudettu: Tilaus on annettava reitille',
      default: 'Oletus: Ei sallittu'
    },
    selectDeviation: 'Valitse poikkeama',
    required: 'Pakollinen',
    allPackages: 'Kaikki paketit',
    packages: 'Paketit',
    package: 'Paketti',
    changeDepartment: 'Vaihda osasto',
    moveDepartment: 'Siirrä osastolle',
    shipmentMoved: 'Lähetys siirretty',
    shipmentMovedText: 'Lähetys {{shipmentId}} on siirretty osastolle {{department}}',
    shipmentMovedGoBack: 'Palaa hakusivulle',
    moveShipmentDescription: 'Jos sinulla ei ole pääsyä osastolle, et voi löytää lähetystä sen siirron jälkeen',
    moveShipmentConfirmText: 'Siirrä lähetys toiseen osastoon?',
    addShipmentToSlot: 'Lisää lähetys paikkaan {{slot}}?',
    changeSlotConfirm: 'Muuta paikkaa lähetys paikkaan {{slot}}?',
    changeSlot: 'Muuta paikkaa',
    selectSlot: 'Valitse paikka',
    AddToSlot: 'Lisää paikkaan',
    optimisationInProgress: 'Optimointi käynnissä. Et voi muuttaa paikkaa, kun reittiä optimoidaan.',
    removeFromSlot: 'Poista paikalta',
    removeShipmentFromSlot: 'Poista lähetys paikalta',
    removeShipmentFromSlotHeading: 'Poista lähetys tästä paikasta?',
    successRemoveFromSlot: 'Lähetys poistettu paikalta',
    successChangeSlot: 'Lähetys muutettu paikkaan',
    successAddToSlot: 'Lähetys lisätty paikkaan',
    changeSlotDescription: 'Paikan muutos tehdään ilman reitin optimointia.',
    addToSlotDescription: 'Valittu paikka optimoidaan olemassa olevilla lähetysjärjestyksillä.',
    pickupOrDeliveryChange: 'Noudon tai toimituksen osoite on muuttunut',
    pickupOrDeliveryChangeDescription:
      'Noudon tai toimituksen osoite on muuttunut. Jos haluat muuttaa osoitteen takaisin, paina alla olevaa painiketta.',
    addressWasUnresolved: 'Osoite oli ratkaisematon'
  },
  terminal: 'Terminaali',
  location: 'Sijainti',
  pickupOrders: {
    acceptOrders: 'Hyväksy tilaukset',
    selected: 'valittu',
    handleOrders: 'Käsittele noutotilaukset',
    found: 'löydetty',
    accept: 'Hyväksy',
    reject: 'Hylkää',
    noResultText: 'Emme löytäneet tuloksia. Yritä haku toisella päivämäärällä.',
    last7d: 'Tänään + viimeiset 7 päivää',

    rejectOptions: {
      notFound: 'Lähettäjää ei löydy nouto-osoitteelta. Ei enää yrityksiä. Nouto suljettu.',
      terminatesPickupOrder: 'Lähettäjä keskeyttää noutotilauksen. Ei enää yrityksiä. Nouto suljetaan.',
      handedOver: 'Lähettäjä on luovuttanut noudon toiselle kuljettajalle. Ei enää yrityksiä. Nouto suljettu.',
      noMoreAttempts: 'Lähettäjä on muuttanut. Ei enää yrityksiä. Nouto suljettu.',
      pickupCorrected: 'Lähettäjä on muuttanut. Nouto korjattu ja lähetetty takaisin aliurakoitsijalle.',
      goodsNotReady: 'Tavaroita ei ole valmiina noudettavaksi. Ei enää yrityksiä. Nouto suljettu.',
      incorrectBooking: 'Virheellinen varaus. Nouto keskeytetty.',
      senderRefuses: 'Lähettäjä kieltäytyy luovuttamasta tavaroita. Ei enää yrityksiä. Nouto suljettu.',
      addressCorrected: 'Virheellinen nouto-osoite. Osoite korjattu.',
      incorrectAddressNoMoreAttempts: 'Virheellinen nouto-osoite. Ei enää yrityksiä. Nouto suljettu.',
      senderNotified: 'Virheellinen nouto-osoite. Ei enää yrityksiä. Nouto suljettu.',
      forbiddenGoods: 'Kielletyt tavarat. Ei enää yrityksiä. Nouto suljettu.',
      timeFrame: 'Noutoa ei voi suorittaa aikataulun puitteissa. Noutotilaus palautetaan varausosastolle.'
    },
    mustSelectReason: 'Sinun on valittava hylkäämisen syy',
    selectRejectReason: 'Valitse hylkäämisen syy*',
    selectReason: 'Valitse syy',
    commentPlaceholder: 'Valinnainen - lisää tietoja hylkäämiseen',
    acceptedSuccessToast: 'hyväksyttiin onnistuneesti',
    rejectedSuccessToast: 'hylättiin onnistuneesti',
    errorToast: 'Jotain meni pieleen',
    rejectOrders: 'Hylkää tilaukset',
    shipment: '{{count}} lähetys',
    shipment_plural: '{{count}} lähetystä'
  },
  editShipments: {
    pickupShouldBeBeforeDelivery: 'Noutoikkunan on oltava ennen toimitusikkunaa',
    pickupEarliestShouldBeBeforeLatest: 'Aikaisimman noutoajan on oltava ennen viimeisintä',
    deliveryEarliestShouldBeBeforeLatest: 'Aikaisimman toimitusajan on oltava ennen viimeisintä',
    success: 'Lähetysten tiedot päivitettiin onnistuneesti',
    mixedShipmentsSelected: 'Olet valinnut lähetyksiä päivämäärän kanssa ja ilman. Nouto- ja toimitustiedot vaaditaan.',
    fieldNotEditable: 'Tätä tietoa ei voi muokata tilauksille, jotka ovat tilassa "Osoitettu paikkaan" tai "Noudettu"',
    bulkActionDisabledReason:
      'Tilauksia, jotka ovat tilassa "Poikkeama", "Toimitettu", "Hylätty" tai "Palautettu", ei voi muokata',
    withoutDateDisabledReason:
      'Tilauksien yhdistelmät tilassa "Osoitettu paikkaan" tai "Noudettu" ja ilman päivämäärää eivät voi muokata',
    pickupDateTime: 'Nouto päivä ja ajat',
    deliveryDateTime: 'Toimitus päivä ja ajat',
    earliestPickupTime: 'Aikaisin noutoaika',
    latestPickupTime: 'Myöhäisin noutoaika',
    earliestDeliveryTime: 'Aikaisin toimitusaika',
    latestDeliveryTime: 'Myöhäisin toimitusaika',
    pickupName: 'Nouto nimi',
    pickupPhoneNumber: 'Nouto puhelinnumero',
    deliveryName: 'Toimitus nimi',
    deliveryPhoneNumber: 'Toimitus puhelinnumero',
    pickupInstructions: 'Nouto-ohjeet',
    deliveryInstructions: 'Toimitusohjeet'
  },
  shipmentSelectColumn: {
    title: 'Mukauta taulukon otsikoita',
    addColumnHeader: 'Lisää sarakkeiden otsikot',
    addColumnBody:
      'Valitse otsikot, jotka haluat näkyvän taulukossa. Vinkkimme on käyttää 10-15 eri otsikkoa, jotta tieto ei katkeaisi. Tarkka määrä riippuu laitteen koosta, jolla työskentelet.',
    orderOfTable: 'Taulukon otsikoiden järjestys',
    orderOfTableDescription: 'Valitse haluamasi järjestys taulukon otsikoille vetämällä ja pudottamalla.'
  },
  bulkActions: {
    routeReceiptList: { disabledTooltip: 'Tilaukset eivät voi olla tilassa Ratkaistu tai Ratkaisematon' },
    unassignShipmentFromSlot: 'Poista lähetyspaikat paikoista',
    unassignShipmentFromSlotDisabledReason: 'Tilausten on oltava tilassa "Osoitettu paikkaan" tai "Tarjottu"',
    forceUnassignShipmentFromSlot: 'Pakota poistamaan lähetykset paikoista',
    disabledMoveDepartmentTooltip:
      'Tilausten on oltava tilassa Luotu, Ratkaistu, Ratkaisematon tai Hylätty siirtääksesi ne',
    deleteDisableTooltip:
      'Lähetystä ei voi poistaa, jos se on tilassa Luotu, Ratkaisematon, Ratkaistu, Tarjottu tai Määrätty',
    duplicateDisableTooltip:
      'Lähetyksen on oltava tilassa Poikennut tai Palautettu, ja osaston tyypin on oltava Kuriiri',
    viewGroupDisabledTooltip: 'Tilaus on määritettävä ennen kuin voit tarkastella ja muokata sen ryhmää'
  },
  vehicle: { weightCapacity: 'Painokapasiteetti', volumeCapacity: 'Tilavuuskapasiteetti', maxRangeKm: 'Toimintasäde' },
  invoicingStatus: {
    INVOICING_FAILED: 'Laskutus epäonnistui',
    CORRECTION_PENDING: 'Oikaisu vireillä',
    INVOICING_PAUSED: 'Laskutus pysäytetty',
    EXCLUDED_FROM_INVOICING: 'Ulkopuolelle',
    NOT_READY_FOR_INVOICING: 'Ei valmis',
    INVOICED_OK: 'Laskutettu OK',
    ZERO_PRICE: 'Epätäydellinen hinta'
  },
  invoice: {
    articleCode: 'Artikkeli',
    allOrdersInvoice: 'Kaikki tilaukset laskutetaan',
    allOrdersInvoiceDescription: 'Emme löytäneet laskuttamattomia tilauksia.',
    handledBy: 'Käsitellyt',
    invoicing: 'Laskutus',
    credit: 'Hyvitys',
    invoice: 'Lasku',
    create: 'Luoda',
    invoiceOrderAction: 'Laskun tilaus',
    customerNumber: 'Asiakasnumero',
    carrierNumber: 'Yksikkönumero',
    details: 'Yksityiskohdat',
    information: 'Laskun tiedot',
    reference: 'Laskun viite',
    items: 'Tuotteet',
    quantity: 'Määrä',
    description: 'Kuvaus',
    customerAmount: 'Asiakkaan summa',
    carrierAmount: 'Yksikkön määrä',
    totalCustomerAmount: 'Asiakkaan kokonaissumma',
    totalCarrierAmount: 'Yksikön kokonaismäärä',
    invoiceDepartment: 'Laskutusosasto',
    invoiceDepartment_plural: 'Laskutusosastot',
    invoiceDepartments: 'Laskutusosasto(t)',
    dashboard: 'Dashboard',
    invoicingJobsSummary: 'Laskutustyön yhteenveto',
    invoicingStatus: 'Laskutuksen tila',
    invoicingStatusDescription: 'Näytetään tilaukset viimeisen 3 kuukauden ajalta.',
    notInvoicedOrders: 'Ei laskutettuja tilauksia',
    ordersOnPage: 'Näytetään {{count}} tilausta sivulla',
    ordersFoundCount: '{{count}} tilaus löydetty',
    ordersFoundCount_plural: '{{count}} tilausta löytyi',
    toBeInvoiced: 'Laskutettava',
    ordersNotInvoiced: 'Tilaukset, joita ei ole vielä laskutettu',
    invoicingFailed: 'Laskutus epäonnistui',
    invoicingFailedDescription: 'Tilausten käsittely epäonnistui',
    text: 'Laskun teksti',
    correctionPending: 'Tilauksen korjauksella',
    correctionPendingDescription: 'Tilaukset odottavilla korjauksilla',
    invoicingPaused: 'Laskutus keskeytetty',
    ordersPausedFromInvoiced: 'Tilaukset, joiden laskutus on keskeytetty',
    invoicingZeroPrice: 'Epätäydellinen hinta',
    invoicingZeroPriceDescription: 'Tilaukset, joissa on hyväksymättömiä nollarivejä',
    sendInvoices: 'Lähetä laskut',
    sendInvoicesDescription: 'Olet laskuttamassa {{osastot}}. Valitse laskutusjakson päivämäärä tai ajanjakso.',
    sendInvoiceDateValidationError: 'Alkaen päivämäärä ei voi olla myöhäisempi kuin Tähän päivään',
    sendInvoiceDateValidationErrorOneMonth: 'Päivämäärän enimmäispituus on 1 kuukausi',
    invoicingInProgress: 'Laskutus osastolle {{departmentName}} on käynnissä, {{count}} laskutustyötä jäljellä',
    invoicingInFinished: 'Laskutus laitokselle {{departmentName}} on valmis',
    orderSuccessInvoice: 'Tilauksen laskutus onnistui',
    orderSuccessInvoice_plural: 'Tilaukset laskutettiin onnistuneesti',
    orderSuccessNotInvoiced: 'Tilaus muutettiin laskuttamattomaksi',
    orderSuccessNotInvoiced_plural: 'Tilaukset muutettiin laskuttamattomiksi',
    orderFailedInvoice: 'Valitun tilauksen laskutus epäonnistui',
    howManyInvoices: 'Kuinka monta laskua lähetetään',
    fetchAmount: 'Hae summa',
    wantToResend: 'Tämä tilaus on jo laskutettu. Oletko varma, että haluat lähettää sen uudelleen',
    invoiceSingleOrder: 'Olet laskuttamassa tätä tilausta. Haluatko jatkaa',
    pauseOrders: 'Pysäytä tilaukset',
    datePeriod: 'Päivämääräjakso',
    ordersWereSuccessfullyPaused: '{{count}} tilauksen laskutuksen pysäytys onnistui',
    ordersWereSuccessfullyPaused_plural: '{{count}} tilauksen laskutus keskeytettiin onnistuneesti',
    releaseOrders: 'Vapauta tilaukset',
    ordersWereSuccessfullyReleased: '{{count}} tilaus vapautettiin onnistuneesti laskutettavaksi',
    ordersWereSuccessfullyReleased_plural: '{{count}} tilausta vapautettiin onnistuneesti laskutettavaksi',
    ordersWereSuccessfullyAcceptedWithIncompletePrice:
      '{{count}} tilaus hyväksyttiin onnistuneesti puutteellisella hinnalla',
    ordersWereSuccessfullyAcceptedWithIncompletePrice_plural:
      '{{count}} tilausta hyväksyttiin onnistuneesti puutteellisella hinnalla',
    addUnit: 'Lisää yksikkö',
    addUnitDescription: 'Lisää yksikkö valitsemiisi tilauksiin. Tämä yksikkö on se, jota tilitetään.',
    addUnitToOrders: 'Lisää yksikkö tilauksiin',
    addUnitToOrder: '{{yksikkö}} lisättiin onnistuneesti {{count}} tilaukseen',
    addUnitToOrder_plural: '{{yksikkö}} lisättiin onnistuneesti {{count}} tilaukseen',
    addUnitBulkDisabled: 'Yksikkö voidaan lisätä vain tilauksiin, joissa ei ole yksikköä',
    approveIncompletePrices: 'Hyväksy epätäydellisillä hinnoilla',
    manualOverrideTitle: 'Manuaalinen ohitus laskutuksen tila',
    manualOverrideModalQuestion:
      'Olet ohittamassa {{count}} tilauksen tilan. Valitut tilaukset saavat tilan Lasku OK. Oletko varma, että haluat jatkaa?',
    unitId: 'Yksikkötunnus',
    bannerOldInvoicePage:
      'Elokuun 26. päivä <0>uusi laskutussivu</0> avautuu oletuksena. Suosittelemme aloittamaan uuden sivun käytön. Lisätietoja uudesta sivusta <1>täällä</1>.',
    acceptIncompletePricesTitle: 'Hyväksy epätäydelliset hinnat',
    acceptIncompletePricesModalQuestion:
      'Olet hyväksymässä {{count}} tilauksen, jonka hinnat ovat puutteelliset. Haluatko varmasti jatkaa?',
    acceptIncompletePricesModalQuestion_plural:
      'Olet hyväksymässä {{count}} tilausta, joiden hinnat eivät ole täydellisiä. Haluatko varmasti jatkaa?',
    confirmCancelDialog: {
      title: 'Sinulla on tallentamattomia muutoksia',
      question: 'Sinulla on tallentamattomia muutoksia. Haluatko jatkaa?',
      abort: 'Pysy sivulla',
      confirm: 'Jatka tallentamatta'
    }
  },
  live: {
    onlyDeviated: 'Näytä vain poikkeamareitit',
    bannerOldLivePage:
      '<0>Kokeile</0> uutta Live-sivua, jossa on parannetut toiminnot ja uusi muotoilu. Suosittelemme käyttämään uutta live-sivua, jotta voit tottua siihen ennen kuin poistamme sivun kokonaan.',
    shipment: 'lähetys',
    shipmentFrom: 'Mistä',
    shipmentTo: 'Minne',
    confirm: 'Vahvista',
    unconfirm: 'Hylkää',
    confirmedBy: 'Vahvistettu',
    theCourier: 'Kuriiri',
    activeRoutes: 'Aktiiviset reitit',
    routeDeviation: 'Reitin poikkeama',
    routeDeviations: 'Reitin poikkeamat',
    multipleDeviations: 'Useita poikkeamia',
    recentDeviations: 'Viimeaikaiset poikkeamat',
    noRoutesFound: 'Reittejä ei löytynyt',
    noDeviationsFound: 'Poikkeamia ei löytynyt',
    consignmentDamage: 'Lähetyksen vahingot',
    shipmentDeviation: 'lähetyksen poikkeama',
    outsideServiceTime: 'Palveluajan ulkopuolella',
    outsideEstimatedTime: 'Arvioidun aikaikkunan ulkopuolella',
    deliveryLocationDeviation: 'Odottamaton sijainti',
    eventTextDeviationOne: 'Kuljettajalla {{name}} on lähetyksen poikkeamia.',
    eventTextDeviationMany: 'Kuljettajalla {{name}} on {{count}} toimituspoikkeamaa.',
    eventTextDamageOne: 'Kuljettajalla {{name}} on vaurioita {{count}} lähetyksessä.',
    eventTextDamageMany: 'Kuljettajalla {{name}} on vaurio lähetyksessä.',
    eventTextServiceTimeWindow: '{{name}} toimitetti palveluaikaikkunan ulkopuolella.',
    eventTextEstimatedTimeWindow: '{{name}} toimitetti arvioidun aikaikkunan ulkopuolella.',
    eventTextDeviated: '{{name}} on poikennut reitiltä.',
    eventTextDeliveryLocationDeviation: '{{name}} toimitti/poikkesi {{etäisyys}} metrin päähän odotetusta sijainnista.',
    eventHelpDeviation: 'Kuljettajalla {{name}} on poikkeama lähetysnumerossa {{shipmentId}}.',
    eventHelpDamage: 'Kuljettajalla {{name}} on vaurio lähetyksessä numero {{shipmentId}}.',
    filterOngoing: 'Jatkuva',
    filterNotStarted: 'Ei alkanut',
    filterFinished: 'valmis',
    filterOnlyDeviated: 'Vain poikkeamat',
    filterShipmentDeviations: 'lähetyksen poikkeamat',
    filterShipmentDamage: 'Lähetysvaurio',
    filterEstimatedTime: 'Arvioitu aika',
    filterServiceTime: 'Palveluaika',
    filterRouteDeviations: 'Reittipoikkeamat',
    filterDeliveryLocationDeviation: 'Odottamaton sijainti',
    filterConfirmed: 'vahvistettu',
    routeProgress: 'Valmis {{complete}} / {{total}}',
    routeDuration: 'From {{from}} - {{to}}',
    routeDelayed: 'viivästynyt',
    routeAhead: 'Eteenpäin',
    routeOnTime: 'Ajallaan',
    routeUnknown: 'Tuntematon',
    routeDeviationsNone: 'Ei poikkeamia',
    routeDeviationCount: '{{unhandledDeviations}} / {{totalDeviations}}',
    routeHasHandleDeviations: 'Reitillä on {{count}} käsiteltyä poikkeamaa',
    routeHasHandleDeviations_plural: 'Reitillä on {{count}} käsiteltyä poikkeamaa',
    openRoute: 'Katso reitti',
    openShipment: 'Katso lähetys',
    viewRoute: 'Näytä reitti',
    deviated: 'poikkeama',
    offRoute: 'Pois reitiltä',
    onRoute: 'Tiellä',
    allRoutesStates: 'Kaikki reittitilat',
    selectedRouteStates: '{{count}} reitin tila',
    selectedRouteStates_plural: '{{count}} reittitilaa',
    noSelectedStates: 'Ei valittuja tiloja',
    allDeviationTypes: 'Kaikki poikkeamatyypit',
    selectedDeviationTypes: '{{count}} poikkeamatyyppi',
    selectedDeviationTypes_plural: '{{count}} poikkeamatyyppiä',
    showHandledDeviations: 'Näytä käsitellyt poikkeamat ({{count}})',
    noSelectedTypes: 'Ei valittuja tyyppejä',
    viewShipment: 'Näytä lähetys',
    selectAllRouteStates: 'Valitse kaikki reittitilat',
    noRoutesWithDeviations: 'Ei reittejä käytettävissä',
    selectAllDeviationTypes: 'Valitse kaikki poikkeamatyypit',
    noDeviationOnRoute: 'Ei poikkeamia reitillä',
    noDeviationAvailable: 'Ei poikkeamia käytettävissä',
    noDeviationsOnFilter: 'Valitulla suodattimella ei ole poikkeamia. Kokeile toista suodatinta',
    noRoutesOnFilter: 'Valitulle suodattimelle ei ole saatavilla reittejä. Kokeile toista suodatinta',
    eventHelpServiceTimeWindow:
      '{{name}} on toimittanut lähetysnumeron {{shipmentId}} klo {{actual}}, joka on palveluaikaikkunan {{earliest}}-{{latest}} ulkopuolella.',
    eventHelpEstimatedTimeWindow:
      '{{name}} on toimittanut lähetysnumeron {{shipmentId}} klo {{actual}}, mikä on arvioidun aikaikkunan {{earliest}}-{{latest}} ulkopuolella.',
    eventHelpDriverDeviated: '{{name}} on toimittanut lähetysnumeron {{shipmentId}} väärässä järjestyksessä.',
    eventHelpDriverDeviatedBefore:
      '{{name}} on toimittanut lähetysnumeron {{shipmentId}} väärässä järjestyksessä, pysähtyen toimitukseen #{{actual}} ennen toimitusta #{{odotettu}}.',
    eventHelpDriverDeviatedAfter:
      '{{name}} on toimittanut lähetysnumeron {{shipmentId}} väärässä järjestyksessä, pysähtyen toimitukseen #{{actual}} toimituksen #{{odotettu}} jälkeen.',
    eventHelpDeliveryLocationDeviation: {
      withShipmentId:
        '{{name}} on toimittanut tai poikennut lähetysnumeron {{shipmentId}} <4>tänne</4>, {{distance}} metrin päähän <9>odotetusta sijainnista</9>.',
      withoutShipmentId:
        '{{name}} on toimittanut tai poikennut <4>tänne</4>, {{distance}} metrin päässä <9>odotetusta sijainnista</9>.'
    },
    search: 'Hae reitin, laitteen tai kuljettajan mukaan',
    unhandled: 'Käsittelemättömät',
    deviations: 'poikkeamat',
    mostDeviations: 'Suurin osa poikkeamista',
    latestInTime: 'Viimeisin ajallaan',
    earliestInTime: 'Aikaisintaan',
    sortOn: 'Lajittele'
  },
  userRolesAdmin: {
    userRoles: 'Käyttäjäroolit',
    successCreateMessage: '{{name}} luotiin onnistuneesti',
    successUpdateMessage: '{{name}} päivitettiin onnistuneesti',
    saveRole: 'Tallenna rooli',
    editRole: 'Muokkaa {{name}}',
    createNewRole: 'Luo uusi rooli',
    nameOfRole: 'Roolin nimi',
    nameRequired: 'Nimi vaaditaan',
    descriptionRequired: 'Kuvaus vaaditaan',
    descriptionLabel: 'Kuvaus (max 240 merkkiä)',
    iconLabel: 'Valitse haluamasi ikoni käyttäjäroolille',
    selectRoleTitle: 'Valitse käyttöoikeus roolille',
    emptyState: 'Hakulauseella ei löytynyt rooleja',
    deleteConfirm: 'Oletko varma, että haluat poistaa {{name}}?',
    deleteSuccess: 'Rooli {{name}} poistettiin onnistuneesti',
    settings: 'Asetukset',
    accessRights: 'Käyttöoikeudet',
    settingsTitle: 'Asetukset roolille',
    settingsDescription: 'Englanti on pakollinen. Jos muita kieliä ei valita, käytetään varakielenä englantia.',
    additionalLanguagesTitle: 'Lisää muita kieliä',
    customerPortalAccess: 'Asiakasportaalin pääsy',
    defaultForUserRole: 'Käytä oletusroolina (valinnainen)'
  },
  validationError: {
    provideEmail: 'Sinun täytyy antaa sähköposti',
    provideValidEmail: 'Sinun täytyy antaa kelvollinen sähköposti',
    youMustSelectCustomer: 'Sinun täytyy valita asiakas'
  },
  apolloAdmin: {
    title: 'Integraatiot',
    emptyTitle: 'Ei pyyntöjä',
    emptyDescription: 'Valitse haluamasi päivämäärät ja paina hae.',
    reqType: 'Pyynnön tyyppi',
    routeId: 'Reitin id',
    routeName: 'Reitin nimi'
  }
}
export default fi
