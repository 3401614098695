import { getTrackingPageBaseUrl } from '@glow/common'
import i18next from 'i18next'
import { List, Map, Set } from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
import { closeModal, getOrderDeviationsByOrderId, setPageStateValue } from '../actions/creators/helpers'
import { getOrderById } from '../actions/creators/queryHelpers'
import { DeviationOrderPropsType } from '../pages/admin/orderDeviationTypes'
import { consignmentEventsForShipmentIdByShipmentId } from '../pages/instant/InstantShipmentDetails'
import { ExpansionPanelProps } from '../primitives/Accordion'
import Container from '../primitives/Container'
import ImmutableComponent from '../primitives/ImmutableComponent'
import Overlay from '../primitives/Overlay'
import { createImmutableEqualSelector } from '../selectors/createImmutableEqualSelector'
import {
  consignmentContainsDeviationInEventsSelector,
  customerOrderDeviationsIdSelector
} from '../selectors/customerOrderDeviationsSelector'
import { customerByIdSelector } from '../selectors/customerSelector'
import { departmentFromIdSelector } from '../selectors/departmentsSelector'
import { orderNoteFromOrderIdSelector } from '../selectors/orderNoteSelectors'
import { routePointsForConsignmentIdSelector } from '../selectors/routePointsSelector'
import { servicesAndVasesTextsPerCodeSelector } from '../selectors/servicesSelectors'
import { shipmentForCustomerSelector } from '../selectors/shipmentSelectors'
import {
  ConsignmentEvent,
  Department,
  OrderIdType,
  OrderNote,
  RoutePoint,
  Shipment,
  Slot,
  Unit
} from '../types/coreEntitiesTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { isEmpty } from '../utils/collectionUtils'
import { GroupedServicesAndVasesTexts } from '../utils/serviceUtils'
import { Events } from './Events'
import { NewShipmentModalContainer } from './newShipmentModal/NewShipmentModalContainer'
import { Consignments } from './newShipmentModal/panels/consignments/Consignments'
import { ShipmentMap } from './newShipmentModal/panels/ShipmentMap'
import { ShipmentModalAccordion } from './newShipmentModal/shared/ShipmentModalAccordion'
import ShipmentLabel from './ShipmentLabel'
import { ShipmentModalCloseButton } from './ShipmentModalCloseButton'
import { MainArea, ShipmentModalContainer } from './ShipmentModalStyles'

class ShipmentModal extends ImmutableComponent<Props> {
  componentDidMount() {
    const orderId = this.props.shipmentId
    if (orderId) {
      this.props.getOrderById(orderId)
      this.props.containsDeviationInEvents && this.props.getOrderDeviationsByOrderId(orderId)
    }
  }

  render() {
    const {
      shipment,
      slot,
      unit,
      events,
      role,
      deliveryDisplayType,
      customerOrderDeviations,
      internalNote,
      shipmentRoutePoints
    } = this.props
    if (isEmpty(shipment)) {
      return null
    }

    const cEvents = shipment?.get('consignments')?.map((c) => {
      const evs = events && events.get(c.get('id'))
      return evs?.map((e) => e.setIn(['packageId'], c.get('packageId'))) || List<ConsignmentEvent>()
    })

    const closeFunction = this.props.ownCloseModal || this.props.closeModal

    return (
      <>
        <Overlay onClick={() => closeFunction()} />
        <ShipmentModalContainer>
          <MainArea>
            <Container noPadding marginTop marginBottom full>
              <NewShipmentModalContainer
                shipment={shipment}
                slot={slot}
                unit={unit}
                allTexts={this.props.allTexts}
                trackingBaseUrl={this.props.deliveryPageBaseUrl}
                role={role}
                internalNote={internalNote}
                enableQuickEdit
                department={this.props.department}
                routePoints={shipmentRoutePoints}
              />
              <ShipmentLabel label={shipment.get('label')} orderId={shipment.get('orderId')} />
            </Container>
          </MainArea>
          <ShipmentModalAccordion
            expansionPanels={expansionPanelsSelector(
              cEvents,
              customerOrderDeviations,
              role,
              deliveryDisplayType,
              shipment
            )}
          />
          <ShipmentModalCloseButton onClick={closeFunction} />
        </ShipmentModalContainer>
      </>
    )
  }
}

const expansionPanelsSelector: (
  events: List<List<ConsignmentEvent>>,
  customerDeviations: Map<number, DeviationOrderPropsType>,
  role: string,
  deliveryDisplayType: string,
  shipment: Shipment
) => List<ExpansionPanelProps> = createImmutableEqualSelector(
  (events: List<List<ConsignmentEvent>>) => events,
  (events: List<List<ConsignmentEvent>>, customerDeviations: Map<number, DeviationOrderPropsType>) =>
    customerDeviations,
  (events: List<List<ConsignmentEvent>>, customerDeviations: Map<number, DeviationOrderPropsType>, role: string) =>
    role,
  (
    events: List<List<ConsignmentEvent>>,
    customerDeviations: Map<number, DeviationOrderPropsType>,
    role: string,
    deliveryDisplayType: string
  ) => deliveryDisplayType,
  (
    events: List<List<ConsignmentEvent>>,
    customerDeviations: Map<number, DeviationOrderPropsType>,
    role: string,
    deliveryDisplayType: string,
    shipment: Shipment
  ) => shipment,
  (events, customerDeviations, role, deliveryDisplayType, shipment) =>
    List<ExpansionPanelProps>([
      {
        header: i18next.t('consignment.events'),
        expandedOnLoad: true,
        content: (
          <Events
            shipmentConsignmentEvents={events}
            customerDeviations={customerDeviations}
            orderType={shipment.get('type')}
          />
        )
      },
      {
        header: i18next.t('consignment.consignmentDetails'),
        content: (
          <Consignments consignments={shipment.get('consignments')} consignmentsCount={shipment.get('noOfPackages')} />
        )
      },
      {
        header: i18next.t('consignment.mapPanelTitle'),
        content: <ShipmentMap shipment={shipment} />
      }
    ])
)

interface StateProps {
  shipmentId?: number
  shipment: Shipment
  events: Map<number, List<ConsignmentEvent>>
  slot: Slot
  unit: Unit
  allTexts: GroupedServicesAndVasesTexts
  role: string
  deliveryDisplayType: string
  deliveryPageBaseUrl: string
  ownCloseModal?: () => void
  containsDeviationInEvents: boolean
  customerOrderDeviations: Map<number, DeviationOrderPropsType>
  internalNote?: OrderNote
  department: Department
  shipmentRoutePoints?: List<RoutePoint>
}

interface DispatchProps {
  setPageStateValue: (key: string, value: any) => void
  closeModal: (keepState?: boolean) => void
  getOrderDeviationsByOrderId: (orderId: OrderIdType) => void
  getOrderById: (id: OrderIdType) => any
}

interface OwnProps {
  shipmentId?: number
  role?: string | null
  closeModal?: () => void
}

type Props = StateProps & DispatchProps & OwnProps

export default connect<StateProps, DispatchProps, OwnProps, AppStateType>(
  (state, ownProps): StateProps => {
    const shipmentId = ownProps.shipmentId
    const events = shipmentId
      ? consignmentEventsForShipmentIdByShipmentId(state, shipmentId)
      : Map<number, List<ConsignmentEvent>>()
    const shipment = shipmentId ? shipmentForCustomerSelector(state, events, shipmentId) : (Map() as Shipment)
    const departmentId = shipment.get('departmentId')
    const department = departmentFromIdSelector(state, departmentId)
    const slot = state.getIn(['entities', 'slots', shipment.get('slotId')])
    const unit = slot ? state.getIn(['entities', 'units', slot.get('courierId')]) : Map()
    const role = ownProps.role || state.getIn(['user', 'role'])
    const allTexts = servicesAndVasesTextsPerCodeSelector(state)

    const customer = customerByIdSelector(state, shipment.get('customerId'))
    const deliveryDisplayType = 'customized'
    const consignmentIds = shipment.get('consignmentIds', Set())
    const shipmentRoutePoints = routePointsForConsignmentIdSelector(state, consignmentIds.first())

    const deliveryPageBaseUrl = getTrackingPageBaseUrl(
      customer?.get('trackingType'),
      department.get('departmentType'),
      shipment.get('type'),
      shipment.get('deliveryCountry')
    )
    const containsDeviationInEvents = consignmentContainsDeviationInEventsSelector(events)
    const customerOrderDeviations =
      (containsDeviationInEvents && customerOrderDeviationsIdSelector(state, shipment.get('customerId'))) ||
      Map<number, DeviationOrderPropsType>()

    const internalNote = orderNoteFromOrderIdSelector(state, shipment.get('orderId'))

    return {
      allTexts,
      shipmentId,
      shipment,
      slot,
      events,
      unit,
      role,
      deliveryDisplayType,
      deliveryPageBaseUrl,
      ownCloseModal: ownProps.closeModal,
      containsDeviationInEvents,
      customerOrderDeviations,
      internalNote,
      department,
      shipmentRoutePoints
    }
  },
  {
    setPageStateValue,
    closeModal,
    getOrderDeviationsByOrderId,
    getOrderById
  }
)(ShipmentModal)
