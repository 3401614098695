import { httpPost, responseHandler } from '@glow/common'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ConsignmentState } from '../../../utils/consignmentState'

export interface PartialShipmentData {
  state: ConsignmentState
  orderId: number
  packageId: string
  courierId?: number
  driverUserId?: number
  consignmentId: number
  isWithoutDate: boolean
  orderType: string | null
  shipmentId: string
  pickupTimeEarliest: string | null
  pickupTimeLatest: string | null
  deliveryTimeEarliest: string | null
  deliveryTimeLatest: string | null
}

export const useSelectedOrderIdsFromUrl = () => {
  const [params] = useSearchParams()

  return useMemo(() => {
    return (
      params
        .get('orderIds')
        ?.split(',')
        .map((x) => Number(x)) ?? []
    )
  }, [params])
}

export const usePartialShipmentData = (selectedOrderIds: number[]) => {
  const [partialShipmentData, setPartialShipmentData] = useState<PartialShipmentData[]>([])
  useEffect(() => {
    const fetch = async () => {
      const response: Promise<PartialShipmentData[]> = responseHandler(
        httpPost('/api/query/getPartialShipmentsData', {
          params: { orderIds: selectedOrderIds },
          query: 'getPartialShipmentsData'
        })
      )
      const result = await response
      setPartialShipmentData(result)
    }
    fetch()
  }, [selectedOrderIds])

  return [partialShipmentData]
}
