import { eventDataValue } from '@glow/entity-types'
import i18next from 'i18next'
import React from 'react'
import { AnyData } from '../../../../actions/creators/baseHelpers'

export function EventInvoicingAction(props: { data: AnyData }) {
  const reason = eventDataValue(props.data, ['reason']) ?? 'manuallySetByUser'

  return <span>{i18next.t(`consignmentEvent.invoiceEventReason.${reason}`)}</span>
}
