import { useToggle } from '@glow/common'
import { ConsignmentEventType } from '@glow/entity-types'
import { ConsignmentEventStatus, ConsignmentStatus } from '@glow/molecule-components'
import { ButtonBase, DefinitionItem, DefinitionList, IconFa, LayoutCard, Text } from '@glow/ui-components'
import { Disclosure } from '@headlessui/react'
import i18next from 'i18next'
import { List, Map } from 'immutable'
import React, { ReactElement, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { formatAmountWithUnit } from '../../../domain/measurementUnits'
import { getConsignmentsForOrderSelector } from '../../../selectors/consignmentSelectors'
import { customerOrderDeviationsIdSelector } from '../../../selectors/customerOrderDeviationsSelector'
import { Consignment, ConsignmentEvent, Shipment } from '../../../types/coreEntitiesTypes'
import { AppStateType } from '../../../utils/appStateReduxStore'
import { toLocaleDatePreserveNull } from '../../../utils/dateTime'
import { filterConsignmentEvents } from '../../../utils/shipmentsUtils'
import { DeviationOrderPropsType } from '../../admin/orderDeviationTypes'
import { ManualOverrideEventsModal } from '../manual-override/Modal'
import {
  ButtonContainer,
  ButtonText,
  ButtonTitle,
  ConsignmentDetailsContainer,
  ConsignmentEventItem,
  ConsignmentEventItemContainer,
  ConsignmentEventItemTime,
  ConsignmentEventItemTitle,
  ConsignmentEventsList,
  Container,
  DisclosureContainer,
  ListContainer
} from './EventViewStyles'
import styles from './EventsView.module.css'
import { ConsignmentEventDetails } from './consignment-event-details'

interface Props {
  consignmentEvents: List<ConsignmentEvent>
  shipment: Shipment
  orderId: number
}

export const EventsView = (props: Props) => {
  const consignments = useSelector((state: AppStateType) => getConsignmentsForOrderSelector(state, props.orderId))

  const [showModal, toggleModal] = useToggle()

  return (
    <Container>
      <div className="flex justify-between">
        <Text as="h5" textType="lg" className="text-black" bold>
          {i18next.t('consignment.events')}
        </Text>
        <ButtonBase variant="secondary" onClick={toggleModal}>
          {i18next.t('manualOverride.manuallyAddEvents')}
        </ButtonBase>
      </div>
      <ListContainer>
        {consignments.map((consignment, index) => (
          <li className="list-plain" style={{ maxWidth: 880 }} key={consignment.get('id')}>
            <LayoutCard noPadding>
              <ConsignmentDisclosure
                disclosureTitleText={
                  <>
                    {index + 1}. {i18next.t('consignment.packageId')}: {consignment.get('packageId')}
                  </>
                }
                disclosureTitleDetails={<ConsignmentDetails consignment={consignment} />}
                disclosureBody={
                  props.consignmentEvents.count() > 0 ? (
                    <ConsignmentEvents {...props} consignment={consignment} />
                  ) : (
                    <></>
                  )
                }
              />
            </LayoutCard>
          </li>
        ))}
      </ListContainer>
      <ManualOverrideEventsModal open={showModal} onClose={toggleModal} shipment={props.shipment} />
    </Container>
  )
}

interface DisclosureProps {
  disclosureTitleText: ReactElement
  disclosureTitleDetails?: ReactElement
  disclosureBody: ReactElement
}

export function ConsignmentDisclosure(props: DisclosureProps): ReactElement {
  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <DisclosureContainer>
          <Disclosure.Button
            as="div"
            style={{ display: 'grid', gap: '1rem', cursor: 'pointer' }}
            role="button"
            aria-label="Toggle events"
          >
            <ButtonContainer>
              <ButtonTitle className="body1">
                <IconFa icon={['far', 'box']} className="text-emphasis-medium" />
                {props.disclosureTitleText}
              </ButtonTitle>
              <ButtonText aria-hidden>
                <IconFa icon={['far', open ? 'chevron-up' : 'chevron-down']} />
              </ButtonText>
            </ButtonContainer>
            {props.disclosureTitleDetails}
          </Disclosure.Button>
          <Disclosure.Panel>
            <>
              <div className="border mb-6" />
              {props.disclosureBody}
            </>
          </Disclosure.Panel>
        </DisclosureContainer>
      )}
    </Disclosure>
  )
}

function ConsignmentDetails(props: { consignment: Consignment }): ReactElement {
  return (
    <ConsignmentDetailsContainer>
      <DefinitionList className={styles['definition-list']}>
        <DefinitionItem
          className="whitespace-nowrap"
          title={i18next.t('homeDeliveryRouteList.weight')}
          value={formatAmountWithUnit(props.consignment.get('weightValue'), props.consignment.get('weightUnit'))}
        />
        <DefinitionItem
          className="whitespace-nowrap"
          title={i18next.t('homeDeliveryRouteList.volume')}
          value={formatAmountWithUnit(props.consignment.get('volumeValue'), props.consignment.get('volumeUnit'))}
        />
        <DefinitionItem
          title={i18next.t('shipmentDetails.location')}
          className="whitespace-nowrap"
          truncate={false}
          value={props.consignment.get('location')?.toString()}
        />
        <DefinitionItem
          title={i18next.t('shipment.goodsInfo')}
          truncate={false}
          value={props.consignment.get('specificationDescription')}
        />
      </DefinitionList>
      <div>
        <ConsignmentStatus
          border
          text={i18next.t(`consignment.message.${props.consignment.get('state')}`)}
          state={props.consignment.get('state')}
          showAllStatuses={true}
        />
      </div>
    </ConsignmentDetailsContainer>
  )
}

function ConsignmentEvents(props: Props & { consignment: Consignment }) {
  const events = useMemo(
    () =>
      filterConsignmentEvents(props.consignmentEvents, props.shipment.get('type'))
        .filter((e) => e.get('consignmentId') === props.consignment.get('id'))
        .sort((a, b) => b.get('eventTime').localeCompare(a.get('eventTime'))),
    [props.consignmentEvents, props.consignment]
  )

  const deviations: Map<number, DeviationOrderPropsType> = useSelector(
    (state: AppStateType) => customerOrderDeviationsIdSelector(state, props.shipment.get('customerId')) ?? Map()
  )

  if (events.count() === 0) {
    return null
  }

  const getFormatedDate = (dateString: string) => {
    const date = toLocaleDatePreserveNull(dateString)
    return date ? `${date.toFormat('HH:mm')} ${date.weekdayLong}, ${date.toFormat('yyyy-MM-dd')}` : ''
  }

  return (
    <ConsignmentEventsList className="list-plain">
      {events.map((e) => (
        <ConsignmentEventItem className="list-plain" key={e.get('id')}>
          <ConsignmentEventItemContainer>
            <ConsignmentEventItemTitle className="body2">
              <ConsignmentEventStatus
                type={e.get('type') as ConsignmentEventType}
                text={i18next.t(`consignmentEvent.message.${e.get('type')}`)}
              />
            </ConsignmentEventItemTitle>
            <ConsignmentEventItemTime>
              <div>{getFormatedDate(e.get('eventTime'))}</div>
              <div>{getName(e.get('role'), e.get('name'))}</div>
            </ConsignmentEventItemTime>
          </ConsignmentEventItemContainer>
          <ConsignmentEventDetails consignmentEvent={e} deviations={deviations} />
        </ConsignmentEventItem>
      ))}
    </ConsignmentEventsList>
  )
}

export const getName = (role: string | undefined, name: string | undefined) => (
  <strong>
    {name && role !== 'CUSTOMER'
      ? name === 'SystemUser'
        ? 'GLOW'
        : name == 'CustomerUser'
          ? i18next.t('application.title.customerPortal')
          : name
      : ''}
  </strong>
)
