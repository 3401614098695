import { List, Map } from 'immutable'
import {
  bookingFormatLocalDateTime,
  getCustomerSubCustomer,
  getOptimusXYValue,
  pureVAS
} from '../pages/instant/bookingOrderFunctions'
import { IOptimusRouting, IOrderForm, ValueAddedService } from '../pages/instant/bookingOrderTypes'
import { Department, DepartmentIdType, SlotIdType } from '../types/coreEntitiesTypes'
import { count, isEmpty } from './collectionUtils'
import { BillingType, DeliveryTimeWindow } from '../domain/deliveryTimeWindow'
import { IOptimusService } from '../domain/optimusService'
import { isProduction } from '@glow/common'

export type TransportLegType = 'PICKUP' | 'DELIVERY' | 'LINEHAUL' | 'TERMINAL'
export type TransportLegPayload = {
  transportLegType: TransportLegType
  articleCode: string
  articleGroup: string
  valueAddedServices: List<ValueAddedService>
  unitId?: string
  operationsId?: string
  pickup?: TransportLegAddress
  delivery?: TransportLegAddress
}
export type TransportLegAddress = {
  addressName: string
  streetName: string
  postalCode: string
  cityName: string
  country: string
  lat?: number
  lng?: number
}

export const getServiceCode = (service: IOptimusService) => {
  const serviceCode = service.get('serviceCode')
  const vasCode = service.get('vasCode') || ''
  const alystraCode = service.get('alystraServiceCode') || ''
  return `${serviceCode}___${vasCode}___${alystraCode}`
}

export const getSelectedService = (
  serviceInfo: string,
  services: List<IOptimusService>,
  customizedServices: List<IOptimusService>
) => {
  const values = serviceInfo && serviceInfo.split('___')
  if (!values) return null
  const serviceCode = values[0]
  const vasCode = values[1] || ''
  const alystraServiceCode = values[2] || ''
  const customizedService = customizedServices.find((it: IOptimusService) =>
    equalService(serviceCode, vasCode, alystraServiceCode, it)
  )
  if (isEmpty(customizedService))
    return services.find((it: IOptimusService) => equalService(serviceCode, vasCode, alystraServiceCode, it))
  else return customizedService
}

const equalService = (serviceCode: string, vasCode: string, alystraServiceCode: string, it: IOptimusService) =>
  serviceCode === it.get('serviceCode') &&
  vasCode === (it.get('vasCode') || '') &&
  alystraServiceCode === (it.get('alystraServiceCode') || '')

export function selectedService(
  services: List<IOptimusService>,
  customizedServices: List<IOptimusService>,
  serviceCode: string,
  vasCodes: List<string>
) {
  if (vasCodes.isEmpty()) {
    const customizedService = customizedServices.find(
      (it: IOptimusService) => serviceCode === it.get('serviceCode') && it.get('vasCode') === undefined
    )
    if (isEmpty(customizedService))
      return services.find(
        (it: IOptimusService) => serviceCode === it.get('serviceCode') && it.get('vasCode') === undefined
      )
    else return customizedService
  } else {
    const customizedService = customizedServices.find(
      (it: IOptimusService) => serviceCode === it.get('serviceCode') && vasCodes.contains(it.getIn(['vasCode'], ''))
    )
    if (isEmpty(customizedService))
      return services.find(
        (it: IOptimusService) => serviceCode === it.get('serviceCode') && vasCodes.contains(it.getIn(['vasCode'], ''))
      )
    else return customizedService
  }
}

export const onGetPricePayload = (
  values: IOrderForm,
  totalWeight: number,
  totalVolume: number,
  routing: IOptimusRouting,
  customizedRouting: IOptimusRouting,
  services: List<IOptimusService>,
  customizedServices: List<IOptimusService>,
  allDepartments: List<Department>,
  departmentId?: DepartmentIdType,
  slotId?: SlotIdType,
  deliveryTimeWindow?: DeliveryTimeWindow,
  orderId?: number
): Map<string, any> => {
  if (
    (isProduction() && isEmpty(routing) && isEmpty(customizedRouting)) ||
    (isEmpty(services) && isEmpty(customizedServices)) ||
    !values.get('pickupDate') ||
    !values.get('pickupTimeEarliest')
  ) {
    return Map()
  }
  const service = getSelectedService(values.get('serviceId'), services, List())
  const customizedService = getSelectedService(values.get('serviceId'), List(), customizedServices)
  if (!service && !customizedService) {
    console.error('service not found', services.toJS())
    return Map()
  }

  const serviceToUse = customizedService ? customizedService : service
  const routingToUse = customizedService ? customizedRouting : routing
  if (!serviceToUse || !routingToUse || (serviceToUse.get('useCustomerZones') && !deliveryTimeWindow)) return Map()

  const quantity = count(values.get('measurements')) || 1
  const weight = !isNaN(totalWeight) ? totalWeight : 0
  const customerIdValues = getCustomerSubCustomer(values.get('customerId'))
  const subCustomer = customerIdValues.subcustomer || values.get('subcustomer')

  const billingType = deliveryTimeWindow ? deliveryTimeWindow.get('billingType') : undefined
  const serviceCode = getServiceCodeForPriceQuery(serviceToUse, billingType)
  const articleCode = getArticleCodeForPriceQuery(serviceToUse, serviceCode)
  const zone = getZoneForPriceQuery(serviceToUse, routingToUse, deliveryTimeWindow)

  const allValueAddedServices = services.concat(customizedServices).filter(pureVAS)
  const selectedValueAddedServices = updateValueAddedServicesWithXYAndCode(
    values.get('additionalServices').filter((vas) => vas.get('vasCode')),
    allValueAddedServices,
    zone,
    weight
  )

  const overrideDepartmentAlystraId = allDepartments
    .find((dept) => dept.get('id')?.toString() == values.get('selectedDepartmentId'))
    ?.get('alystraId')

  const selectedDepartmentAlystraId = allDepartments.find((dept) => dept.get('id') == departmentId)?.get('alystraId')

  const payload = Map({
    department: departmentId,
    customerId: customerIdValues.customerNumber,
    subcustomer: '' === subCustomer ? null : subCustomer,
    serviceId: serviceCode,
    operationsId:
      overrideDepartmentAlystraId ||
      serviceToUse.get('routingOverride') ||
      routingToUse.get('operation') ||
      selectedDepartmentAlystraId,
    articleCode: articleCode,
    articleGroup: routingToUse.get('articleGroup'),
    quantity,
    weight,
    weightAddition: serviceToUse.get('weightAddition'),
    xValue: getOptimusXYValue(serviceToUse.get('xParameter'), zone, quantity, weight),
    yValue: getOptimusXYValue(serviceToUse.get('yParameter'), zone, quantity, weight),
    volume: !isNaN(totalVolume) && totalVolume > 0 ? totalVolume / 1000 : 0,
    alystraCustomer: customerIdValues.customerNumber,
    measurements: values.get('measurements'),
    pickup: {
      zipCode: values.get('pickupZipCode'),
      streetName: values.get('pickupAddress'),
      cityName: values.get('pickupZipArea'),
      country: values.get('pickupCountryCode'),
      lat: values.get('pickupAddressLat'),
      lng: values.get('pickupAddressLng')
    },
    delivery: {
      zipCode: values.get('deliveryZipCode'),
      streetName: values.get('deliveryAddress'),
      cityName: values.get('deliveryZipArea'),
      country: values.get('deliveryCountryCode'),
      deliveryTimeEarliest: bookingFormatLocalDateTime(
        values.get('deliveryDate') || values.get('pickupDate'),
        deliveryTimeWindow?.get('timeFrom') || values.get('deliveryTimeEarliest')
      ),
      deliveryTimeLatest: bookingFormatLocalDateTime(
        values.get('deliveryDate') || values.get('pickupDate'),
        deliveryTimeWindow?.get('timeTo') || values.get('deliveryTimeLatest')
      ),
      lat: values.get('deliveryAddressLat'),
      lng: values.get('deliveryAddressLng')
    },
    from: bookingFormatLocalDateTime(values.get('pickupDate'), values.get('pickupTimeEarliest')),
    useCustomerZones: serviceToUse.get('useCustomerZones'),
    valueAddedServices: selectedValueAddedServices.toJS(),
    note: values.get('orderNote'),
    unitId: values.get('preplannedUnitId'),
    slotId: slotId,
    orderId
  })

  return payload
}

export function getServiceCodeForPriceQuery(service: IOptimusService, billingType?: BillingType): string {
  let serviceCode = service.get('alystraServiceCode')

  if (service.get('useCustomerZones') && billingType) {
    switch (billingType) {
      case BillingType.EVENING:
        serviceCode = service.get('eveningCode') || serviceCode
        break
      case BillingType.WEEKEND:
        serviceCode = service.get('weekendCode') || serviceCode
        break
      case BillingType.WEEKEND_EVENING:
        serviceCode = service.get('weekendEveningCode') || serviceCode
        break
      case BillingType.DAY:
      default:
        break
    }
  }

  return serviceCode
}

export function getArticleCodeForPriceQuery(service: IOptimusService, serviceCode: string): string {
  return service.get('useCustomerZones') ? serviceCode : service.get('alystraArticleCode')
}

export function getZoneForPriceQuery(
  service: IOptimusService,
  routing: IOptimusRouting,
  deliveryTimeWindow?: DeliveryTimeWindow
) {
  return service.get('useCustomerZones') && deliveryTimeWindow
    ? String(deliveryTimeWindow.get('zoneNumber'))
    : routing.get('zone')
}

export function updateValueAddedServicesWithXYAndCode(
  selectedVases: List<ValueAddedService>,
  allVases: List<IOptimusService>,
  zone: string,
  weight: number
): List<ValueAddedService> {
  return selectedVases.map((selectedVas) => {
    const vas = allVases.find((it: IOptimusService) => selectedVas.get('vasCode') === it.get('vasCode'))
    const quantity =
      selectedVas.get('quantity').length <= 0 || isNaN(Number(selectedVas.get('quantity')))
        ? 1
        : Number(selectedVas.get('quantity'))
    return selectedVas
      .set('xValue', getOptimusXYValue(vas ? vas.get('xParameter') : '', zone, quantity, weight))
      .set('yValue', getOptimusXYValue(vas ? vas.get('yParameter') : '', zone, quantity, weight))
      .set('vasCode', vas ? vas.get('alystraArticleCode') : '')
      .set('quantity', quantity.toString())
  })
}
