const nb = {
  language: 'nb',
  userRoles: {
    admin: 'Admin',
    planner: 'Normal',
    read_only_planner: 'Lesetilgang',
    plus_planner: 'Plus',
    partner: 'Partner',
    customer: 'Kunde',
    customs_agent: 'Tollagent',
    handler: 'Behandler'
  },
  application: {
    recipient: 'Mottaker',
    save: 'Lagre',
    update: 'Oppdater',
    required: '*Obligatorisk felt',
    requiredShort: '*Obligatorisk',
    requiredLocation: '* Lokasjon mangler',
    search: 'Søk',
    logOut: 'Logg ut',
    proceed: 'Fortsett',
    courier: 'Sjåfør',
    couriers: 'Sjåfører',
    consignments: 'Leveranser',
    address: 'Addresse',
    estimated: 'Estimert',
    adjusted: 'Justert',
    actual: 'Faktisk',
    funeral: 'Begravelse',
    tracking: 'Sporing',
    trackingPage: 'Sporingsside',
    delivery: 'Levering',
    phoneNumberShort: 'Tlf nr.',
    yes: 'Ja',
    no: 'Nei',
    httpErrorToString: 'HTTP-feil: kode {{status}} {{statusText}}',
    anErrorOccurred: 'En feil har oppstått!',
    noAccessTitle: 'Ingen tilgang',
    noAccessMessage: 'Du har ikke tilgang til å denne funksjonaliten',
    autoComplete: 'Søk etter adresse ...',
    autoCompleteWithoutDots: 'Søk etter adresse',
    totalWeight: 'Totalvekt',
    totalVolume: 'Totalt volum',
    title: {
      root: 'Velkommen',
      planner: 'Planlegger',
      admin: 'Administrasjon',
      plannerLive: 'Planlegger - Live',
      plannerLiveSlot: 'Planlegger - Live - Slot',
      plannerCouriers: 'Planlegger - Sjåfører',
      plannerImport: 'Planlegger - Import',
      plannerUnresolved: 'Planlegger - Uløste',
      plannerConsignments: 'Planlegger - Leveranser',
      plannerSearch: 'Planlegger - Search',
      plannerPlanning: 'Planlegger - planlegging',
      plannerInvoicing: 'Planlegger - fakturering',
      plannerVehicle: 'Planlegger - Vehicles',
      report: 'Rapporter',
      recipient: 'Pakkesporing - sporing av pakker - sporing - Bring',
      orderConfirmation: 'Ordrebekreftelse',
      customerPortal: 'Kundeportal',
      jobs: 'Jobbovervåking',
      holidays: 'Helligdager',
      shipmentLabels: 'Labels',
      addresses: 'Adresser',
      airexpressInvoicing: 'Air Express Fakturering',
      routeReceiptList: 'Kvitteringsliste',
      loadList: 'Lasteliste',
      airexpressAdminTerminals: 'Terminaler'
    },
    language: { en: 'Engelsk', nb: 'Norsk', sv: 'Svensk', dk: 'Dansk', fi: 'Finsk' },
    country: {
      AllCountry: 'Alle land',
      Country: 'Land',
      AT: 'Østerrike',
      BE: 'Belgia',
      BA: 'Bosnia og Herzegovina',
      HR: 'Kroatia',
      CZ: 'Tsjekkia',
      DK: 'Danmark',
      GB: 'Storbritannia',
      EE: 'Estland',
      FO: 'Færøyene',
      FR: 'Frankrike',
      DE: 'Tyskland',
      GR: 'Hellas',
      HU: 'Ungarn',
      IS: 'Island',
      IE: 'Irland',
      IT: 'Italia',
      LT: 'Litauen',
      MK: 'Nord-Makedonia',
      NL: 'Nederland',
      NO: 'Norge',
      PL: 'Polen',
      RU: 'Russland',
      ES: 'Spania',
      SE: 'Sverige',
      CH: 'Sveits',
      UA: 'Ukraina'
    },
    timeDistance: 'Planlagt tid og avstand',
    driverReturnToAddress: 'Ikke spesifiser en returadresse',
    plannedTime: 'Planlagt tid',
    plannedDistance: 'Planlagt avstand',
    plannedWeight: 'Planlagt vekt',
    distance: 'Avstand'
  },
  serviceLevel: { notSet: 'Ikke valgt', none: 'Av', label: 'Nedgrader servicenivå' },
  button: {
    edit: 'Rediger',
    back: 'Tilbake',
    cancel: 'Avbryt',
    done: 'Ferdig',
    clear: 'Tøm',
    close: 'Lukk',
    save: 'Lagre',
    delete: 'Slett',
    export: 'Eksport',
    new: 'Lag ny',
    search: 'Søk',
    add: 'Legg til',
    remove: 'Fjern',
    assign: 'Tildel',
    fullEdit: 'Full redigering',
    continue: 'Fortsett'
  },
  modals: {
    preplan: {
      create: 'Preplan sending',
      change: 'Bytt preplanned sjåfør',
      shipmentInfo: 'Preplan sending {{shipmentId}} ({{pickupName}} til {{deliveryName}})',
      pickDriver: 'Velg en sjåfør..',
      error: 'Kunne ikke preplan sending',
      notification: {
        preplannedMessage: 'Sendingen var preplanned til {{courierId}}',
        unpreplannedMessage: 'Sendingen var unpreplanned'
      }
    },
    maps: {
      save: 'Lagre',
      cancel: 'Avbryt',
      delete: 'Slett',
      editMode: 'Rediger',
      mapArea: '{{routeName}} kartområde',
      saved: 'Lagret',
      deleted: 'Slettet'
    }
  },
  consignment: {
    includePreAdvice: 'Preavisering',
    onlyFailedPreAdvice: 'Preavisering feilet',
    onlyReturnPreAdvice: 'Retur till avsender',
    consignmentDetails: 'Forsendelsesdetaljer',
    search: 'Søk etter leveranser',
    noShipmentsFound: 'Fant ingen leveranser',
    clickButtonToSearch: 'Klikk på Søk-knappen for å søke',
    consignmentsForDate: 'Leveranser for {{date}}',
    showOnlyEditable: 'Kun redigerbare',
    showOnlyNotAssigned: 'Ikke tildelt',
    withoutDate: 'Uten dato',
    extendedSearch: 'Utvidet søk',
    showDeleted: 'Vis slettede',
    showDeviated: 'Vis avvik',
    deletedBy: 'Sletted av',
    deletionReason: 'Årsak',
    numberOfConsignments: 'Fant {{number}} leveranser',
    showAll: 'Alle',
    edit: 'Endre',
    deleteOrders: 'Slett',
    copyOrder: 'Kopier',
    duplicateOrder: 'Duplisere',
    editOrder: 'Rediger',
    deletedOrderNotCopyable: 'Den slettede ordren kan ikke kopieres grunnet manglende ordredetaljer i Glow',
    restoreOrder: 'Gjenopprett',
    deletedOrderNotRestorable: 'Den slettede ordren kan ikke gjenopprettes grunnet manglende ordredetaljer i Glow',
    deleteConfirmation: 'Er du sikker på at du vil slette denne sendingen ?',
    deleteMultipleConfirmation: 'Er du sikker på at du vil slette valgte sendinger?',
    copyConfirmation: 'Er du sikker på at du vil kopiere denne sendingen ?',
    earliestPickupTime: 'Tidligste hentetidspunkt',
    latestPickupTime: 'Seneste hentetidspunkt',
    editConsignment: ' Rediger leveringsinformasjon',
    changeDateAndTime: 'Sett dato og tider',
    searchShipments: 'Søk i leveranser:',
    searchCustomers: 'Søk i kunder:',
    filterBy: 'Filtrer på:',
    limitedShipmentsShownMessage: 'Viser maksimalt {{count}} ordre, vurder å begrense ditt søk',
    limitedUnresolvedAddressesShownMessage: 'Viser maksimalt {{count}} ufullstendige adresser',
    bulkActions: 'Endre sendinger',
    bulkActionsMultiple: 'Endre sendinger ({{count}})',
    addToRecurringGroup: 'Legg til i recurring group',
    moveShipmentsToDifferentDepartment: 'Flytt til en annen avdeling',
    addEvents: 'Legg til hendelse',
    disabledEditDeleteHelperText:
      'Denne ordren kan ikke redigeres eller slettes, vennligst kontakt ditt lokale Bringkontor for å gjøre endringer på ordren.',
    editPickupConsignment: 'Oppdater henteinformasjon',
    editDeliveryConsignment: 'Oppdater leveringsinformasjon',
    editPickupAndDeliveryTime: 'Oppdater henting og leveringstid',
    editConsignmentLocation: ' Lokasjon',
    editConsignmentTime: ' Tid',
    editConsignmentName: ' Navn',
    editConsignmentPhone: ' Telefonnummer',
    editConsignmentInstructions: ' Instruksjoner',
    earliestDeliveryTime: 'Tidligste leveringstid',
    latestDeliveryTime: 'Seneste leveringstid',
    enterPickupInstructions: 'Angi henteinstruksjoner',
    enterDeliveryInstructions: 'Angi leveringsinstruksjoner',
    enterDeliveryName: 'Angi navn for levering',
    enterPickupName: 'Angi navn for henting',
    enterPickupPhone: 'Angi telefonnummer for henting',
    enterDeliveryPhone: 'Angi telefonnummer for Levering',
    specificationSize: 'Størrelse',
    enterSpecificationSize: 'Legg inn størrelse',
    editSpecificationSize: 'Rediger størrelse',
    enterSpecificationDescription: 'Legg inn detaljer',
    editSpecificationDescription: 'Rediger detaljer',
    editPickupInfoDescription: 'For å kunne redigere må du velge minst ett av valgene',
    updateLocationFor: 'Oppdater informasjon om {{count}} leveranser',
    updateLocationFor_plural: 'Oppdater henteinformasjon for {{count}} leveranser',
    comment: 'Kommentar',
    filter: 'Filtrer leveranser',
    state: 'Status',
    show: 'Vis',
    delivery: 'Leveres',
    pickup: 'Hentes',
    pickups: '{{count}} opphenting',
    pickups_plural: '{{count}} opphentinger',
    estimatedDelivery: 'Estimert levering',
    requestedTimePickup: 'Forventet hentet',
    requestedTimeDelivery: 'Forventet levering',
    customerReference: 'Kundereferanse',
    timeNotSet: 'Ikke satt',
    shipmentId: 'Sendingsnummer',
    packageId: 'Kollinummer',
    noCourier: 'Ingen sjåfør',
    noSlot: 'Ingen slot',
    showEvents: 'Vis hendelser →',
    hideEvents: 'Skjul hendelser ←',
    events: 'Hendelser',
    sendMessage: 'Send melding',
    sendCommentFailed: 'Lagring av kommentar feilet',
    recipient: 'Mottaker',
    unresolved: 'Ufullstendige leveranser',
    description: 'Detaljer',
    pickupAddress: 'Henteadresse',
    numberOfPackages: 'Kolli',
    registeredPickupAddress: 'Registrert henteadresse',
    pickupZipCode: 'Postnummer',
    pickupZipArea: 'Område',
    pickupLocation: 'Lokasjon',
    deliveryAddress: 'Leveringsaddresse',
    deliveryZipCode: 'Postnummer',
    deliveryZipArea: 'Område',
    deliveryLocation: 'Lokasjon',
    registeredDeliveryAddress: 'Registrert leveringsadresse',
    updated: 'Forsendelsen ble oppdatert',
    moved: 'Sendingen har blitt flyttet',
    unresolvedMessage:
      'Det finnes {{count}} forsendelse der vi ikke finner posisjon i kartet. Vennligst oppdater disse..',
    unresolvedMessage_plural:
      'Det finnes {{count}} forsendelser der vi ikke finner adressen. Vennligst legg inn adressene.',
    foundUnresolvedMessage:
      'Vi har funnet følgende gyldige adresser for forsendelsen, om de er korrekte trykk lagre. Eventuelt søk opp korrekt adresse i kartet nedenfor.',
    remove: 'Fjern',
    alterSlot: 'Endre slot (ikke optimaliser)',
    alterSlotAndReoptimize: 'Endre slot (optimaliser ny slot med samme rekkefølge)',
    alterSlotNotAvailable: 'Ikke tilgjengelig så lenge optimisering pågår.',
    optimizingNewSlot: 'Optimaliserer levering til endret slot',
    selectSlot: 'Velg ny slot for denne leveransen',
    slotSelect: 'Velg slot',
    confirmSlotChange: 'Bekreft endring av slot',
    pickupInstructions: 'Henteinstrukser',
    deliveryInstructions: 'Leveringsinstrukser',
    specificationDescription: 'Beskrivelse',
    tracking: 'Sporingslenke',
    recipientPhone: 'Mottakers telefonnummer',
    customerRef: 'Kundereferanse',
    service: 'Tjeneste',
    message: {
      CREATED: 'Opprettet',
      ASSIGNED: 'Tildelt slot',
      UNASSIGNED: 'Ikke tildelt slot',
      COLLECTED: 'Plukket opp',
      DELIVERED: 'Levert',
      DEVIATED: 'Avvik',
      COMMENT: 'Kommentar',
      RESOLVED: 'Løst',
      UNRESOLVED: 'Uløst',
      OFFERED: 'Tilbudt',
      REJECTED: 'Avvist',
      ACCEPTED: 'Akseptert',
      RETURNED: 'Returnert',
      DELETED: 'Slettet'
    },
    manualOverrideTitle: 'Manuell overstyring',
    adjustTabTitle: 'Redigere',
    mapPanelTitle: 'Kart',
    recipientName: 'Mottakers navn',
    receivedBy: 'Mottatt av:',
    manuallyChangeLocations: 'Manuelt endre lokasjoner',
    manualOverrideDeviationTimeConfirm: 'Bekreft manuell avvikelse for valgt sending',
    manualOverrideDeliveryTimeConfirm: 'Bekreft manuell levering for valgt sending',
    onManuallyChangeLocationsConfirm: 'Er du sikker på at du vil gjøre sendingen uløst?',
    unresolveDeliveryAddress: 'Gjør leveringsadresse ufullstendig',
    unresolvePickupAddress: 'Gjør opphentingsadresse ufullstendig',
    reference: 'Referanse',
    shipmentIdNumber: 'Nr',
    shipmentDetails: 'Leveransedetaljer',
    customer: 'Kunde',
    subcustomer: 'Kundenummer',
    dateAndTime: 'Dato og tid',
    filterCustomer: 'Filtrer kunde',
    searchShipmentDateError: 'Angi datoperiode under 120 dager.',
    searchPhraseLength: 'Søkeord bør være minst {{minLength}} tegn for utvidet søk.',
    moveOrder: 'Flytt leveranse',
    moveOrderTitle: 'Flytt leveransen til en annen avdeling',
    moveOrderDefault: 'Velg avdeling',
    label: 'Etikett',
    createLabel: 'Lag etikett',
    createLabelShipment: 'Lag etikett (Sending)',
    createMultipleLabel: 'Lag etikett ({{count}})',
    viewLabel: 'Se etikett',
    viewLabels: 'Se etiketter',
    generateLabelErrorDescription: 'Beklager, kan ikke generere etiketter nå',
    generatingLabels: 'Etikett PDF URL-er vil snart komme til syne under',
    labelsGeneratedSuccessfully: 'Alle etikett PDF-er ble generert vellykket',
    vas: 'Tillegstjenester (VAS)',
    price: 'Pris',
    orderSources: { glow_customerBooking: 'GLOW kundebooking', glow_recurring: 'Gjentagende' },
    shipmentSyncStatus: { no: 'Nei', yes: 'Ja', partial: 'Delvis' },
    unresolvedSuccessfully: 'Sendingen er nå uløst.',
    wrongCoordinates: 'Feil koordinater?',
    moveShipmentToUnresolved: 'Flytt sendingen til "Uløste" for å rette på koordinatene.',
    mapUnavailable: 'Kartet vises når sendingen er løst.',
    selectOtherActions: 'Velg andre handlinger'
  },
  numberSeries: { numberSeries: 'Nummerserier' },
  preadvice: {
    preadvice: 'Avisering',
    hdDepartments: 'HD-avdelinger',
    ceDepartments: 'Budavdelinger',
    departmentGroups: 'Avdelingsgrupper',
    customers: 'Kunder',
    customerGroups: 'Kundegrupper',
    customersCount: 'Antall kunder',
    customerGroupsCount: 'Antall kundegrupper',
    departmentsCount: 'Antall avdelinger',
    departmentGroupsCount: 'Antall avdelingsgrupper',
    locked: 'Avisering låst',
    updated: 'Avisering oppdatert',
    rule: 'Regel',
    ruleTrigger: 'Preadvice på',
    result: {
      NO_RULE: 'Ingen regel matchet',
      NO_DELIVERY_TIME_WINDOW: 'Fant ingen tidsvinduer',
      SMS_SENT: 'Preadvice-regel sjekket. SMS lagt i kø',
      EMAIL_SENT: 'Preadvice-regel sjekket. Epost lagt i kø',
      NO_VALID_PHONE_OR_EMAIL: 'Verken gyldig telefonnummer eller epostadresse',
      FEATURE_OFF: 'Preadvice er skrudd av',
      ALREADY_PRE_ADVICED: 'Leveringstid allerede satt. Ingen handling utført',
      CANCELLED: 'Preadvice avbrutt',
      WAITING_FOR_PACKAGES: 'Venter på pakker',
      ALREADY_IN_PROGRESS: 'Venter på at mottaker skal booke tid',
      ERROR: 'Det skjedde en feil',
      WRONG_TRIGGER: 'Trigger matcher ikke regel. Ignorerer',
      MANUAL_ATTEMPT: 'Preadvice-forsøk inkrementert manuelt',
      PRE_ADVICE_LIMIT_REACHED: 'Grense for antall preadvice nådd',
      PRE_ADVICE_LOCKED: 'Preadvice er låst',
      ORDER_DELIVERED: 'Ordren er allerede levert',
      RETURN_LAST_LEG_CREATION_SKIPPED: 'Returben ble ikke opprettet'
    },
    error: {
      PRE_ADVICE_RULE_COLLISIONS: {
        label: 'Regelkonflikt!',
        detail:
          'Regel for {{service}} vil overstyre regelsett {{policySetNames}} gjennom knytning der til {{relations}}.',
        department: 'avdeling',
        department_group: 'avdelingsgruppe',
        customer: 'kunde',
        customer_group: 'kundegruppe'
      }
    },
    noPoliciesFound: 'Ingen regelsett',
    policies: 'Regelsett for automatisk avisering',
    policy: {
      deleteConfirm: 'Er du sikker på at du vil slette dette regelsettet?',
      name: 'Navn',
      new: 'Nytt aviseringsrelegelsett',
      edit: 'Endre aviseringsrelegelsett',
      description: 'Beskrivelse',
      save: 'Lagre',
      saveAnyway: 'Lagre likevel',
      servicePolicies: 'Aviseringsregler',
      targets: 'Kunde-/avdelingsknytninger',
      servicePolicy: {
        edit: 'Endre regelsett',
        new: 'Legg til regel for tjeneste',
        remove: 'Fjern valgte',
        serviceCode: 'Tjeneste',
        serviceCodeLegend: 'Legg til tjenestekode, f.eks 3123',
        vasCodeLegend: 'Legg til tilleggstjeneste f.eks SWAP',
        sameDayLegend: 'Huk av av hvis du vil at tjenesten skal utføres som Same Day Service',
        triggerEvent: 'Avisering på',
        triggerEventLegend:
          'Hva som skal forsårsåke at preavisering sendes. At vi mottar EDI til Glow, når sendingen ankommer HUB eller kommer til DIP, eller hvis den ikke skal trigges velger du MANUAL',
        cutoffLegend:
          'Cutoff-tidspunkt. Etter dette tidspunktet tilbys ikke lenger første ledige tidsvindu til mottaker, men dagen etter.',
        relativeCutoffMinutes: 'Cutoffminutter',
        relativeCutoffMinutesLegend:
          'Minimum antall minutter fra aviseringstidspunktet til det neste tilgjengelige tidsvinduet. Overstyrer cutofftid.',
        calendarDays: 'Kalenderdager',
        calendarDaysLegend: 'Hvor mange dager skal vises til mottaker i kalenderen.',
        noServicePoliciesFound: 'Ingen aviseringsregler',
        returnToSenderAfterDays: 'Retur til avsender',
        returnToSenderAfterDaysLegend:
          'Etter hvor mange dager skal sendingen returneres til avsender i tilfelle vi ikke har oppnådd kontakt med mottaker.',
        schemeLegend: 'Type preavisering som skal sendes; Push, Pull, Return eller Pick up parcel.',
        searchCustomer: 'Søk kunde',
        remindAfterHours: 'Påminnelse til mottaker',
        remindAfterHoursLegend:
          'Relevant for Push og Pull: etter hvor mange timer skal det sendes påminnelse til mottaker om å avtale leveringstid.',
        preAdviceAttempts: 'Aviseringsforsøk',
        preAdviceAttemptsLegend: 'Hvor mange påminnelser som skal sendes før sendingen returneres.',
        reminderToSenderAfterHours: 'Påminnelse til avsender',
        reminderToSenderAfterHoursLegend:
          'Etter hvor mange timer skal det sendes påminnelse til avsender om at det ikke er er avtalt levering.',
        deliveryAttempts: 'Leveringsforsøk',
        deliveryAttemptsLegend: 'Hvor mange leveringsforsøk før sendingen returneres til avsender.',
        label: { days: 'Dager', hours: 'Timer', smss: 'stk' },
        legend: { days: 'Dager', hours: 'Timer', smss: 'Maks antall SMS' },
        returnToPup: 'Retur til pickup point'
      }
    }
  },
  holidays: { holidays: 'Helligdager', addHoliday: 'Legg til ny helligdag', country: 'Land', date: 'Dato' },
  department: {
    departmentTitle: 'Avdeling',
    department: 'avdeling',
    department_plural: 'avdelinger',
    departmentWithCount: '{{count}} avdeling',
    departmentWithCount_plural: '{{count}} avdelinger',
    departmentGroups: 'Avdelingsgrupper',
    numberOfDepartmentGroups: 'Avdelingsgrupper',
    numberOfDepartments: 'Antall avdelinger',
    search: 'Søk etter avdelinger',
    noDepartmentName: 'Navn mangler',
    noDepartmentId: 'Id mangler',
    noCouriers: 'Ingen sjåfører',
    courierCount_plural: '{{count}} sjåfører',
    courierCount: '{{count}} sjåfør',
    noCourier: 'Ingen sjåfører',
    couriers: 'Antall sjøfører',
    plannerCount_plural: '{{count}} planleggere',
    plannerCount: '{{count}} planlegger',
    noPlanner: 'Ingen planleggere',
    planners: 'Antall planleggere',
    choose: 'Velg avdeling',
    new: 'Ny avdeling',
    description: 'Beskrivelse',
    name: 'Navn',
    created: 'Avdeling opprettet',
    updated: 'Avdeling oppdatert',
    departmentType: 'Avdelingstype',
    notSet: 'Ikke valgt',
    courier: 'Bud',
    express: 'Ekspress',
    hd: 'Hjemlevering',
    other: 'Annet',
    all: 'Alle',
    terminalName: 'Terminalnavn',
    terminalNameTooltip: 'Fylles ut dersom avdelingsnavn ikke er det samme som navn på hentested',
    selectArea: 'Velg område...',
    address: 'Adresse',
    zipCode: 'Postnummer',
    zipArea: 'Poststed',
    orderBillingType: {
      title: 'Ordretype for fakturering',
      selectBillingType: 'Velg ordretype',
      customerBillingNumber: 'Kundens faktureringsnummer',
      addMoreOrderBillingInfo: 'Legg til ny ordretype'
    },
    departmentId: 'Avdelings Id',
    settingsInGlow: 'Innstillinger for mottagers sporingsside',
    settingsBodyText: 'Forskjellig innhold og kommunikasjon vil bli vist/sendt til mottakerene, basert på dine valg.',
    disableSms: 'Deaktiver sporings-SMS på rute',
    smsShouldNotBeSent: 'Sporings-SMS skal ikke sendes til mottakere på denne avdelingens ruter.',
    autoRecurrentTime: 'Automatisk gjentagende tid',
    autoInvoiceTitle: 'Automatisk fakturering',
    autoInvoiceEnable: 'Sett automatisk fakturering',
    autoInvoiceTime: 'Tidspunkt',
    autoInvoiceOffset: {
      legend: 'Ordre som skal skal faktureres',
      oneDayEarlier: 'Levert 1 dag siden',
      twoDaysEarlier: 'Levert 2 dager siden',
      threeDaysEarlier: 'Levert 3 dager siden',
      fourDaysEarlier: 'Levert 4 dager siden',
      fiveDaysEarlier: 'Levert 5 dager siden'
    }
  },
  departmentGroup: {
    departmentGroup: 'Avdelingsgruppe',
    name: 'Navn',
    deleteConfirm: 'Er du sikker på at du vil slette avdelingsgruppen?',
    edit: 'Rediger avdelingsgruppe'
  },
  menu: {
    defaultDisabled: 'Velg en kunde for å aktivere dette menypunktet',
    home: 'Hjem',
    admin: 'Administrasjon',
    planner: 'Planlegger',
    report: 'Rapporter',
    customer: 'Kunde',
    apiOrder: 'Finn API-ordre',
    priceMotor: 'Prismotor'
  },
  eventsList: { timeWindow: 'Tidsvindu', from: 'Fra', to: 'Til' },
  consignmentEvent: {
    message: {
      comment: 'Kommentar',
      created: 'Registrert',
      completed: 'Fullført',
      assigned: 'Tildelt sjåfør',
      plannedOnRoute: 'Planlagt på rute',
      plannedOnRouteMoreInfo: 'Planlagt på rute {{routeName}} ({{unitName}})',
      delivered: 'Levert',
      collected: 'Hentet',
      returned: 'Returnert',
      deviated: 'Avvik',
      damaged: 'Skadet',
      notification: 'Melding',
      slotChanged: 'Slot endret',
      unassigned: 'Ikke tildelt sjåfør',
      rejected: 'Avvist',
      image: 'Bilde',
      error: 'Feil',
      started: 'Startet',
      stopped: 'Stoppet',
      accepted: 'Akseptert',
      offered: 'Tilbudt',
      unitName: 'Enhet',
      driverName: 'Sjåfør',
      'delivery-id-check': 'ID-sjekk',
      'delivered-manually-overridden': 'Levert manuelt overstyrt',
      'deviated-manually-overridden': 'Avvik manuelt overstyrt',
      'collected-manually-overridden': 'Opphenting manuelt endret',
      'pickup-location-changed': 'Henteadresse endret',
      'delivery-time-estimated': 'Oppdatert leveringstid',
      'slot-manually-changed': 'Slot manuelt overstyrt',
      'pickup-time-changed': 'Opphentingstid endret',
      'manually-change-location': 'Manuell overstyring av lokasjoner',
      'pickup-consignment-data-changed': 'Henteinformasjon har blitt oppdatert',
      'delivery-consignment-data-changed': 'Leveringsinformasjon har blitt oppdatert',
      'specification-size-data-changed': 'Størrelse oppdatert',
      'specification-description-data-changed': 'Detaljer oppdatert',
      'delivery-time-purchased': 'Tilpasset tidsvindu bestilt av mottaker',
      'digital-identity-verified': 'Mottakerens identitet bekreftet digitalt',
      'digital-identity-verification-failed': 'Kunne ikke verifisere mottakerens identitet digitalt',
      'flex-delivery-ordered': 'Forenklet leveranse bestilt av mottaker',
      'flex-delivery-ordered-by-sender': 'Forenklet leveranse bestilt av avsender',
      'flex-delivery-changed': 'Forenklet leveranse endret',
      'flex-delivery-confirmation-sms-sent': 'Forenklet leveransebekreftelse SMS sendt',
      'return-to-pickup-point-delivery-deviated': 'Vil overleveres på utleveringssted',
      recipientName: 'Mottakernavn',
      receivedBy: 'Mottatt av',
      'reject-order-correction': 'Avvist ordre-korreksjon',
      'accept-order-correction': 'Akseptert ordre-korreksjon',
      'handed-over-to-other-unit': 'Overlevert til annen enhet',
      deliveredWithoutSignature: 'Levert uten signatur',
      deliveredWithoutSignatureReason: {
        left_package_outside: 'Plassert som instruert',
        did_not_get_signature: 'Personlig'
      },
      'returned-manually-overridden': 'Manuell endring av retur',
      'eta-sms-sent': 'ETA SMS sendt',
      calculatedEta: 'Beregnet ankomst: {{eta}}',
      etaSmsLabel: 'Beregnet ankomst',
      scanned: 'Ankommet terminal',
      'arrived-at-terminal': 'Ankommet distribusjonsterminal',
      'order-sorted': 'Sortert',
      'not-arrived-at-distributing-terminal': 'Ikke ankommet distribusjonsterminal',
      'pickup-eta-sms-sent': 'Pickup ETA SMS sendt',
      'handed-in-at-posten-terminal': 'Ankommet terminal',
      'pickup-sms-sent': 'Sporings-SMS sendt',
      'pre-pickup-sms-sent': 'Sporings-SMS forhåndssendt',
      'pickup-address-unresolved': 'Opphentingadresse ble gjort ufullstendig',
      'delivery-address-unresolved': 'Leveringsadresse ble gjort ufullstendig',
      'in-transit': 'I transitt',
      'upcoming-pickup-sms-sent': 'Nærstående leveranse SMS sendt',
      'order-sorted-same-day': 'Dato satt til i dag via terminal app',
      'signature-sms-sent': 'Signatur-SMS sendt',
      'flex-delivery-cancelled': 'Simplified delivery avbestilt',
      'delivery-time-ordered': 'Leveringsdato endret av mottaker',
      'delivery-time-ordered-internal': 'Leveringsdato endret',
      'delivery-time-updated-from-hf-sync': 'Leveringstid synkronisert fra HF',
      'planned-delivery-date-ordered': 'Planlagt leveringsdato bestilt',
      'delay-sms-sent': 'SMS om forsinkelse sendt',
      'manual-delay-sms-sent': 'Manuell forsinkelse SMS sendt',
      'route-planning-complete': 'Rute ferdig',
      'signature-delivered': 'Signatur fra mottaker',
      showPicture: 'Vis bilde',
      'external-address-wash': 'Leveringsinformasjon har blitt oppdatert',
      'order-note-changed': 'Notat endret',
      'order-data-changed': 'Ordre endret',
      'order-moved-to-department': 'Flyttet mellom avdelinger',
      'order-moved-to-department-with-data': 'Sendingen er flyttet fra',
      'package-measurements-changed': 'Pakkestørrelse endret',
      'driver-deviated-from-route': 'Sjåføren avvek fra ruta',
      'delivered-outside-service-time-window': 'Levering eller avvik utenfor tjenesten sitt tidsvindu',
      'delivered-outside-estimated-time-window': 'Levering eller avvik utenfor det estimerte tidsvinduet',
      'seen-by-recipient': 'Sporingsside sett av mottaker',
      'first-estimated-times-seen': 'Første estimerte tid sett av mottaker',
      'pre-advice-push-sms-sent': 'Preadvice PUSH-SMS sendt',
      'pre-advice-push-return-sms-sent': 'Preadvice PUSH-RETURN-SMS sendt',
      'pre-advice-pull-sms-sent': 'Preadvice PULL-SMS sendt',
      'pre-advice-pull-reminder-sms-sent': 'Preadvice PULL påminnelse til mottaker sendt',
      'pre-advice-pup-sms-sent': 'Preadvice PUP-SMS sendt',
      'pre-advice-return-sms-sent': 'Preadvice Return-SMS sendt',
      'pre-advice-return-email-sent': 'Preadvice Return-email sendt',
      'delivery-location-deviation': 'Levering eller avvik ikke på forventet sted',
      'pre-advice-pull-reminder-to-sender-email-sent': 'Preadvice PULL påminnelse til avsender sendt',
      'pre-advice-pup-reminder-to-sender-email-sent': 'Preadvice PUP påminnelse til avsender sendt',
      'pre-advice-return-return-to-sender-email-sent': 'Preadvice RETURN return til avsender sendt',
      'pre-advice-pull-return-to-sender-email-sent': 'Preadvice PULL return til avsender epost sendt',
      'pre-advice-manual-attempt': 'Manuelt preadvice-forsøk',
      'pre-advice-manual-request': 'Manuell preadvice startet',
      'pre-advice-status-changed': 'Aviseringstatus endret',
      'pre-advice-active-return-to-customer-email-sent': 'Preadvice aktiv retur til kunde, epost sendt',
      'pre-advice-active-return-not-picked-up-email-sent': 'Preadvice aktiv retur ble ikke hentet, epost sendt',
      'pre-advice-passive-return-to-customer-email-sent': 'Preadvice passiv retur til kunde, epost sendt',
      'reported-damaged': 'Meldt skade',
      'reported-missing-article': 'Rapportert manglende produkt',
      'delivery-sms-sent': 'Leverings SMS sendt',
      'parcelbox-allocated': 'Reservert plass i pakkeboks',
      'apartment-information-updated': 'Leilighetsinformasjon oppdatert',
      'claim-registered-email-sent': 'E-post om skadede- eller manglende varer sendt',
      'invoicing-failed': 'Fakturering feilet',
      'invoiced-ok': 'Fakturering OK',
      'parcel-locker-tracking-sms-sent': 'Sporings-SMS for pakkeboks-leveranse sendt',
      serviceUpgradePurchasedDescription: 'Innbæring bestilt av mottaker',
      'preliminary-loaded': 'Foreløpig lastet',
      'manual-price-override': 'Pris overstyrt',
      'incomplete-price-approved': 'Ufullstendig pris godkjent',
      'incomplete-price-unapproved': 'Godkjenning av ufullstendig pris trukket tilbake'
    },
    rejectedReason: {
      reason: 'Årsak',
      private: 'Private årsaker',
      notEnoughTime: 'Rekker ikke stoppet i tide',
      notEnoughSpaceOnVehicle: 'Forsendelse får ikke plass på bil',
      other: 'Andre årsaker'
    },
    idCheck: {
      idCheckFailed: 'ID-sjekk feilet, men ble levert med {{idCheckFailedResult}}',
      idCheckSummaryOther: 'Annet ({{otherType}}), fødselsdato {{birthDate}}',
      idCheckSummary: '{{idCheckType}}, fødselsdato {{birthDate}}'
    },
    damaged: {
      damageType: 'Type skade',
      causeCode: 'Årsakskode',
      courierInput: 'Melding fra sjåfør',
      showPictureLink: 'Vis bilde {{idx}}',
      type: { A: 'Skade på eske/emballasje', D: 'Trykk/klemskade', E: 'Totalskadet' }
    },
    invoiceInitiationType: {
      automatic: 'Planlagt fakturering',
      'manual-department-level': 'Manuell avdelingsfakturering',
      'manual-order-level': 'Manuell ordrefakturering'
    },
    changedFrom: 'Endret fra -',
    name: 'Navn',
    deliveryOrderedByReceiver: 'Levering til pakkeboks valgt av mottaker'
  },
  planner: {
    chooseYourDepartment: 'Velg din avdeling',
    unassignedShipments: 'Uanviste forsendelser',
    filterCustomers: 'Filtrer kunder',
    filterServices: 'Filtrer tjenester',
    autoRoute: 'Automatisk rute',
    saved: 'Lagret',
    allUserRoles: 'Alle brukerroller',
    userRolesCount_plural: '{{count}} brukerroller',
    createNewUser: 'Opprett ny bruker',
    userRolesCount: '{{count}} brukerrolle',
    role: 'Rolle',
    searchForNameOrNumber: 'Søk etter navn eller telefonnummer',
    noPlannersFound: 'Ingen planleggere funnet',
    typeOfRole: 'Type brukerrolle',
    selectUserRole: 'Velg brukerrolle',
    users: 'Brukere',
    connectDepartment: 'Koble avdeling til bruker',
    banner: {
      tryNewRoutePlanner:
        'Fra 1. mai vil <0>ruteplanlegger 2.0</0> være den eneste tilgjengelige ruteplanleggeren. Hvis du ikke har brukt ruteplanleggeren før, oppfordrer vi deg til å begynne å bruke den fra nå av. <1>Les mer på Zendesk</1> og andre relaterte artikler for å få en oversikt over hvordan den fungerer.'
    },
    totalTitle: 'Kolli',
    pickList: 'Plukkliste',
    export: 'Eksporter for fakturering',
    deleteSlot: 'Slett',
    editSlot: 'Rediger',
    deleteSlotConfirmDialogText: 'Er du sikker du vil slette denne slotten?',
    deleteFilteredSlots: 'Slett alle ruter',
    deleteAllSlotsConfirmDialogText: 'Er du sikker på at du vil slette alle rutene?',
    created: 'Planlegger opprettet',
    updated: 'Planlegger oppdatert',
    deleted: 'Planlegger har blitt slettet',
    removeDepartment: 'Fjern avdeling {{name}}',
    connectAirlineTerminal: 'Koble flyplassterminal(er) til bruker',
    connectedAirlineTerminals: 'Tilkoblede flyplassterminaler ({{count}})',
    noConnectedTerminals: 'Ingen tilkoblede terminaler ennå',
    removeTerminal: 'Fjern terminal {{name}}',
    searchTerminals: 'Søk etter flyplassterminaler',
    youMustSelectTerminal: 'Du må velge flyplassterminal',
    confirmDelete: 'Er du sikker på at du vil slette denne brukeren?',
    youMustProvideName: 'Du må oppgi brukerens navn',
    youMustProvidePhonenumber: 'Du må oppgi telefonnummer',
    routePlanning: 'Ruteplanlegging',
    routePlanningConsignmentsAssigned: 'tildelt',
    routePlanningRangeDescription: 'Filtrer pakker basert på leveringstidspunkt',
    unresolvedConsignments: 'Ufullstendige',
    consignments: 'Leveranser',
    couriers: 'Sjåfører',
    units: 'Enheter',
    invoicing: 'Fakturering',
    planner: 'Planlegger',
    planners: 'Planleggere',
    noPlanners: 'Ingen planleggere',
    missingId: 'Id mangler',
    missingName: 'Navn mangler',
    search: 'Søk',
    searchForPlanner: 'Søk etter planleggere',
    name: 'Navn',
    phoneNumber: 'Telefonnummer',
    selectDepartments: 'Velg avdeling(er)',
    createSlot: 'Opprett slot',
    editSlotForm: 'Rediger slot',
    routeUpdated: 'Ruten har blitt oppdatert',
    createSlotFormName: 'Navn',
    createSlotFormUnit: 'Enhet',
    createSlotFormCourier: 'Sjåfør',
    createSlotFormSlotMode: 'Modus',
    createSlotFormSlotModePickup: 'Tegn hentingsområde i kart',
    createSlotFormSlotModeDelivery: 'Tegn leveringsområde i kart',
    createSlotFormSlotModeManual: 'Velg pakker manuelt',
    createSlotFormSlotModeSelected: 'Velg pakker',
    createSlotFormTimeRange: 'Tid',
    createSlotFormStartLocation: 'Start-lokasjon',
    createSlotFormEndTime: 'Sluttid',
    createSlotFormEndLocation: 'Slutt-lokasjon',
    createSlotFormSlotColor: 'Farge',
    createSlotFormTimeRangeTooBig: 'For stort tidsspenn. Maks er {{hours}} timer.',
    createSlotFormFilterCustomers: 'Filtrer kunder',
    createSlotFormFilterServices: 'Filtrer tjenester (ingen valgt betyr alle)',
    slotFilterInfo:
      'Denne ruten er filtrert, dvs at kun ordre som tilhører valgte kunde(r) og valgte tjeneste(r) vil kunne knyttes til ruten.',
    slotFilteredCustomer: 'Ruten inkluderer KUN ordre fra følgende kunder',
    slotFilteredService: 'Ruten inkluderer KUN ordre med følgende tjenester',
    onlyShowApplicable: 'Vis kun tildelte og gyldige for ruten basert på kunde-/tjenestefiltrering',
    optimizeButtonLabel: 'Optimaliser og tildel ruter',
    optimizeSlotButtonLabel: 'Re-optimaliser slot',
    reversedOptimizeSlotButtonLabel: 'Reverser leveringsrekkefølgen',
    optimizingSince: 'Optimalisering pågår, startet kl. {{startTime}}',
    alterSlotIdOnConsignment: 'Er du helt sikker på at du vil endre rute for denne leveransen?',
    addToRoute: 'Legg til rute...',
    pickup: 'Opphenting',
    delivery: 'Levering',
    notAssigned: 'Ikke tildelt',
    readOnly: 'Kun lesetilgang',
    plus: 'Ekstra rettigheter',
    readOnlyAccessPlannersWithCount: '{{count}} trafikkplanleggere med lese-tilgang',
    plusPlannersWithCount: '{{count}} trafikkplanleggere med ekstra rettigheter',
    normalPlannersWithCount: '{{count}} trafikkplanleggere med normale rettigheter',
    saveTemplate: 'Lagre som mal',
    loadTemplate: 'Hent fra mal',
    routeListForFilteredSlots: 'Kvitteringsliste (print alle)',
    loadListForFilteredSlots: 'Lasteliste (print alle)',
    sendPrePickupSmsesToAll: 'Forhåndssend sporings-sms',
    alertBeforeSendingPrePickupSms: 'Er du sikker på at du vil forhåndssende sporings-SMS for alle ruter for i dag?',
    createTemplateFormName: 'Navn på malen',
    createOrUpdateTemplateDescription:
      'For å kunne lagre må du ENTEN skrive ett unikt navn på en ny mal eller velge å oppdatere en eksisterende mal nedenfor',
    newTemplate: 'Lag en ny mal',
    overwriteTemplate: 'Oppdater mal',
    templateSaved: 'Malen ble lagret',
    updateTemplateDescription: 'Velg eksisterende mal for å oppdatere: ',
    updateTemplateConfirm: 'Er du sikker på at du vil oppdatere malen?',
    selectOrDeleteTemplateDescription: 'Her kan du velge å bruke en mal, eller velge en mal og deretter slette den',
    selectTemplate: 'Velg mal',
    deleteTemplate: 'Slett',
    selectTemplateDescription: 'Velg mal: ',
    templateSelected: 'Mal har blitt valgt',
    templateDeleted: 'Malen er nå slettet',
    templateDeleteConfirm: 'Er du sikker på at du vil slette malen?',
    missingDeliveryMarker: 'Ikke en gyldig leveringsadresse eller lokasjon',
    missingPickupMarker: 'Ikke en gyldig henteadresse eller lokasjon',
    unassignConsignmentFromSlot: 'Fjern leveransen fra tildelt slot',
    unassignBulkConsignmentsFromSlotsAlert: 'Ønsker du å fjerne sendingene fra sine respektive ruter?',
    unassignedFromSlot: 'Leveransen har nå blitt fjernet fra tilhørende slot',
    unassignBulkConsignmentsFromSlots: 'Sendingene har nå blitt fjernet fra sine respektive ruter',
    unassignTitle: 'Fjern leveransen fra tilhørende slot',
    unassignButton: 'Fjern fra slot',
    forceUnassignButton: 'Tving fjerning fra slot',
    moveOrder: 'Er du sikker på at du vil flytte denne leveransen?',
    driverName: 'Sjåfør',
    filters: { assigned: 'Tildelt', unassigned: 'Ikke tildelt', pickups: 'Opphentinger', deliveries: 'Leveringer' },
    saveSelected: 'Lagre valgte ({{count}})',
    showSaved: 'Vis lagrede ({{count}})',
    showAll: 'Vis alle',
    clearSaved: 'Fjern lagrede',
    sendPrePickupSms: 'Forhåndssend sporings-SMS til mottaker',
    sendingSmsText: 'Sendinger {{count}} sporings-SMS',
    prePickupSmsSent: '{{sentCount}} av {{totalCount}} sporings-SMS sendt',
    manualDelaySms: {
      smsSent: '{{count}} manuelle forsinkelse SMS sendt',
      heading: 'Manuell forsinkelse SMS',
      sendingSmsText: 'Sender {{count}} manuelle forsinkelse SMS-er',
      selectSmsDescription: 'SMS-en vil starte med "Hei, vi er dessverre forsinket". Velg SMS-avslutning nedenfor',
      selectSmsEnding: 'Velg SMS-avslutning',
      endingType: {
        none: 'Ingen',
        soon: '"Vi kommer til å levere så raskt som mulig"',
        tomorrow: '"Vi forsøker å levere neste virkedag"'
      },
      disabledTooltip: 'Ordrer kan ikke være i tilstanden Levert'
    },
    h2: { vasHelperText: 'Bruk VAS 1158 for MBH' },
    loadList: {
      header: 'Ruteoppsummering',
      listName: 'Lasteliste',
      unknownRouteName: 'Ukjent rutenavn',
      unknownUnitName: 'Ukjent enhetsnavn',
      noDataText: 'Ingenting å se her'
    },
    locationList: { listName: 'Lokasjonsliste' },
    assignedUnits: 'Tildelte enheter',
    unassignedUnits: 'Utildelte enheter',
    noUnitSelected: 'Ingen enhet valgt',
    unitAssignmentCount: 'Enhet tilordnet til {{count}} ruter',
    breakDeletedFromRoute: 'Pausetiden ble fjernet fra rute'
  },
  plannerDashboard: {
    vehicleTypes: {
      car: 'Bil',
      bicycle: 'Sykkel',
      truck: 'Lastebil',
      van: 'Varebil',
      motorcycle: 'Motorsykkel',
      cargobike: 'Lastesykkel'
    }
  },
  updateServiceLevel: {
    menuItem: 'Endre servicenivå',
    selectOption: 'Velg servicenivå',
    heading: 'Endre servicenivå',
    confirmation: 'Servicenivå oppdatert',
    subtext: 'Endre servicenivå for {{count}} sending',
    subtext_plural: 'Endre servicenivå for {{count}} sendinger’',
    failed: {
      title: 'Kunne ikke endre servicenivå',
      message: 'Kunne ikke endre servicenivå for {{count}} sending',
      message_plural: 'Kunne ikke endre servicenivå for {{count}} sendinger'
    }
  },
  manualPreAdvice: {
    menuItem: 'Manuell preavisering',
    selectOption: 'Velg preaviseringstype',
    heading: 'Manuell preavisering',
    confirmation: 'Preavisering(er) opprettet',
    subtext: 'Manuell preavisering av {{count}} sending',
    subtext_plural: 'Manuell preavisering av {{count}} sendinger',
    failed: {
      title: 'Manuell preavisering feilet',
      message: 'Noe gikk galt ved preavisering av {{count}} sending',
      message_plural: 'Noe gikk galt ved preavisering av {{count}} sendinger'
    }
  },
  setPickupDeliveryDuration: {
    menuItem: 'Angi varighet for henting/levering',
    heading: 'Angi varighet for henting og levering',
    subtext: 'Angi varighet for henting og levering på {{count}} forsendelse',
    subtext_plural: 'Angi varighet for henting og levering på {{count}} forsendelser',
    pickupDuration: 'Varighet henting',
    deliveryDuration: 'Varighet levering',
    confirmation: 'Varighet oppdatert',
    tooltipTitle: 'Når du oppgir varigheter, er det maksimale antallet minutter og sekunder 59'
  },
  importExportOrders: {
    importExportOrder: 'Import / Eksport',
    importSuccess: 'Alle bestillinger i regnearket legges til eller oppdateres i systemet',
    importSuccessTitle: 'Suksess',
    importOrdersHeading: 'Importer ordre',
    importStatus: 'Det er for øyeblikket {{imports}} importer i kø med totalt {{orders}} ordre',
    exportForInvoice: 'Eksport for fakturering',
    fileTemplateLink: 'Last ned Excel-mal',
    uploadFile: 'Importer ordre',
    uploadingMessage: 'Laster opp filen og importerer bestillingene...',
    needToSelectFile: 'Du må velge en fil',
    exportSuccess: 'Alle bestillinger i den valgte datoperioden er lastet ned til regnearket',
    exportSuccessTitle: 'Suksess',
    testDataWarning: 'Ikke bruk personopplysninger i testmiljø',
    testDataInfo:
      'Dette er et qa/testmiljø, og du har ikke lov til å bruke produksjonsdata i test uten berørte brukeres eksplisitte samtykke.',
    fromDate: 'Fra dato',
    toDate: 'Til dato',
    fromDateNotAfterToDate: "Kan ikke være senere enn 'Til dato'",
    toDateNotBeforeFromDate: "Kan ikke være tidligere enn 'Fra dato'",
    removeControlColumns: 'Fjern kontrollkolonner',
    importResults: 'Importer resultater',
    fileName: 'Importert fil',
    processedAt: 'Behandlet',
    createdShipmentId: 'Opprettet forsendelses-ID',
    date: 'Dato',
    exportShipments: 'Eksporter forsendelser',
    createLabels: 'Opprett etiketter',
    selectSlot: 'Velg rute',
    selectExportLabel: 'Velg hva du vil eksportere',
    allOrders: 'Alle Ordre',
    deviatedOrders: 'Ordre med avvik',
    all: 'Alle',
    noSelection: 'Ingen valg',
    selectFile: 'Velg fil',
    noFileSelected: 'Ingen fil',
    updateSelectedFile: 'Oppdater fil',
    jobList: {
      title: 'Tidligere eksporter',
      content: 'Innhold',
      created: 'Opprettet',
      user: 'Bruker',
      dates: 'Datoer',
      download: 'Last ned',
      exporting: 'Eksporterer...',
      inQueue: 'I eksportkø',
      exportFailed: 'Eksporten mislyktes',
      updated: 'Oppdatert',
      previousImports: 'Tidligere import',
      fileName: 'Filnavn',
      numberOfOrders: 'Antall ordre',
      imported: 'Importert',
      viewOrders: 'Vis ordre',
      lastImports: 'Viser de siste {{count}} importene'
    },
    exportStartedNotification: 'Eksporten startet',
    exportStartedNotificationMessage: 'Rapporten din vil være tilgjengelig så snart den er behandlet.',
    exportFailedNotification: 'Eksporten mislyktes. Prøv igjen senere',
    refreshData: 'Oppdater data i eksisterende rapport',
    refreshDataDescription:
      'Velg dette alternativet hvis du allerede har opprettet en eksisterende rapport for de spesifikke datoene',
    types: {
      SHIPMENT_EXPORT: 'Forsendelser',
      AIREXPRESS_SHIPMENTS: 'Forsendelser (med AirExpress-data)',
      COURIER_WORK_REPORT: 'Arbeidsrapport for sjåfører',
      COURIER_WORK_REPORT_EXTENDED: 'Utvidet arbeidsrapport for sjåfører',
      SOCIAL_CONTROL: 'Sosialkontroll',
      LOCATION_LIST: 'Lokasjonsliste',
      ORDER_EXPORT: 'Eksport av ordre',
      ORDER_DEVIATION_EXPORT: 'Ordre (Avvik)',
      ORDER_EXPORT_ANONYMOUS: 'Ordre (Anonymisert Data)',
      ORDER_PARTNER_EXPORT: 'Ordre (For Partnere)',
      DEVIATION_EVENTS: 'Avvikshendelser'
    },
    exportTitles: {
      SHIPMENT_EXPORT: 'Eksporter forsendelser',
      AIREXPRESS_SHIPMENTS: 'Eksporter forsendelser (med AirExpress-data)',
      COURIER_WORK_REPORT: 'Arbeidsrapport for sjåfører',
      COURIER_WORK_REPORT_EXTENDED: 'Utvidet arbeidsrapport for sjåfører',
      SOCIAL_CONTROL: 'Eksporter Sosialkontroll',
      LOCATION_LIST: 'Eksporter lokasjonsliste',
      ORDER_EXPORT: 'Eksporter ordre',
      ORDER_DEVIATION_EXPORT: 'Eksporter Ordre med avvik',
      ORDER_EXPORT_ANONYMOUS: 'Eksporter Ordre Anonymisert',
      ORDER_PARTNER_EXPORT: 'Eksporter Ordre for partnere',
      DEVIATION_EVENTS: 'Eksporter avvikshendelser'
    },
    exportDescriptions: {
      AIREXPRESS_SHIPMENTS: '',
      ORDER_EXPORT:
        'Hvis du ønsker å bruke data i testmiljøer, anbefaler vi å bruke alternativet "Ordre (anonymiserte data)"',
      ORDER_DEVIATION_EXPORT:
        'Hvis du ønsker å bruke data i testmiljøer, anbefaler vi å bruke alternativet "Ordre (anonymiserte data)"',
      ORDER_EXPORT_ANONYMOUS: 'Kan brukes hvis data skal importeres til et testmiljø',
      ORDER_PARTNER_EXPORT: 'Tilpasset excelfil som kan brukes for import i Opter'
    }
  },
  unit: {
    unitWithCount: '{{count}} enhet',
    unitWithCount_plural: '{{count}} enheter',
    new: 'Ny enhet',
    edit: 'Endre enhet',
    addCourier: 'Legg til sjåfør',
    hideAddCourier: 'Skjul legg til sjåfør',
    requiresCourierUsers: 'Må ha minst én sjåfør',
    created: 'Enheten har blitt opprettet',
    updated: 'Enheten har blitt oppdatert',
    search: 'Søk etter enheter fra andre avdelinger...',
    unitsInCurrentDepartment: 'Enheter i nåværende avdeling',
    unitsInOtherDepartments: 'Enheter i andre avdelinger',
    saveUnit: 'Lagre enhet',
    addCourierToUnit: 'Legg sjåfør til enhet'
  },
  courier: {
    isActive: 'Er aktiv',
    canLogIn: 'Kan logge inn',
    recipientName: 'Mottakernavn',
    created: 'Sjåføren har blitt opprettet',
    updated: 'Sjåføren har blitt oppdatert',
    courier: 'Sjåfør',
    couriers: 'Sjåfører',
    noCouriers: 'Ingen sjåfører',
    courierWithCount: '{{count}} sjåfør',
    courierWithCount_plural: '{{count}} sjåfører',
    numberOfCouriers: 'Fant {{number}} sjåfører',
    multipleDrivers: 'Flere transportører',
    missingId: 'Id mangler',
    missingName: 'Navn mangler',
    search: 'Søk etter sjåfører',
    new: 'Ny sjåfør',
    edit: 'Endre sjåfør',
    name: 'Navn',
    phoneNumber: 'Telefonnummer',
    driverId: 'Sjåfør-ID',
    capacity: 'Kapasitet',
    weightCapacity: 'Vektkapasitet (kg)',
    volumeCapacity: 'Volumkapasitet (m³)',
    maxRangeKm: 'Rekkevidde (km)',
    color: 'Farge',
    resetColor: 'tilbakestill farge',
    speedFactor: 'Hastighetsfaktor',
    speedFactorExplanation: 'lavere = tregere',
    serviceTimeFactor: 'Opphentings- og leveransehastighetsfaktor',
    serviceTimeFactorExplanation: 'lavere = raskere',
    defaultStopLocation: 'Sluttlokasjon',
    defaultStartLocation: 'Startlokasjon',
    slotNumDeliveries: '{{count}} levering',
    slotNumDeliveries_plural: '{{count}} leveringer',
    slotNumPickups: '{{count}} opphenting',
    slotNumPickups_plural: '{{count}} opphentinger',
    deliverPerformButtonLabel: 'Levér',
    handoverPerformButtonLabel: 'Overlever',
    deviationPerformButtonLabel: 'Avvik',
    deliverWithoutSignatureButtonLabel: 'Uten signatur',
    deliverWithSignatureButtonLabel: 'Signatur',
    deviateNotAtHomeButtonLabel: 'Ikke hjemme',
    deviateLocNotFoundButtonLabel: 'Fant ikke destinasjonen',
    deviateRecipientDeclinedButtonLabel: 'Mottaker ville ikke ha den',
    deviateTimeWindow: 'Fikk ikke mulighet å levere innen tidsvinduet',
    addressNotFound: 'Adresse ikke funnet',
    deliverySpotUnavailable: 'Avtalt leveringssted utilgjengelig',
    noAvailableHatchInParcelBoxMachine: 'Ingen ledig luke',
    packageDoesNotFitInParcelBox: 'Pakken over tillat mål/vekt',
    unknownErrorForParcelBoxDelivery: 'Annet Ukjent',
    errorInParcelBoxMachine: 'Automaten er ute av drift',
    deviation: {
      'H-12': 'Sendingen er forsøkt levert, men mottaker var ikke tilstede',
      'H-11': 'Pakken ble forsøkt levert, men mottakeren ble ikke funnet på stedet',
      'H-10': 'Pakken ble forsøkt levert, men adressen ble ikke funnet',
      'H-13': 'Sendingen er forsøkt levert, men er nektet mottatt',
      '7-10': 'Leveransen kunne ikke plukkes opp pga ufullstending adresse',
      '7-12': 'Avsenderen var ikke tilgjengelig. Leveransen har ikke blitt hentet',
      '7-82': 'Leveransen var ikke klar for å bli plukket opp',
      'V-41-K': 'Sendingen er forsinket, den vil bli videresendt så raskt det lar seg gjøre',
      'H-07-R': 'Mislykket sosialkontroll',
      'H-07-Z': 'Mislykket sosialkontroll',
      'H-08-Z': 'Mislykket alderskontroll',
      'H-09-Z': 'Mislykket ID-kontroll',
      'VI-16-X': 'Fikk ikke mulighet å levere innen tidsvinduet',
      'H-11-Z': 'Adresse ikke funnet',
      'H-BL-A': 'Avtalt leveringssted utilgjengelig',
      'V-100-CA': 'Det var ingen ledig pakkeboks i nærheten av mottaker. Pakken blir levert hjem til mottaker',
      'V-100-CI': 'Ingen ledig luke',
      'V-80-CI': 'Pakken over tillat mål/vekt',
      'V-99-CI': 'Annet Ukjent',
      'V-AO-CI': 'Automaten er ute av drift'
    },
    completionProgressCounter: '{{numCompleted}}/{{numTotal}} ferdige',
    lockCode: 'Dørkode',
    flexDelivery: 'Forenklet levering',
    fuelType: 'Drivstofftype',
    fuelTypes: {
      gasoline: 'Bensin',
      natural_gas: 'Naturgass',
      ethanol: 'Bioetanol',
      bio_gas: 'Biogass',
      electric: 'Elektrisitet',
      bicycle: 'Sykkel'
    },
    fuelCategory: 'Drivstoffkategori',
    fuelCategories: {
      fossil: 'Fossilt drivstoff',
      fossil_free: 'Fossilfritt drivstoff',
      zero_emissions: 'Null utslipp'
    },
    latestLogin: 'Siste pålogging',
    latestVersion: 'Siste versjon',
    failedSocialControl: 'Mislykket sosialkontroll',
    failedIdControl: 'Mislykket ID-kontroll',
    failedAgeControl: 'Mislykket alderskontroll',
    incompletePickupAddress: 'Leveransen kunne ikke plukkes opp pga ufullstending adresse.',
    custNotAvailableAtPickupAddress: 'Avsenderen var ikke tilgjengelig. Leveransen har ikke blitt hentet.',
    goodsNotReadyAtPickupForCust: 'Leveransen var ikke klar for å bli plukket opp.',
    shipmentNotAvailableAtTerminal: 'Sendingen er forsinket, den vil bli videresendt så raskt det lar seg gjøre.',
    registrationNumber: 'Registreringsnummer',
    commercialTrafficEligibility: 'Kommersiell trafikk berettigelse',
    vehicleBrand: 'Kjøretøymerke',
    vehicleModel: 'Kjøretøymodell',
    vehicleControl: 'Kjøretøykontroll',
    vehicleControlComment: 'Kommentar om kjøretøykontroll',
    euroClass: 'Euroklasse',
    euroClassTypes: { euro5: 'Euroklasse 5', euro6: 'Euroklasse 6' },
    alcoholDeliveryEducation: 'Alkoholleveringsutdanning',
    approved: 'Godkjent',
    notApproved: 'Ikke godkjent',
    couriersInCurrentDepartment: 'Sjåfører i nåværende avdeling',
    couriersInOtherDepartments: 'Sjåfører i andre avdelinger',
    inCurrentDepartment: 'Nåværende avdeling',
    inOtherDepartments: 'Andre avdelinger',
    noCouriersExplain: 'Vi fant ingen sjåfører. Vennligst forsøk igjen med å søke på noe annet',
    confirmConnect: 'Koble sjåføren til nåværende avdeling',
    confirmModalDescription: '',
    searchForCouriers: 'Søk etter sjåfører',
    dateOfBirthWithFormat: 'Fødselsdato (yyyy-mm-dd)',
    occupationalInjuryInsurance: 'Yrkesskadeforsikring',
    editCourier: 'Rediger Sjåfør:',
    courierUserUpdated: 'Sjåfør har blitt oppdatert.',
    courierScanEnabled: 'Skann',
    breakTime: 'Pausetid',
    breakDuration: 'Pauselengde (min)',
    timeWindow: 'Pausestart (tidligst og senest)',
    breakTimeDescription:
      'I feltet <i>Pausestart (tidligst og senest)</i> velger du den tidligste og seneste tiden pausen skal starte. F.eks. 11:00-17:00 betyr at det siste sjåføren kan starte pausen er 17:00.',
    between: 'Mellom',
    editBreakTime: 'Endre pausetid',
    updateBreakTime: 'Oppdater pausetid',
    latestBreakStart: 'Senest pausestart',
    radioButtons: { noBreakTime: 'Ingen pausetid', timeWindow: 'Tidsvindu', driveTime: 'Kjøretid' },
    driveTimeDescription:
      'Du setter maksimal kjøretid før sjåføren må ta en pause. For eksempel, etter åtte timer må sjåføren ta en 30 minutters pause.',
    maxDrivingTime: 'Maks kjøretid',
    selectMaxDriveTime: 'Velg maks kjøretid',
    hours: '{{hours}} timer',
    breakTooltip: 'Du kan bare ha én pausetype per enhet'
  },
  slot: {
    minutesDelay: 'minutter forsinket',
    minutesAhead: 'minutter før tiden',
    minutes: 'minutter',
    time: 'Tid',
    created: 'Opprettet',
    assigned: 'Tildelt',
    lock: 'Lås',
    lockDescription:
      'Når slotten er låst, så vil den ikke være en del av nye ruteoptimaliseringer, og kan ikke bli endret på.',
    toggleVisibleSlotDescription: 'Skjul slot.',
    inProgress: 'I behandling',
    onHold: 'På vent',
    complete: 'Fullført',
    unknownState: 'Ukjent tilstand',
    courierSelect: 'Velg sjåfør',
    unitSelect: 'Velg enhet',
    printableSlotList: 'Slot-liste for utskrift',
    followsRoute: 'Følger',
    doesNotFollowRoute: 'Avviker',
    hasLocation: 'Synlig',
    hasNoLocation: 'Ikke synlig',
    showActiveSlots: 'Vis aktive',
    showSlotNotStarted: 'Vis ikke startet',
    showCompletedSlot: 'Vis fullførte',
    showPerfectSlot: 'Vis perfekte',
    filterSlots: 'Filtrer slots',
    planningCompleted: 'Ferdig',
    addMapArea: 'Velg område i kart',
    editMapArea: 'Endre område i kart'
  },
  login: {
    heading: 'Logg Inn',
    phone: 'Tast inn ditt Glow registerte telefonnummer, med landkode (f.eks. +47 ...), og vi sender SMS kode',
    code: 'Vi har sendt deg en SMS kode, dersom ditt nummer er registrert. Denne koden er gyldig i 5 minutter. Tast inn koden.',
    requestNewCode: 'Be om en ny kode.',
    verifyFailed:
      'Kode eller telefonnummer er feil, eller du har forsøkt å logge inn for mange ganger. Prøv igjen, eller be om ny kode.',
    requestFailed: 'Kunne ikke sende kode, prøv igjen..',
    verifyTokenButton: 'Verifiser kode',
    requestTokenButton: 'Be om kode',
    privacyLink: 'Personvernerklæring',
    passwordWrong: 'Feil passord',
    customer: 'Jeg er en kunde',
    forgotPassword: 'Glemt passordet?',
    tryApp: 'Prøv den nye appen!',
    appDownload: 'Last ned sjåfør-appen her og få tilgang til mere funksjonalitet!',
    appInfo:
      'Er du en sjåfør med en Android-telefon? Last ned sjåfør-appen <1>her</1> og få tilgang til mere funksjonalitet!',
    note: 'Merk! Bruk bare webversjonen av budprogrammet hvis du av en eller annen grunn ikke kan bruke appene til iOS og Android',
    resendToken: 'Send kode på nytt',
    submitPassword: 'Send inn passord',
    loginAsCustomer: 'Logg inn som kunde',
    loginAsPlanner: 'Logg inn som planlegger',
    token: 'Kode',
    password: 'Passord',
    tokenRequired: 'Kode kreves',
    forgotPasswordDescription:
      'For å fortsette må du angi et passord. Vi sender deg en e-post med en lenke for å angi dette. Hvis e-postadressen din er endret, må du kontakte en administrator for å endre den.,',
    sendEmailDescription:
      'For å fortsette trenger vi e-posten din. Vi sender deg en bekreftelseslenke til den oppgitte adressen. Med denne lenken kan du angi 2-faktor passord.',
    emailFormatValidation: 'E-post må være i gyldig format,',
    sendEmailSuccess: 'E-posten skal komme innen kort tid',
    sendEmailFailed: 'Sending av e-post mislyktes, prøv igjen,',
    setYourPassword: 'Angi passordet ditt',
    sendEmail: 'Send e-post',
    passwordsDontMatch: 'Passord stemmer ikke',
    passwordNotMeetRequirements: 'Passord oppfyller ikke kravene,',
    passwordRequired: 'Passord er påkrevd,',
    newPassword: 'Nytt passord',
    repeatPassword: 'Gjenta passordet ditt',
    lowercaseLetter: 'En liten bokstav',
    uppercaseLetter: 'En stor bokstav',
    numberCharacter: 'Et tall',
    minimumCharacters: 'Minimum 15 tegn',
    passwordDescription: 'Du kan nå angi passordet ditt, det må inneholde følgende'
  },
  error: {
    '404Title': 'Fant ikke siden',
    '404Message': 'Siden du leter etter er ikke tilgjengelig',
    '500Message': '{{error}} - Beklager, men vi får ikke tak i dine data... prøver igjen om litt..',
    title: 'Noe gikk galt!',
    dateRequiredWhenSecureLogin: '*Obligatorisk felt om BankID er aktivert',
    dateNotValid: 'Ugyldig dato',
    errorMessage: 'Beklager! Dette gikk ikke bra, men utviklerne har fått beskjed om feilen..',
    errorClickToReload: 'Trykk her for å laste siden på nytt',
    phoneNumber: 'Du må angi en gyldig telefonnummer, f.eks. +47 12345678',
    phoneNumberPrefix: 'Du må angi en landkode, f.eks. +47 ...',
    somethingWentWrong: 'Noe gikk galt når vi forsøkte å lagre...',
    notAValidNumber: 'Må være et nummer',
    notAValidAlphaNumeric: 'Må være tall og/eller bokstaver',
    notAValidDate: 'Må være en gyldig dato (yyyy-mm-dd)',
    notAValidTime: 'Må være et gyldig klokkeslett (hh:mm)',
    notAValidDuration: 'Må være en gyldig varighet (mm:ss)',
    notAValidZipCode: 'Må være et gyldig postnummer',
    notAValidTimezone: 'Må være en gyldig tidssone. Prøv "Europe/Oslo" eller lignende',
    somethingWentWrongOnTheServer: 'Noe gikk galt på serveren...',
    somethingWentWrongWithTheRequest: 'Noe gikk galt med operasjonen...',
    somethingWentWrongWithTheResponse: 'Noe gikk galt med operasjonen...',
    selectAtLeastOne: 'Du må velge minst ett av valgene',
    requiredBreakDuration: 'Du må fylle inn pauselengde',
    notAValidTimeWindow: 'Tidligste pausestart må være før seneste',
    requiredEarliestStartBreak: 'Du må fylle inn tidligste pausestart',
    requiredLatestStartBreak: 'Du må fylle inn seneste pausestart',
    requiredIfDriveTimeDuration: 'Du må fylle inn maks kjøretid',
    timeInWrongFormat: 'Tiden er ikke gyldig'
  },
  loading: 'Vi jobber med å finne dine data...',
  newAppVersion: 'Vi har akkurat lagt ut en ny versjon, den lastes nå.',
  loadingReceiptList: 'Kvitteringslister blir generert...',
  invoicing: { departmentInvoicingInProgress: 'Fakturering pågår...' },
  months: {
    january: 'Januar',
    february: 'Februar',
    march: 'Mars',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    october: 'Oktober',
    december: 'Desember'
  },
  weekdays: {
    monday: 'Mandag',
    tuesday: 'Tirsdag',
    wednesday: 'Onsdag',
    thursday: 'Torsdag',
    friday: 'Fredag',
    saturday: 'Lørdag',
    sunday: 'Søndag'
  },
  weekdaysShort: {
    sunday: 'Søn',
    monday: 'Man',
    tuesday: 'Tir',
    wednesday: 'Ons',
    thursday: 'Tor',
    friday: 'Fre',
    saturday: 'Lør'
  },
  maps: { addressLookup: 'Søk etter adresse' },
  notifications: {
    dismiss: 'Lukk',
    dismissAll: 'Lukk alle',
    error: 'Feil',
    reload: 'Last inn på nytt',
    reloadCourier: 'Trykk her for å laste på nytt',
    navigate: 'Gå til siden',
    networkError:
      'Det ser ut til at du har mistet nettverketstilkoblingen, dette kan ha vært midlertidig, men siden må lastes på nytt',
    networkErrorCourier: 'Du har vært uten nettverk, last siden på nytt.'
  },
  grid: {
    columns: {
      department: 'Avdeling',
      deadline: 'Frist',
      failedReason: 'Feilmelding',
      exceptions: 'Unntak',
      dispatchAsGroup: 'Dispatch som gruppe',
      name: 'Navn',
      senderName: 'Avsenders navn',
      delearly: 'LEV',
      dellate: 'LEV',
      zip: 'Postnr',
      estimated: 'Estimert tid',
      adjusted: 'Justert',
      timeWindow: 'Tidsvindu',
      onCar: 'på enhet',
      frequency: 'Frekvens',
      startDate: 'Startdato',
      endDate: 'Sluttdato (valgfritt)',
      from: 'Fra',
      to: 'Til',
      days: 'Dager',
      holidays: 'Helligdager',
      startdate: 'Startdato',
      enddate: 'Sluttdato',
      orders: 'Ordre',
      id: 'ID',
      shipmentId: 'Sendingsnummer',
      packageId: 'Kollinummer',
      errorMessage: 'Feilmelding',
      customer: 'Kunde',
      pickup: 'Opphenting',
      delivery: 'Levering',
      service: 'Tjeneste',
      address: 'Adresse',
      zipcode: 'Postnummer',
      area: 'By',
      weight: 'Vekt',
      volume: 'Volum{{unit}}',
      qty: 'Ant',
      preplanned: 'Planlagt',
      group: 'Gruppe',
      totalOrders: 'Totalt antall',
      ordersFailed: 'Ordre feilet',
      date: 'Dato',
      finished: 'Ferdig',
      failed: 'Feilet',
      ordersInvoiced: 'Ordre fakturert',
      stop: 'Stopp',
      pickupContactPerson: 'Kontaktperson henting',
      pickupPhone: 'Telefon henting',
      deliveryContactPerson: 'Kontaktperson levering',
      deliveryPhone: 'Telefon levering',
      contactPerson: 'Kontaktperson',
      phone: 'Telefon',
      deliveryReferencePlaceholder: 'Mottakers referanse',
      specificationDescriptionPlaceholder: 'Forsendelsesinformasjon',
      groupName: 'Gruppenavn',
      customerNumber: 'Kundenummer',
      orderNote: 'Notat',
      returned: 'Returnert',
      price: 'Pris',
      slotName: 'Rute',
      waitingTime: 'Ventetid',
      deliveryEtaSmsSentAt: 'Leverings ETA-SMS sendt',
      trip: 'Tur',
      addresses: {
        title: 'Adresser',
        search: 'Adressesøk',
        searchAddresses: 'Søk etter adresser',
        filterAddresses: 'Filtrer adresser',
        deleteConfirmation: 'Er du sikker på at du vil slette denne adressen?',
        deleteMultipleConfirmation: 'Er du sikker på at du vil slette alle valgte adresser?',
        deleteSuccess: 'Adresse slettet',
        deleteMultipleSuccess: 'Adresser slettet',
        delete: 'Slett',
        name: 'Navn',
        phone: 'Telefonnummer',
        address: 'Adresse',
        zipArea: 'Poststed',
        zipCode: 'Postnummer',
        country: 'Land',
        contactPerson: 'Kontaktperson',
        instructions: 'Instruksjoner',
        deleteAddress: 'Slett adresse',
        deleteMultipleAddresses: 'Slett adresser ({{count}})',
        loc: 'Sted',
        locTitle: 'Vis på kartet'
      },
      orderGrid: { vehicleType: 'Kjøretøy' },
      dispatch: {
        id: 'ID',
        address: 'Addresse',
        adjusted: 'Justert',
        alreadyAssigned: '<< Allerede tildelt',
        customerName: 'Kunde',
        service: 'Tjeneste',
        name: 'Navn',
        timeWindow: 'Tidsvindu'
      },
      orderSource: 'Ordrekilde',
      postalCodes: '# postnumre',
      displayEndCustomerPriceShd: 'Vis kundepris',
      courier: 'Sjåfør',
      createdBy: 'Opprettet av',
      createdAt: 'Opprettet',
      plannedDeliveryDate: 'Planlagt dato',
      orderedDeliveryDate: 'Bestilt dato',
      lastUpdatedAt: 'Sist oppdatert',
      route: 'Rute',
      instructions: 'Instruksjoner',
      pickupDuration: 'PU varighet',
      deliveryDuration: 'LEV varighet',
      originalPickupAddress: 'Opprinnelig avsenderadresse',
      originalPickupZipCode: 'Opprinnelig avsenderpostnummer',
      originalPickupZipArea: 'Opprinnelig avsendersted',
      originalPickupCountry: 'Opprinnelig avsenderland',
      originalDeliveryAddress: 'Opprinnelig leveringsadresse',
      originalDeliveryZipCode: 'Opprinnelig leveringspostnummer',
      originalDeliveryZipArea: 'Opprinnelig leveringssted',
      originalDeliveryCountry: 'Opprinnelig leveringsland',
      packagesArrivedAtDip: 'Ankommet',
      packagesCollected: 'Hentet',
      packagesDelivered: 'Levert',
      originalDeliveryEarliest: 'Opprinnelig LEV',
      originalDeliveryLatest: 'Opprinnelig LEV',
      preAdvices: 'Preavisering',
      preAdviceScheduledDate: 'Preavisering utsendt',
      hasPreAdviceSettings: 'Preaviseringsregler',
      arrivedAtHub: 'Ankommet HUB',
      returnToSender: 'Retur til avsender',
      preAdviceFailed: 'Mislykket',
      deliveryAttempts: 'Leveranseforsøk'
    }
  },
  openBooking: {
    pickupReferencePlaceholder: 'Kundereferanse – synlig på faktura',
    customerContactPlaceholder: '$t(instant.booking.customerContact) – synlig på faktura',
    invoiceInfoPlaceholder: 'Fakturainformasjon – synlig på faktura',
    confirmed: {
      header: 'Takk!',
      text: 'Din leveranse er nå booket. Oppdateringer om opphenting og leveranse sendes på mail til {{email}}.',
      support: 'Har du spørsmål angående din bestilling og leveranse? Ring kundeservice på telefon 04050.',
      book: 'Vil du booke flere bud til en fordelaktig pris? <0>Ta kontakt med oss</0> for å få et tilbud.'
    },
    pickupTimePlaceholder: 'Tidligste hentetidspunkt',
    error: {
      notInRangeZipCode: 'Tjenesten er ikke tilgjengelig i valgt område',
      notInSameZipCodeRange: 'Tjenesten er ikke tilgjengelig mellom de valgte områdene',
      notInAreaRangeZipCode: 'Postnummeret samsvarer ikke med noen kode for området',
      pickupTimeNotWithinServiceTimeWindow: 'Hentetiden er ikke innenfor tjenestetidsvinduet',
      pickupTimeBeforeEarliestServicePickupTime: 'Hentetiden kan ikke være før den tidligste hentetiden for tjenesten',
      pickupNotAvailableForWeekend: 'Hentetjeneste er ikke tilgjengelig i helgene',
      invalidOrganisationNumber: 'Ugyldig organisasjonsnummer'
    }
  },
  instant: {
    openMap: 'Åpne kart',
    group: 'Grupper valgte',
    editGroup: 'Endre gruppe',
    groupName: 'Gruppenavn',
    groupOrders: 'Grupper ordre',
    viewGroup: 'Vis gruppe',
    ordersToGroup: 'Ordre i gruppen',
    fixedOrder: 'Fast rekkefølge',
    paidTogether: 'Betales sammen',
    saveGroup: 'Lagre gruppe',
    ungroup: 'Avgrupper',
    removeSelected: 'Fjern valgte',
    split: 'Splitt valgte',
    noCourierSelected: 'Ingen sjåfør valgt',
    noOrderSelected: 'Ingen ordre valgt',
    workingHours: 'Arbeidstid',
    chatButton: 'Chat med sjåfør',
    offeredShipments: 'Tilbudte leveringer',
    rejectedShipments: 'Avviste leveringer',
    rejectedShipmentsSnackbar: '{{count}} avvist levering av "{{name}}"',
    rejectedShipmentsSnackbar_plural: '{{count}} avviste leveringer av "{{name}}"',
    toBeDone: 'Leveringer som skal gjøres',
    finishedRoutePoints: 'Fullførte leveringer',
    suggestedRouteApplied: 'Foreslått rute lagret',
    suggestedRouteCancelled: 'Foreslått rute slettet',
    reorderValidationError: 'Foreslått rute er ikke gyldig',
    planSlot: 'Planlegg rute',
    columnSettings: {
      reset: 'Nullstill',
      settings: 'Innstillinger',
      hideColumns: 'Vis kolonner',
      saveAndClose: 'Lagre og lukk'
    },
    confirmation: {
      heading: 'Ordrebekreftelse',
      from: 'Fra',
      to: 'Til',
      service: 'Tjeneste',
      pickup: 'Henting',
      delivery: 'Levering',
      shipmentNumber: 'Sendingsnummer',
      printLabel: 'Skriv ut merkelapp',
      copyOrder: 'Kopier ordre',
      editOrder: 'Rediger ordre'
    },
    goods: {
      custom: 'Egendefinert',
      letter: 'Brev',
      smallbox: 'Liten boks',
      mediumbox: 'Mellomstor boks',
      largebox: 'Stor boks',
      tube: 'Rør',
      quarterpallet: 'Kvartpall',
      halfpallet: 'Halvpall',
      fullpallet: 'Pall',
      suitcase: 'Koffert',
      movingbox: 'Flytteeske',
      trolley: 'Vogn',
      parcel: 'Løs kolli',
      expressbox: 'Express boks'
    },
    instantGrid: {
      editColumnTooltip: 'Trykk for å redigere',
      deleteColumnTooltip: 'Trykk for å slette sending',
      unassignColumnTooltip: 'Trykk for å ta sending av enhet',
      dragColumnTooltip: 'Klikk og dra for å tildele',
      filtersActive: 'Aktive filtre',
      clearFilters: 'Fjern alle filtre'
    },
    booking: {
      addPackage: 'Legg til ny pakke',
      removePackageRow: 'Fjern pakke',
      cannotRemoveLastPackageRow: 'Kan ikke fjerne siste pakke',
      copyPackageRow: 'Kopier pakken',
      headerCreate: 'Ny ordre',
      headerEdit: 'Rediger av ordre',
      headerCopy: 'Kopier ordre',
      headerRestore: 'Gjenopprett ordre',
      customer: 'Kunde',
      customerPlaceholder: 'Kunde id',
      invalidSubcustomer: 'Subcustomer-navn er ugyldig',
      shipmentOrPackageId: 'Sendingsnummer eller kollinummer',
      creditBlockedCustomer: 'Kredittsperret kunde',
      sender: 'Avsender',
      setAsDefaultAddress: 'Sett som standardadresse',
      defaultAddressUpdated: 'Standardadresse oppdatert',
      recipient: 'Mottaker',
      pickupNamePlaceholder: 'Navn',
      pickupAddressPlaceholder: 'Addresse',
      pickupZipCodePlaceholder: 'Postnummer',
      pickupZipAreaPlaceholder: 'Poststed',
      pickupCountryPlaceholder: '-- Velg land --',
      pickupPhoneNumberPlaceholder: 'Telefon',
      pickupContactPerson: 'Kontaktperson',
      customerReference: 'Kundereferanse',
      pickupReferencePlaceholder: 'Kundereferanse – dette feltet er synlig på kundens faktura',
      pickupInformationPlaceholder: 'Henteinformasjon',
      customerContact: 'Kontaktperson hos kunden',
      customerContactPlaceholder: '$t(instant.booking.customerContact) – dette feltet er synlig på kundens faktura',
      customerInfoPlaceholder: 'Kundeinformasjon',
      customerInfoHelper:
        'Denne informasjonen er knyttet til kunden. Endringer som blir gjort vil være synlig for alle ordre relatert til denne kunden.<br><br>For å legge til ordrespesifikk informasjon, bruk «notater» feltet under.',
      deliveryNamePlaceholder: 'Navn',
      deliveryAddressPlaceholder: 'Addresse',
      deliveryZipCodePlaceholder: 'Postnummer',
      deliveryZipAreaPlaceholder: 'Poststed',
      deliveryCountryPlaceholder: '-- Velg land --',
      deliveryPhoneNumberPlaceholder: 'Telefon',
      deliverySecondPhoneNumberPlaceholder: 'Telefon 2',
      deliveryEmailPlaceHolderPlaceholder: 'E-post',
      deliveryReferencePlaceholder: 'Mottakers referanse',
      deliveryInformationPlaceholder: 'Leveringsinformasjon',
      orderNote: 'Notat',
      clearData: 'Slett data',
      deliveryContactPerson: 'Kontaktperson',
      dimensions: 'Forsendelse',
      weightPlaceholder: 'Vekt (kg)',
      lengthPlaceholder: 'Lengde (cm)',
      widthPlaceholder: 'Bredde (cm)',
      heightPlaceholder: 'Høyde (cm)',
      specificationDescription: 'Forsendelse',
      specificationDescriptionPlaceholder: 'Forsendelsesinformasjon',
      service: 'Tjeneste',
      serviceSelect: 'Velg tjeneste',
      serviceSelectDisabled: 'Endring av tjenester på HD ordre må gjøres i HappyFlow',
      goodsEditDisabled: 'Endringer på gods på HD ordre må gjøres i HappyFlow',
      standardServices: '-- STANDARD TJENESTER --',
      customizedServices: '-- KUNDESPESIFIKKE TJENESTER --',
      serviceCode: 'Tjenestekode',
      servicePlaceholder: 'Tjenestekode',
      invoiceInfo: 'Fakturainformasjon',
      invoiceInfoPlaceholder: 'Fakturainformasjon – denne teksten er synlig på kundens faktura',
      manualInvoiceInfoPlaceholder:
        'Manuell fakturahåndtering (Hvis dette feltet er fylt ut vil ordren måtte faktureres manuelt)',
      pickup: 'Henting',
      delivery: 'Levering',
      pickupDatePlaceholder: 'Hentedato',
      pickupTimePlaceholder: 'Hentetid',
      deliveryDatePlaceholder: 'Leveringsdato',
      deliveryTimePlaceholder: 'Leveringstid',
      restoreButton: 'Gjenopprett',
      orderButton: 'Bestill',
      editButton: 'Oppdater',
      copyButton: 'Bestill',
      created: 'Ordren er registrert',
      edited: 'Ordren er oppdatert',
      quickSelect: 'Hurtigvalg',
      volume: 'Volum',
      totalVolume: 'Totalt volum {{volume}}',
      totalWeight: 'Totalvekt {{weight}}',
      removeItem: 'Fjern',
      copyItem: 'Kopier',
      addItem: 'Legg til',
      calculatePrice: 'Beregn pris',
      priceExplanations: {
        header: 'Prisforklaring',
        article: 'Artikkel: {{article}}',
        priceListName: 'Prisliste:: {{pricelist}}',
        explanations: 'Forklaringer:',
        noInfo: 'Ingen prisinformasjon tilgjengelig'
      },
      approveIncompletePrice: 'Jeg godkjenner alle rader med nullpris',
      prePlannedToCourier: 'Velg sjåfør',
      automaticDepartment: 'Automatisk',
      departmentLabel: 'Avdeling',
      emailNotifications: 'E-post varslinger',
      eventRegistered: 'Registrert',
      eventPickup: 'Opphenting',
      eventDelivery: 'Levering',
      emailAddress: 'E-postadresse',
      emailRecipientText: 'Følgende e-postadresser vil motta varslinger:',
      invalidEmail: 'Ugyldig e-post',
      editIndicator: 'Rediger',
      updateIndicator: 'Uppdater',
      copyIndicator: 'Kopier',
      quantity: 'Antall',
      additionalServices: 'Tilleggstjenester',
      paymentInformation: 'Betalingsinformasjon',
      paidTogetherLabel: 'Faktureres sammen?',
      groupingLabel: 'Sendingsgruppering',
      groupIdPlaceholder: 'Unik gruppe ID',
      groupOrderPlaceholder: 'Ordningsrekkefølge',
      addRecipient: 'Legg til mottaker',
      addPickup: 'Legg til avsender',
      vehicleType: 'Kjøretøytype',
      vehicleTypeUpdated: 'Kjøretøytype oppdatert',
      selectVehicleType: 'Velg kjøretøytype',
      customerPrice: 'Kundepris',
      resourcePrice: 'Unitpris',
      priceDetails: 'Prisdetaljer',
      priceList: 'Prisliste',
      manualPrice: 'Manuell',
      vehicleTypes: {
        car: 'Bil',
        bicycle: 'Sykkel',
        truck: 'Lastebil',
        van: 'Varebil',
        motorcycle: 'Motorsykkel',
        cargobike: 'Lastesykkel'
      },
      date: 'Dato',
      unNumber: 'UN-nummer',
      hazardous: 'Klasse',
      group: 'Gruppe',
      netWeight: 'Netto vekt',
      name: 'Navn',
      restrictions: 'Begrensninger',
      totalPoints: 'Totale poeng'
    },
    courierSorting: { alystraId: 'Sjåfør id', empty: 'Tom' },
    courierFilter: {
      title: 'Filtrer sjåfører',
      all: 'Vis alle',
      inactive: 'Skjul inaktive',
      offline: 'Skjul offline',
      both: 'Skjul inaktive og offline'
    },
    invoicingFilter: {
      ALL: 'Vis alle',
      INVOICED_OK: 'Vis fakturerte',
      TO_BE_INVOICED: 'Klare for fakturering',
      ORDER_CORRECTION: 'Med ordre-korreksjon',
      INVOICED_FAILED: 'Fakturert med feil'
    },
    shipmentFilter: {
      title: 'Filtrer sendinger',
      showAll: 'Vis alle',
      notCollected: 'Ikke plukket opp',
      hideAll: 'Skjul alle'
    },
    offer: {
      carEmpty: 'Enheten er tom',
      suggestedDelivery: 'Foreslått leveringsrekkefølge',
      newShipments: 'Nye sendinger',
      manualRoute: 'Manuell ruteplanlegging',
      confirmRoute: 'Bekreft rute',
      assigning: 'Tildel',
      replanning: 'Endre ruten til',
      shipmentsTo: 'sendinger til',
      shipmentTo: 'sending til'
    },
    useFlexible: 'Bruk fleksible tider',
    forceOptimizing: 'Tving leveringsrekkefølge',
    replanRoute: 'Endre ruten',
    recurring: {
      successMessage: '{{number}} sending(er) ble lagt til på valgte gjentakende grupper',
      recurringOrders: 'Gjentagende ordre',
      includedOrders: 'Inkluderte ordre',
      details: 'Detaljer',
      editOriginal: 'Rediger original',
      copyOriginal: 'Kopier original',
      notStarted: 'Ikke startet',
      executing: 'Kjører...',
      ended: 'Ferdig',
      pending: 'Gjenstår',
      total: 'Totalt',
      failedOrders: 'Feilede ordre',
      exceptionsHeading: 'Unntak for ordre',
      addExceptionHeading: 'Ny unntaksperiode',
      exceptionEnd: 'Slutt',
      editExceptions: 'Rediger unntak',
      addRecurring: 'Gjentagende',
      addToGroup: 'Velg gjentagende gruppe',
      exceptions: 'Unntak',
      recurringOrderAddedConfirmation: 'Ordre lagt til i gruppe',
      exceptionAddedConfirmation: 'Unntak lagret',
      dispatchAsGroup: 'Dispatch som gruppe',
      name: 'Navn',
      frequency: 'Frekvens',
      frequencies: { weekly: 'Hver uke', second_week: 'Annenhver uke', third_week: 'Hver tredje uke' },
      daysOfWeek: 'Ukedager',
      includeHolidays: 'Inkludert helligdager',
      startDate: 'Startdato',
      endDate: 'Sluttdato (valgfritt)',
      save: 'Lagre',
      fromDate: 'Fra dato',
      confirmDelete: 'Er du sikker på at du vil slette valgte gjentagende ordre?',
      from: 'Fra',
      createOrders: 'Lag ordre',
      showResult: 'Vis resultat',
      startdate: 'Startdato',
      enddate: 'Sluttdato',
      orders: 'Ordre',
      failed: 'Feilet',
      by: 'Av',
      on: 'den',
      reset: 'nullstill',
      confirmReset: 'Vil du nullstille gjentagende ordre for {{date}}?'
    },
    corrections: {
      title: 'Korrigeringer',
      headline: 'Orderekorrigering for {{date}}',
      accepted: 'Korreksjon akseptert',
      rejected: 'Korreksjon avvist',
      approve: 'GODKJENN',
      decline: 'AVVIS',
      shipmentId: 'Sendingsnummer',
      courier: 'Enhet',
      from: 'Fra',
      to: 'Til',
      details: 'Detaljer',
      pickupWaitingTime: 'Ventetid opphenting',
      deliveryWaitingTime: 'Ventetid levering',
      pickupLoadingTime: 'Lastetid opphenting',
      deliveryLoadingTime: 'Lastetid levering',
      comment: 'Kommentar',
      weight: 'Vekt',
      volume: 'Volum',
      packages: 'Pakke',
      serviceCode: 'Tjenestekode'
    },
    suggestedUnits: {
      unitName: 'Enhetsnavn',
      driverName: 'Sjåførnavn',
      slotName: 'Rute',
      timeAtLocation: 'Tid på plassering',
      distanceFromLocation: 'Avstand fra lokasjon',
      shipmentInformation: 'Sendingsinformasjon',
      suggestedVehicles: 'Foreslåtte kjøretøy',
      suggestedVehiclesOnPickup: 'Foreslåtte kjøretøy ved opphenting',
      suggestedVehiclesOnDelivery: 'Foreslåtte kjøretøy ved levering',
      suggestedUnits: 'Foreslåtte enheter',
      noSuggestionsAvailable: 'Ingen forslag tilgjengelig',
      pickupTime: 'Opphentingstid',
      deliveryTime: 'Leveringstid',
      filterBy: 'Sorter etter'
    }
  },
  errors: {
    DEPARTMENT_INVALID_ALYSTRA_ID: 'Ugyldig alystra id',
    ENTITY_DOES_NOT_EXIST: 'Entiteten eksisterer ikke',
    INVALID_DATE_RANGE: 'Ugyldig dato tidsrom',
    INVALID_DELIVERY_LATITUDE: 'Ugyldig breddegrad',
    INVALID_DELIVERY_LONGITUDE: 'Ugyldig lengdegrad',
    INVALID_LATITUDE: 'Ugyldig breddegrad',
    INVALID_LONGITUDE: 'Ugyldig lengdegrad',
    INVALID_OR_MISSING_TIME: 'Ugyldig eller manglende tid',
    INVALID_PHONE_NUMBER: 'Ugyldig telefonnummer',
    DUPLICATE_PHONE_NUMBER: 'Duplikat telefonnummer',
    INVALID_PICKUP_LATITUDE: 'Ugyldig opphenting breddegrad',
    INVALID_PICKUP_LONGITUDE: 'Ugyldig opphenting lengdegrad',
    INVALID_SLOT_TYPE: 'Ugyldig slot type',
    MISSING_ADDRESS: 'Manglende addresse',
    MISSING_ALYSTRA_ID: 'Manglende alystra id',
    MISSING_AREA: 'Manglende område',
    MISSING_CAPACITY: 'Manglende kapasitet',
    MISSING_COLOR: 'Manglende farge',
    MISSING_CONSIGNMENT_INPUTS: 'Manglende pakke inndata',
    MISSING_CONSIGNMENT: 'Manglende pakke',
    MISSING_CONSIGNMENTS: 'Manglende pakker',
    MISSING_COURIER_ID: 'Manglende sjåførs id',
    MISSING_COURIER: 'Du må angi sjåfør',
    MISSING_CREATED_AT: 'Manglende opprett tid',
    MISSING_CUSTOMER_ID: 'Manglende kunde id',
    MISSING_CUSTOMER_REF: 'Manglende kundereferanse',
    MISSING_DATE: 'Manglende dato',
    MISSING_DAYS: 'Manglende dager',
    MISSING_DEPARTMENT: 'Manglende kontor',
    MISSING_DESCRIPTION: 'Manglende beskrivelse',
    MISSING_DEVIATION_TEXT: 'Manglende avvikstekst',
    MISSING_END_LOCATION_LATITUDE: 'Manglende slutt-lokasjon breddegrad',
    MISSING_END_LOCATION_LONGITUDE: 'Manglende slutt-lokasjon lengdegrad',
    MISSING_EVENT_DATA: 'Manglende eventdata',
    MISSING_EVENT_TYPE: 'Manglende eventtype',
    MISSING_FILE_NAME: 'Manglende filnavn',
    MISSING_FROM_DATE: 'Manglende fra dato',
    MISSING_ID: 'Manglende id',
    MISSING_LOCATION_LATITUDE: 'Manglende lokasjon breddegrad',
    MISSING_LOCATION_LONGITUDE: 'Manglende lokasjon lengdegrad',
    MISSING_LOCATION_POSITION: 'Manglende lokasjon position',
    MISSING_LOCATION_TIME: 'Manglende lokasjon tid',
    MISSING_NAME: 'Manglende navn',
    MISSING_GROUP: 'Manglende gruppering',
    MISSING_PACKAGE_ID: 'Manglende pakke id',
    MISSING_PHONE: 'Manglende telefonnummer',
    MISSING_PLANNER_ID: 'Manglende planner id',
    MISSING_SERVICE_CODE: 'Manglende tjenestekode',
    MISSING_SHIPMENT_ID: 'Manglende shipment id',
    MISSING_SLOT_ID: 'Manglende slot id',
    MISSING_SLOT_TYPE: 'Manglende slot type',
    MISSING_SMS_TOKEN: 'Manglende sms token',
    MISSING_START_LOCATION_LATITUDE: 'Manglende start lokasjon breddegrad',
    MISSING_START_LOCATION_LONGITUDE: 'Manglende start lokasjon lengdegrad',
    MISSING_TO_DATE: 'Manglende til dato',
    MISSING_ZIP_AREA: 'Manglende poststed',
    MISSING_ZIP_CODE: 'Manglende postnummer',
    MISSING_COUNTRY_CODE: 'Manglende landkode',
    NO_SUCH_USER: 'Finner ikke bruker',
    PARAM_NOT_OF_EXPECTED_TYPE: 'Parameter ikke av forventet type',
    PARAM_NULL_NOT_ALLOWED: 'Parameter null er ikke lov',
    QUERY_NAME_MISSING: 'Query navn mangler',
    QUERY_PARAMS_MISSING: 'Query params mangler',
    USER_NOT_EXIST_OR_INCORRECT_ROLE: 'Bruker eksisterer ikke eller har ugyldig rolle',
    USER_EXIST_BUT_INCOMPATIBLE_ROLE: 'Bruker eksisterer men har en annen rolle',
    SLOT_CANNOT_BE_STARTED: 'Slot kan ikke startes',
    SLOT_CANNOT_BE_DELETED: 'Slot kan ikke slettes',
    COURIER_NOT_PART_OF_DEPARTMENT: 'Sjåfør ikke den del av avdeling',
    CONSIGNMENT_IN_INCORRECT_STATE_CANNOT_UPDATE: 'Pakke i feil tilstand, kan ikke oppdatere',
    ORDER_DOES_NOT_EXISTS: 'Ordre eksisterer ikke',
    OPTIMIZE_ALREADY_RUNNING: 'Ruteoptimalisering pågår',
    CANNOT_CHANGE_ORDER: 'Kan ikke endre ordre',
    NO_ACCESS: 'Ingen tilgang',
    NO_RELEVANT_CONSIGNMENTS_OR_SLOTS: 'Ingen relevante leveringer for gitte slots',
    NO_RELEVANT_CONSIGNMENTS: 'Ingen relevante leveringer for gitte slots',
    NO_RELEVANT_SLOTS: 'Kun ruter som ikke er startet eller er satt på pause kan brukes for denne optimaliseringen',
    NOT_EQUAL_PICKUP_ADDRESSES: 'Alle henteadresser må være like for å begrense alle forsendelser til én sjåfør',
    NO_SUGGESTIONS_FOUND_FOR_ORDERS: 'Fant ingen sjåfører pakkene kan tildeles',
    EXCEL_MISSING_SHIPMENT_ID: 'Manglende shipment id oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_CUSTOMER_REF: 'Manglende kundereferanse oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_CUSTOMER_ID: 'Manglende kunde id oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_DEPARTMENT: 'Manglende avdeling oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_PACKAGE_ID: 'Manglende pakke id oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_NAME: 'Manglende navn oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_INVALID_PHONE: 'Ugyldig telefonnummer oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_ADDRESS: 'Manglende adresse oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_ZIP_AREA: 'Manglende postnummer oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_ZIP_CODE: 'Manglende poststed oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_FROM_DATE: 'Manglende fra dato oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_TO_DATE: 'Manglende til dato oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_NOTIFICATION_SMS: 'Manglende notifikasjons SMS oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_SIZE: 'Manglende størrelse oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_ORDERS: 'Fant ingen ordrer i regnearket.',
    EXCEL_MISSING_COUNTRY: 'Fant ikke land kode eller navn i celle {{col}} på rad {{row}}',
    EXCEL_NO_ACCESS_DEPARTMENT: 'Ikke tilgang til avdeling i {{col}} on row {{row}}',
    EXCEL_INVALID_FROM_DATE: 'Fra dato kan ikke være før i dag i cell {{col}} på rad {{row}}',
    EXCEL_INVALID_TO_DATE: 'Til dato kan ikke være før i dag i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_DESCRIPTION: 'Manglende ordrebeskrivese i "{{cell}}" på rad {{row}}',
    EXCEL_MISSING_PICKUP_INSTRUCTIONS: 'Manglende hentebeskrivelse i "{{cell}}" på rad {{row}}',
    EXCEL_MISSING_DELIVERY_INSTRUCTIONS: 'Manglende leveringsbeskrivelse i "{{cell}}" på rad {{row}}',
    MISSING_PICKUP_LOCATION_AND_TIME: 'Mangler henteadresse og tidspunkt',
    MISSING_EARLIEST_PICKUP_DATE: 'Mangler tidligste hentetidspunkt',
    MISSING_LATEST_PICKUP_DATE: 'Mangler seneste hentetidspunkt',
    MISSING_EARLIEST_DELIVERY_DATE: 'Mangler tidligste leveringstidspunkt',
    MISSING_LATEST_DELIVERY_DATE: 'Mangler seneste leveringstidspunkt',
    INVALID_EARLIEST_PICKUP_DATE: 'Ugyldig hentetidspunkt',
    INVALID_LATEST_PICKUP_DATE: 'Ugyldig hentetidspunkt',
    INVALID_EARLIEST_DELIVERY_DATE: 'Ugyldig leveringstidspunkt',
    INVALID_LATEST_DELIVERY_DATE: 'Ugyldig leveringstidspunkt',
    CANNOT_MODIFY_ORDERS_FROM_DIFFERENT_SOURCE: 'Du kan ikke overskrive ordre fra andre kilder enn Excel',
    PICKUP_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST: 'Opphentingstid må være før leveransetid',
    DELIVERY_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST:
      'Tidligste leveringstidspunkt må være før siste leveringstidspunkt',
    CANNOT_DELIVER_ON_A_HOLIDAY: 'Kan ikke levere på en helligdag',
    MISSING_TEMPLATE_ID: 'Mangler id på mal',
    TEMPLATE_DOES_NOT_EXIST: 'Malen finnes ikke',
    TEMPLATE_CANNOT_BE_DELETED: 'Malen kan ikke slettes',
    MISSING_TEMPLATE_SLOTS: 'Malen mangler slots',
    TEMPLATE_NAME_ALREADY_EXISTS: 'Det finnes allerede en mal med dette navnet',
    COURIER_ALREADY_EXISTS: 'Det finnes allerede en sjåfør med dette telefonnummeret',
    ORDER_EXIST_FOR_SERVICE: 'Kan ikke slette tjenesten da det eksisterer ordre tilknyttet denne',
    NO_SERVICE_FOUND: 'Legg til ny tjeneste før du tilknytter en kunde',
    CANNOT_DELETE_AS_SERVICE_EXIST: 'Fjern tilknytt til kunde før du sletter tjenesten',
    SLOT_IN_INCORRECT_STATE_CANNOT_UPDATE: 'Slot er ikke i en gyldig tilstand for denne endringen',
    GRAPHHOPPER_UNASSIGNED:
      'Klarte ikke å tildele alle leveransene (pga. tid, kapasitet eller andre faktorer), detaljer: {{details}}',
    GRAPHHOPPER_REQUEST_FAILED: 'Forespørsel om å optimere ruten feilet',
    GRAPHHOPPER_LATEST_ARRIVAL_AT_DELIVERY_AFTER_EARLIEST_DEPARTURE:
      'Siste ankomst for levering kan ikke være etter tidligste avgang - er tidsvinduet passert?',
    GRAPHHOPPER_EARLIEST_GREATER_THAN_LATEST: 'Tidligste tid kan ikke være etter seneste - er noen av tidene passert?',
    GRAPHHOPPER_PICKUP_EARLIEST_AFTER_LATEST:
      'Tidsvindu for avgang for pakke "{{packageId}}" kan ikke være passert ({{pickupEarliest}} - {{pickupLatest}})',
    GRAPHHOPPER_DELIVERY_EARLIEST_AFTER_LATEST:
      'Tidsvindu for levering for pakke "{{packageId}}" kan ikke være passert ({{deliveryEarliest}} - {{deliveryLatest}}',
    SLOT_CANNOT_BE_EDITED: 'Slot er ikke i en gyldig tilstand for denne endringen',
    INVALID_BASIC_SERVICE_CODE: 'Ugyldig basis tjenestekode',
    MISSING_DELIVERY_DATE_LATEST: 'Missing delivery date latest',
    NO_SERVICE_MATCHING_SERVICE_CODE: 'Ingen tjenester matcher tjenestekoden',
    NO_SERVICE_MATCHING_DAY_OF_WEEK: 'Ingen tjenester matcher ukedagen',
    NO_SERVICE_MATCHING_PICKUP_AND_DELIVERY_TIMES: 'Ingen tjenester matcher opphentings- og leveringstidene',
    ORDER_CANNOT_BE_MOVED: 'Leveransen kan ikke flyttes, den kan ikke være koblet til en rute',
    MISSING_DESTINATION_DEPARTMENT_ID: 'Mangler mottakeravdeling',
    SHIPMENT_NOT_FOUND: 'Fant ikke sendingen',
    DUPLICATE_EXCEL_IMPORT:
      'Det samme filnavnet er allerede importert til denne avdelingen i dag. Du kan se resultatene av importen <0>her</0>. Endre filnavnet hvis du vil importere igjen.',
    SHIPMENT_INVALID_QUERY:
      'Under Peak vil søk være begrenset for å sikre ytelse. Du kan søke etter alle bestillinger på en dag eller legge til et søkeparameter.',
    DURATION_IS_MORE: 'Vennligst begrens ditt søk til {{maxDuration}} dager.',
    ADDRESS_CANNOT_BE_DELETED: 'Adresse brukes som standardadresse og kan ikke slettes.',
    UNKOWN_ERROR: 'Endringer kunne ikke lagres',
    INCORRECT_STATES_OR_ASSIGNED_TO_SLOT: 'Ugyldig status eller planlagt på rute',
    CANNOT_MOVE_ORDERS: 'Kan ikke flytte ordre',
    DESTINATION_DEPARTMENT_INELIGIBLE: 'Destinasjonsavdelingen er ugyldig',
    DEPARTMENT_MISSING_ADDRESS_DETAILS: 'Avdelingen mangler adressedetaljer',
    MISSING_DELIVERY_PHONE_NUMBER: 'Telefonnummer mangler for',
    INVALID_DELIVERY_PHONE_NUMBER: 'Telefonnummeret er ikke gyldig for',
    ROLE_IN_USE: 'Rollen kan ikke fjernes siden rollen er i bruk.',
    INVALID_NAME: 'Rollenavnet må være unikt. En rolle med dette navnet eksisterer allerede.',
    TEXT_CANNOT_BE_DELETED:
      'Teksten brukes på en tjeneste og kan ikke slettes. Koble teksten fra tjenesten for å slette den.'
  },
  customer: {
    showAll: 'Vis alle kunder',
    customer: 'Kunde',
    groups: {
      title: 'Kundegruppe',
      titleWithCount: '{{count}} kundegrupper',
      name: 'Gruppenavn',
      description: 'Beskrivelse',
      numberOfCustomers: 'Antall kunder',
      none: 'Ingen kundegrupper',
      new: 'Ny kundegruppe',
      edit: 'Rediger kundegruppe',
      searchCustomer: 'Søk kunde',
      members: 'Kunder i gruppen',
      save: 'Lagre',
      deleteConfirm: 'Er du sikker på at du vil slette kundegruppen?',
      deleted: 'Kundegruppen er slettet.',
      cannotDelete: 'Kundegruppen kan ikke slettes fordi den brukes i en aviseringsregel.'
    },
    customerTitle: 'Kunder',
    customerNo: 'Kundenr',
    name: 'Navn',
    emailToSender: 'E-post til avsender',
    deviations: 'Avvik',
    add: 'Legg til',
    noOrders: 'Ingen ordre i Glow',
    noOrdersDescription: 'Kundene du har tilgang til har ingen ordre i Glow',
    choose: 'Vennligst velg kunde',
    search: 'Søk',
    customerNumber: 'Kundenummer',
    searchHint: 'Søk etter leveranser basert på sendings id, kundereferanse, pakke id, leveringsnavn eller adresse',
    senderName: 'Avsenders navn',
    addSenderName: 'Legg til avsendernavn',
    senderNameAdded: 'Avsendernavn lagt til',
    addInvoiceHDOrdersFromDate: 'Legg til startdato for fakturering av HD-ordre gjennom Glow',
    invoiceHDOrdersFromDate: 'Startdato for fakturering av HD-ordre gjennom Glow',
    invoiceHDOrdersFromDateAdded: 'Startdato for fakturering av HD-ordre endret',
    billingCustomerNumber: 'Faktureringskundenummer',
    addBillingCustomerNumber: 'Legg til faktureringskundenummer',
    billingCustomerNumberAdded: 'Faktureringskundenummer lagt til',
    searchPage: {
      fromDate: 'Fra dato',
      toDate: 'Til dato',
      today: 'I dag',
      tomorrow: 'I morgen',
      yesterday: 'I går',
      last30days: 'Siste 30 dager',
      plusMinus15Days: '+/- 15 dager',
      plusMinus30Days: '+/- 30 dager'
    },
    smsText: {
      title: 'SMS-tekst',
      addEditSmsText: 'Opprett/rediger SMS-tekst',
      smsTextUpdated: 'SMS-tekst opprettet/endret',
      description: 'Beskrivelse',
      submit: 'Lagre',
      smsType: 'SMS type',
      smsTypeTexts: {
        pickup: 'Sporings-SMS',
        pre_pickup: 'Forhåndssend sporings-SMS',
        manual_delay: 'Manuell forsinkelse SMS',
        eta_pickup: 'ETA SMS opphenting',
        eta_delivery: 'ETA SMS levering',
        flex_delivery_confirmation: 'Flex levering SMS-bekreftelse',
        advise: 'Rådgi SMS',
        delivery_confirmation: 'Leveringsbekreftelse SMS',
        delivery: 'Leverings SMS',
        deviated: 'Leveringsavvik SMS'
      }
    },
    showNotCollected: 'Ikke lastet',
    booking: {
      pickupReady: 'Klar for henting',
      pickup: 'Hentes',
      delivery: 'Leveres',
      deliveryAvailable: 'Levering tilgjengelig',
      priceComment:
        'Du blir fakturert i henhold til din kundeavtale med Bring. Avgifter er ikke inkludert i denne prisen.',
      serviceUnavailable: 'Den valgte tjenesten er ikke tilgjengelig for postnummer {{postalCode}}',
      recipientPrice: 'Mottakerpris:',
      customerContactPlaceholder: 'Bestilt av – Dette feltet er synlig på kundens faktura'
    },
    email: {
      emailAddress: 'E-mail address',
      true: 'yes',
      false: 'no',
      informationToCustomer: 'Informasjon til kunden',
      returnToCustomer: 'Retur til kunden',
      addEmailToList: 'Legg til e-post på listen',
      registeredEmailSubscription: 'Registrert e-postabonnement',
      updateEmail: 'Oppdater e-post',
      noRegisteredEmails: 'Ingen registrerte e-poster',
      detailsSavedSuccessfully: 'E-post til avsender ble lagret med suksess'
    },
    customerDeviations: { deviationType: 'Type avvik' },
    customerInfo: 'Kundeinfo',
    customerName: 'Kundenavn',
    invoicingAddress: 'Fakturaadresse',
    organisationNumber: 'Organisasjonsnummer',
    visitingAddress: 'Besøksadresse',
    creditBlockedFrom: 'Kredittblokkert fra dato',
    creditBlockedFromInfo: 'Blokkér kreditt for denne kunden fra valgt dato',
    from: 'Fra',
    address: 'Adresse',
    zipCode: 'Postnummer',
    zipArea: 'Sted',
    country: 'Land',
    edit: 'Rediger {{name}}',
    selectCountry: 'Velg land',
    addNewCustomer: 'Legg til ny kunde',
    bookingPageModalHeading: 'Booking i kundeportalen',
    bookingPageModalDescription:
      'Skru av booking dersom kunden ikke skal kunne booke sendinger på dette kundenummeret i Glow kundeportal.',
    bookingActive: 'På',
    bookingInactive: 'Av',
    brandedTrackingActive: 'På',
    brandedTrackingInactive: 'Av',
    bookingPageAvailable: 'På',
    bookingPageHidden: 'Av'
  },
  serviceMapping: {
    serviceName: 'Tjenestenavn',
    pickupTime: 'Tid pr henting',
    deliveryTime: 'Tid pr levering',
    internalName: 'Internt navn',
    internalDescription: 'Intern beskrivelse',
    externalName: 'Externt navn',
    externalDescription: 'Ekstern beskrivelse',
    allowFlexDelivery: 'Tillat Flex Delivery',
    timeWindow: 'Tidsvindu',
    service: 'Tjeneste',
    vas: 'VØT',
    courierApp: 'Sjåførapp',
    allowCourierDescription: 'Tillat sjåføren å akseptere eller avvise ordre med denne tjenesten',
    allowCourierCheckbox: 'Ja, sjåføren har tillatelse',
    serviceDeliveryType: 'Tjenestetype',
    noServiceDeliveryTypeSelected: 'Ikke angitt',
    serviceDeliveryTypes: {
      Delivery: 'Levering',
      Return: 'Retur',
      PickupPoint: 'Utleveringssted'
    }
  },
  feedback: {
    title: 'Tilbakemelding',
    message: 'Gi oss forslag til hvordan denne siden kan bli bedre',
    linkText: 'Gi tilbakemelding'
  },
  texts: {
    title: 'Tekster',
    update: 'Oppdater tekst',
    group: 'Gruppering',
    edit: 'Endre',
    delete: 'Slett',
    add: 'Legg til tekst',
    reset: 'Nullstill',
    confirmDelete: 'Er du sikker på at du vil slette teksten?',
    added: 'Tekst lagt til',
    deleted: 'Tekst slettet',
    updated: 'Text endret',
    nameToolTip: 'Navn vises når tekst kobles til tjenesten'
  },
  countries: { norway: 'Norge', sweden: 'Sverige', denmark: 'Danmark', germany: 'Tyskland' },
  billingType: { day: 'dag', evening: 'kveld', weekend: 'helg', weekendEvening: 'helgekveld' },
  shipmentsExport: {
    unit: 'Enhet',
    exportButton: 'Eksporter til Excel',
    shipmentId: 'Sendingsnummer',
    returned: 'Returnert',
    department: 'Avdeling',
    customer: 'Kunde',
    customerNumber: 'Kundenummer',
    date: 'Dato',
    pickupEarliest: 'Tidligste opphenting',
    pickupLatest: 'Seneste opphenting',
    deliveryEarliest: 'Tidligste levering',
    deliveryLatest: 'Seneste levering',
    serviceCode: 'Tjeneste',
    additionalServices: 'Tilleggstjenester',
    pickupName: 'Opphenting',
    pickupAddress: 'Opphentingsadresse',
    pickupZipCode: 'Opphentingspostnummer',
    pickupZipArea: 'Opphentingssted',
    pickupCoordinates: 'Opphentingskoordinater',
    pickupInstructions: 'Henteinstrukser',
    deliveryName: 'Levering',
    deliveryAddress: 'Leveringsaddresse',
    deliveryZipCode: 'Leveringspostnummer',
    deliveryZipArea: 'Leveringssted',
    deliveryCoordinates: 'Leveringskoordinater',
    deliveryInstructions: 'Leveringsinstrukser',
    quantity: 'Antall',
    weight: 'Vekt',
    volume: 'Volum',
    customerRef: 'Kundereferanse',
    pickupContactPerson: 'Kontaktperson henting',
    pickupPhone: 'Telefon henting',
    deliveryContactPerson: 'Kontaktperson levering',
    deliveryPhone: 'Telefon levering',
    deliveryEmail: 'E-post levering',
    recipientRef: 'Mottakerreferanse',
    price: 'Pris',
    group: 'Gruppe',
    pickupTime: 'Opphentet/ forsøk',
    delivered: 'Levert/ forsøk',
    deliveryEtaSmsSentAt: 'Leverings ETA-SMS sendt',
    courierName: 'Sjåfør',
    goodsInfo: 'Forsendelsesinformasjon',
    note: 'Notat',
    slotName: 'Rute',
    estimatedArrivalTime: 'Estimert',
    orderSource: 'Ordrekilde'
  },
  importExport: {
    dropText: 'Dra fil hit for å laste opp',
    export: 'Eksporter',
    exportAddresses: 'Eksporter kundeadresser til Excel',
    importExcel: 'Importer fra Excel',
    imported: 'adresser importert',
    noRecipients: 'Ingen gyldige mottakere ble funnet',
    or: 'eller',
    recipients: 'mottakere',
    selectFile: 'Velg fil',
    uploadingFile: 'Laster opp...'
  },
  customMeasurements: {
    name: 'Navn',
    weight: 'Vekt',
    height: 'Høyde',
    length: 'Lengde',
    width: 'Bredde',
    heading: 'Egendefinerte godstyper',
    error: 'Feil ved lagring av godstype: ',
    confirmDelete: 'Er du sikker på at du vil slette denne godstypen?',
    new: 'Lag ny',
    customizeGoodsTypes: 'Definer egne godstyper',
    customGoodsTypes: 'Egendefinerte godstyper',
    defaultGoodsTypes: 'Standardgodstyper',
    invalidName: 'Navnet må inkludere minst én bokstav'
  },
  unresolved: {
    unresolvedAddresses: 'Uløste adresser',
    allResolved: 'Alle adresser er løst!',
    address: 'Addresse',
    noOfShipments: 'Antall leveranser',
    shipment: 'leveranse',
    shipment_plural: 'leveranser',
    shipmentNumber: 'Sendingsnummer',
    customerRef: 'Kundereferanse',
    senderName: 'Avsenders navn',
    senderPhone: 'Avsenders telefonnummer',
    recipientName: 'Mottakers navn',
    recipientPhone: 'Mottakers telefonnummer',
    searchAndSetLocation: 'Søk og velg lokasjon',
    setALocationToContinue: 'Velg lokasjon for å fortsette',
    resolveAddress: 'Løs adresse',
    suggestedAddressFound: 'Foreslått adresse funnet',
    thereIsOneUnresolvedAddress: 'Det er {{count}} uløst adresse',
    thereIsOneUnresolvedAddress_plural: 'Det er {{count}} uløste adresser'
  },
  optimizing: {
    department: 'Avdeling',
    userName: 'Bruker',
    viewReqResp: 'Vis',
    totalTime: 'Tid',
    kbSize: 'KB-størrelse på request',
    showAll: 'Vis alle',
    numberOfPackages: 'Antall pakker',
    numberOfVehicles: 'Antall kjøretøy',
    processingTime: 'Behandlingstid i sekunder'
  },
  date: { date: 'Dato', today: 'I dag', tomorrow: 'I morgen', yesterday: 'I går', dateAndTime: 'Dato og tid' },
  routeReceipt: {
    printRouteReceiptList: 'Skriv ut kvitteringsliste',
    noRoutesInfoAvailable: 'Ingen ruteinformasjon tilgjengelig ennå'
  },
  homeDeliveryRouteList: {
    receiptList: 'Kvitteringsliste',
    shipments: 'Sendinger',
    weight: 'Vekt',
    volume: 'Volum',
    loadingMeters: 'Lastmeter',
    palletFootPrints: 'Pallplasser',
    sender: 'Avsender',
    package: 'Kolli',
    shipmentNo: 'Sendingsnummer',
    recipient: 'Mottaker',
    time: 'Tid',
    mobile: 'Mobil',
    other: 'Øvrig',
    attemptedDelivery: 'Bomtur',
    receipt: 'Kvittering',
    dateOfBirth: 'Fødselsdato',
    signature: 'Signatur',
    nameClarification: 'Navn',
    goodsLine1: 'Husk å sjekke varene for synlige skader før signering.',
    goodsLine2: 'Hvis det er synlige skader, be sjåføren registrere dem innen du signerer.',
    damage: 'Skade',
    missing: 'Mangler',
    packageNumber: 'Kollinummer',
    comments: 'Kommentar'
  },
  shipment: {
    columnCategories: {
      aboutTheShipment: 'Om forsendelsen',
      goods: 'Varer',
      timeAndDate: 'Tid og dato',
      planning: 'Planlegging',
      pickupInformation: 'Henteinformasjon',
      customer: 'Kunde',
      deliveryInformation: 'Leveringsinformasjon',
      originalSender: 'Opprinnelig avsender',
      originalDelivery: 'Opprinnelig levering',
      service: 'Tjeneste',
      preAdvice: 'Forhåndsvarsel',
      invoicingInformation: 'Faktureringsinformasjon'
    },
    shipment: 'Leveranse',
    customer: 'Kunde',
    customerContact: 'Kontaktperson hos kunden',
    customerReference: 'Kundereferanse',
    customerNumber: 'Kundenummer',
    route: 'Rute',
    service: 'Tjeneste',
    additionalServices: 'Tilleggstjenester',
    quantity: 'Antall',
    totalWeight: 'Total vekt',
    totalVolume: 'Totalt volum',
    totalLoadingMeters: 'Lastmeter totalt',
    totalPalletFootPrints: 'Pallplasser totalt',
    goodsInfo: 'Forsendelsesinformasjon',
    packages: 'Kolli',
    pickupInfo: 'Henteinformasjon',
    pickupDate: 'Hentedato',
    pickupTime: 'Hentetid',
    pickupInstructions: 'Henteinstrukser',
    deliveryInfo: 'Leveringsinformasjon',
    deliveryDate: 'Leveringsdato',
    deliveryTime: 'Leveringstid',
    receiverReference: 'Mottakerreferanse',
    deliveryInstructions: 'Leveringsinstrukser',
    trackingPage: 'Mottakers sporingsside',
    internalNote: 'Internt notat',
    closeWhileEditing: 'Er du sikker på at du vil stenge redigeringen?\nAlle ulagrede endringer vil bli tapt.',
    name: 'Navn',
    address: 'Addresse',
    zipCode: 'Postnummer',
    zipArea: 'Poststed',
    phone: 'Telefon',
    secondPhone: 'Telefon 2',
    email: 'E-postadresse'
  },
  bookingTimeWindow: {
    week: 'Uke',
    bookButton: 'Bestill',
    customButton: 'Tilpasset tidsvindu',
    removeBookingButton: 'Fjern bestilling',
    bookedInfo: 'Bestilt {{date}} mellom {{timeWindow}}',
    notBookedInfo: 'Ikke bestilt',
    tableHeaderDate: 'Dato',
    tableHeaderSlots: 'Tilgjengelige tidsvinduer',
    serverError: 'En feil har oppstått. Vennligst forsøk igjen senere.',
    previousButton: 'Forrige',
    nextButton: 'Neste',
    noAvailableDeliveryTimes: 'Ingen tilgjengelige leveringstider denne måneden',
    customTimeTitle: 'Angi tilpasset tidsvindu for forsendelse',
    customDateLabel: 'Dato',
    customTimeWindowLabel: 'Leveringstidsvindu',
    backFromCustomTimeButton: 'Tilbake til tilgjengelige tider',
    errorEarliestNotBeforeLatest: 'Tidligste leveringstid må være før seneste',
    arrivedAtTerminal: 'Ankommet på terminal',
    bookCustomTimeSlot: 'Book tidsvindu',
    shipmentBooked: 'Sending booket',
    shipmentBookedWithCustomTimeWindow: 'Sending booket med tilpasset tidsvindu',
    updateBooking: 'Oppdater booking',
    removeTimeSlot: 'Fjern tidsvindu',
    bookingChanged: 'Bookingen er endret',
    bookingSuccessful: 'Bookingen ble gjennomført',
    customTimeWindowAdded: 'Tilpasset tidsvindu for sending ble lagt til',
    timeFrom: 'Tid fra',
    timeTo: 'Tid til',
    timeFieldError: 'Den tidligste leveringstiden må være før den siste',
    availableTimeSlot: 'Tilgjengelige tidsvinduer',
    updateButtonTooltip: 'For å oppdatere bestillingen må du først velge et nytt tidsvindu'
  },
  manualOverride: {
    scannedFormDescription: 'Type ankomstscan trigges av hvilken avdeling du velger.',
    eventType: {
      delivered: 'Levert',
      collected: 'Hentet',
      returned: 'Returnert',
      deviated: 'Avvik',
      scanned: 'Skannet',
      preadvised: 'Preadvice-forsøk',
      'not-arrived-at-distributing-terminal': 'Ikke ankommet'
    },
    selectEventType: 'Velg en hendelsestype',
    noEventSelected: 'Ingen hendelsestype valgt',
    selectManifest: 'Velg flight',
    noManifestSelected: 'Ingen flight valgt',
    disabledEventReason: {
      returned: ' - Avvik påkrevd',
      collected: ' - Dispatching påkrevd',
      default: ' - Ikke tillatt'
    },
    fillDeliverOrReturnEventInfo: 'Fyll inn navn, dato og tid',
    fillDeviationEventInfo: 'Velg avvik og fyll inn dato og tid',
    fillGeneralEventInfo: 'Fyll inn dato og tid',
    eventMessage: 'Hendelser opprettet',
    allPackagesSelected: 'Alle kolli valgt',
    noActiveDriverError: 'Ingen aktive sjåfører på enheten',
    selectPackages: 'Velg kolli',
    selectDriver: 'Velg sjåfør',
    selectUnit: 'Velg enhet',
    selectDepartment: 'Overstyr avdeling'
  },
  zendesk: {
    email: 'Din epostadresse:',
    confirmEmail: 'Gjenta epostadresse:',
    send: 'Lagre',
    missingEmail:
      'For å kunne bruke de nye supportsidene, må du registrere din Posten- eller Bring-epostadresse. Du trenger bare å gjøre dette én gang.'
  },
  instantPlannerUnification: {
    message:
      'Modulene Instant og Planlegger er nå kombinert. Vennligst bruk Planlegger for å finne alt som tidligere lå under Instant.',
    linkTitle: 'Les mer her.'
  },
  shipmentFormPage: {
    findShipment: 'Finn sending',
    searchPhraseError: 'Du må oppgi minst 3 tegn',
    selectStateLabel: 'Velg status nedtrekksmeny',
    selectDateLabel: 'Velg dato nedtrekksmeny',
    submit: 'Vis resultater',
    inputPlaceholder: 'Søk sendingsnummer, telefonnummer, adresse, område, postnummer ...',
    clearButtonLabel: 'tøm søkefraseknapp',
    states: 'Statuser',
    allStates: 'Alle statuser',
    customDate: 'Tilpasset',
    noDate: 'Ingen datobegrensning',
    selectFromDate: 'Velg fra dato',
    selectToDate: 'Velg til dato',
    include: 'Inkluder',
    exclude: 'Ekskluder',
    selectAdvancedOperator: 'Velg avansert operatør nedtrekksmeny',
    ofTheseRequirements: 'av disse kravene',
    tableColumn: 'Tabellkolonne',
    condition: 'Betingelse',
    searchTerm: 'Søkeord',
    all: 'ALLE',
    any: 'NOEN',
    tableDropdown: 'Velg tabell nedtrekksmeny',
    conditionDropdown: 'Velg betingelse nedtrekksmeny',
    typeSearch: 'Skriv inn søkeord',
    addNewRow: 'Legg til ny rad',
    deleteRow: 'Slett nåværende rad',
    service: 'Tjeneste',
    goodsDescription: 'Varebeskrivelse',
    route: 'Rute',
    unit: 'Enhet',
    deliveryArea: 'Leveringsområde',
    deliveryZipcode: 'Leverings postnummer',
    state: 'Status',
    today: 'I dag',
    tomorrow: 'I morgen',
    yesterday: 'I går',
    last30Days: 'Siste 30 dager',
    plusMinus15Days: '+/- 15 dager',
    plusMinus30Days: '+/- 30 dager',
    shipmentIds: 'Flere forsendelser'
  },
  shipmentResultPage: {
    noResultTitle: 'Ingen resultater funnet',
    batchLimitReached: 'Viser maksimalt 2000 sendinger, vurder å begrense søket',
    withoutDateLimitReached:
      'Når du søker uten dato, vil vi vise maksimalt {{limit}} forsendelser. Vurder å begrense søket ditt.',
    noResultExplanation: 'Vi fant ingen resultater. Prøv å søke med et annet ord.'
  },
  shipmentTable: {
    quantity: 'Kvantitet',
    delEta: 'LEV ETA',
    delDuration: 'LEV Varighet',
    delDurationTitle: 'Leveringsvarighet',
    delDeliveryFrom: 'LEV fra',
    delDeliveryFromTitle: 'Leveransetid fra',
    delDeliveryTo: 'LEV til',
    delDeliveryToTitle: 'Leveransetid til',
    timeOfDelivery: 'Leveransetidspunkt',
    puDuration: 'HENT Varighet',
    puDurationTitle: 'Opphentingsvarighet',
    puPickupFrom: 'HENT fra',
    puPickupFromTitle: 'Opphenting fra',
    puPickupTo: 'HENT til',
    puPickupToTitle: 'Opphenting til',
    timeOfPickup: 'Opphentingstidspunkt',
    delEtaTitle: 'Forventet leveringstidspunkt',
    recipientRef: 'Mottakerreferanse',
    loading: 'Laster...',
    pickupName: 'Opphentingsnavn',
    deliveryName: 'Leveringsnavn',
    selectAllOnPage: 'Velg alle på siden ({{count}})',
    selectAll: 'Velg alle ({{count}})',
    deselectAll: 'Fjern alle valg',
    numSelectedShipments: '{{numSelected}} valgt',
    createLabels: 'Lag etiketter',
    editShipments: 'Rediger forsendelsesinformasjon',
    moveShipments: 'Flytt til et annet avdeling',
    yourSearchResult: 'Ditt søkeresultat',
    numberOfShipmentsFound: '{{count}} forsendelser funnet'
  },
  shipmentResultActions: {
    confirmDeleteTitle: 'Slette sending?',
    confirmDuplicateTitle: 'Dupliser sending?',
    confirmDuplicateButton: 'Dupliser',
    deleteSuccess: 'Sendingen ble slettet',
    deleteFailure: 'Kunne ikke slette sendingen'
  },
  tablePagination: {
    goToPage: 'Gå til side',
    page: 'Side',
    of: 'av'
  },
  moveDepartmentModal: {
    errorMessage: 'Ordre: {{orderIds}}, feilmelding: {{error}}',
    ordersMovedText: 'Ordre {{orderIds}} har blitt flyttet til avdeling {{department}}',
    partialSuccessMessage: '{{count}} av {{fullCount}} sendinger ble flyttet',
    title: 'Flytt valgte sending(er) til en annen avdeling'
  },
  sendManualDelaySmsModal: {
    errorMessage: '{{error}} {{shipmentIds}}',
    partialSuccessMessage: '{{count}} av {{fullCount}} SMS ble sendt',
    successMessage: 'Manuell forsinkelses-SMS ble sendt vellykket'
  },
  multistopModal: {
    title: 'Gruppeinformasjon',
    description: 'Gruppevisning for bestillinger som betales sammen.',
    debitLines: 'Debetlinjer',
    notFound: 'Ingen gruppeinformasjon funnet',
    shipmentId: 'Sendingsnummer',
    deliveryDate: 'Leveringsdato',
    stopAddress: 'Stoppadresse',
    postalCode: 'Postnummer',
    noPrice: 'Ingen prisinformasjon tilgjengelig for gruppe',
    priceUpdated: 'Gruppens priser har blitt oppdatert'
  },
  shipmentDetails: {
    eventWasAdded: 'Hendelse ble lagt til',
    issueSaving: 'Det oppstod et problem med lagringen',
    noSelectedPackages: 'Ingen pakker ble valgt',
    pickupAndDelivery: 'Henting og levering',
    whereIsTheOrder: 'Hvor er bestillingen',
    communicationToRecipient: 'Kommunikasjon til mottaker',
    yesPremium: 'Ja, premium',
    alerts: 'Varsler',
    pickupDeliveryInfo: 'Hentings- og leveringsinformasjon',
    quickEdit: 'Hurtigredigering',
    quickEditTitle: 'Rediger hentings- og leveringsdetaljer',
    aboutShipment: 'Om sending',
    mapLinkText: 'Vis kartkoordinater',
    copyShipment: 'Kopier sending',
    editShipment: 'Rediger sendingsdetaljer',
    location: 'Sted',
    time: 'Tid',
    selectDate: 'Velg dato',
    manualChangeEvent: 'Manuelt endre hendelse',
    disabledEventReason: {
      returned: 'Avvik kreves',
      collected: 'Må være tildelt rute',
      default: 'Ikke tillatt'
    },
    selectDeviation: 'Velg avvik',
    required: 'Påkrevd',
    allPackages: 'Alle kolli',
    packages: 'kolli',
    package: 'kolli',
    changeDepartment: 'Endre avdeling',
    moveDepartment: 'Flytt avdeling',
    shipmentMoved: 'Sending flyttet',
    shipmentMovedText: 'Sending {{shipmentId}} er flyttet til avdeling {{department}}',
    shipmentMovedGoBack: 'Gå tilbake til søkesiden',
    moveShipmentDescription:
      'Hvis du ikke har tilgang til avdelingen, vil du ikke kunne finne sendingen etter flyttingen',
    moveShipmentConfirmText: 'Flytt sendingen til en annen avdeling?',
    addShipmentToSlot: 'Legg til sending i {{slot}}?',
    changeSlotConfirm: 'Endre rute for sending til {{slot}}?',
    changeSlot: 'Endre rute',
    selectSlot: 'Velg rute',
    AddToSlot: 'Legg til på rute',
    optimisationInProgress: 'Optimalisering pågår. Du kan ikke endre rute mens optimalisering pågår.',
    removeFromSlot: 'Fjern fra rute',
    removeShipmentFromSlot: 'Fjern sendingen fra rute',
    removeShipmentFromSlotHeading: 'Fjern sendingen fra denne ruten?',
    successRemoveFromSlot: 'Sendingen ble fjernet fra rute',
    successChangeSlot: 'Sendingen endret rute',
    successAddToSlot: 'Sendingen ble lagt til i rute',
    changeSlotDescription: 'Endring av rute utføres uten ruteoptimalisering.',
    addToSlotDescription:
      'Den valgte ruten vil bli optimalisert med de eksisterende sendingene som forblir i samme rekkefølge',
    pickupOrDeliveryChange: 'Hentingen eller leveringen er endret',
    pickupOrDeliveryChangeDescription:
      'Hentings- eller leveringsadressen er endret. Hvis du vil endre adressen tilbake, trykk på en av knappene nedenfor.',
    addressWasUnresolved: 'Adressen ble gjort uløst'
  },
  location: 'Plassering',
  scannedAtDelegatedDepartment: 'Skannet fra den delegerte avdelingen.',
  pickupOrders: {
    acceptOrders: 'Aksepter ordre',
    selected: 'valgte',
    handleOrders: 'Håndter Opphentingsordre',
    found: 'funnet',
    accept: 'Aksepter',
    reject: 'Avvis',
    noResultText: 'Vi kunne ikke finne noe resultat. Vennligst forsøk med en annen dato.',
    last7d: 'I dag + 7 forrige dager',
    rejectOptions: {
      notFound: 'Avsender ikke funnet på opphentingsadressen. Ingen flere forsøk. Opphenting stengt.',
      terminatesPickupOrder: 'Avsender avslutter opphentingsoppdrag. Ingen flere forsøk. Opphenting stengt.',
      handedOver: 'Avsender har avlevert opphentingen til en annen tranrutetør. Ingen flere forsøk. Opphenting stengt.',
      noMoreAttempts: 'Avsender har flyttet. Ingen flere forsøk. Opphenting stengt.',
      pickupCorrected: 'Avsender har flyttet. Opphenting korrigert og sendt tilbake til underleverandør.',
      goodsNotReady: 'Gods ikke klart for opphenting. Ingen flere forsøk. Opphenting stengt.',
      incorrectBooking: 'Feil bestilling. Opphenting kansellert.',
      senderRefuses: 'Avsender nekter å overlevere godset. Ingen flere forsøk. Opphenting stengt.',
      addressCorrected: 'Feil opphentingsadresse. Adressen er korrigert.',
      incorrectAddressNoMoreAttempts: 'Feil opphentingsadresse. Ingen flere forsøk. Opphenting stengt.',
      senderNotified: 'Feil opphentingsadresse. Ingen flere forsøk. Opphenting stengt.',
      forbiddenGoods: 'Forbudte varer. Ingen flere forsøk. Opphenting stengt.',
      timeFrame: 'Henting kan ikke gjøres innen tidsrammen. Hentebestillingen er sendt tilbake til bestillingskontoret.'
    },
    mustSelectReason: 'Du må velge en grunn for avslaget',
    selectRejectReason: 'Velg grunn for avslaget*',
    selectReason: 'Velg grunn',
    commentPlaceholder: 'Valgfritt - gi ekstra informasjon for avslaget',
    acceptedSuccessToast: 'ble godkjent',
    rejectedSuccessToast: 'ble avvist',
    errorToast: 'Noe gikk galt',
    rejectOrders: 'Avvis bestillinger',
    shipment: '{{count}} forsendelse',
    shipment_plural: '{{count}} forsendelser'
  },
  editShipments: {
    pickupShouldBeBeforeDelivery: 'Opphentingstidsvindu må være før leveringstidsvindu',
    pickupEarliestShouldBeBeforeLatest: 'Den tidligste opphentingstiden må være før den seneste',
    deliveryEarliestShouldBeBeforeLatest: 'Den tidligste leveringstiden må være før den seneste',
    success: 'Sendingsinformasjon ble vellykket oppdatert',
    mixedShipmentsSelected: 'Du har valgt sendinger med og uten dato. Opphenting og leveringsinformasjon er påkrevd.',
    fieldNotEditable: 'Denne informasjonen kan ikke endres for ordre med status Tildelt til rute eller Opphentet',
    bulkActionDisabledReason: 'Ordre i status Avvik, Levert, Avvist eller Returnert kan ikke redigeres',
    withoutDateDisabledReason:
      'Kombinasjonen av ordre i status Tildelt til rute eller Opphentet og uten dato kan ikke redigeres',
    pickupDateTime: 'Opphenting dato og tid',
    deliveryDateTime: 'Levering dato og tid',
    earliestPickupTime: 'Tidligste hentetidspunkt',
    latestPickupTime: 'Seneste hentetidspunkt',
    earliestDeliveryTime: 'Tidligste leveringstid',
    latestDeliveryTime: 'Seneste leveringstid',
    pickupName: 'Opphenting navn',
    pickupPhoneNumber: 'Opphenting telefonnummer',
    deliveryName: 'Levering navn',
    deliveryPhoneNumber: 'Levering telefonnummer',
    pickupInstructions: 'Henteinstruksjoner',
    deliveryInstructions: 'Leveringsinstruksjoner'
  },
  shipmentSelectColumn: {
    title: 'Tilpass kolonneoverskrift',
    addColumnHeader: 'Legg til kolonneoverskrift',
    addColumnBody: `Select the headings you want to be displayed in the data table. Our tip is to use 10-15 different
    heading in order for the information not to be cut off. The exact amount depends on the size of the
    device you are working on.`,
    orderOfTable: 'Rekkefølgen på kolonneoverskriftene',
    orderOfTableDescription: 'Velg den foretrukne rekkefølgen for tabelloverskriftene ved å dra og slippe.'
  },
  bulkActions: {
    routeReceiptList: {
      disabledTooltip: 'Bestillinger kan ikke være i tilstanden løst eller uløst'
    },
    unassignShipmentFromSlot: 'Fjern sendingene fra rute',
    unassignShipmentFromSlotDisabledReason: 'Ordrene må være i status Tildelt til rute eller Tilbudt',
    forceUnassignShipmentFromSlot: 'Tving fjerning fra rute',
    disabledMoveDepartmentTooltip: 'Ordrer må være i tilstand Opprettet, Løst, Uavklart eller Avvist for å bli flyttet',
    deleteDisableTooltip:
      'Forsendelsen kan ikke slettes hvis den er i tilstand Opprettet, Uavklart, Løst, Tilbudt eller Tildelt',
    duplicateDisableTooltip: 'Sendinger må være i tilstand Avviket eller Returnert, og avdelingstype må være Bud',
    viewGroupDisabledTooltip: 'Bestillingen må tildeles før du kan vise og redigere gruppen'
  },
  vehicle: { weightCapacity: 'Vektkapasitet', volumeCapacity: 'Volumkapasitet', maxRangeKm: 'Rekkevidde' },
  invoicingStatus: {
    INVOICING_FAILED: 'Fakturering mislyktes',
    CORRECTION_PENDING: 'Retting venter',
    INVOICING_PAUSED: 'Fakturering satt på pause',
    EXCLUDED_FROM_INVOICING: 'Ekskludert',
    NOT_READY_FOR_INVOICING: 'Ikke klar',
    INVOICED_OK: 'Fakturert OK',
    ZERO_PRICE: 'Mangelfull pris'
  },
  invoice: {
    articleCode: 'Artikkel',
    allOrdersInvoice: 'Alle ordre faktureres',
    allOrdersInvoiceDescription: 'Vi kunne ikke finne noen ufakturerte ordre.',
    handledBy: 'Håndtert av',
    invoicing: 'Fakturering',
    credit: 'Kreditt',
    invoice: 'Faktura',
    create: 'Skape',
    invoiceOrderAction: 'Fakturaordre',
    customerNumber: 'Kundenummer',
    carrierNumber: 'Enhetsnummer',
    details: 'Detaljer',
    information: 'Fakturainformasjon',
    reference: 'Fakturareferanse',
    items: 'Artiklar',
    quantity: 'Mengde',
    description: 'Beskrivelse',
    customerAmount: 'Kundebeløp',
    carrierAmount: 'Enhetsbeløp',
    totalCustomerAmount: 'Totalt kundebeløp',
    totalCarrierAmount: 'Totalt enhetsbeløp',
    invoiceDepartment: 'Fakturaavdeling',
    invoiceDepartment_plural: 'Fakturaavdelinger',
    invoiceDepartments: 'Fakturaavdeling(er)',
    dashboard: 'Dashbord',
    invoicingJobsSummary: 'Oppsummering av faktureringsjobber',
    invoicingStatus: 'Faktureringsstatus',
    invoicingStatusDescription: 'Viser order fra de siste 3 månedene.',
    notInvoicedOrders: 'Ikke fakturerte ordreer',
    ordersOnPage: 'Viser {{count}} ordre på siden',
    ordersFoundCount: '{{count}} ordre funnet',
    ordersFoundCount_plural: '{{count}} ordre funnet',
    toBeInvoiced: 'Skal faktureres',
    ordersNotInvoiced: 'ordre som ikke er fakturert ennå',
    invoicingFailed: 'Fakturering mislyktes',
    invoicingFailedDescription: 'ordre behandlet mislykket',
    text: 'Fakturatekst',
    correctionPending: 'Med ordrekorrigering',
    correctionPendingDescription: 'Ordre med ventende rettelser',
    invoicingPaused: 'Fakturering satt på pause',
    ordersPausedFromInvoiced: 'Ordre som er satt på pause fra å bli fakturert',
    invoicingZeroPrice: 'Mangelfull pris',
    invoicingZeroPriceDescription: 'Ordre med ikke-godkjente nullrader',
    sendInvoices: 'Send fakturaer',
    sendInvoicesDescription:
      'Du er i ferd med å fakturere {{avdelinger}}. Velg dato eller datoperiode for faktureringsperioden.',
    sendInvoiceDateValidationError: 'Fra-dato kan ikke være senere enn Til-dato',
    sendInvoiceDateValidationErrorOneMonth: 'Maks datospenn er 1 måned',
    invoicingInProgress: 'Fakturering for {{departmentName}} pågår, {{count}} faktureringsjobber gjenstår',
    invoicingInFinished: 'Faktureringen for {{departmentName}} er fullført',
    orderSuccessInvoice: 'Ordren ble fakturert',
    orderSuccessInvoice_plural: 'Ordre ble vellykket fakturert',
    orderSuccessNotInvoiced: 'Ordren ble endret til ikke fakturert',
    orderSuccessNotInvoiced_plural: 'ordre ble endret til ikke fakturert',
    orderFailedInvoice: 'Fakturering for valgt ordre mislyktes',
    howManyInvoices: 'Hvor mange fakturaer vil bli sendt',
    fetchAmount: 'Hent beløp',
    wantToResend: 'Denne ordreen er allerede fakturert. Er du sikker på at du vil sende den på nytt',
    invoiceSingleOrder: 'Du er i ferd med å fakturere denne ordreen. Vil du fortsette',
    pauseOrders: 'Sett ordre på pause',
    datePeriod: 'Dato periode',
    ordersWereSuccessfullyPaused: '{{count}} ordre ble midlertidig stoppet fra fakturering',
    ordersWereSuccessfullyPaused_plural: '{{count}} ordre ble midlertidig stoppet fra fakturering',
    releaseOrders: 'Frigi ordre',
    ordersWereSuccessfullyReleased: '{{count}} ordre ble frigitt for fakturering',
    ordersWereSuccessfullyReleased_plural: '{{count}} ordre ble frigitt for fakturering',
    ordersWereSuccessfullyAcceptedWithIncompletePrice: '{{count}} ordre ble aksptert med ufullstendig pris',
    ordersWereSuccessfullyAcceptedWithIncompletePrice_plural: '{{count}} ordre ble aksptert med ufullstendig pris',
    addUnit: 'Legg til enhet',
    addUnitDescription: 'Legg til en enhet til dine valgte ordre. Denne enheten er den som vil bli egenfakturert.',
    addUnitToOrders: 'Legg til enhet i ordre',
    addUnitToOrder: '{{unit}} ble lagt til i {{count}} ordre',
    addUnitToOrder_plural: '{{unit}} ble lagt til i {{count}} ordre',
    addUnitBulkDisabled: 'Enhet kan kun legges til ordre uten enhet',
    approveIncompletePrices: 'Godkjenn med manglende priser',
    manualOverrideTitle: 'Manuell overstyring av faktureringsstatus',
    manualOverrideModalQuestion:
      'Du er i ferd med å overstyre statusen til {{count}} ordre. De valgte ordreene vil få status Faktura OK. Er du sikker på at du vil fortsette?',
    unitId: 'Enhets ID',
    bannerOldInvoicePage:
      'Den 26. august vil <0>den nye faktureringssiden</0> åpnes som standard. Vi oppfordrer deg til å begynne å bruke den nye siden. Mer info om den nye siden <1>her</1>.',
    acceptIncompletePricesTitle: 'Godkjenn mangelfulle priser',
    acceptIncompletePricesModalQuestion:
      'Du er i ferd med å godkjenne {{count}} ordre med mangelfull pris. Er du sikker på at du vil fortsette?',
    acceptIncompletePricesModalQuestion_plural:
      'Du er i ferd med å godkjenne {{count}} ordre med mangelfulle priser. Er du sikker på at du vil fortsette?',
    confirmCancelDialog: {
      title: 'Du har ulagrede endringer',
      question: 'Du har ulagrede endringer. Ønsker du å fortsette?',
      abort: 'Bli på siden',
      confirm: 'Fortsett uten å lagre'
    }
  },
  live: {
    onlyDeviated: 'Vis kun ruter med avvik',
    bannerOldLivePage:
      '<0>Prøv</0> den nye Live-siden, med forbedrede funksjoner og nytt design. Vi oppfordrer deg til å bruke den nye live-siden for å venne deg til den før vi fjerner den gamle siden fullstendig.',
    shipment: 'Leveranse',
    shipmentFrom: 'Fra',
    shipmentTo: 'Til',
    confirm: 'Bekreft',
    unconfirm: 'Avkreft',
    confirmedBy: 'Bekreftet av {{name}} klokken {{time}}',
    theCourier: 'Sjåføren',
    activeRoutes: 'Aktive ruter',
    routeDeviation: 'Avvik på ruten',
    routeDeviations: 'Avvik på ruten',
    multipleDeviations: 'Flere avvik',
    recentDeviations: 'Nylige avvik',
    noRoutesFound: 'Ingen ruter funnet',
    noDeviationsFound: 'Ingen avvik funnet',
    consignmentDamage: 'Skade på leveranse',
    shipmentDeviation: 'Avvik på leveranse',
    outsideServiceTime: 'Utenfor servicevindu',
    outsideEstimatedTime: 'Utenfor estimert tidsvindu',
    deliveryLocationDeviation: 'Uventet lokasjon',
    eventTextDeviationOne: '{{name}} har et leveranseavvik',
    eventTextDeviationMany: '{{name}} har {count} leveranseavvik',
    eventTextDamageOne: '{{name}} har skader på {count} leveranser',
    eventTextDamageMany: '{{name}} har skader på en leveranse',
    eventTextServiceTimeWindow: '{{name}} leverte utenfor servicevinduet',
    eventTextEstimatedTimeWindow: '{{name}} leverte utenfor det estimerte tidsvinduet',
    eventTextDeviated: '{{name}} har avviket fra ruten sin',
    eventTextDeliveryLocationDeviation:
      '{{name}} leverte/registrerte et avvik {{distanse}} meter fra forventet lokasjon',
    eventHelpDeviation: '{{name}} har registrert et avvik på leveranse {{shipmentId}}',
    eventHelpDamage: '{{name}} har registrert en skade på leveranse {{shipmentId}}',
    filterOngoing: 'Aktive',
    filterNotStarted: 'Ikke startet',
    filterFinished: 'Vis fullførte',
    filterOnlyDeviated: 'Vis kun ruter med avvik',
    filterShipmentDeviations: 'Avvik på leveranse',
    filterShipmentDamage: 'Skade på leveranse',
    filterEstimatedTime: 'Estimert tid',
    filterServiceTime: 'Servicetid',
    filterRouteDeviations: 'Avvik på ruten',
    filterDeliveryLocationDeviation: 'Uventet lokasjon',
    filterConfirmed: 'Bekreftet',
    routeProgress: 'Fullført {{complete}} av {{total}}',
    routeDuration: 'Fra {{from}} til {{to}}',
    routeDelayed: 'Forsinket',
    routeAhead: 'Foran',
    routeOnTime: 'Tidsnok',
    routeUnknown: 'Ukjent',
    routeDeviationsNone: 'Ingen avvik',
    routeDeviationCount: '{{unhandledDeviations}} av {{totalDeviations}}',
    routeHasHandleDeviations: 'Ruten har {{count}} håndtert avvik',
    routeHasHandleDeviations_plural: 'Ruten har {{count}} håndterte avvik',
    openRoute: 'Se rute',
    openShipment: 'Se leveranse',
    viewRoute: 'Se rute',
    deviated: 'Avvik',
    offRoute: 'Følger ikke ruten',
    onRoute: 'Følger ruten',
    routeStateFilter: 'Rutestatusfilter',
    allRoutesStates: 'Alle rutestatuser',
    selectedRouteStates: '{{count}} rutestatus',
    selectedRouteStates_plural: '{{count}} rutestatuser',
    noSelectedStates: 'Ingen valgte statuser',
    allDeviationTypes: 'Alle avvikstyper',
    selectedDeviationTypes: '{{count}} avvikstype',
    selectedDeviationTypes_plural: '{{count}} avvikstyper',
    showHandledDeviations: 'Vis håndterte avvik ({{count}})',
    noSelectedTypes: 'Ingen valgte typer',
    viewShipment: 'Se leveranse',
    selectAllRouteStates: 'Velg alle rutestatuser',
    noRoutesWithDeviations: 'Ingen ruter tilgjengelig',
    selectAllDeviationTypes: 'Velg alle avvikstyper',
    noDeviationOnRoute: 'Ingen avvik på ruten',
    noDeviationAvailable: 'Ingen ruter tilgjengelig',
    noDeviationsOnFilter: 'Det er ingen tilgjengelige avvik på det valgte filteret. Prøv et annet filter.',
    noRoutesOnFilter: 'Det er ingen tilgjengelige ruter på det valgte filteret. Prøv et annet filter.',
    eventHelpServiceTimeWindow:
      '{{name}} har levert leveranse {{shipmentId}} klokken {{actual}}, som er utenfor servicevinduet {{earliest}}-{{latest}}.',
    eventHelpEstimatedTimeWindow:
      '{{name}} har levert leveranse {{shipmentId}} klokken {{actual}}, som er utenfor det estimerte tidsvinduet {{earliest}}-{{latest}}.',
    eventHelpDriverDeviated: '{{name}} har levert leveranse {{shipmentId}} i feil rekkefølge.',
    eventHelpDriverDeviatedBefore:
      '{{name}} har levert leveranse {{shipmentId}} i feil rekkefølge ved å levere #{{actual}} før #{{expected}}.',
    eventHelpDriverDeviatedAfter:
      '{{name}} har levert leveranse {{shipmentId}} i feil rekkefølge ved å levere #{{actual}} etter #{{expected}}.',
    eventHelpDeliveryLocationDeviation: {
      withShipmentId:
        '{{name}} har levert eller avviket leveranse {{shipmentId}} <4>her</4>, {{distance}} meter unna <9>forventet lokasjon</9>.',
      withoutShipmentId:
        '{{name}} har levert eller avviket <4>her</4>, {{distance}} meter unna <9>forventet lokasjon</9>.'
    },
    search: 'Søk etter rute, enhet eller sjåfør',
    departmentFilter: 'Avdelingsfilter',
    allDepartments: 'Alle avdelinger',
    deviationTypeFilter: 'Avvikstypefilter',
    mostDeviations: 'Flest avvik',
    latestInTime: 'Senest på tid',
    earliestInTime: 'Tidligst på tid',
    sortOn: 'Sorter på',
    unhandled: 'Uhåndterte',
    deviations: 'avvik'
  },
  userRolesAdmin: {
    userRoles: 'Brukerroller',
    successCreateMessage: '{{name}} ble opprettet',
    successUpdateMessage: '{{name}} ble oppdatert',
    saveRole: 'Lagre rolle',
    editRole: 'Rediger {{name}}',
    createNewRole: 'Opprett ny rolle',
    nameOfRole: 'Rollenavn',
    nameRequired: 'Navn er påkrevd',
    descriptionRequired: 'Beskrivelse er påkrevd',
    descriptionLabel: 'Beskrivelse (maks 240 tegn)',
    iconLabel: 'Velg hvilket ikon du ønsker for brukerrollen',
    selectRoleTitle: 'Velg tilgangsrett for rolle',
    emptyState: 'Ingen roller funnet for søkefrasen',
    deleteConfirm: 'Er du sikker på at du vil slette {{name}}?',
    deleteSuccess: 'Rolle {{name}} ble slettet',
    settings: 'Innstillinger',
    accessRights: 'Tilgangsrettigheter',
    settingsTitle: 'Innstillinger for rolle',
    settingsDescription: 'Engelsk er påkrevd. Hvis ingen andre språk velges, vil engelsk være reserve.',
    additionalLanguagesTitle: 'Legg til ekstra språk',
    customerPortalAccess: 'Tilgang til kundeportal',
    defaultForUserRole: 'Bruk som standardrolle (valgfritt)'
  },
  validationError: {
    provideEmail: 'Du må oppgi en e-post',
    provideValidEmail: 'Du må oppgi en gyldig e-post',
    youMustSelectCustomer: 'Du må velge en kunde'
  },
  apolloAdmin: {
    title: 'Integrasjoner',
    emptyTitle: 'Ingen anrop',
    emptyDescription: 'Velg datoen du vil se og klikk på søk.',
    reqType: 'Type anrop',
    routeId: 'Rute-ID',
    routeName: 'Rutenavn'
  }
}
export default nb
