import { Map } from 'immutable'
import { OrderIdType, OrderPreadvice } from '../types/coreEntitiesTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { createImmutableEqualSelector } from './createImmutableEqualSelector'

export const preAdviceForOrderSelector: (state: AppStateType, orderId: OrderIdType) => OrderPreadvice | null =
  createImmutableEqualSelector(
    (state: AppStateType) =>
      (state.getIn(['entities', 'consignmentPreAdvices']) as Map<number, OrderPreadvice>) || Map(),
    (state: AppStateType, orderId: OrderIdType) => orderId,
    (preAdvices: Map<number, OrderPreadvice>, orderId: OrderIdType) =>
      preAdvices.find((pas) => pas.get('orderId') === orderId) || null
  )
