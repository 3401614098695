import { getTrackingPageBaseUrl } from '@glow/common'
import i18next from 'i18next'
import { Collection, List, Map, OrderedSet, Seq, Set } from 'immutable'
import { DateTime } from 'luxon'
import React from 'react'
import { connect } from 'react-redux'
// @ts-expect-error
import { Control, Errors, Form } from 'react-redux-form/lib/immutable'
import {
  ADD_CONSIGNMENT_TO_SLOT_AND_REOPTIMIZE,
  CREATE_LABEL,
  GET_LATEST_CONSIGNMENT_EVENT_ACTIONS,
  LOCK_OR_UNLOCK_PRE_ADVICE,
  MOVE_ORDERS,
  QUICK_EDIT_SHIPMENT,
  SET_CONSIGNMENT_SLOT,
  UNASSIGN_CONSIGNMENT_FROM_SLOT
} from '../actions/actionTypes'
import { getAirExpressOrderById } from '../actions/creators/airexpressHelpers'
import { getLatestConsignmentEventActions } from '../actions/creators/consignmentEventHelpers'
import { mergeFormValues } from '../actions/creators/formHelpers'
import {
  addConsignmentsToSlotAndReoptimize,
  changeSlotForConsignments,
  closeModal,
  getAllDepartmentsWithAdminLevelAccess,
  getOrderDeviationsByOrderId,
  getPreAdviceForOrder,
  getSlotById,
  getSlots,
  moveOrders,
  setPageStateValue,
  unassignConsignmentsFromSlot,
  unresolveDeliveryAddress,
  unresolvePickupAddress
} from '../actions/creators/helpers'
import { getOrderById } from '../actions/creators/queryHelpers'
import { DeviationOrderPropsType } from '../pages/admin/orderDeviationTypes'
import { consignmentEventsForShipmentIdByShipmentId } from '../pages/instant/InstantShipmentDetails'
import { sortByName } from '../pages/instant/bookingOrderFunctions'
import { ExpansionPanelProps } from '../primitives/Accordion'
import { TertiaryButton } from '../primitives/Button'
import ErrorContainer from '../primitives/ErrorContainer'
import { ValidationError } from '../primitives/ErrorMessages'
import { H3 } from '../primitives/Headings'
import ImmutableComponent from '../primitives/ImmutableComponent'
import Overlay from '../primitives/Overlay'
import Select from '../primitives/Select'
import { consignmentIdToPackageIdSelector } from '../selectors/consignmentSelectors'
import { createImmutableEqualSelector } from '../selectors/createImmutableEqualSelector'
import {
  consignmentContainsDeviationInEventsSelector,
  customerOrderDeviationsIdSelector
} from '../selectors/customerOrderDeviationsSelector'
import { customerByIdSelector } from '../selectors/customerSelector'
import { allDepartmentsSelector, departmentFromIdSelector, departmentsSelector } from '../selectors/departmentsSelector'
import { errorMessagesFor } from '../selectors/httpStatusSelectors'
import { IOptimizeJobResult, optimizeJobsResultsByDepartmentIdSelector } from '../selectors/optimizeJobResultsSelector'
import { orderNoteFromOrderIdSelector } from '../selectors/orderNoteSelectors'
import { orderFromIdSelector } from '../selectors/orderSelector'
import { preAdviceForOrderSelector } from '../selectors/preAdviceSelectors'
import { routePointsForConsignmentIdSelector } from '../selectors/routePointsSelector'
import { servicesAndVasesTextsPerCodeSelector } from '../selectors/servicesSelectors'
import { shipmentByIdSelector } from '../selectors/shipmentSelectors'
import { slotsByDateAndDepartmentIdSelector } from '../selectors/slotSelectors'
import {
  ConsignmentEvent,
  ConsignmentIdType,
  Department,
  DepartmentIdType,
  Order,
  OrderIdType,
  OrderNote,
  OrderPreadvice,
  PackageIdType,
  RoutePoint,
  Shipment,
  Slot,
  SlotIdType,
  Unit,
  isHdDepartment,
  orderTypeSupportsPreAdvice
} from '../types/coreEntitiesTypes'
import { ImmutableMap } from '../types/immutableTypes'
import { AppStateType } from '../utils/appStateReduxStore'
import { concat, isEmpty, isNotEmpty } from '../utils/collectionUtils'
import { isTodayOrTomorrow } from '../utils/dateTime'
import { DepartmentType } from '../utils/departmentAndDepartmentGroupUtils'
import { isAdminUser } from '../utils/roles'
import { GroupedServicesAndVasesTexts } from '../utils/serviceUtils'
import { getShipmentConsignmentEvents } from '../utils/shipmentsUtils'
import { Events } from './Events'
import LockPreAdvice from './LockPreAdvice'
import { sortDepartmentsAccessToFirst } from './MoveShipmentsToAnotherDepartmentModal'
import { ShipmentChangeSlot } from './ShipmentChangeSlot'
import ShipmentLabel from './ShipmentLabel'
import { ShipmentModalCloseButton } from './ShipmentModalCloseButton'
import { MainArea, ShipmentModalContainer } from './ShipmentModalStyles'
import { NewShipmentModalContainer } from './newShipmentModal/NewShipmentModalContainer'
import { ManualOverride } from './newShipmentModal/panels/ManualOverride'
import { ShipmentMap } from './newShipmentModal/panels/ShipmentMap'
import { BookingTimeWindow } from './newShipmentModal/panels/bookingTimeWindow/BookingTimeWindow'
import { Consignments } from './newShipmentModal/panels/consignments/Consignments'
import { ShipmentModalAccordion } from './newShipmentModal/shared/ShipmentModalAccordion'

interface MoveShipmentProps {
  moveOrders: (departmentId: DepartmentIdType) => void
  moveOrderDepartments: List<Department>
  moveOrderGroupedDepartments?: List<Collection<number, Department>>
  orderIds: List<OrderIdType>
  showDialogBox: boolean
  mergeFormValues: (formName: string, values: Map<string, any>) => void
}

export interface IMoveOrderFormProps {
  departmentId: DepartmentIdType
}

export interface IMoveOrderForm extends ImmutableMap<IMoveOrderFormProps> {}

export class MoveShipmentToAnotherDepartment extends ImmutableComponent<MoveShipmentProps> {
  componentDidMount(): void {
    this.props.mergeFormValues('moveOrderToAnotherDepartmentForm', Map({ departmentId: '' }))
  }

  onSubmit(value: IMoveOrderForm) {
    if (this.props.showDialogBox && !confirm(i18next.t('planner.moveOrder'))) return
    this.props.moveOrders(value.get('departmentId'))
  }

  render() {
    if (
      !this.props.moveOrders ||
      isEmpty(this.props.orderIds) ||
      (isEmpty(this.props.moveOrderDepartments) && isEmpty(this.props.moveOrderGroupedDepartments))
    )
      return null

    const { moveOrderDepartments, moveOrderGroupedDepartments } = this.props

    return (
      <Form model="moveOrderToAnotherDepartmentForm" onSubmit={(v: IMoveOrderForm) => this.onSubmit(v)}>
        <H3>{i18next.t('consignment.moveOrderTitle')}</H3>
        <div style={{ marginBottom: '2rem' }}>
          <Select>
            <Control.select model=".departmentId">
              <option key={0} value="">
                {i18next.t('consignment.moveOrderDefault')}
              </option>
              {(moveOrderDepartments &&
                isNotEmpty(moveOrderDepartments) &&
                moveOrderDepartments.sortBy((it) => it.get('name')).map(deptElement)) || (
                <>
                  {moveOrderGroupedDepartments &&
                    moveOrderGroupedDepartments.map((deptGroup, i) => (
                      <optgroup key={i} label="–––––––––––––––––">
                        <>{deptGroup.sortBy((it) => it.get('name')).map(deptElement)}</>
                      </optgroup>
                    ))}
                </>
              )}
            </Control.select>
            <Errors
              className="errors"
              model=".departmentId"
              show="touched"
              wrapper={ValidationError}
              messages={{ required: `${i18next.t('application.required')}` }}
            />
          </Select>
          <TertiaryButton marginLeft type="submit">
            {i18next.t('consignment.moveOrder')}
          </TertiaryButton>
        </div>
      </Form>
    )
  }
}

function deptElement(dept: Map<string, any>) {
  const departmentId = dept.get('id')
  return (
    <option key={`department-${departmentId}`} value={departmentId}>
      {dept.get('name')}
    </option>
  )
}

class ShipmentEditModal extends ImmutableComponent<Props> {
  componentDidMount(): void {
    const orderId = this.props.id
    if (orderId) {
      const getOrderFunction =
        this.props.department.get('departmentType') == DepartmentType.AirExpress
          ? this.props.getAirExpressOrderById
          : this.props.getOrderById

      getOrderFunction(orderId)
      this.props.getPreAdviceForOrder(orderId)
      this.loadData(this.props)
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    this.loadData(this.props, prevProps)
  }

  loadData(props: Props, prevProps?: Props) {
    const orderId = props.id
    if (
      orderId &&
      (orderId !== prevProps?.id || this.props.containsDeviationInEvents !== prevProps?.containsDeviationInEvents)
    ) {
      props.containsDeviationInEvents && props.getOrderDeviationsByOrderId(orderId)
    }

    props.getAllDepartmentsWithAdminLevelAccess()
    const departmentId = props.shipment.get('departmentId')
    if (departmentId && departmentId !== prevProps?.shipment.get('departmentId')) {
      if (isTodayOrTomorrow(props.currentDate)) {
        props.getSlots(
          departmentId,
          props.currentDate.toISODate(),
          props.currentDate.plus({ days: 1 }).toISODate(),
          true,
          true
        )
      }
    }

    const slotId = props.slotId
    if (slotId && slotId !== prevProps?.slotId) {
      props.getLatestConsignmentEventActions(Set([slotId]))
      props.getSlotById(slotId, true)
    }
  }

  closeModal(editingShipmentModal: boolean) {
    const closeFunction = this.props.ownCloseModal || this.props.closeModal
    if (!editingShipmentModal || (editingShipmentModal && confirm(i18next.t('shipment.closeWhileEditing')))) {
      closeFunction()
    }
  }

  handleMoveOrders = (departmentId: DepartmentIdType) => {
    this.props.moveOrders(
      List([this.props.shipment.get('orderId')]),
      departmentId,
      this.props.shipment.get('consignmentIds').toList()
    )
  }

  render() {
    const {
      availableSlots = List(),
      errorMessages,
      shipment,
      events,
      unit,
      slot,
      customerOrderDeviations,
      internalNote,
      consignmentIdToPackageId,
      editingShipmentModal,
      department,
      shipmentRoutePoints,
      mawbNo,
      awbNo
    } = this.props

    if (!this.props.shipmentId || isEmpty(shipment)) {
      return null
    }

    const consignmentEvents = getShipmentConsignmentEvents(shipment, events)

    return (
      <>
        <Overlay onClick={() => this.closeModal(editingShipmentModal)} />
        <ShipmentModalContainer>
          <MainArea>
            {errorMessages && !errorMessages.isEmpty() && (
              <ErrorContainer center style={{ marginBottom: '1em' }}>
                {errorMessages.map((msg: string) => (
                  <>
                    {msg}
                    <br />
                  </>
                ))}
              </ErrorContainer>
            )}
            <NewShipmentModalContainer
              shipment={shipment}
              slot={slot}
              unit={unit}
              allTexts={this.props.allTexts}
              trackingBaseUrl={this.props.deliveryPageBaseUrl}
              internalNote={internalNote}
              enableQuickEdit={true}
              routePoints={shipmentRoutePoints}
              department={department}
              mawbNo={mawbNo}
              awbNo={awbNo}
            />
            <ShipmentLabel label={shipment.get('label')} orderId={shipment.get('orderId')} />
            <ShipmentChangeSlot
              slot={slot}
              availableSlots={availableSlots}
              shipment={shipment}
              changeSlotForConsignments={this.props.changeSlotForConsignments}
              optimizeJobResults={this.props.optimizeJobResults}
              addConsignmentsToSlotAndReoptimize={this.props.addConsignmentsToSlotAndReoptimize}
              unassignConsignmentsFromSlot={this.props.unassignConsignmentsFromSlot}
            />

            <MoveShipmentToAnotherDepartment
              moveOrders={this.handleMoveOrders}
              moveOrderDepartments={this.props.moveOrderDepartments}
              moveOrderGroupedDepartments={this.props.moveOrderGroupedDepartments}
              orderIds={List([shipment.get('orderId')])}
              showDialogBox={true}
              mergeFormValues={this.props.mergeFormValues}
            />
            {this.props.preAdviceStatus && this.props.preAdviceStatus.get('hasPreAdviceSetting') && (
              <LockPreAdvice preAdviceStatus={this.props.preAdviceStatus} orderId={shipment.get('orderId')} />
            )}
          </MainArea>
          <ShipmentModalAccordion
            expansionPanels={expansionPanelsSelector(
              shipment,
              consignmentEvents,
              customerOrderDeviations,
              consignmentIdToPackageId,
              this.props.department,
              this.props.departmentOrGroupId,
              this.props.departmentsForUser
            )}
          />
          <ShipmentModalCloseButton onClick={() => this.closeModal(editingShipmentModal)} />
        </ShipmentModalContainer>
      </>
    )
  }
}

export const expansionPanelsSelector: (
  shipment: Shipment,
  events: List<List<ConsignmentEvent>>,
  customerDeviations: Map<number, DeviationOrderPropsType>,
  consignmentIdToPackageId: Seq.Keyed<ConsignmentIdType, PackageIdType>,
  department: Department,
  departmentOrGroupId: string | undefined,
  departmentsForUser: List<Department>
) => List<ExpansionPanelProps> = createImmutableEqualSelector(
  (shipment: Shipment) => shipment,
  (shipment: Shipment, events: List<List<ConsignmentEvent>>) => events,
  (
    shipment: Shipment,
    events: List<List<ConsignmentEvent>>,
    customerDeviations: Map<number, DeviationOrderPropsType>
  ) => customerDeviations,
  (
    shipment: Shipment,
    events: List<List<ConsignmentEvent>>,
    customerDeviations: Map<number, DeviationOrderPropsType>,
    consignmentIdToPackageId: Seq.Keyed<ConsignmentIdType, PackageIdType>
  ) => consignmentIdToPackageId,
  (
    shipment: Shipment,
    events: List<List<ConsignmentEvent>>,
    customerDeviations: Map<number, DeviationOrderPropsType>,
    consignmentIdToPackageId: Seq.Keyed<ConsignmentIdType, PackageIdType>,
    department: Department
  ) => department,
  (
    shipment: Shipment,
    events: List<List<ConsignmentEvent>>,
    customerDeviations: Map<number, DeviationOrderPropsType>,
    consignmentIdToPackageId: Seq.Keyed<ConsignmentIdType, PackageIdType>,
    department: Department,
    departmentOrGroupId: string | undefined
  ) => departmentOrGroupId,
  (
    shipment: Shipment,
    events: List<List<ConsignmentEvent>>,
    customerDeviations: Map<number, DeviationOrderPropsType>,
    consignmentIdToPackageId: Seq.Keyed<ConsignmentIdType, PackageIdType>,
    department: Department,
    departmentOrGroupId: string | undefined,
    departmentsForUser: List<Department>
  ) => departmentsForUser,
  (
    shipment,
    events,
    customerDeviations,
    consignmentIdToPackageId,
    department,
    departmentOrGroupId,
    departmentsForUser
  ) =>
    List<ExpansionPanelProps>([
      {
        header: i18next.t('consignment.events'),
        expandedOnLoad: true,
        content: (
          <Events
            shipmentConsignmentEvents={events}
            customerDeviations={customerDeviations}
            orderType={shipment.get('type')}
          />
        )
      },
      {
        header: i18next.t('consignment.consignmentDetails'),
        content: (
          <Consignments consignments={shipment.get('consignments')} consignmentsCount={shipment.get('noOfPackages')} />
        )
      },
      {
        hidden: !isHdDepartment(department) && !orderTypeSupportsPreAdvice(shipment.get('type')),
        header: i18next.t('consignment.bookingPanelTitle'),
        content: <BookingTimeWindow shipment={shipment} />
      },
      {
        header: i18next.t('consignment.mapPanelTitle'),
        content: <ShipmentMap allowUnresolving shipment={shipment} departmentOrGroupId={departmentOrGroupId} />
      },
      {
        header: i18next.t('consignment.manualOverrideTitle'),
        content: (
          <ManualOverride
            consignmentIdToPackageId={consignmentIdToPackageId}
            shipmentState={shipment.get('state')}
            orderIds={OrderedSet.of(shipment.get('orderId'))}
            departmentIds={List([department.get('id')])}
            departments={departmentsForUser}
          />
        )
      }
    ])
)

interface StateProps {
  id: number | undefined
  slotId: SlotIdType | null
  shipment: Shipment
  shipmentRoutePoints?: List<RoutePoint>
  department: Department
  events: Map<ConsignmentIdType, List<ConsignmentEvent>>
  slot: Slot
  unit: Unit
  availableSlots: List<Slot>
  optimizeJobResults: Collection<number, IOptimizeJobResult>
  errorMessages: any
  moveOrderDepartments: List<Department>
  moveOrderGroupedDepartments: List<Collection<number, Department>>
  ownCloseModal?: () => void
  allTexts: GroupedServicesAndVasesTexts
  deliveryPageBaseUrl: string
  containsDeviationInEvents: boolean
  customerOrderDeviations: Map<number, DeviationOrderPropsType>
  currentDate: DateTime
  internalNote?: OrderNote
  consignmentIdToPackageId: Seq.Keyed<ConsignmentIdType, PackageIdType>
  editingShipmentModal: boolean
  departmentsForUser: List<Department>
  mawbNo?: string
  awbNo?: string
  preAdviceStatus: OrderPreadvice | null
}

interface DispatchProps {
  setPageStateValue: (key: string, value: any) => void
  closeModal: (keepState?: boolean) => void
  moveOrders: (
    orderIds: List<OrderIdType>,
    departmentId: DepartmentIdType,
    consignmentIds: List<ConsignmentIdType>
  ) => void
  unassignConsignmentsFromSlot: (slotId: number, consignmentIds: Set<number>) => void
  addConsignmentsToSlotAndReoptimize: (value: Map<string, any>, consignmentIds: Set<number>) => void
  changeSlotForConsignments: (value: Map<string, any>, fromSlotId: number, consignmentIds: Set<number>) => void
  getOrderById: (id: OrderIdType) => any
  getAirExpressOrderById: (id: OrderIdType) => any
  getSlotById: (id: SlotIdType, shallowFetch: boolean) => void
  getAllDepartmentsWithAdminLevelAccess: () => void
  mergeFormValues: (formName: string, values: Map<string, any>) => void
  getOrderDeviationsByOrderId: (orderId: OrderIdType) => void
  getSlots: (
    departmentId: DepartmentIdType,
    fromDate: string,
    toDate: string,
    shallowFetch: boolean,
    excludeRoutePoints: boolean
  ) => void
  unresolveDeliveryAddress: (orderId: OrderIdType, departmentId?: DepartmentIdType) => void
  unresolvePickupAddress: (orderId: OrderIdType, departmentId?: DepartmentIdType) => void
  getLatestConsignmentEventActions: (slotIds: Set<SlotIdType>) => void
  getPreAdviceForOrder: (orderId: OrderIdType) => void
}

interface OwnProps {
  shipmentId?: OrderIdType | undefined
  closeModal?: () => void
  departmentOrGroupId?: string
}

type Props = StateProps & DispatchProps & OwnProps

export default connect<StateProps, DispatchProps, OwnProps, AppStateType>(
  (state, ownProps): StateProps => {
    const shipmentId = ownProps.shipmentId
    const shipment = shipmentId ? shipmentByIdSelector(state, shipmentId) : (Map() as Shipment)
    const order = shipmentId ? orderFromIdSelector(state, shipmentId) : (Map() as Order)
    const mawbNo = order?.get('mawbNo') as string
    const awbNo = order?.get('awbNo') as string
    const preAdviceStatus = shipmentId ? preAdviceForOrderSelector(state, shipmentId) : null
    const slotId = shipment.get('slotId')
    const events =
      (shipmentId && consignmentEventsForShipmentIdByShipmentId(state, shipmentId)) ||
      Map<ConsignmentIdType, List<ConsignmentEvent>>()
    const slot = slotId ? (state.getIn(['entities', 'slots', slotId], Map()) as Slot) : (Map() as Slot)
    const unit = slot ? (state.getIn(['entities', 'units', slot.get('courierId')], Map()) as Unit) : (Map() as Unit)
    const departmentId = shipment.get('departmentId')
    const department = departmentFromIdSelector(state, departmentId)
    const allDepartments = allDepartmentsSelector(state)
    const departmentsForUser = departmentsSelector(state)

    const isAdmin = isAdminUser(state)
    const departments = isAdmin ? departmentsForUser.sort(sortByName) : List<Department>()
    const groupedDepartments = isAdmin
      ? List<List<Department>>()
      : sortDepartmentsAccessToFirst(departmentsForUser, allDepartments)

    const currentDate = shipment.get('pickupTimeEarliestCached')
    const optimizeJobResults = optimizeJobsResultsByDepartmentIdSelector(state, departmentId)
    const allTexts = servicesAndVasesTextsPerCodeSelector(state)
    const customer = customerByIdSelector(state, shipment.get('customerId'))

    const deliveryPageBaseUrl = getTrackingPageBaseUrl(
      customer?.get('trackingType'),
      department.get('departmentType'),
      shipment.get('type'),
      shipment.get('deliveryCountry')
    )

    const slots = (
      currentDate ? slotsByDateAndDepartmentIdSelector(state, departmentId, currentDate) : List()
    ) as List<Slot>
    const errorMessages = concat(
      errorMessagesFor(state, SET_CONSIGNMENT_SLOT),
      errorMessagesFor(state, UNASSIGN_CONSIGNMENT_FROM_SLOT),
      errorMessagesFor(state, MOVE_ORDERS),
      errorMessagesFor(state, ADD_CONSIGNMENT_TO_SLOT_AND_REOPTIMIZE),
      errorMessagesFor(state, CREATE_LABEL),
      errorMessagesFor(state, QUICK_EDIT_SHIPMENT),
      errorMessagesFor(state, GET_LATEST_CONSIGNMENT_EVENT_ACTIONS),
      errorMessagesFor(state, LOCK_OR_UNLOCK_PRE_ADVICE)
    )
    const containsDeviationInEvents = consignmentContainsDeviationInEventsSelector(events)
    const customerOrderDeviations =
      (containsDeviationInEvents && customerOrderDeviationsIdSelector(state, shipment.get('customerId'))) ||
      Map<number, DeviationOrderPropsType>()

    const internalNote = orderNoteFromOrderIdSelector(state, shipment.get('orderId'))
    const consignmentIdToPackageId = consignmentIdToPackageIdSelector(state, shipment.get('consignmentIds', Set()))

    const consignmentIds = shipment.get('consignmentIds', Set())
    const shipmentRoutePoints = routePointsForConsignmentIdSelector(state, consignmentIds.first())

    return {
      id: shipmentId,
      slotId,
      mawbNo,
      awbNo,
      shipment,
      department,
      events,
      slot,
      unit,
      availableSlots: slots,
      optimizeJobResults,
      errorMessages,
      deliveryPageBaseUrl,
      moveOrderDepartments: departments,
      moveOrderGroupedDepartments: groupedDepartments,
      ownCloseModal: ownProps.closeModal,
      allTexts,
      containsDeviationInEvents,
      customerOrderDeviations,
      currentDate,
      internalNote,
      consignmentIdToPackageId,
      shipmentRoutePoints,
      editingShipmentModal: state.getIn(['pageState', 'editingShipmentModal'], false),
      departmentsForUser,
      preAdviceStatus
    }
  },
  {
    setPageStateValue,
    closeModal,
    moveOrders,
    unassignConsignmentsFromSlot,
    addConsignmentsToSlotAndReoptimize,
    changeSlotForConsignments,
    getOrderById,
    getAirExpressOrderById,
    getSlotById,
    getSlots,
    getAllDepartmentsWithAdminLevelAccess,
    getOrderDeviationsByOrderId,
    mergeFormValues,
    unresolveDeliveryAddress,
    unresolvePickupAddress,
    getLatestConsignmentEventActions,
    getPreAdviceForOrder
  }
)(ShipmentEditModal)
