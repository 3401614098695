import { isBring, isRunningTests, useSetBackgroundColor } from '@glow/common'
import { siteAnalyticsTrackPageViewDynamically } from '@glow/instrumentation'
import { ErrorBoundary } from '@glow/molecule-components'
import i18n from 'i18next'
import React, { Suspense, useEffect, useRef } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { HelmetProvider } from 'react-helmet-async'
import { I18nextProvider } from 'react-i18next'
import { Provider, useSelector } from 'react-redux'
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom'
import { ROUTER_LOCATION_CHANGE_ACTION } from '../actions/actionTypes'
import { AppMenuProvider } from '../contexts/AppMenuContext'
import { ToastProvider } from '../contexts/ToastContext'
import { useAppDispatch } from '../reducers/redux-hooks'
import { store } from '../reducers/store'
import { GlobalStyle } from '../styles/global'
import { AppStateType } from '../utils/appStateReduxStore'
import { getCustomerPortalLink } from '../utils/customerPortalUtils'
import { isAuthPath, isOpenPath } from '../utils/loginUtils'
import { ROLE_CUSTOMER } from '../utils/roles'
import { AppRoutes } from './AppRoutes'
import { MetaTags } from './meta-tags'

export function App() {
  useSetBackgroundColor()

  return (
    <DndProvider backend={HTML5Backend}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <BrowserRouter>
            <Suspense fallback={null}>
              <LocationObserver>
                <AuthRedirector>
                  <HelmetProvider>
                    <MetaTags />
                    <ErrorBoundary
                      isRunningTests={isRunningTests()}
                      title={i18n.t('error.title')}
                      buttonText={i18n.t('error.errorClickToReload')}
                    >
                      <ToastProvider>
                        <AppMenuProvider>
                          <AppRoutes />
                        </AppMenuProvider>
                      </ToastProvider>
                    </ErrorBoundary>
                  </HelmetProvider>
                </AuthRedirector>
              </LocationObserver>
            </Suspense>
            <GlobalStyle />
          </BrowserRouter>
        </Provider>
      </I18nextProvider>
    </DndProvider>
  )
}

const LocationObserver = ({ children }: { children: any }) => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const previousLocation = useRef('')

  useEffect(() => {
    dispatch({ type: ROUTER_LOCATION_CHANGE_ACTION })
  }, [location.pathname])

  useEffect(() => {
    const url = window.location.origin + location.pathname + location.search
    siteAnalyticsTrackPageViewDynamically({
      pageUrl: url,
      pageTitle: document.title,
      previousUrl: previousLocation.current
    })
    previousLocation.current = url
  }, [location.pathname])

  return children
}

const AuthRedirector = ({ children }: { children: any }) => {
  const user = useSelector((state: AppStateType) => state.get('user'))
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (
      isBring() &&
      user.get('role') === ROLE_CUSTOMER &&
      location.pathname.indexOf('/customer') === -1 &&
      !isOpenPath()
    ) {
      window.location.href = getCustomerPortalLink()
    } else if (user.get('requiresEmail') && !isAuthPath()) {
      navigate('/auth/set-email')
    } else if (user.get('requiresPwd') && !isAuthPath()) {
      navigate('/auth/request-password')
    }
  }, [user.get('requiresEmail'), user.get('requiresPwd'), user.get('role'), location.pathname])

  return children
}
